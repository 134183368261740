<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        src="@/assets/btn_close.png"
        alt=""
      />
       <div class="top">
           <img src="@/assets/icons/image_cross.png" width="80" height="55" alt="">
           <div class="crossSucess mt-6">{{$t('crossSucess')}}</div>
           <div class="receive mt-4">{{$t('receiving_address')}}</div>
             <div class="title-12">{{ this.uptickAddress }}</div>
        <div class="title-12">{{ this.BscAddress }}</div>
        <!-- <h3 class="end">{{$t('popupSoldoutEndsale')}}</h3>
        <h3 class="content">8888</h3> -->
      </div>
      <div class="footer">
        <button class="sub" :class="{'sub-dis': isPay}" submit @click="subCommit">{{$t('conversion')}}</button>
      </div> 
    </v-card>
    <uComponents  ref="ucom"></uComponents>
  </div>
</template>
<script>
import api from "@/api";
	import {
		getAddress
	} from "../../keplr/wallet";
export default {
  data: () => ({
    newOpen: true,
  nftName:"",
  isShowFalse: false,
    isPay: false,
    isShowSuccess: false,
    uptickAddress:'',
    BscAddress:''
  
  }),
     props: {
   
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  
  async mounted() {
  
    let accountInfo = await getAddress(0)
     this.uptickAddress = accountInfo.address

       // 获取BSC Address
     let param = { did: this.$store.state.did };
        let myInfo = await api.home.getMyInfo(param);
         this.BscAddress = myInfo.data.bscAddress
      
  },
  methods: {
    //提交转送数据
    async subCommit() {
     // this.$emit("getOpen", false);
     try {
       console.log('ssss');
       
     } catch (error) {
       console.log('error,error',error);
     }
    
    },
    //关闭弹框，发送open值
    closeDialog() {
      this.open = false;
      this.$emit("getOpen", this.open);
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  min-height: 280px;
  width: 750px;

  .top {
    display: flex;
    flex-direction: column;
        justify-content: center;
    align-items: center;
    margin: 45px 50px 0;
    .crossSucess{
        font-family: Helvetica;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            line-height: 20px;
            letter-spacing: 0px;
            color: #270645;
            text-align: center;
    }
    .receive{
            font-family: Helvetica;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            line-height: 20px;
            letter-spacing: 0px;
            color: #270645;
    }
    .title-12{
        font-family: Helvetica;
        font-size: 12px;
        font-weight: normal;
        letter-spacing: 0px;
        color: #766983;
    }

    .end {
      margin: 0 auto 13px;
      width: 100%;
      height: 21px;
      text-align: center;
     font-family:Helvetica;
      font-size: 23px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      white-space: nowrap;
    }

    .content {
      margin: 19px auto 25px;
      width: 226px;
      min-height: 22px;
      font-family:Helvetica;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      text-align: center;
    }
  }

  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }

  .footer {
    margin:0 auto;

    .sub {
      margin: 30px auto;
      width: 450px;
      height: 51px;
   	background-color: #7800f4;
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
     font-family:Helvetica;
     font-weight: bold;
      font-size: 20px;
      color: #ffffff;
    }
  }
  &.mobile {
    .footer {
       width:100%;margin:0;
      .sub {
         margin-left:10%;
        width: 80%;
      }
    }
  }
}


.sub-dis {
    position: relative;
    pointer-events: none;
    background-image: linear-gradient(
      #766983, 
      #766983), 
     linear-gradient(
      #270645, 
      #270645) !important;
     background-blend-mode: normal, 
      normal;
     border-radius: 25px;
     opacity: 0.9;
}
.sub-dis::after {
    content: "";
    background-image: url(../../assets/loading.gif);
    background-size: 100%;
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-top: 5px;
    
    // box-shadow: 0 0 10px #fff;
    // border-radius: 50%;
}
</style>

