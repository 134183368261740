<template>
   <div>
      <v-card-title class="text--title px-5 py-4">{{value.name}}</v-card-title>
         <img
                v-if="value.disabled == 3"
                class="mt-3"
                src="@/assets/icons/ban.png"
                width="25px"
                height="25px"
                style="position: absolute;"
                alt=""
            />
             <div class="status">
          
            <img v-if="value.disabled != 3 && value.saleStatus == '1'&&this.owerfrom =='cardPage'" src="@/assets/icons/icon_onsale.png" alt="Onsale" />
         </div>
      <v-card-text v-if="value.useType == 1" class="d-flex flex-row justify-space-between py-0 px-5">
         <template v-if="value.auctionStatus == '0'"
         >
            <div>
               <div class="text--sub-title text--secondary-rd">
                  {{ $t("popupAuctionStartingBid") }}
               </div>
               <div class="d-flex flex-row mt-2" style="margin-bottom: 10px;">
                  <div>
                      <v-img :src="
                require('@/assets/icons/' +
                  getChainByName(value.coinDenom)+
                  '.png')
          " width="22px" height="22px" />
                  </div>
                  <div
                      class="ml-2 text--number text--secondary-rd font-weight-medium" style="line-height:22px;"
                  >
                     {{ parseFloat(value.lastBidPrice) }}
                  </div>
               </div>
            </div>
            <div>
               <div class="text--sub-title text--secondary-rd">
                  {{ $t("workCardStartingin") }}
               </div>
               <div class="mt-2">
                  <div class="text--number text--secondary-rd font-weight-medium">
                     {{ hour }}h {{ minutes }}m {{ second }}s
                  </div>
               </div>
            </div>
         </template
         >
         <template v-else-if="value.auctionStatus == '1' ">
            <div>
               <div class="text--sub-title text--primary-rd">
                  {{ $t("workCardCurrentBid") }}
               </div>
               <div class="d-flex flex-row mt-2" style="margin-bottom: 10px;">
                  <div>
                      <v-img :src="
                require('@/assets/icons/' +
                  getChainByName(value.coinDenom)+
                  '.png')
          " width="22px" height="22px" />
                  </div>
                  <div class="ml-2 text--number text--primary-rd font-weight-medium" style="line-height:22px;">
                     {{  parseFloat(value.lastBidPrice) }}
                  </div>
               </div>
            </div>
            <div>
               <div class="text--sub-title text--primary-rd">
                  {{ $t("workCardEndingin") }}
               </div>
               <div class="mt-2">
                  <div class="text--number text--primary-rd font-weight-medium">
                     {{ hour }}h {{ minutes }}m {{ second }}s
                  </div>
               </div>
            </div>
         </template>
           <template v-else-if="value.auctionStatus == '4' ">
            <div class="d-flex justify-space-between status-7">
               <div>
                  <div class="text--sub-title text--primary-rd">
                     {{ priceTitle }}
                  </div>
                  <div class="d-flex flex-row mt-2">
                     <div>
                       <v-img :src="
                require('@/assets/icons/' +
                  getChainByName(value.coinDenom)+
                  '.png')
          " width="22px" height="22px" />
                     </div>
                     <div
                         class="
                     ml-2
                     text--number text--primary-rd
                     font-weight-medium
                   " style="line-height:22px;"
                     >
                        {{ parseFloat(value.minPrice) }}
                     </div>
                  </div>
               </div>
            </div>
            <div class="sold_out">{{$t("token_state_end")}}</div>
         </template>
          <template v-else-if="value.auctionStatus == '6' ">
            <div class="d-flex justify-space-between status-7">
               <div>
                  <div class="text--sub-title text--primary-rd">
                     {{ $t("finalBid") }}
                  </div>
                  <div class="d-flex flex-row mt-2">
                     <div>
                        <v-img :src="
                require('@/assets/icons/' +
                  getChainByName(value.coinDenom)+
                  '.png')
          " width="22px" height="22px" />
                     </div>
                     <div
                         class="
                     ml-2
                     text--number text--primary-rd
                     font-weight-medium
                   " style="line-height:22px;"
                     >
                        {{ parseFloat(value.minPrice) }}
                     </div>
                  </div>
               </div>
            </div>
            <div class="sold_out">{{$t("SoldOut")}}</div>
         </template>
        
         <template v-else>
            <div class="d-flex justify-space-between status-7">
               <div>
                  <div class="text--sub-title text--primary-rd">
                     {{ $t("workCardCurrentBid") }}
                  </div>
                  <div class="d-flex flex-row mt-2">
                     <div>
                       <v-img :src="
                require('@/assets/icons/' +
                  getChainByName(value.coinDenom)+
                  '.png')
          " width="22px" height="22px" />
                     </div>
                     <div
                         class="
                     ml-2
                     text--number text--primary-rd
                     font-weight-medium
                   " style="line-height:22px;"
                     >
                        {{ parseFloat(value.minPrice)}}
                     </div>
                  </div>
               </div>
            </div>
            <div class="sold_out">{{$t("workCardSoldOut")}}</div>
         </template>
      </v-card-text>
      <v-card-text  v-else  class="py-0 px-5" style="height: 40px; margin-top: 13px;">
         <template>
            <template v-if="value.onsale&&value.onsale==true  ">
               <div class="d-flex justify-space-between">
                  <div>
                     <!-- <div class="text--sub-title text--primary-rd">Current Bid</div> -->
                     <div class="d-flex flex-row " style="margin-top:5px">
                        <div>
                            <div>
                      <v-img :src="
                require('@/assets/icons/' +
                  getChainByName(value.coinDenom)+
                  '.png')
          " width="22px" height="22px" />
                  </div>
                        </div>
                        <div
                            class="ml-2 text--number text--primary-rd font-weight-medium" style="line-height:22px;"
                        >
                           {{parseFloat(value.minPrice)}}
                        </div>
                     </div>
                  </div>
               </div>
            </template>
            <template v-else>
               <div class="d-flex justify-space-between">
                   <div>
                      <div class="d-flex flex-row " style="margin-top:5px">
                         <div>
                                   <v-img :src="
                require('@/assets/icons/' +
                  getChainByName(value.coinDenom)+
                  '.png')
          " width="22px" height="22px" />
                         </div>
                         <div
                             class="ml-2 text--number text--primary-rd font-weight-medium" style="line-height:22px;"
                         >
                            {{parseFloat(value.minPrice)}}
                         </div>
                      </div>
                   </div>
               </div>
               <div class="sold_out" v-if="owerfrom != 'cardPage'">{{$t("workCardSoldOut")}}</div>
            </template>
         </template>
      </v-card-text>
   </div>
</template>

<script lang='js'>
  

  export default {
    name: "pageCardContent",
    props: {
      value: {
        type: Object,
        default: () => ({
          denomType: 8,
          onsale: true
        })
      },
       owerfrom: {
      type: String,
    },
    },
    data: function() {
      return {

        isStart: false,
        now: "",
        start: "",
        end: "",
        day: 0,
        hour: 0,
        minutes: 0,
        second: 0,
        seconds: "",
        priceTitle:''
      };
    },
    mounted() {
      this.Time(); //调用定时器
      window.eventBus.$on('LangChange', this.onLangChange);
      if(this.value.auctionStatus == '4')
      {
         if(this.value.auctionNoBid)
         {
            this.priceTitle = this.$t("workCardStartBid")
         }else{
            this.priceTitle = this.$t("workCardCurrentBid")
         }
      }
     
    },
    methods: {
       onLangChange(){
           if(this.value.auctionStatus == '4')
      {
         if(this.value.auctionStatus == '4')
      {
         if(this.value.auctionNoBid)
         {
            this.priceTitle = this.$t("workCardStartBid")
         }else{
            this.priceTitle = this.$t("workCardCurrentBid")
         }
      }
      }
       },
      getTime() {
        this.now = Date.parse(new Date());
        this.start = this.value.auctionStartTime;
        this.end = this.value.auctionEndTime;
        // this.start = Date.parse(new Date("2022-01-09 13:48:00"));
        // this.end = Date.parse(new Date("2022-01-09 13:49:00"));

        if (this.value.auctionStatus == 0 || this.value.auctionStatus == 1) {
          if (this.now < this.start) {
            this.isStart = false;
            this.seconds = (this.start - this.now) / 1000;
          } else if (this.now < this.end) {
            this.isStart = true;
            this.seconds = (this.end - this.now) / 1000;
          }
        }
      },
      // 天 时 分 秒 格式化函数
      countDown() {
        let d = parseInt(this.seconds / (24 * 60 * 60));
        this.day = d < 10 ? "0" + d : d;
        let h = parseInt(((this.seconds / (60 * 60)) % 24) + d * 24);
        this.hour = h < 10 ? "0" + h : h;
        let m = parseInt((this.seconds / 60) % 60);
        this.minutes = m < 10 ? "0" + m : m;
        let s = parseInt(this.seconds % 60);
        this.second = s < 10 ? "0" + s : s;
      },
      //定时器没过1秒参数减1
      Time() {
        setInterval(() => {
          if (this.seconds > 0) {
            this.seconds -= 1;
            this.countDown();
          }else{
            this.getTime();
          }
        }, 1000);
      },
    },
  };

</script>

<style lang='scss' scoped>
   .status-7{
      padding-bottom:10px;
   }
   .text--primary-rd {
      color: #270645;
   }

   .text--secondary-rd {
      color: #766983;
   }

   .text--info {
      font-size: 10px;
      line-height: 1;
      letter-spacing: 0px;
   }

   .text--title {
      font-size: 20px;
      line-height: 24px;
      width: 250px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
   }

   .text--sub-title {
      font-size: 12px;
      line-height: 1;
   }

   .text--number {
      font-size: 15px;
      line-height: 1;
      font-weight: bold !important;
   }

   .v-divider {
      border-color: #e3e3e3;
   }

   .sold_out {
      width: 115px;
      height: 36px;
      background-image: linear-gradient(
              91deg,
              #d300fd 0%,
              #7471ff 58%,
              #00fdcf 100%
      ),
      linear-gradient(#ffffff, #ffffff);
      background-blend-mode: normal, normal;
      border-radius: 18px;
      text-align: center;
     font-family:Helvetica;
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 36px;
      letter-spacing: 0px;
      color: #ffffff;
      position: absolute;
      right: -13px;
      bottom: 14px;
   }
   .status {
         float: right;
         position: absolute;
         right: -5px;
         bottom: 111px;

         img {
            width: 76px;
            height: 60px;
         }

        
      }

   .btnbox {
      width: 119px;
      height: 35px;
      box-sizing: border-box;
      padding: 2px;
      background-image: linear-gradient(
              0deg,
              #d202fd 0%,
              #a538fe 26%,
              #776eff 51%,
              #01facd 100%
      );
      border-radius: 17px;

      .rd {
         width: 100%;
         height: 100%;
         border: none;
         border-radius: 17px;
         background: #fff;
        font-family:Helvetica;
         font-size: 13px;
         font-weight: bold;
         font-stretch: normal;
         letter-spacing: 0;
         color: #270645;
      }
   }
</style>
