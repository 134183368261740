
// import Web3 from 'web3';
const Web3 = require('web3');
import store from "../store/index.js"
import { getMyBalance} from "../keplr/iris/wallet.js";
import {getAddress } from "../keplr/wallet";

let localAccount = "";
(function() {
	let json = window.localStorage.getItem("key_user");
	if(!json) return;
	
	let data = JSON.parse(json);
	if(!data) return;

	localAccount = data.did;
})();

export const getkeplaWeb3 = async () => {
	var web3;
	if (window.keplr) {
		// Modern dapp browsers
		try {

			const chainId = "irishub-1";
			await window.keplr.enable(chainId);
			web3 = await window.getOfflineSigner(chainId);

			//xxl TODO tochange the keystore
			window.addEventListener("keplr_keystorechange", keplrKeystoreChange);
			
		} catch (error) {
			console.log('denied');
		}
	} else {
		this.$Message.error('请连接kepla钱包！');
	}
	 let account = await web3.getAccounts();
	 console.log(account)
	 
	//  var balance = web3.eth.getBalance("iaa1xhhf3a80n7zamu32dyku77lactynx9sgs0vvz4");

	//  console.log("accountBalance -----")
	//  console.log(balance)

	 return account[0].address
};




export const getMatamaskWeb3 = async () => {
	var web3;
	if (window.ethereum) {
		// Modern dapp browsers
		web3 = new Web3(window.ethereum);
		try {
			console.log("连接metamask钱包")
			await window.ethereum.enable();
			await window.ethereum.eth_requestAccounts();
		} catch (error) {
			console.log('denied');
		}
	} else {
		this.$Message.error('请连接metamask钱包！');
	}
	let accounts = await web3.eth.getAccounts();
	return accounts;
};


export const keplrKeystoreChange = async () => {
    window.removeEventListener("keplr_keystorechange", keplrKeystoreChange);
    //
    console.log("xxl Key store in Keplr is changed. You may need to refetch the account info.")
    
    let account = await getkeplaWeb3();
    let balance =  await getMyBalance();
    let mount = balance.format
    
    localStorage.setItem("key_balance",mount);
    console.log("xxl KeplrBtn ::: ");
    console.log("account",account)
	console.log("balance",balance)

	localStorage.setItem("key_user", JSON.stringify({user:true,did:account}));
    
    store.commit('SET_DID', account)
	console.log("did",store.state.did)

	let IrisObj = await getAddress(1);
	let USDCObj = await getAddress(3);
	let AtomObj = await getAddress(2);
	 //  获取钱包地址
	let IrisAddress = IrisObj.address;
let USDCAddress = USDCObj.address;
let AtomAddress = AtomObj.address;
	let IrisTokenAddress = {
	  IrisAddress:IrisAddress,
	  USDCAddress:USDCAddress,
	  AtomAddress:AtomAddress,
	}
	console.log("wxl --- getAddress",IrisObj,USDCObj,AtomObj)
   localStorage.setItem("key_tokenAddress", JSON.stringify(IrisTokenAddress));
	
    if(account != localAccount)
    	location.reload();
}
