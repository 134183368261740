<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        src="@/assets/btn_close.png"
        alt=""
      />
      <div class="top">
        <h3 class="end">{{ $t("widthDrawNotice") }}</h3>
      </div>
      <div class="footer mt-5">
        <button
          class="sub v-btn uc"
          :class="{ 'sub-dis': isPay }"
          submit
          @click="subCommit"
        >
          {{ $t("popupTransferCommit") }}
        </button>
      </div>
    </v-card>
    <v-snackbar
      v-model="isShowFalse"
      color="error"
      centered
      timeout="2000"
      style="opacity: 0.8"
      ><span style="color: white; font-size: 20px">{{
        falseTitle
      }}</span></v-snackbar
    >
    <v-snackbar
      v-model="isShowSuccess"
      color="success"
      centered
      timeout="2000"
      style="opacity: 0.8"
      ><span style="color: white; font-size: 20px">{{
        successTitle
      }}</span></v-snackbar
    >
    <uComponents ref="ucom"></uComponents>
  </div>
</template>
<script>
import api from "@/api";
 import { orderPay,quiryTx} from "../../keplr/iris/wallet";
export default {
  data: () => ({
    newOpen: true,
    nftName: "",
    isShowFalse: false,
    isPay: false,
    isShowSuccess: false,
    falseTitle: "this is a error",
    successTitle: "create success",
  }),
  props: {
    address: {
      type: String,
    },
    name: {
      type: String,
    },
    onSaleTokenIds: {
      type: Array,
    },
    offSaleTokenIds: {
      type: Array,
    },
    parentValue: {
      type: Object
    }
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  mounted() {
    this.nftName = this.name;
    console.log("parentValue -- ",this.parentValue)
  },
  methods: {
    async subCommit() {
       this.isPay = true
         let res = await api.home.getFee();
        let auctionNo = this.parentValue.auctionVo.auctionNo;
      let recipient = res.data.irisMerchantAuctionAddress;
        let orderPrice = 0.02;

        let memo = {
          type: "AUCTION_REVOKE", //类型
          param: {
            auctionNo: auctionNo, //竞拍号
          },
        };
        //链上付款
        console.log("wxl ------ 222222");
        console.log(orderPrice, recipient, memo);
        let payResult = await orderPay(
          orderPrice,
          recipient,
          JSON.stringify(memo)
        );
        // hash = payResult.hash;
        console.log(payResult);
let isDoingParams = {
          tokenIds: this.parentValue.onsaleTokenIds,
          contractAddress: this.parentValue.contractAddress,
          type: 2,
        };
          let isding = await api.home.isDoing(isDoingParams);
        if(payResult.hash)
        {
                 let isShowTip = localStorage.getItem("IRIS_isShowTip_"+this.$store.state.did)
        if(isShowTip == 'true'){
              this.$mtip({
                title:this.$t("transactionInprogressSuccess"),
              });
        }
         
              this.$emit("getOpen", false);
            this.isPay = false
            this.$router.push({ name: "PersonalPage" });
               while (this.flag) {
              console.log("wwwwww");
              await this.sleep(5000).then(async () => {
                 //console.log(result.hash)
                let res = await quiryTx(payResult.hash);
                console.log("wxl -----  quiryTx");
                console.log(res);
                if (res.code == 0) {
              let t = this.$t("auction_Withdrawn")
              let reg=new RegExp('【N】','g')//g代表全部
              let regN=new RegExp('<br/>','g')//g代表全部
              let name = "【" + this.name + "】"
              let title=t.replace(reg,name).replace(regN," ");
                let isShowTip = localStorage.getItem("IRIS_isShowTip_"+this.$store.state.did)
        if(isShowTip == 'true'){
                this.$mtip({
                title:title,
              });
        }
             
                  this.flag = false;
                } else if (res.code == -1) {
                  console.log("error");
            let isShowTip = localStorage.getItem("IRIS_isShowTip_"+this.$store.state.did)
        if(isShowTip == 'true'){
                  this.$mtip({
                    title:res.log,
              });
        }
               
              this.flag = false;
                } else {
                  this.flag = true;
                }
              });
            }
        }
        
    },
    closeDialog() {
      this.open = false;
      this.$emit("getOpen", this.open);
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  min-height: 239px;
  width: 600px;

  .top {
    display: flex;
    flex-direction: column;
    margin: 30px auto 0;

    .end {
      margin: 30px auto 13px;
      width: 100%;
      height: 21px;
      text-align: center;
      font-weight: bold;;
      font-size: 25px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      white-space: nowrap;
    }

    .content {
      margin: 19px auto 25px;
      width: 226px;
      min-height: 22px;
      font-family: Helvetica;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      text-align: center;
    }
  }

  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }

  .footer {
    margin: 0 auto;

    .sub {
      margin: 30px auto;
      width: 450px;
      height: 51px;
      // background-image: linear-gradient(
      //     90deg,
      //     #d300fd 0%,
      //     #a439fe 26%,
      //     #7471ff 52%,
      //     #00fdcf 100%
      //   ),
      //   linear-gradient(#270645, #270645);
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
      font-weight: bold;;
      font-size: 20px;
      color: #ffffff;
    }
  }
  &.mobile {
    .footer {
      width: 100%;
      margin: 0;
      .sub {
        margin-left: 10%;
        width: 80%;
      }
    }
  }
}

img {
  display: inline-block;
  width: 126px;
  height: 126px;
  background-color: #8a73ff;
  border-radius: 5px;
  margin: 1px 22px 10px 52px;
}
.sub-dis {
  position: relative;
  pointer-events: none;
  background-image: linear-gradient(#766983, #766983),
    linear-gradient(#270645, #270645) !important;
  background-blend-mode: normal, normal;
  border-radius: 25px;
  opacity: 0.9;
}
.sub-dis::after {
  content: "";
  background-image: url(../../assets/loading.gif);
  background-size: 100%;
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: 110px;

  // box-shadow: 0 0 10px #fff;
  // border-radius: 50%;
}

.mobile {
  .end {
    word-wrap: break-word;
    white-space: unset !important;
  }
}
</style>

