const iris = require("@irisnet/irishub-sdk");

const BigNumber = require('big-number');
const long = require('long');
const chainId = process.env.VUE_APP_GRAVITY_CHAIN_ID;
// const md5 = require('md5');

// import { 
//   getRanHex
// } from "../../utils/helper";

//构建 iris-sdk 配置
let config = {
    node: window.location.protocol+"//"+ window.location.host + "/gravity",
    chainNetwork: iris.types.ChainNetwork.Iris,
    chainId: chainId,
    //gas: process.env.VUE_APP_IRIS_BASE_GAS,
    gas: '250000',
    fee: { denom: process.env.VUE_APP_IRIS_DENOM, amount: '75000' },
};

// irisnet sdk 初始化
const client = iris
    .newClient(config)
    .withKeyDAO({
        write: () => {
        }, read: () => {
            return '';
        }
    })
    .withRpcConfig({ timeout: 50000 });


async function getAccountInfo() {

    try {
        await window.keplr.enable(chainId);
        // get accountInfo
        const accountKeplr = await window.keplr.getKey(chainId);
        console.log("xxl accountKeplr 000------");
        console.log(accountKeplr);

        const offlineSigner = window.getOfflineSigner(chainId);
        const accounts = await offlineSigner.getAccounts();

        console.log("xxl accounts 011------");
        console.log(accounts);

        let accountIris = await client.auth.queryAccount(accountKeplr.bech32Address);

        console.log("xxl accountIris 111------");
        console.log(accountIris);

        return {
            address: accountKeplr["address"],
            bech32Address: accountKeplr["bech32Address"],
            pubKey: accountKeplr["pubKey"],
            accountNumber: accountIris["accountNumber"],
            sequence: accountIris["sequence"]
        }
    } catch (error) {
        console.log(error);
        console.log('denied getAccountInfo Gravity');
    }

}

export async function queryTokenFromGravity() {
    try {
        let accountInfo = await getAccountInfo()
        let allBal = await client.bank.queryAllBalances(accountInfo.bech32Address)
        console.log("xxl allBal : ", allBal);
    
        return allBal;
    } catch (error) {
        return 0 
    }
    

}


async function signAndBroadcastTx(accountInfo, msgs, memo = ' ') {

    try {

        console.log("0");
        //set fee
        let txLen = msgs.length;
        let pulsFee = BigNumber(process.env.VUE_APP_IRIS_STEP_GAS)
            .multiply(txLen - 1)
        let baseGas = process.env.VUE_APP_IRIS_BASE_GAS
        if (txLen == 1) {
            baseGas = 150000
        }
        let totalGas = BigNumber(baseGas)
            .plus(pulsFee)
            .toString();

        console.log("xxl totalGas 0....");
        console.log(totalGas);
        console.log("xxl totalGas 1....");


        client.withGas(totalGas);
        let totalFeeAmount = BigNumber(totalGas).divide(10).multiply(3).toString()
        let newFee = { denom: process.env.VUE_APP_IRIS_DENOM, amount: totalFeeAmount };


        client.withFee(newFee);

        client.auth.defaultStdFee = {
            "amount": [newFee],
            "gasLimit": totalGas
        }
        //


        // 构建 sdk base Tx
        let bTx = {};
        bTx.account_number = accountInfo.accountNumber;
        // xxl 0107 fix sequence is 0 bug
        //bTx.sequence = accountInfo.sequence == 0 ? 1:accountInfo.sequence;
        bTx.sequence = accountInfo.sequence + "";
        bTx.memo = memo;

        console.log(1);
        // 构建 sdk 离线签名结构
        console.log("xxl msgs 0....");
        console.log(msgs);
        console.log("xxl msgs 1....");

        //
        let tx_o = client.tx.buildTx(msgs, bTx);
        console.log(Buffer.from(accountInfo.pubKey).toString('hex'));
        tx_o.setPubKey(Buffer.from(accountInfo.pubKey).toString('hex'));

        console.log(1.2);
        let signDoc = tx_o.getSignDoc(bTx.account_number, chainId);

        console.log(2);
        // 构建 keplr 离线签名结构
        let keplr_signDoc_obj = {
            bodyBytes: signDoc.getBodyBytes(),
            authInfoBytes: signDoc.getAuthInfoBytes(),
            chainId: chainId,
            accountNumber: new long(signDoc.getAccountNumber())
        };

        // console.log(3);
        // console.log([
        //   keplr_signDoc_obj.chainId,accountInfo.bech32Address,keplr_signDoc_obj
        // ])
        // keplr 签名
        let s = await window.keplr.signDirect(keplr_signDoc_obj.chainId, accountInfo.bech32Address, keplr_signDoc_obj);

        console.log(4);

        // 将 keplr 签名整合到 sdk 离线签名结构
        tx_o.addSignature(s.signature.signature);
        // 更新gas相关签名，防止修改GAS 导致失败
        tx_o.authInfo = iris.types.tx_tx_pb.AuthInfo.deserializeBinary(s.signed.authInfoBytes);
        // 更新gas相关签名，防止修改 导致失败
        tx_o.body = iris.types.tx_tx_pb.TxBody.deserializeBinary(s.signed.bodyBytes);

        console.log(5);
        console.log(tx_o);

        // sdk broadcast tx
        //let res = await client.tx.broadcast(tx_o, iris.types.BroadcastMode.Commit);
        let res = await client.tx.broadcast(tx_o, iris.types.BroadcastMode.Sync);
        console.log('res:', res);

        return res;

    } catch (error) {
    
        console.log(error);
        console.log('signAndBroadcastTx error');
    }
}

export async function ibcTransferFromGravity(
    source_port,
    source_channel,
    token,
    receiver,
    timeout_timestamp
) {

    try {
        let timeout_height = 0;
        if (!timeout_height && !timeout_timestamp) {
            // throw new SdkError("there must be one timeout_height or timeout_timestamp");
            console.log('there must be one timeout_height or timeout_timestamp');
            return;
        }

        let accountInfo = await getAccountInfo()
        console.log("xxl iris.types.TxType.MsgTransfer ", iris.types.TxType.MsgTransfer);
        console.log("xxl accountInfo ", accountInfo);

        const msgs = [{
            type: iris.types.TxType.MsgTransfer,
            value: {
                source_port: source_port,
                source_channel: source_channel,
                token: token,
                sender: accountInfo.bech32Address,
                receiver: receiver,
                timeout_height: timeout_height,
                timeout_timestamp: timeout_timestamp
            }
        }];
        console.log("xxl msgs", msgs);
        let txInfo = await signAndBroadcastTx(accountInfo, msgs);

        return {
            txInfo,
            denomInfo: msgs
        }
    } catch (error) {
        console.log(error);
        console.log('denied getAccountInfo');
    }
}


export async function quiryTxGravity(tx) {
    console.log("xxl ....");
    try {
        let result = await client.tendermint.queryTx(tx);
        console.log(result);
        if (result.tx_result != null && result.tx_result.code == 0) {
            return {
                code: "0",
                log: ""
            }
        } else if (result.tx_result != null && result.tx_result.code != 0) {
            return {
                code: "-1",
                log: result.tx_result.log
            }
        } else {
            return {
                code: "-2",
                log: "cannot get log"
            }
        }
    } catch (e) {
        return [-3, e.toString()];
    }
}
