import 
service ,{$postBodyJson}
from '@/utils/request';
import { BASE_URL } from '../config';
import rsa from "@/utils/RSAEncrypt.js";
import request from "@/utils/twitter";
const OAuth = require('oauth-1.0a');
const crypto = require('crypto');
let key = 'wG8HhF30bw0iYvSurTdty7i8I'
let secret = '7UKzmduApjmoCSVWO56VgrwCby1JMrXYSzJNpbH3JJCxk61K0h'
const oauth = OAuth({
    consumer: {
      key:key,
      secret:secret
    },
    signature_method: 'HMAC-SHA1',
    hash_function: (baseString, key) => crypto.createHmac('sha1', key).update(baseString).digest('base64')
  });
  
 
//   const authHeader = oauth.toHeader(oauth.authorize(authData));

export function access_token(oauth_token,oauth_verifier,callback) {
   const authData ={
        url: "https://api.twitter.com/oauth/request_token?oauth_callback="+callback,
        method: 'POST',
       data:{
        oauth_token:oauth_token,
        oauth_verifier:oauth_verifier
    
       }
      }
   return request({
		url: `access_token`,
		method: 'post',
		headers: {
			'Authorization': oauth.toHeader(oauth.authorize(authData))["Authorization"],	
			'content-type': "application/json",
			'accept': "application/json"
        },
    
	})

}


export function request_token() {
   const authData ={
        url: "https://api.twitter.com/oauth/request_token?oauth_callback=http://localhost:8084/myInfo",
        method: 'POST',

      }
    return request({
         url: `request_token`,
         method: 'post',
         headers: {
             'Authorization': oauth.toHeader(oauth.authorize(authData))["Authorization"],
         
             'content-type': "application/json",
             'accept': "application/json"
         },
     
     })
 
 }

 // getToken
export function getToken(href) {

	let params = {
		callback: href,
	};
	return service.post(`${BASE_URL}/user/getTwitterToken`, transObjToParamStr(params));

}

// Newsletter
export function Newsletter(params) {
    return service.post('/uptickevmapi', 
    transObjToParamStr(params)
    )
}

export function getHomeNftListTopsku(params) {
    return service.post(`${BASE_URL}/market/topsku`, {
        params
    })
}
export function getHomeNftListCreators(params) {
    return service.post(`${BASE_URL}/raredrop/indexCreators`, {
        params
    })
}


// 首页 gallery  raredrop/gallery

export function getGalleryList(params) {

    return service.post(`${BASE_URL}/market/getFirstPublish.json`,
		transObjToParamStr(params)
		)


}
// activity/luck/irisLuckEnable.json
export function irisLuckEnable() {
  
    return service.get(`${BASE_URL}/data/irisLuckEnable.json`
        
    )
}

export function getPersoncollectionList(params) {
    return service.post(`${BASE_URL}/homepage/collection.json`,
        transObjToParamStr(params)
    )
}

// 拍卖列表
export function getAuctionList(params) {
    return service.post(`${BASE_URL}/contract/detail.json`, 
        transObjToParamStr(params)
    )

}

// 获取市场列表 market/getMarketplace.json?
export function getMarketPlaceList(params) {
    return service.post(`${BASE_URL}/market/getMarketplace.json`, 
    transObjToParamStr(params)
)

}


//统计弹层列表
export function getTotalList(params) {
    return service.post(`${BASE_URL}/orderInfo/orderInfoList.page`, 
        transObjToParamStr(params)
    )
}



//创建者信息  
export function createInfo(params) {
    return service.post(`${BASE_URL}/user/creator/info.json`, 
        transObjToParamStr(params)
    )
}
// 创作者列表
export function createList(params) {
    return service.post(`${BASE_URL}/raredrop/creator/list`, 
        transObjToParamStr(params)
    )
}

//我的粉丝
export function myFans(params) {
    return service.post(`${BASE_URL}/fans/myFans.page`, 
        transObjToParamStr(params)
    )
}
// 我关注的列表
export function myFocus(params) {
    return service.post(`${BASE_URL}/fans/myFocus.page`, 
        transObjToParamStr(params)
    )
}

// 编辑个人信息
export function editMyInfo(params) {
    return service.post(`${BASE_URL}/passport/update.do`, 
        transObjToParamStr(params,true)
    )
}

// 获取系统信息
export function sysMessage(params) {
    return service.post(`${BASE_URL}/sys/sysMsg`, 
        transObjToParamStr(params)
    )
}

//获取个人信息
export function getMyInfo(params) {
    return service.post(`${BASE_URL}/raredrop/userInfo.json`, 
        transObjToParamStr(params)
    )
}
// 添加关注
export function addDo(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/fans/add.do`, 
        transObjToParamStr(params)
    )
}

// 是否关注
///api/1.0/raredrop/fans/isFans.do
export function isFans(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/follow/fans/isFans.do`, 
        transObjToParamStr(params)
    )
}
// 取消关注
export function removeDo(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/fans/remove.do`, 
        transObjToParamStr(params)
    )
}
// 添加收藏或者喜欢
export function followDo(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/follow/follow.do`, 
        transObjToParamStr(params,true)
    )
}

// 收藏列表
export function followList(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/follow/list.page`, 
        transObjToParamStr(params)
    )
}

//是否收藏
export function isFollow(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/raredrop/isFollow.info`, 
        transObjToParamStr(params)
    )
}
// 他人主页列表
export function otherPage(params) {
    console.log(BASE_URL)
	if(params.owner!=undefined&&params.owner!=""){
		return service.post(`${BASE_URL}/contract/market/homepage.page`,
		transObjToParamStr(params)
		)
	}else{
	return service.post(`${BASE_URL}/contract/homepage.page`,
	    transObjToParamStr(params)
	)
	}

}

//系统消息查询（分页显示),不需要参数
export function sysNews(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/raredrop/sysNews/list.page`, 
        transObjToParamStr(params)
    )
}

//添加个人留言（一对一）
export function message(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/words/add.do`, 
        transObjToParamStr(params)
    )
}

// 检查是否有留言 ,返回新消息条数，页面显示massage，以及头像小红点
export function isNewMessage(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/words/noRead.json`, 
        transObjToParamStr(params)
    )
}

//留言、回复记录（指定好友）
export function messageRecord(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/raredrop/words/record.page`, 
        transObjToParamStr(params)
    )
}

//score/order/record.page
export function orderRecord(params) {
    return service.post(`${BASE_URL}/score/order/record.page`, 
        transObjToParamStr(params)
    )
}

//留言、回复（好友列表）
export function listRecord(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/raredrop/words/list.page`, 
        transObjToParamStr(params)
    )
}

//查询群发记录
export function messageRecordGroup(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/raredrop/words/group/record.page`, 
        transObjToParamStr(params)
    )
}

//添加群发记录
export function messageGroup(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/raredrop/words/group/add.do`, 
        transObjToParamStr(params)
    )
}

//创作者投诉
export function accountComplaint(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/raredrop/complaint/creator/create.do`, 
        transObjToParamStr(params)
    )
}

// 监测是否可作者申诉
export function checkComplaint(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/raredrop/complaint/creator/check.do`, 
        transObjToParamStr(params)
    )
}

// 创作者申诉
export function accountAppeal(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/raredrop/complaint/creator/appeal.do`, 
        transObjToParamStr(params)
    )
}
// 作品投诉
export function nftComplaint(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/complaint/create.do`, 
        transObjToParamStr(params)
    )
}
// 作品申诉
export function nftAppeal(params) {
    console.log(BASE_URL)
   return service.post(`${BASE_URL}/complaint/appeal.do`,
       transObjToParamStr(params)
   )
}

//资产进行中
//POST /api/1.0/raredrop/assets/doing.do
export function isDoing(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/nft/doing.do`, 
        transObjToParamStr(params)
    )
}
// cerfified/create.do
export function cerfifiedApply(params) {
    return service.post(`${BASE_URL}/cerfified/create.do`, 
        transObjToParamStr(params)
    )
}

// 创建资产
export function txCache(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/raredrop/txCache/chadd.do`, 
        transObjToParamStr(params)
    )
}

// 验证linkUrl nft/contractCollection/check
export function checkLinkUrl(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/nft/contractCollection/check`, 
        transObjToParamStr(params)
    )
}


//发送验证邮件
 // map.put("type","BINDING");
 //           map.put("to",key);
export function getEmailCode(params) {
    return service.post(`${BASE_URL}/email/getCodeWeb.do`, 
        transObjToParamStr(params)
    )
}

//验证邮箱验证码
 // map.put("type","BINDING");
 //        map.put("address",address);
 //        map.put("code",code);
export function emailVerify(params) {
    return service.post(`${BASE_URL}/passport/emailVerifyWeb.do`, 
        transObjToParamStr(params)
    )
}
// 获取费用
export function getFee() {
    console.log(BASE_URL)
    return service.get(`${BASE_URL}/raredrop/data/fee` )
}

export function getMarket() {
	return service.get(`/fee?currency=USD&symbol=IRIS,ATOM` )
}


// 奖励积分
export function scoreReward(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/score/mining/reward.json`, 
        transObjToParamStr(params)
    )
}
// 积分明细
export function scoreRecord(params) {
    return service.post(`${BASE_URL}/score/record.page`, 
        transObjToParamStr(params)
    )
}
// score/exchangeInfo 圈存总数
export function scoreTotalInfo(params) {
    return service.post(`${BASE_URL}/score/exchangeInfo`, 
        transObjToParamStr(params)
    )
}
//score/exchangeList 圈存总记录
export function scoreList(params) {
    return service.post(`${BASE_URL}/score/exchangeList`, 
        transObjToParamStr(params)
    )
}


// 创建的收藏卡列表
export function couponList(params) {
    // {"pageNumber":"1","contractId":"9529","pageSize":"50"}
    return service.post(`${BASE_URL}/coupon/list.json`, 
        transObjToParamStr(params)
    )
}
// 是否拥有优惠卡或者转卖卡
export function getCouponInfoJson(params) {
	// http://192.168.1.113:9997/api/1.0/coupon/info.json?userAddress=iaa15k328z4v65qd7tp82fcvrymfmfn6lnkq5ep30c&denom=uptick611b93934fc8e453b253518c93d1e478
	
    return service.get(`${BASE_URL}/coupon/info.json?`+ transObjToParamStr(params)
    )

}


//POST /api/1.0/nft/contractCollection/create
export function createFolder(params) {
    // {"pageNumber":"1","contractId":"9529","pageSize":"50"}
    return service.post(`${BASE_URL}/nft/contractCollection/create`, 
        transObjToParamStr(params)
    )
}
//POST /api/1.0/nft/contractCollection/update
export function FolderUpdate(params) {
    // {"pageNumber":"1","contractId":"9529","pageSize":"50"}
    return service.post(`${BASE_URL}/nft/contractCollection/update`, 
        transObjToParamStr(params)
    )
}


//onsle
export async function onSale(param) {

    let data = param;
    
    console.log("wxl data")
    console.log(data);
    
    let result =  await $postBodyJson('/merchant/onsaleByHash',data);

    console.log("xxl result is ::");
    console.log(result);


 
    return result;
}

// saleout 
export async function Saleout(param) {

    
    let data = param;
    
 
    console.log("wxl data")
    console.log(data);
    
    let result =  await $postBodyJson('/merchant/offsale',data);

    console.log("xxl result is ::");
    console.log(result);
    return result;

}

// buyNow
export async function BuyNow(param) {

    let data = param;
    
 
    console.log("wxl data")
    console.log(data);
    
    let result =  await $postBodyJson('/merchant/transferByHash',data);

    console.log("xxl result is ::");
    console.log(result);
    return result;

}
// 预下单  order/preOrder.do
export function preOrder(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/orderInfo/preOrder.do`, 
        transObjToParamStr(params)
    )
}
//一键读取
export function readAllMessage(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/sys/read`, 
        transObjToParamStr(params)
    )
}
// 根据订单号查询  order/preOrder.do
export function orderByOrderNo(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/raredrop/order/findByOrderNo.json`, 
        transObjToParamStr(params)
    )
}
// patDo
export function payDo(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/orderInfo/wallet/pay.do`, 
        transObjToParamStr(params)
    )
}

// 获取拍卖最新价格  Post /auction/lastBidPrice
export function currentBid(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/raredrop/auction/lastBidPrice`, 
        transObjToParamStr(params)
    )
}
//POST /api/1.0/coupon/nftList
export function getCollectionList(params) {
    // {"pageNumber":"1","contractId":"9529","pageSize":"50"}
    return service.post(`${BASE_URL}/coupon/nftList`, 
        transObjToParamStr(params)
    )
}


// POST /api/1.0/raredrop/assetHistory
// 卡包详情--卡历史
export function assetHistory(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/nft/history.json`, 
        transObjToParamStr(params)
    )
}

// POST /api/1.0/raredrop/creator/list
// 创作者列表
export function creatorList(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/user/creator/list`, 
        transObjToParamStr(params)
    )
}

// POST /api/1.0/raredrop/creator/list
// 加V创作者列表
export function creatorVList(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/user/creatorV `, 
        transObjToParamStr(params)
    )
}

// POST /api/1.0/raredrop/order/transactions.page
// 我购买的订单列表
export function transactions(params) {
    return service.post(`${BASE_URL}/transaction/transactions.page`, 
        transObjToParamStr(params)
    )
}

// 一手商品转送给他人  contractAddress\tokenIds
export function transferToOther(params) {
	// String owner, String toOwner, 比transferToOwn 多出的两个参数
    return service.post(`${BASE_URL}/raredrop/transfer/other`, 
        transObjToParamStr(params)
    )
}
// POST
// 出价竞拍
//出价价格 BidPrice  拍卖人 bidder=did，  auctionNo 拍卖号
export function auctionUpdate(params) {
  
    return service.post(`${BASE_URL}/raredrop/auction/update/web`, 
        transObjToParamStr(params)
    )
}


// 拍卖详情
export function bidHistory(params) {
    return service.get(`${BASE_URL}/nft/auction/detail?`+ transObjToParamStr(params), 
       ""
    )
}

// 取消订单
export function orderCancel(params) {
  
    return service.post(`${BASE_URL}/raredrop/close.do`, 
        transObjToParamStr(params)
    )
}
// 销毁
export function burnNft(params) {
  
    return service.post(`${BASE_URL}/raredrop/burn.do`, 
        transObjToParamStr(params)
    )
}

//获取我参加过的活动列表  /api/1.0/activity/luck/joinListByOwner

export function joinListByOwner(params) {
  
    return service.post(`${BASE_URL}/activity/luck/joinListByOwner`, 
        transObjToParamStr(params)
    )
}


//获取我参加过的活动详情  /api/1.0/activity/luck/joinListByOwner

export function joinInfoByOwner(params) {
  
    return service.post(`${BASE_URL}/activity/luck/joinInfoByOwner`, 
        transObjToParamStr(params)
    )
}

// 获取抽奖活动列表 /api/1.0/activity/luck/joinList
export function joinList(params) {
  
    return service.post(`${BASE_URL}/activity/luck/joinList`, 
        transObjToParamStr(params)
    )
}

//获取抽奖活动信息 /api/1.0/activity/luck/info
export function luckInfo(params) {
  
    return service.post(`${BASE_URL}/activity/luck/info`, 
        transObjToParamStr(params)
    )
}
//    获取个人活动详情 /api/1.0/raredrop/promo/user
export function getPromoUser(params) {
  
    return service.post(`${BASE_URL}/promo/user`, 
        transObjToParamStr(params)
    )
}
//  领取活动卡片  POST /api/1.0/raredrop/applyPromo
export function applyPromo(params) {
  
    return service.post(`${BASE_URL}/promo/applyPromo`, 
        transObjToParamStr(params)
    )
}

//raredrop/coupon/useLog
export function userLog(params) {
  
    return service.post(`${BASE_URL}/coupon/useLog`, 
        transObjToParamStr(params)
    )
}
//POST /api/1.0/nft/contractCollection/search
export function collectionList(params) {
  
    return service.post(`${BASE_URL}/nft/contractCollection/search`, 
        transObjToParamStr(params)
    )
}
export function nftBurn(params) {
    return service.post(`${BASE_URL}/nft/burn.do`,
        transObjToParamStr(params,true)
    )
}

//POST /api/1.0/nft/contractCollection/detail
export function collectionDetail(params) {
  
    return service.post(`${BASE_URL}/nft/contractCollection/detail`, 
        transObjToParamStr(params)
    )
}

//POST /api/1.0/nft/contractCollection/add
export function addFolder(params) {
  
    return service.post(`${BASE_URL}/nft/contractCollection/add`, 
        transObjToParamStr(params)
    )
}
//POST /api/1.0/nft/contractCollection/out
export function removeFolder(params) {
  
    return service.post(`${BASE_URL}/nft/contractCollection/out`, 
        transObjToParamStr(params)
    )
}


// Todo 需要对接接口，接入测试数据
//  集合推荐
export function getRecommendList(params) {

    return service.post(`${BASE_URL}/market/getRecommendContract.json`, 
    transObjToParamStr(params)
)
    // return service.post(`${BASE_URL}/nft/getRecommendContract.json`,
    //     transObjToParamStr(params)
    // )
}

// 我的邀请人列表 /user/myInvite
export function myInviteList(params) {
  
    return service.post(`${BASE_URL}/user/inviter `, 
        transObjToParamStr(params)
    )
}
//我的推荐者 /user/myRecommended
export function myRecommended(params) {
  
    return service.post(`${BASE_URL}/user/invitee `, 
        transObjToParamStr(params)
    )
}
//邀请码归属校验
export function checkInviteCode(params) {
  
    return service.post(`${BASE_URL}/user/inviteCode/check`, 
        transObjToParamStr(params)
    )
}
//添加推荐人接口
export function addInviteCode(params) {
  
    return service.post(`${BASE_URL}/user/inviteCode/invite`, 
        transObjToParamStr(params)
    )
}
//POST /api/1.0/nft/getMyTokenId.json
export function getNftOwn(params) {
    return service.post(`${BASE_URL}/homepage/getMyTokenId.json`,
        transObjToParamStr(params)
    )
}

///getMyAssetsNoFolder.json
export function getMyAssetsNoFolder(params) {
  
    return service.post(`${BASE_URL}/homepage/getMyAssetsNoFolder.json`, 
        transObjToParamStr(params)
    )
}
//POST /api/1.0/nft/getMyContract.json
export function getOwnByCollection(params) {
    return service.post(`${BASE_URL}/homepage/getMyContract.json`,
        transObjToParamStr(params)
    )
}
export function getPersonnftList(params) {
    return service.post(`${BASE_URL}/homepage/token.json`,
        transObjToParamStr(params)
    )
}
//POST /api/3.0/nft/skuInfo.json
export function nftTokenInfo(params) {
    return service.post(`${BASE_URL}/nft/skuInfo.json`,
        transObjToParamStr(params)
    )
}
//POST /api/3.0/nft/skuInfo.json 卡详情
export function cardDetailInfo(params) {
    return service.post(`${BASE_URL}/nft/tokenInfo.json`,
        transObjToParamStr(params)
    )
}
//睁眼睛闭眼睛
export function openAsset(params) {
    //String nftId,  String nftAddress, String owner, Boolean assetPublic
   return service.post(`${BASE_URL}/nft/saveState.do`, 
       transObjToParamStr(params,true)
   )
}
//POST /api/3.0/raredrop/contractCollection/detail
export function collectionInfo(params) {
    return service.post(`${BASE_URL}/market/collection/list.json`,
        transObjToParamStr(params)
    )
}
// POST /api/1.0/follow/isFollow.info
export function isCollect(params) {
    return service.post(`${BASE_URL}/follow/isFollow.info`,
        transObjToParamStr(params)
    )
}
// 隐藏用户列表
export function hideUserList(params) {
  
    return service.post(`${BASE_URL}/market/blockOwnerList`, 
        transObjToParamStr(params)
    )
}
//隐藏集合列表
export function hideCollectList(params) {
  
    return service.post(`${BASE_URL}/market/blockNftAddressList`, 
        transObjToParamStr(params)
    )
}
// 隐藏资产列表
export function hideNftsList(params) {
  
    return service.post(`${BASE_URL}/market/blockNftIdList`, 
        transObjToParamStr(params)
    )
}
//隐藏资产
export function hideNfts(params) {
  
    return service.post(`${BASE_URL}/market/block`, 
        transObjToParamStr(params)
    )
}
//释放资产
export function releaseNfts(params) {
  
    return service.post(`${BASE_URL}/market/unBlock `, 
        transObjToParamStr(params)
    )
}

// 积分兑换
export function scoreExchange(params) {
    return service.post(`${BASE_URL}/score/exchange.do`, 
        transObjToParamStr(params,true)
    )
}

// 权益卡领取记录 airdrop/sysList
export function claimList(params) {
  
    return service.post(`${BASE_URL}/airdrop/iris/info `, 
        transObjToParamStr(params)
    )
}

// 查询uptick地址的接口
export function getUptickAddress(params) {
  
    return service.post(`${BASE_URL}/airdrop/getUptickAddress`, 
        transObjToParamStr(params)
    )
}
// 领取积分
export function claimPoint(params) {
  
    return service.post(`${BASE_URL}/airdrop/iris/receive `, 
        transObjToParamStr(params,true)
    )
}


export function getOwnByCreator(params) {
    return service.post(`${BASE_URL}/homepage/getOwnByCreator.json`,
        transObjToParamStr(params)
    )
}
// airdrop/show/nft 获取官方NFT推荐卡片
export function getOfficialList() {
    return service.post(`${BASE_URL}/airdrop/show/nft`,
       
    )
}

// 查询空投列表
export function airdropList(params) {
    return service.post('/apidrop/airdrop/list', 
        transObjToParamAirDrop(params)
    )
}


// 空投领取
export function evmReceive(params) {
    return service.post('/apidrop/airdrop/evm/receive', 
        transObjToParamAirDrop(params,true)
    )
}

// 根据nftId查询跨链记录 /api/1.0/cross/getDataByNftID.json
export function getCrossListByTokenId(params) {
    return service.post(`${BASE_URL}/cross/getDataByNftID.json`, 
        transObjToParamStr(params)
    )
}
// 根据钱包地址查询跨链记录 /api/1.0/cross/getDataByAddress.json
export function getCrossListByAddress(params) {
    return service.post(`${BASE_URL}/cross/getDataByAddress.json`, 
        transObjToParamStr(params)
    )
}

function transObjToParamAirDrop(object,isSign) {
	
    let data
    if(!object){
        object={}  
    }
    if(isSign){
        let sign =  rsa.encryptAirdrop(object)
        object = {sign:sign}
    }


    let arr = [];
    for (const key in object) {
        if (Object.hasOwnProperty.call(object, key)) {
            const element = object[key];
            arr.push(`${key}=${element}`);
        }
    }
    return arr.join('&');
}
function transObjToParamStr(object,isSign) {
    console.log("xxl fix the bug 343 ...");
	let json_user = window.localStorage.getItem("key_user");

	if(json_user != null && JSON.stringify(json_user.length) != 0){
		let data = JSON.parse(json_user);
		if(!object.did || object.did==null ){
			object.did=data.did;
        }
      if(!object.authenticationAddress){
		  object.authenticationAddress= object.did 
	  }
            
         
	}
	let lang = localStorage.getItem("KEY_LANG");
	object.chainType="IRIS_IRISHUB";
	object.lang=lang;
	object.mobileVersion="1.3.2";
    let arr = [];
    // arr.push(`did=${data.did}`)
    // arr.push(`chainType = IRIS_IRISHUB`)
    // arr.push(`lang=${lang}`)
    if(isSign){
        let sign =  rsa.encrypt(object)
        object = {sign:sign}
    }
    for (const key in object) {
        if (Object.hasOwnProperty.call(object, key)) {
            const element = object[key];
            arr.push(`${key}=${element}`);
        }
    }
    return arr.join('&');
}
