<template>
    <Layout />
</template>

<script>
import Layout from "@/layout/index.vue";

export default {
  name: "App",
  components: { Layout },
};
</script>
