<template>
  <ScrollX source='feature'>
    <div class="scroll-item" v-for="(creator, i) in creators" :key="i">
      <FeaturedCreator :creator="creator" />
    </div>
  </ScrollX>
</template>

<script>
import ScrollX from "@/components/scroll/ScrollX.vue";
import FeaturedCreator from "./components/FeaturedCreator.vue";

export default {
  name: "FeaturedCreators",
  components: { ScrollX, FeaturedCreator },
  props: {
    creators: {
      type: Array,
      default: () => 	[],
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-item {
  display: inline-block;
  padding: 9px;
  line-height: 0;
  
}
</style>
