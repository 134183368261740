<template>
  <div class="contain">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        src="@/assets/icons/btn_close.png"
        alt=""
      />
      <div class="top">
        <div class="d-flex flex-row">
          <h3>{{ $t("relevance_nft") }}</h3>
          <div class="allselect">
            <!-- <v-checkbox
            :value="allCheckbox"
            @click="allcheckboxChange"
          ></v-checkbox> -->
            {{ allNftNum }}/12
          </div>
        </div>

        <div class="cardbox">
          <div
            class="list d-flex flex-column"
            v-for="(item, index) in nftList"
            :key="index + '2'"
            style="position:relative;padding-left:10px"
          >
            <div class="d-flex flex-row">
              <v-checkbox
                :key="index"
                :value="item.checked"
                @click="childcheckboxChange(index, item)"
                readonly
              ></v-checkbox>
              <div
                class="content d-flex flex-row align-center"
              >
                <img :src="item.src" alt="" />
                <p>{{ item.text }}</p>
              </div>
              <img
                class="img"
                v-if="item.collectionType == '1'"
                src="@/assets/icons/icon_z.png"
                @click="getCollectionList(item,index)"
                alt=""
                :key="index+'3'"
              />
            </div>

            <div v-if="item.expand" style="margin-left:30px;padding-left:10px;max-height:220px;overflow-y: auto;">
              <div
                class="collectionlist d-flex flex-row"
                v-for="(v, i) in item.collectionList"
                :key="i + '1'"
              >
                <v-checkbox
                  :key="i"
                  :value="v.checked"
                  @click="childcheckboxChange1(i, v, item)"
                  readonly
                ></v-checkbox>
                <div class="content d-flex flex-row align-center">
                  <img :src="v.src" alt="" />
                  <p>{{ v.text }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <button class="sub" @click="subCommit">
            {{ $t("popupTransferCommit") }}
          </button>
        </div>
      </div>
    </v-card>
    <PromptBox ref="promptBox"></PromptBox>
  </div>
</template>

<script>
import api from "@/api";
import { getFileSrc } from "@/utils/file";
import PromptBox from "@/components/PromptBox.vue";
const NFT_KEY = "CARDNFT";
export default {
  components: { PromptBox },
  data: () => ({
    collectionList: [],
    nftList: [],
    checkbox: false,
    imgSrc: "",
    src: "",
    allNftNum: 0,
    totalList: [],
    isClick: false,
    collection_id:0,
    showCollection:false,
    ex:[],
    isloading:false
  }),
  props: {
    constructID: {
      type: Number,
    },
	tokenId: {
	  type: String,
	},
    getNftList: {
      type: Array,
    },
    deductionType:{
      type: Number,
    },
    parentValue:{
      type:Object}
  },

  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  async mounted() {
    console.log("11111111",this.constructID,this.tokenId)
    await this.getList();
    // 接口请求
    this.nftList.forEach((ele) => {
      ele.checked = false;
      if(ele.collectionType == 1)
      {
        if(this.deductionType == 1)
        {
           console.log("0000000", ele.enabled)
        ele.enabled =false
        }
       
      }
    });
    this.collectionList.forEach((ele) => {
      ele.checked = false;
    });
    console.log("3333333333",this.deductionType)
    // if (this.getNftList) {
    //   this.allNftNum = this.getNftList.length;
    // }
    // for (let i = 0; i < this.nftList.length; i++) {
    //   for (let j = 0; j < this.getNftList.length; j++) {
    //     if (this.nftList[i].address == this.getNftList[j].address) {
    //       this.nftList[i].checked = true;
    //       this.$forceUpdate();
    //     }
    //   }
    // }
  },
  methods: {
    async getList() {
      let params = {
        pageNumber: "1",
        // contractId: this.constructID,
        pageSize: "200",
        // authenticationAddress: "iaa1xhhf3a80n7zamu32dyku77lactynx9sgs0vvz4",
        authenticationAddress: this.$store.state.did,
      };
        if(this.parentValue.collectionType != 1){
        params.contractId = this.parentValue.contractId
      }
	  
      let resList = await api.home.couponList(params);
     

      for (let i = 0; i < resList.data?.list.length; i++) {
        if (resList.data.list[i].imgUrl) {
          this.imgSrc = await getFileSrc(NFT_KEY, resList.data.list[i].imgUrl);
        }

        this.nftList.push({
          src: this.imgSrc,
          text: resList.data.list[i].name,
          address: resList.data.list[i].address,
          collectionType: resList.data.list[i].collectionType,
          expand: false,
          enabled:true
        });
     
      }
    },
    async getCollectionList(item,index) {
      this.$forceUpdate();
      this.collectionList=[]
       this.collection_id == 0 ? (this.collection_id!=index?this.collection_id=index:this.collection_id=index) : this.collection_id = 0;
    
    
      let params = {
        contractAddress: item.address,
         pageNumber: "1",
        pageSize: "200",
	  	tokenId:this.parentValue.tokenId
      };
    
       if(this.isloading){
        return
      }
       this.isloading = true
      let collectionList = await api.home.getCollectionList(params);
     
     
      for (let i = 0; i < collectionList.data?.list.length; i++) {
        if (collectionList.data.list[i].imgUrl) {
          this.src = await getFileSrc(
            NFT_KEY,
            collectionList.data.list[i].imgUrl
          );
        }

        this.collectionList.push({
          src: this.src,
          text: collectionList.data.list[i].name,
          tokenId: collectionList.data.list[i].tokenId,
		    address: collectionList.data.list[i].contractAddress,
          type:"nft"
        });
       
        
      
      }
      this.isloading = false
      item.collectionList = this.collectionList;
      item.expand = !item.expand;
      if(item.expand == false)
      {
        item.enabled =true
       this.allNftNum = this.allNftNum- this.ex.length
       this.ex.length = 0
        
      }
    },
    //关闭弹框，发送open值
    closeDialog() {
      this.$emit("Openselectnft", false);
    },

    childcheckboxChange(index, item) {
      if(item.collectionType == '1' && this.deductionType == 1 ){
        item.enabled = false
      }
      console.log("item.enabled",item.enabled)
      if(!item.enabled) return;

      if (item.checked == false && this.allNftNum >= 12) {
        this.$refs.promptBox.show(this.$t("zm_limit"), 1);
        //this.nftList[index].checked = false;
        //this.nftList[index].disabled = true;
        //  this.nftList[index].checked = false
      } else {
        //item.disabled = false
        this.nftList[index].checked = !this.nftList[index].checked;
        if (this.nftList[index].checked) {
          this.allNftNum++;
        } else {
          this.allNftNum--;
        }
        console.log(item.checked);
      }
      this.$forceUpdate();

      // this.allCheckbox = !this.nftList.some((v) => !v.checked);
      // c
      //   let Index = 0;
      //   if (this.nftList[index].checked) {
      //     this.totalList.push({ src: item.src, text: item.text });
      //     Index = Index++;
      //     this.allNftNum++;
      //   } else {
      //     this.totalList.splice(Index, 1);
      //   }

      console.log(this.totalList);
    },
     childcheckboxChange1(index, item, parent) {
      if (item.checked == false && this.allNftNum >= 12) {
        this.$refs.promptBox.show(this.$t("zm_limit"), 1);
        //this.nftList[index].checked = false;
        //this.nftList[index].disabled = true;
        //  this.nftList[index].checked = false
      } else {
        //item.disabled = false
       parent.collectionList[index].checked = !parent.collectionList[index].checked;
        if (parent.collectionList[index].checked) {
          this.allNftNum++;
          if(parent.checked ){
             parent.checked = false;
           this.$forceUpdate();
           this.allNftNum--;
          }
         
           
        } else {
          this.allNftNum--;
        }
        console.log(item.checked);
      }
      this.$forceUpdate();
       this.ex = parent.collectionList.filter(f => f.checked);
    
      if(this.ex.length > 0) {
        parent.enabled=false;
      } else {
        parent.enabled=true;
      }

      console.log(this.totalList);
    },
    subCommit() {
      this.totalList = this.nftList.filter((item) => item.checked);
      for (let i = 0; i < this.nftList.length; i++) {

          if(this.nftList[i].collectionList){
            for (let j = 0; j < this.nftList[i].collectionList.length; j++) {
             if(this.nftList[i].collectionList[j].checked == true)
             {
               this.totalList.push(this.nftList[i].collectionList[j])
             }
              
            }

          }
        
      }
      this.$emit("Openselectnft", false);
      console.log("11111111",this.nftList,this.totalList)
      this.$emit("getCardList", this.totalList);
    },
  },
};
</script>

<style lang="scss" scoped>
.contain {
  .card {
    width: 600px;
    height: 645px;
    background-image: linear-gradient(#ffffff, #ffffff),
      linear-gradient(#6f58d9, #6f58d9);
    background-blend-mode: normal, normal;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
    .clo {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 30px;
      height: 31px;
      background-color: #fff;
      border-radius: 50%;
      margin-right: 0;
      cursor: pointer;
    }
    .top {
      margin-left: 52px;
      padding: 20px 0;

      h3 {
        font-family:Helvetica;
        font-size: 25px;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #270645;
      }
      .allselect {
        padding-left: 340px;
        // margin-left: 40px;
        margin-bottom: 30px;
        font-weight: bold;
        span {
          height: 13px;
          font-family:Helvetica;
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 63px;
          letter-spacing: 0px;
          color: #270645;
        }
      }
      .cardbox {
        // padding-left: 28px;
        height: 453px;
        overflow-y: auto;
        .list {
          .content {
            width: 400px;
            height: 51px;
            background-image: linear-gradient(#ffffff, #ffffff),
              linear-gradient(#ffffff, #ffffff);
            background-blend-mode: normal, normal;
            box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
            border-radius: 3px;
            // padding-top:8px;
            padding-left: 9px;
            margin-left: 10px;
            cursor: pointer;
            img {
              width: 35px;
              height: 35px;
              margin-right: 12px;
              object-fit: cover;
            }
            p {
              width: 318px;
              min-height: 14px;
              margin: 0;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              -o-text-overflow: ellipsis;
            }
          }
          .img {
            margin-left: 10px;
            width: 31px;
            height: 51px;
          }
          .collectionlist {
               
          .content {
            width: 360px;
            height: 51px;
            background-image: linear-gradient(#ffffff, #ffffff),
              linear-gradient(#ffffff, #ffffff);
            background-blend-mode: normal, normal;
            box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
            border-radius: 3px;
            // padding-top:8px;
            padding-left: 9px;
            margin-left: 10px;
            cursor: pointer;
            img {
              width: 35px;
              height: 35px;
              margin-right: 12px;
              object-fit: cover;
            }
            p {
              width: 318px;
              min-height: 14px;
              margin: 0;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              -o-text-overflow: ellipsis;
            }
          }
          .img {
            margin-left: 10px;
            width: 31px;
            height: 51px;
          }
        }
        }
        
      }
      .footer {
        margin-right: 52px;
        margin-top: 20px;
        text-align: center;
        .sub {
          margin: 0 auto;
          width: 450px;
          height: 51px;
          background-image: linear-gradient(
              90deg,
              #d300fd 0%,
              #a439fe 26%,
              #7471ff 52%,
              #00fdcf 100%
            ),
            linear-gradient(#270645, #270645);
          background-blend-mode: normal, normal;
          border-radius: 25px;
          opacity: 0.9;
          font-size: 20px;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
