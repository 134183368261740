 import api from "@/api";
import Vue from "vue";
import Vuex from "vuex";


Vue.use(Vuex);

const chain = [{
    chainType: 'IRIS_IRISHUB',
    chainIdDesc: 'IRIS链',
    chainId: 'nyancat-9',
    simplify: 'IRIS'
  },
  {
    chainType: 'EVM_BSC',
    chainIdDesc: '币安智能链',
    chainId: '97',
    simplify: 'BNB'
  },
  {
    chainType: 'EVM_POLYGON',
    chainIdDesc: 'Polygon链',
    chainId: '80001',
    simplify: 'MATIC'
  },
  {
    chainType: 'EVM_ETH',
    chainIdDesc: '以太坊链',
    chainId: '42',
    simplify: 'ETH'
  },
]

export default new Vuex.Store({
  state: {
    userAddress: 'iaa1ax4p4vkzccmldun8kpvqn45xru3twd6e6fvgp0', //test
    //链的信息
    chainType: chain[0].chainType,
    //个人信息
    info: {
      nickName: '',
      did: 'iaa1ax4p4vkzccmldun8kpvqn45xru3twd6e6fvgp0', //test
      userAddress: 'iaa1ax4p4vkzccmldun8kpvqn45xru3twd6e6fvgp0' //test
    },
    did:'',
	homeScrollTop: 0,

    searchInput: undefined,

    NFTList: [],
    NFTListCursor: undefined,
    metaInfo: { }
  },
  mutations: {
    SET_DID(state, did) {
      state.did = did
    },
    SET_CHAINTYPE(state, chainType) {
      state.chainType = chainType
    },
    SET_USER_INFO(state, info) {
      state.info = info
    },
    SET_HOME_SCROLLTOP(state, top) {
      state.homeScrollTop = top
    },
    SET_SEARCH(state, input) {
      state.searchInput = input
    },
    SET_NFT_LIST(state, data = { NFTList: []}) {
      state.NFTList = data.NFTList;
      state.NFTListCursor = data.NFTListCursor
    },
    CAHNGE_META_INFO(state, metaInfo) { 
      state.metaInfo = metaInfo;
  }
  },
  actions: {
    setdid({
      commit
    }, did) {
      commit('SET_DID', did)

    },
    setChainType({
      commit
    }, chainType) {
      commit('SET_CHAINTYPE', chainType)
    },
    async setInfo({
      state,
      commit
    }) {
      const {
        chainType,
        did
      } = state;

      console.log("xxl setInfo 123...... ");
      console.log([
        chainType,
        did    
      ]);
	  if(did){
	      const response = await api.home.getMyInfo({
        chainType,
        did
      })
      console.log(response);
      console.log("xxl 000 ......");

      console.log('response', response)
      commit('SET_USER_INFO', response)	  
	  }


    },
    setSearchInput({ commit }, input) {
      commit('SET_SEARCH', input)
    },

    setList({ commit }, data = { NFTList: []}) {
      commit('SET_NFT_LIST', data)
    },
  },
  getters: {
    did: state => state.info && state.info.did
  },
  modules: {},

});