const {
  SigningStargateClient,  
  StargateClient
} = require("@cosmjs/stargate");

const {
  getAddress
} = require("../wallet")

const chainId = process.env.VUE_APP_COSMOS_CHAIN_ID;
const url =window.location.protocol+"//"+ window.location.host + "/cosmos";
let client;

async function signingStargateClient() {

  try {

    console.log("xxl signingStargateClient is ",window.keplr);
    await window.keplr.enable(chainId);
    console.log("xxl 1");
    const offlineSigner = window.getOfflineSigner(chainId);

    console.log("xxl 2");
    console.log("xxl 3",[url, offlineSigner]);
    client = await SigningStargateClient.connectWithSigner(url, offlineSigner);


    console.log("xxl 3");
    return client;
  }catch(e){
    console.log("xxl error ",e);
  }

}

client = signingStargateClient();

export async function ibcTransferFromCosmos(
  source_port,
  source_channel,
  token,
  receiver,
  timeout_timestamp

){
  // let client = await signingStargateClient();
  const fee = {
    amount: [{
      denom: 'uatom',
      amount: 2000,
  }],
    gas: "99000",
  };
  
  let accountInfo = await getAddress(2);

  try {
    let txInfo = await client.sendIbcTokens(
      accountInfo.address,
      receiver,
      token,
      source_port,source_channel,
      undefined,timeout_timestamp,fee
    );
    console.log(txInfo);
    return txInfo;
  } catch (error) {
    console.log(error);
  }
}

export async function queryTokenFromCosmos(){
  try {
    
  let accountInfo = await getAddress(2)
  const client = await StargateClient.connect(url);
  const balances = await client.getAllBalances(accountInfo.address);
  console.log("client balances ",balances);

  return balances;
  } catch (error) {
    return 0
  }


}


export async function quiryTxCosmos(tx){

  console.log("cosmosxxl ....");
  try{
    
    const client = await StargateClient.connect(url);
    let result = await client.queryClient.tx.getTx(tx);
    console.log("xxl ---- tx_result",result)
    if(result.txResponse != null && result.txResponse.code == 0 ){
      return {
        code:"0",
        log:""
      }
    }else if(result.txResponse != null && result.txResponse.code != 0){
      return {
        code:"-1",
        log:result.txResponse.rawLog
      }
    }else{
      return  {
      code:"-2",
      log:"cannot get log"
    }
    }


    }catch(e){
    return [-3,e.toString()];
    }

  }

  export async function sendTokenWithLedger(chainId,node,from,to,fee,amount){

      // See above.
      await window.keplr.enable(chainId);
      let offlineSigner = window.getOfflineSigner(chainId);
      offlineSigner.signDirect = undefined;
      const client = await SigningStargateClient.connectWithSigner(
          node,  
          offlineSigner
      )
      const result = await client.sendTokens(from, to, [amount], fee, "")
      console.log("xxl sendTokenWithLedger" ,result);

      return result;

  

  }

