import { queryTokenFromIris } from "./iris/wallet";
import { queryTokenFromCosmos } from "./cosmos/wallet";
import { queryTokenFromGravity } from "./gravity/wallet";

let currentGetBalances;
// let currentGetAddresses;
// let currentIbcTransfer;
//1.for iris,uptick and cosmos
export async function getBalances() {
    console.log('wxl --- getBalances,',currentGetBalances)
    if (currentGetBalances) {
        let balances = await currentGetBalances();
        return balances;
    }    
    
}
( async function () {
   await addIRISNetwork();
})();

//2.for iris,uptick and cosmos
export async function getAddress(chainType) {
    return getAccountInfo(chainType);
}

//chainType 0:uptick 1:iris 2:cosmos 3:gravity1gjmwgj0lduw0d75xggy3394ahq8fd2mnu0tlwm

export function init(chainType) {
    if (chainType == 1) {
        currentGetBalances = queryTokenFromIris;
        // currentIbcTransfer = ibcTransferFromIris;
    }
    else if (chainType == 2) {
        currentGetBalances = queryTokenFromCosmos;
        // currentIbcTransfer = ibcTransferFromCosmos;
    }
    else if (chainType == 3) {
        currentGetBalances = queryTokenFromGravity;
    }
}


const typeChainIdList = [
    process.env.VUE_APP_UPTICK_CHAIN_ID,
    process.env.VUE_APP_IRIS_CHAIN_ID,
    process.env.VUE_APP_COSMOS_CHAIN_ID,
    process.env.VUE_APP_GRAVITY_CHAIN_ID
] 

async function getAccountInfo(chainType) {

    try {
          console.log("xxl keplr ",window.keplr);
          if (window.keplr) {
            await window.keplr.enable(typeChainIdList[chainType]);
          }
          else {
            console.log('xxl connect keplr ');
          }
          
          const accountKeplr = await window.keplr.getKey(typeChainIdList[chainType]); 
          console.log("xxl accountKeplr 111------");
          console.log(accountKeplr);
  
          const offlineSigner = window.getOfflineSigner(typeChainIdList[chainType]);
          const accounts = await offlineSigner.getAccounts();
  
          console.log("xxl accounts",accounts);
  
          return accounts[0];
      }catch (error) {
            console.log(error);
            console.log('denied getAccountInfo ALL Keplr');
      }
      
  }

  export async function addIRISNetwork() { 
      debugger
    if (!window.getOfflineSigner || ! window.keplr) {
        console.log('Please install keplr extension');
    } else {
        if (window.keplr.experimentalSuggestChain) {
            try {
                // Keplr v0.6.4 introduces an experimental feature that supports the feature to suggests the chain from a webpage.
                // cosmoshub-3 is integrated to Keplr so the code should return without errors.
                // The code below is not needed for cosmoshub-3, but may be helpful if you’re adding a custom chain.
                // If the user approves, the chain will be added to the user's Keplr extension.
                // If the user rejects it or the suggested chain information doesn't include the required fields, it will throw an error.
                // If the same chain id is already registered, it will resolve and not require the user interactions.
                await window.keplr.experimentalSuggestChain({
                  "chainId": process.env.VUE_APP_IRIS_CHAIN_ID,
                  "chainName": "nyancat-9",
                  "rpc": process.env.VUE_APP_IRIS_NODE, 
                  "rest": process.env.VUE_APP_IRIS_REST,
                  "stakeCurrency": {
                    "coinDenom": "NYAN",
                    "coinMinimalDenom":process.env.VUE_APP_IRIS_DENOM,
                    "coinDecimals": 6
                  },
                  "bip44": {
                    "coinType": 118
                  },
                  "bech32Config": {
                    "bech32PrefixAccAddr": "iaa",
                    "bech32PrefixAccPub": "iaapub",
                    "bech32PrefixValAddr": "iaavaloper",
                    "bech32PrefixValPub": "iaavaloperpub",
                    "bech32PrefixConsAddr": "iaavalcons",
                    "bech32PrefixConsPub": "iaavalconspub"
                  },
                  "currencies": [
                    {
                      "coinDenom": "NYAN",
                      "coinMinimalDenom": "unyan",
                      "coinDecimals": 6
                    }
                  ],
                      "feeCurrencies": [
                        {
                          "coinDenom": "NYAN",
                            "coinMinimalDenom": process.env.VUE_APP_IRIS_DENOM ,
                            "coinDecimals": 6,
                            "gasPriceStep": {
                              "low": 0.01,
                              "average": 0.025,
                              "high": 0.25
                            }
                          }
                        ],
                        "coinType": 118,
                        "beta": true
					
			
				
                });

                // location.reload();
            } catch(e){
                // alert("Failed to suggest the chain");
                // location.reload();
                console.log('Failed to suggest the chain',e);
            }
        } else {
            alert("Please use the recent version of keplr extension");
        }
    }
}