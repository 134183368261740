<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        src="@/assets/btn_close.png"
        alt=""
      />
      <div class="top">
        <h3 class="end">{{ $t("account_banne")}}</h3>
        <h3 class="content">{{ $t("account_banne_des")}}</h3>
      </div>
      <div class="footer">
         <button
          class="sub v-btn uc"
          submit
          @click="cancel"
        >
       {{ $t("orderActionCancel")}}
        </button>
        <button
          class="sub v-btn uc "
          submit
          @click="appeal"
        >
        {{ $t("headerUserCenterNavAppeal")}}
        </button>
      </div>
    </v-card>
    <v-snackbar
      v-model="isShowFalse"
      color="error"
      centered
      timeout="2000"
      style="opacity: 0.8"
      ><span style="color: white; font-size: 20px">{{
        falseTitle
      }}</span></v-snackbar
    >
    <v-snackbar
      v-model="isShowSuccess"
      color="success"
      centered
      timeout="2000"
      style="opacity: 0.8"
      ><span style="color: white; font-size: 20px">{{
        successTitle
      }}</span></v-snackbar
    >
      <v-dialog v-model="openAppeal" persistent>
         <accountAppeal @getOpen="OpenApp"></accountAppeal>
      </v-dialog>
    <uComponents ref="ucom"></uComponents>
  </div>
</template>
<script>
import api from "@/api";
import AccountAppeal from "@/components/popup/accountAppeal.vue";
export default {
  data: () => ({
     openAppeal: false,
    newOpen: true,
    nftName: "",
    isShowFalse: false,
    isPay: false,
    isShowSuccess: false,
    falseTitle: "this is a error",
    successTitle: "create success",
  }),
  components: {
    AccountAppeal,
   
	},
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  mounted() {
   
  },
  methods: {
     appeal() {   
        // this.$emit("getOpenBan", false);
      this.openAppeal = true;

      
    },
    cancel(){
   this.$emit("getOpenBan", false);
    },
    closeDialog() {
      this.open = false;
      this.$emit("getOpenBan", this.open);
    },
     OpenApp(e) {
       console.log("wxl ---- OpenApp",e)
         this.openAppeal = e;
         this.$emit("getOpenBan", false);
      },
  },
};
</script>
<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  min-height: 239px;
  width: 600px;

  .top {
    display: flex;
    flex-direction: column;
    margin: 30px auto 0;

    .end {
      margin: 0 auto 13px;
      width: 100%;
      height: 21px;
      text-align: center;
      font-weight: bold!important;
      font-size: 25px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      white-space: nowrap;
    }

    .content {
      margin: 19px auto 10px;
      width: 500px;
      min-height: 22px;
      font-family: Helvetica;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      text-align: center;
    }
  }

  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }

  .footer {
    margin: 0 auto;

    .sub {
      margin: 20px;
      width: 150px;
      height: 51px;
      // background-image: linear-gradient(
      //     90deg,
      //     #d300fd 0%,
      //     #a439fe 26%,
      //     #7471ff 52%,
      //     #00fdcf 100%
      //   ),
      //   linear-gradient(#270645, #270645);
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
      font-weight: bold;;
      font-size: 20px;
      color: #ffffff;
    }
  }
  &.mobile {
    .footer {
      width: 100%;
      margin: 0;
      .sub {
        margin-left: 10%;
        width: 80%;
      }
    }
  }
}

img {
  display: inline-block;
  width: 126px;
  height: 126px;
  background-color: #8a73ff;
  border-radius: 5px;
  margin: 1px 22px 10px 52px;
}
.sub-dis {
  position: relative;
  pointer-events: none;
  background-image: linear-gradient(#766983, #766983),
    linear-gradient(#270645, #270645) !important;
  background-blend-mode: normal, normal;
  border-radius: 25px;
  opacity: 0.9;
}
.sub-dis::after {
  content: "";
  background-image: url(../../assets/loading.gif);
  background-size: 100%;
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: 110px;

  // box-shadow: 0 0 10px #fff;
  // border-radius: 50%;
}
</style>

