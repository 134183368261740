

import Vue from "vue";
import logoimg from "@/assets/default.png"
let chainObj=[
  {
      name:"IRIS",
      img:"chain_iris_w",
      chain_type:1,
      denom:"uiris",
      src:'@/assets/icons/chain_iris_w.png'
    },
   
    {
      name:"ATOM",
      img:"chain_atom_w",
      chain_type:2,
      denom:"ibc/27394FB092D2ECCD56123C74F36E4C1F926001CEADA9CA97EA622B25F41E5EB2",
      src:'@/assets/icons/chain_atom_w.png'
    },
     {
      name:"USDC",
      img:"chain_usdc_w",
      chain_type:3,
      denom:'ibc/A5A5EBF3B7A180FBA2A3CE133153F5B904AA8B085691F66A15BE8156B6722652',
      src:'@/assets/icons/chain_usdc_w.png'
    },
];
const getChainByName = (denom) => {

  let env =  process.env.VUE_APP_IRIS_CHAIN_ID
  if(env == 'nyancat-9'){
   chainObj=[
      {
          name:"IRIS",
          img:"chain_iris_w",
          chain_type:1,
          denom:"unyan",
          src:'@/assets/icons/chain_iris_w.png'
        },
       
        {
          name:"ATOM",
          img:"chain_atom_w",
          chain_type:2,
          denom:"ibc/733D9E6DE683B9726B2C6A6460566B11FAF4C075DC08861685F269537D43E24D",
          src:'@/assets/icons/chain_atom_w.png'
        },
         {
          name:"USDC",
          img:"chain_usdc_w",
          chain_type:3,
          denom:'ibc/5C465997B4F582F602CD64E12031C6A6E18CAF1E6EDC9B5D808822DC0B5F850C',
          src:'@/assets/icons/chain_usdc_w.png'
        },
    ]

  }
  if(denom != null){
    for (let index = 0; index < chainObj.length; index++) {
      if(denom == chainObj[index].denom){
          return chainObj[index].img
      }   
   }  
  }else{
    return chainObj[0].img
  }
   
}
const getImageIpfs = (ipfs, type) => {
  if(ipfs && ipfs!="null") {
  let url=`http://ipfs.upticknft.com/ipfs/${ipfs}`
      return url;
  } else {
      if(type == "avatar") {
          return logoimg;
      }
      return logoimg;
  }
}

const getChainNameByDenom = (denom) => {
  let env =  process.env.VUE_APP_IRIS_CHAIN_ID
  if(env == 'nyancat-9'){
   chainObj=[
      {
          name:"IRIS",
          img:"chain_iris_w",
          chain_type:1,
          denom:"unyan",
          src:'@/assets/icons/chain_iris_w.png'
        },
       
        {
          name:"ATOM",
          img:"chain_atom_w",
          chain_type:2,
          denom:"ibc/733D9E6DE683B9726B2C6A6460566B11FAF4C075DC08861685F269537D43E24D",
          src:'@/assets/icons/chain_atom_w.png'
        },
         {
          name:"USDC",
          img:"chain_usdc_w",
          chain_type:3,
          denom:'ibc/5C465997B4F582F602CD64E12031C6A6E18CAF1E6EDC9B5D808822DC0B5F850C',
          src:'@/assets/icons/chain_usdc_w.png'
        },
    ]

  }
  if(denom != null){
    for (let index = 0; index < chainObj.length; index++) {
      if(denom == chainObj[index].denom){
          return chainObj[index].name
      }   
   }  
  }else{
    return ''
  }
 
}

const getPriceByUsd = (denom,price) => {
  let usd_price = localStorage.getItem("KEY_USD_PRICE");
  let exchangePrice ;
  for (let index = 0; index < chainObj.length; index++) {
    if(denom == 'uiris')  {
      exchangePrice =parseFloat(Number((price * JSON.parse(usd_price).IrisPrice).toFixed(4))) 
    }else if(denom == 'ibc/27394FB092D2ECCD56123C74F36E4C1F926001CEADA9CA97EA622B25F41E5EB2')
    {
      exchangePrice =parseFloat( Number((price * JSON.parse(usd_price).AtomPrice).toFixed(4)))
    }else if(denom == 'ibc/A5A5EBF3B7A180FBA2A3CE133153F5B904AA8B085691F66A15BE8156B6722652')
    {
      exchangePrice = parseFloat(Number(price).toFixed(4));
    }else if(denom == 'unyan')
    {
      exchangePrice =parseFloat(Number((price * JSON.parse(usd_price).IrisPrice).toFixed(4)))
    }else if(denom == 'ibc/733D9E6DE683B9726B2C6A6460566B11FAF4C075DC08861685F269537D43E24D')
    {
      exchangePrice =parseFloat( Number((price * JSON.parse(usd_price).AtomPrice).toFixed(4)))
    }else if(denom == 'ibc/5C465997B4F582F602CD64E12031C6A6E18CAF1E6EDC9B5D808822DC0B5F850C')
    {
      exchangePrice = parseFloat(Number(price).toFixed(4));
    }
    
  }  
  return exchangePrice
}


export function init() {
  Vue.prototype.getImageIpfs = getImageIpfs;
    Vue.prototype.getChainByName = getChainByName;
    Vue.prototype.getChainNameByDenom = getChainNameByDenom;
    Vue.prototype.getPriceByUsd = getPriceByUsd;
}