<template>
    <v-app :class="{ 'drawer-opened': drawerOpened }">
        <Header v-model="drawerOpened" :containerId="containerId" style="z-index: 200;position: sticky;top: 0px;" v-if="show" />
        <v-main v-show="!drawerOpened">
            <v-container :id="containerId" class="content">
                <router-view :key="key"></router-view>
            </v-container>

        </v-main>
        <Footer v-if="!drawerOpened && show" />
           <!-- <v-main >
             <SystemMaintain />
        </v-main> -->
    </v-app>
</template>

<script>
    import Header from './Header.vue';
    import Footer from './Footer.vue';
    import SystemMaintain from '../../src/systemMaintain/index.vue';

    export default {
        name: 'Layout',
        components: {
            Header,
            Footer,
            SystemMaintain
        },
        data: function() {
            return {
                drawerOpened: false,
                containerId: 'container'
            };
        },
        computed: {
            key() {
                return this.$route.name !== undefined ?
                    this.$route.name + new Date() :
                    this.$route + new Date();
            },
              show: function() {
        return this.$route.name !== 'Twitter' 
    },
        },
       
        beforeMount() {
            // window.addEventListener("mousedown", function(e) {
            //     console.log(2267, e);
            //     e.preventDefault();
            //       e.stopPropagation();
            //     return false;
            // }, true)
            this.$store.dispatch('setInfo');
        }
    };
</script>

<style lang="scss" scoped>
    .drawer-opened {
        height: 100vh;
    }

    .content {
        margin: 0 auto;
        padding: 0;
        width: calc(100% - 40px);

        @media screen and (min-width: #{map-get($grid-breakpoints, 'lg')}) {
            width: 1220px;
        }
    }
</style>

<style>
    .width-100 {
        display: inline-block !important;
        width: 100% !important;
        height: auto !important;
        overflow: hidden !important;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
        -o-text-overflow: ellipsis !important;
    }

    .width-18 {
        display: inline-block !important;
        width: 180px !important;
        height: auto !important;
        overflow: hidden !important;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
        -o-text-overflow: ellipsis !important;
    }

    .width-8 {
        display: inline-block !important;
        width: 80px !important;
        height: auto !important;
        overflow: hidden !important;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
        -o-text-overflow: ellipsis !important;
    }

    .theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active),
    .theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-icon,
    .theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-btn,
    .theme--light.v-tabs>.v-tabs-bar .v-tab--disabled {
        color: #766983 !important;
    }
</style>
