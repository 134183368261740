<template>
  <div>
    <v-menu :attach="$el" right offset-y nudge-bottom="10px">
      <template v-slot:activator="{ on, attrs, value }">
        <Chip v-bind="attrs" v-on="on" v-if="show">
          <span style="color:rgba(255,255,255)">IRISnet</span>
          <v-icon class="ml-2" color="rgba(255,255,255)" v-if="value">mdi-menu-up</v-icon>
          <v-icon class="ml-2" color="rgba(255,255,255)" v-else>mdi-menu-down</v-icon>
        </Chip>
         <Chip v-bind="attrs" v-on="on" v-else>
          <img src="http://ipfs.upticknft.com/ipfs/QmcJvR5fUXNaSZQ9kMW55js1bLKNvM2tCDMmet1P6cLRGE" alt="" width="25" height="25" class="mr-lg-3 mr-0">
          <span  v-if="!isMobile">IRISnet</span>
          <v-icon class="ml-2" color="primary" v-if="value">mdi-menu-up</v-icon>
          <v-icon class="ml-2" color="primary" v-else>mdi-menu-down</v-icon>
        </Chip>
      </template>
      <MarketList :list="marketlist">
        <template v-slot:default="{ label }">
          {{ label }}
        </template>
      </MarketList>
    </v-menu>
  </div>
</template>

<script lang="js">
import Chip from '@/components/Chip.vue';
import MarketList from '@/components/MarketList.vue';

const marketlist = [
  { label: 'Uptick Chain',value:'Uptick Chain', icon:'http://ipfs.upticknft.com/ipfs/QmV2mXL5jZjuLvNGtga1UvCSkRQNEcGThMZxefvKrNGgYK' },
  { label: 'IRISnet', value:'IRISnet',icon: 'http://ipfs.upticknft.com/ipfs/QmcJvR5fUXNaSZQ9kMW55js1bLKNvM2tCDMmet1P6cLRGE' },
    { label: 'Polygon',value:'Polygon', icon: 'https://ipfs.upticknft.com/ipfs/QmPnYDKfLftUmDdRbRvbeXjLfJ5tvYeR6inFohuQDUossc ' },
  { label: 'BSC',value:'BSC', icon: 'https://ipfs.upticknft.com/ipfs/QmP8a3Lrq9oxvftJbosM3bA43yHpMVdfDdbPcBJU34Cevh' },
  { label: 'Arbitrum',value:'Arbitrum', icon: 'https://ipfs.upticknft.com/ipfs/QmXGtYVcXhwEezy9bcT7tKpqc6Stgdu25gicNdZpE4hZNp' },
  { label: 'Loopring',value:'Loopring', icon: 'http://ipfs.upticknft.com/ipfs/QmWTVBZe9RuyovjMjTsZjmghFB41vyMaJYSDRd3jp8ibgH' },
];

export default {
  name: 'LangSelect',
  components: { Chip, MarketList },
  props: {
      value: String
  },
  data: function() {
    return {
        marketlist,
        name:''
     
    };
  },

  computed: {
       show: function () {
        return this.$route.name === 'EditSpotlight' || this.$route.name === 'Spotlight'
     },
      isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },

  
  watch: {
   
  },
  methods:{
     
  },
};
</script>

<style lang="scss" scoped>
.v-menu__content{
  position:fixed !important;
  top: 75px !important;
}</style>
