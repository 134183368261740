<template>
  <v-card class="rd elevation-0" :class="{'bord':isCreateCheck,smallsmallCard:smallsmallCard ,bigCard:bigCard ,filterBigCard:filterBigCard}" >
    <Work :hash="hash" :isCreateCheck="isCreateCheck" :denomType="denomType" :value="value"  :fileUrl="fileUrl" :cardType="type" @imgClick="workClick" />
    <AuctionCardContent v-if="type === 'auction'" :value="value" />
    <SellCardContent v-else-if="type === 'sell'" :value="value" :isMinPrice="isMinPrice"/>
    <UserCard v-else-if="type === 'usercard'" :value="value" :isMinPrice="isMinPrice"/>
    <MyCardContent v-else-if="type === 'my'" :value="value"  :endType="endType" :owerfrom="owerfrom" :sourcemarket="sourcemarket" />
    <CollectCardContent  v-else-if="type === 'collect'" :value="value"  />
    <PageCardContent  v-else-if="type === 'page'" :value="value" :owerfrom="owerfrom"/>
    <OtherWorkCard v-else-if="type === 'other'" :value="value" :owerfrom="owerfrom" :selectType='selectType'/>
    <MycollectCard v-else-if="type === 'mycollect'" :value="value" />
     <MyCardContentCollection v-else-if="type === 'mycreate'" :value="value" />
    <ClearModeCard v-else-if="type === 'clearmode'" :value="value" />
  </v-card>
</template>

<script>
import Work from "@/components/work/index.vue";
import AuctionCardContent from "./AuctionCardContent.vue";
import SellCardContent from "./SellCardContent.vue";
import MyCardContent from "./MyCardContent.vue";
import MyCardContentCollection from "./MyCardContentCollection.vue";
import CollectCardContent from "./CollectCardContent.vue";
import PageCardContent from "./PageCardContent.vue";
import OtherWorkCard from "./OtherWorkCard.vue"
import MycollectCard from "./MycollectCard.vue"
import ClearModeCard from "./ClearModeCard.vue";
import UserCard from "./userCard.vue";


export default {
  name: "Card",
  components: { UserCard,OtherWorkCard,Work, AuctionCardContent, SellCardContent, MyCardContent,CollectCardContent,PageCardContent,MycollectCard,MyCardContentCollection,ClearModeCard},
  props: {
    isCreateCheck:{
      type:Boolean
    },
    value: {
      type: Object,
    },
    type: {
      type: String, // auction / sell / my / collect
    },
    owerfrom: {
      type: String,
    },
     sourcemarket: {
      type: String,
    },
    
    favorite:{
      type:String
    },
    isMinPrice:{
      type:String
    },
    isopac:{
      type:Boolean
    },
    endType:{
       type:String
    },
    smallCard:{
      type:Boolean
    },
    smallsmallCard:{
       type:Boolean
    },
    bigCard:{
       type:Boolean
    },
    filterBigCard:{
       type:Boolean
    },
    selectType:{
      type:Number
    },
   
  },
  data: () => ({
    loading: false,
    selection: 1,
    
  }),
  computed: {
    hash: function () {
      return this.value && this.value.imgUrl;
    },
    denomType: function () {
      return this.value && this.value.denomType;
    },
    fileUrl: function () {
      return this.value && this.value.fileUrl;
    },
  },
  methods: {
    reserve() {
      this.loading = true;
      setTimeout(() => (this.loading = false), 2000);
    },
    mounted(){
      console.log("999999999999999",this.isCreateCheck)

      
    },

	workClick() {
    debugger
    if (this.owerfrom == "personalPage") {
      this.$router.push({ name: "Carddetail", query: {contractAddress: this.value.contractAddress, tokenId: this.value.tokenId,owner:this.value.nftOwner } });
    } else if (this.owerfrom == "auction") {
	
		if(this.value.auctionNo==null){
			 this.$router.push({ name: "Saledetail", query: { id: this.value.id } });
		}else{
			this.$router.push({ name: "Detail", query: { auctionNo: this.value.auctionNo } });
		}
      
    } else if (this.owerfrom == "myCreations") {
      if(this.value.useType===1){
		 	this.$router.push({ name: "Detail", query: { auctionNo: this.value.auctionNo } });
      }else{
        if(this.value.collectionType == 1)
      {
        this.$router.push({ name: "Saledetail", query: { id: this.value.id,tokenId: this.value.tokenId } });
      }else{
         this.$router.push({ name: "Saledetail", query: { id: this.value.id } });
      }
      }
	
    } 
    else if (this.owerfrom == "my") {
    this.$router.push({name: "Carddetail", query: {contractAddress: this.value.contractAddress, tokenId: this.value.tokenId ,owner:this.value.nftOwner} });
    
      }else if(this.owerfrom == "create") {
        console.log(this.owerfrom);
    }else if(this.owerfrom=="collectionPage"){
      console.log(this.owerfrom);
       if(this.value.useType===1){
		 	this.$router.push({ name: "Detail", query: { auctionNo: this.value.auctionNo } });
      }else{
        console.log("00000000000 ----- ---- --")
                 if(this.value.collectionType == 1)
      {
        this.$router.push({ name: "Saledetail", query: { id: this.value.id,tokenId: this.value.tokenId } });
      }else{
        this.$router.push({ name: "Saledetail", query: { id: this.value.id } });
      }
      //  this.$router.push({ name: "Saledetail", query: { id: this.value.contractId,tokenId: this.value.tokenId } });
      }
    }else if(this.owerfrom=="createPage")
    {
       if(this.value.useType===1){
		 	this.$router.push({ name: "Detail", query: { auctionNo: this.value.auctionNo } });
      }else{
       this.$router.push({ name: "Saledetail", query: { id: this.value.contractId,tokenId: this.value.tokenId } });
      }
    }else if(this.owerfrom== "folderPage")
    {
       if(this.value.useType===1){
		 	this.$router.push({ name: "Detail", query: { auctionNo: this.value.auctionNo } });
      }else{
       this.$router.push({ name: "Saledetail", query: { id: this.value.id } });
      }
  
    } 
     else if(this.owerfrom=="resale"){
         let query = { };
         query.contractAddress = this.value.contractAddress;
          query.tokenId = this.value.tokenId;
          query.owner = this.value.nftOwner;

        if(this.value.useType == '1'){
         
      this.$router.push({ name: "Detail", query });
        }else{
           this.$router.push({ name: "Saledetail", query });
        }
      }else if(this.owerfrom== "cardPage"){
      this.$router.push({ name: "Carddetail", query: { contractAddress: this.value.contractAddress, tokenId: this.value.tokenId,owner:this.value.nftOwner } });

    }else if(this.type == "sell")
    {
      if(this.value.useType===1){
        	this.$router.push({ name: "Detail", query: { auctionNo: this.value.auctionNo } });
      }else{
           if(this.value.collectionType == 1)
      {
        this.$router.push({ name: "Saledetail", query: { id: this.value.id,tokenId: this.value.tokenId } });
      }else{
        this.$router.push({ name: "Saledetail", query: { id: this.value.id } });
      }
      }
      
    }else if(this.type == "page")
    {
      if(this.value.useType===1){
        	this.$router.push({ name: "Detail", query: { auctionNo: this.value.auctionNo } });
      }else{
            if(this.value.collectionType == 1)
      {
        this.$router.push({ name: "Saledetail", query: { id: this.value.id,tokenId: this.value.tokenId } });
      }else{
        this.$router.push({ name: "Saledetail", query: { id: this.value.id } });
      }
      }
     
    }
    else if(this.type == "other")
    {
     let query = { };
        query.contractAddress = this.value.contractAddress;
          query.tokenId = this.value.tokenId;
          query.owner = this.value.nftOwner;
        if(this.value.useType== '1'){
         
      this.$router.push({ name: "Detail", query });
        }else{
           this.$router.push({ name: "Saledetail", query });
        }
        
    
      
    }
    else{
      
      if(this.type == "collect" )
      {
    
           let query = { };
        query.contractAddress = this.value.contractAddress;
          query.tokenId = this.value.tokenId;
          query.owner = this.value.nftOwner;
        if(this.value.useType== '1'){
         
      this.$router.push({ name: "Detail", query });
        }else{
           this.$router.push({ name: "Saledetail", query });
        }
      
      }else{
      let query = { };
         query.nftAddress = this.value.nftAddress;
          query.nftId = this.value.nftId;
          query.owner = this.value.nftOwner
        if(this.value.useType== '1'){
         
      this.$router.push({ name: "Detail", query });
        }else{
           this.$router.push({ name: "Saledetail", query });
        }
        
      }
     
     
    }
    // window.location.reload();
	},
	forceUpdate() {
		this.$forceUpdate();
	}
  },
};
</script>

<style lang="scss" scoped>
.v-card.rd {
  // overflow: hidden;
  width: 100%;
  min-height: unset;
  // justify-content: center;
  background-image: linear-gradient(#ffffff, #ffffff),
    linear-gradient(#6f58d9, #6f58d9);
  background-blend-mode: normal, normal;
  // box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.09);
  border-radius: 5px;
  border: 1px solid #cccccc;
  &.bord{
 border: 2px solid #7800f4 !important;
  }  

  &:hover  {
    box-sizing: border-box;
    border-radius: 5px;
    border: solid 2px #7800f4;
    
  }

  //  &::v-deep .v-sheet--outlined {
  //    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.09);
  // }

  @media screen and (max-width: 500px ) {

  }

  @media screen and (min-width: #{map-get($grid-breakpoints, 'lg')}) {
    width: 220px;
    background-image: linear-gradient(#ffffff, #ffffff),
      linear-gradient(#ffffff, #ffffff);
    background-blend-mode: normal, normal;
    // box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
  }
}

.v-card.smallCard {

  @media screen and (min-width: #{map-get($grid-breakpoints, 'lg')}) {
    width: 212px;
    background-image: linear-gradient(#ffffff, #ffffff),
      linear-gradient(#ffffff, #ffffff);
    background-blend-mode: normal, normal;
    // box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
  }
}
.v-card.smallsmallCard {

  @media screen and (min-width: #{map-get($grid-breakpoints, 'lg')}) {
    width: 220px;
    background-image: linear-gradient(#ffffff, #ffffff),
      linear-gradient(#ffffff, #ffffff);
    background-blend-mode: normal, normal;
    // box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
  }
}
.v-card.showCaseCard {

  @media screen and (min-width: #{map-get($grid-breakpoints, 'lg')}) {
    width: 100%;
    background-image: linear-gradient(#ffffff, #ffffff),
      linear-gradient(#ffffff, #ffffff);
    background-blend-mode: normal, normal;
    // box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
  }
}

.v-card.bigCard {

  @media screen and (min-width: #{map-get($grid-breakpoints, 'lg')}) {
    width: 280px;
    background-image: linear-gradient(#ffffff, #ffffff),
      linear-gradient(#ffffff, #ffffff);
    background-blend-mode: normal, normal;
    // box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
  }
}
.v-card.filterBigCard {

  @media screen and (min-width: #{map-get($grid-breakpoints, 'lg')}) {
    width: 298px;
    background-image: linear-gradient(#ffffff, #ffffff),
      linear-gradient(#ffffff, #ffffff);
    background-blend-mode: normal, normal;
    // box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
  }
}
</style>
