// VUE_APP_API_URL +  api
export const BASE_URL = `/uptickapi1`;
export const TWITTER_URL = `/twitterapi`;

// image base url
export const BASE_IMAGE_URL = `${process.env.VUE_APP_FILE_API_URL}/v2/image`;

// video base url
export const BASE_VIDEO_URL = `${process.env.VUE_APP_FILE_API_URL}/file`;

//TWITTER_URL
