<template>
  <div
    style="overflow: hidden; height: 125px"
    class="pb-3 cards"
    :class="{ mobile: isMobile }"
  >
    <v-card-title class="text--title px-5 py-4"
      >{{ value.name ? value.name : "No Name" }}
    </v-card-title>
    <v-card-text
      class="d-flex flex-row align-center justify-space-between px-5 pb-6"
      style="height: 49px"
    >
      <div
        class="d-flex flex-row mt-2"
        v-if="
          value.useType != '1' &&
          value.amount != 0 &&
          value.onsalePrice &&
          value.useType != 6
        "
      >
        <div>
          <v-img
            :src="
              require('@/assets/icons/' +
                getChainByName(value.coinDenom) +
                '.png')
            "
            alt="logo"
            width="22px"
            height="22px"
          />
        </div>
        <div
          class="ml-2 text--number text--primary-rd"
          style="line-height: 22px;font-weight:bold;!important"
        >
          {{ value.onsalePrice }}
        </div>
      </div>

      <div
        v-else-if="value.listAmount == 0 && value.amount > 0 && !isMobile"
        class="nobid"
      >
        {{ $t("NotListedYet") }}
      </div>
      <img
        src="@/assets/icons/icon_code.png"
        width="20px"
        height="20px"
        alt=""
        v-if="value.useType == 6 && value.saleStatus != '6'"
        @click="showQRcode"
      />

      <v-spacer></v-spacer>

      <div class="d-flex flex-row align-center" style="position: relative">
        <!-- 跨链 -->
        <img
        v-if='value.offsaleTokenIds.length > 0'

          class="ml-5"
          src="@/assets/icons/icon_cross.png"
          width="25px"
          height="25px"
          @click="openCrossChain"
          @mouseenter="mousedCross()"
          @mouseleave="leaveCross()"
          alt=""
        />
        <!-- 销毁弹层按钮 -->
        <img
          class="ml-3"
          src="@/assets/icons/icon_burn.png"
          width="25px"
          height="25px"
          @click="burnBtn"
          v-if="value.isBurn == true"
          alt=""
        />
        <!-- 销售统计弹层按钮 -->
        <img
          v-if="value.creatorOwner == this.$store.state.did"
          class="ml-3"
          src="@/assets/icons/icon_data.png"
          width="25px"
          height="25px"
          style="z-index: 1"
          @click="saleTotalBtn"
          alt=""
        />
        <div class="crossdes" v-if="showCross">
          <div class="name">Cross-chain transfer</div>
        </div>
      </div>
    </v-card-text>
    <v-card-text class="py-0 px-5" style="height: 44px">
      <template
        v-if="
          value.useType == '0' ||
          value.useType == null ||
          value.useType == '3' ||
          value.useType == '4' ||
          value.useType == '5'
        "
      >
        <!-- 被禁止/被投诉 -->
        <template v-if="value.disabled >= 2">
          <div
            v-if="value.disabled == '4'"
            class="d-flex justify-space-between"
          >
            <div
              v-if="value.creatorOwner != this.$store.state.did"
              class="d-flex flex-row"
            >
              <img
                class="mt-n2 ban"
                src="@/assets/icons/ban.png"
                width="20px"
                height="20px"
                alt=""
                @mouseenter="moused()"
                @mouseleave="leave()"
              />
              <div class="explain d-flex flex-row align-center" v-if="show">
                <span>{{ $t("restricted_nft") }}</span>
              </div>
            </div>
            <div v-else class="d-flex flex-row">
              <div class="mt-n2 text-wrap text--info text--secondary-rd">
                {{ $t("workCardSuspected") }}
              </div>
              <v-btn
                class="haveAppeal mt-n2 mr-n3"
                dark
                rounded
                width="100"
                height="28"
              >
                {{ $t("headerUserCenterNavAppealed") }}
              </v-btn>
            </div>
          </div>
          <div v-else class="d-flex justify-space-between">
            <img
              class="mt-n2 ban"
              src="@/assets/icons/ban.png"
              width="20px"
              height="20px"
              alt=""
              @mouseenter="moused()"
              @mouseleave="leave()"
            />
            <v-btn
              v-if="
                value.disabled == 2 &&
                value.creatorOwner == this.$store.state.did
              "
              class="sale ml-4"
              dark
              rounded
              width="100"
              height="28"
              @click="AppealBtn"
              >{{ $t("workCardAppeal") }}
            </v-btn>
            <div class="explain d-flex flex-row align-center" v-if="show">
              <span>{{ $t("restricted_nft") }}</span>
            </div>
          </div>
        </template>
        <!-- 进行中 -->
        <template
          v-else-if="
            value.saleStatus == '6' ||
            value.saleStatus == '7' ||
            value.saleStatus == '8' ||
            value.saleStatus == '9' ||
            value.saleStatus == '31'
          "
        >
          <div
            class="d-flex justify-center align-center pt-1 text--info text--secondary-rd"
          >
            {{ $t("workCardInprogress") }}
          </div>
        </template>
        <!-- 已上架，显示下架按钮 -->
        <template v-else-if="value.onsaleTokenIds.length > 0">
          <v-btn
            class="sale"
            dark
            rounded
            depressed
            width="178"
            height="28"
            @click="EndSaleBtn"
            >{{ $t("popupSoldoutEndsale") }}
          </v-btn>
        </template>
        <!-- 已下架，显示上架按钮 -->
        <template v-else-if="value.offsaleTokenIds.length > 0">
          <div class="d-flex justify-space-between" style="margin-top: -8px">
            <div class="btnbox">
              <v-btn class="rd" rounded @click="TransferBtn"
                >{{ $t("popupNewTransferTransfer") }}
              </v-btn>
            </div>
            <!-- 收藏部分的上架按钮  替换为 上架按钮 -->
            <template>
              <v-btn
                class="satrtsale uc"
                dark
                rounded
                depressed
                @click="putOnSaleBtn"
                >{{ $t("popupMarketStartSale") }}
              </v-btn>
            </template>
          </div>
        </template>
        <!-- 售完状态 -->
        <template v-else-if="value.amount == 0">
          <div class="sold_out">{{ $t("workCardSoldOut") }}</div>
        </template>
      </template>
      <!-- <template v-if="value.sellout == true">
          <div class="sold_out">{{ $t("workCardSoldOut") }}</div>
        </template> -->
      <!-- 拍卖 -->
      <template v-if="value.useType == '1'">
        <!-- 被禁止/被投诉 -->
        <template v-if="value.disabled >= 2">
          <div v-if="value.disabled == 4" class="d-flex justify-space-between">
            <div class="mt-n2 text-wrap text--info text--secondary-rd">
              {{ $t("workCardSuspected") }}
            </div>
            <v-btn
              class="haveAppeal mt-n2 mr-n3"
              dark
              rounded
              width="100"
              height="28"
            >
              {{ $t("headerUserCenterNavAppealed") }}
            </v-btn>
          </div>
          <div v-else class="d-flex justify-space-between">
            <!-- <div class="mt-1 text-wrap text--info text--secondary-rd">
                  {{ $t("workCardSuspected") }}
               </div> -->
            <img
              class="mt-n2"
              src="@/assets/icons/ban.png"
              width="20px"
              height="20px"
              alt=""
              @mouseenter="moused()"
              @mouseleave="leave()"
            />
            <v-btn
              v-if="
                value.disabled == 2 &&
                value.creatorOwner == this.$store.state.did
              "
              class="sale ml-4"
              dark
              rounded
              width="100"
              height="28"
              @click="AppealBtn"
              >{{ $t("workCardAppeal") }}
            </v-btn>
            <div class="explain d-flex flex-row align-center" v-if="show">
              <span>Suspected of violations and has been restricted.</span>
            </div>
          </div>
        </template>

        <!-- 进行中 -->
        <template
          v-else-if="
            value.saleStatus == '4' ||
            value.saleStatus == '5' ||
            value.saleStatus == '6' ||
            value.saleStatus == '7' ||
            value.saleStatus == '8' ||
            value.saleStatus == '9'
          "
        >
          <div
            class="d-flex justify-center align-center pt-1 text--info text--secondary-rd"
          >
            {{ $t("workCardInprogress") }}
          </div>
        </template>

        <!-- 活动未开始 -->
        <template
          v-else-if="value.auctionVo && value.auctionVo.auctionStatus == '0'"
        >
          <div class="d-flex flex-column mt-n12">
            <div>
              <div class="text--sub-title text--secondary-rd">
                {{ $t("popupAuctionStartingBid") }}
              </div>
              <div class="d-flex flex-row">
                <div>
                  <v-img
                    :src="
                      require('@/assets/icons/' +
                        getChainByName(value.coinDenom) +
                        '.png')
                    "
                    alt="logo"
                    width="22px"
                    height="22px"
                  />
                </div>
                <div
                  class="ml-2 text--number text--primary-rd font-weight-medium"
                  style="line-height: 22px"
                >
                  {{ value.auctionVo.lastBidPrice }}
                </div>
              </div>
            </div>
            <div>
              <div class="text--sub-title text--secondary-rd mt-2">
                {{ $t("popupAuctionStartDate") }}
              </div>
              <div class="mt-1">
                <div class="text--number text--primary-rd font-weight-medium">
                  {{ hour }}h {{ minutes }}m {{ second }}s
                </div>
              </div>
            </div>
          </div>
          <v-btn
            class="withdraw"
            dark
            rounded
            depressed
            width="91"
            height="28"
            @click="AbanBtn"
            >{{ $t("Withdraw") }}
          </v-btn>
        </template>

        <!-- <template v-if="value.auctionVo.auctionStatus == '4' || value.auctionVo.auctionStatus == '5'">
        <div
          class="d-flex justify-center align-center pt-1 text--info text--secondary-rd"
        >
     {{ $t("workCardInprogress") }}
        </div>
      </template> -->
        <!-- 拍卖开始 -->
        <template
          v-else-if="
            value.auctionVo.auctionStatus == '1' ||
            value.auctionVo.auctionStatus == '3'
          "
        >
          <div class="d-flex flex-column mt-n12">
            <div>
              <div class="text--sub-title text--secondary-rd">
                {{ $t("workCardCurrentBid") }}
              </div>
              <div class="d-flex flex-row">
                <div>
                  <div>
                    <v-img
                      :src="
                        require('@/assets/icons/' +
                          getChainByName(value.auctionVo.coinDenom) +
                          '.png')
                      "
                      alt="logo"
                      width="22px"
                      height="22px"
                    />
                  </div>
                </div>
                <div
                  class="ml-2 text--number text--primary-rd font-weight-medium"
                  style="line-height: 22px"
                >
                  {{ value.auctionVo.lastBidPrice }}
                </div>
              </div>
            </div>
            <div class="mt-1">
              <div class="text--sub-title text--secondary-rd mt-1">
                {{ $t("workCardEndingin") }}
              </div>
              <div class="mt-1">
                <div class="text--number text--primary-rd font-weight-medium">
                  {{ hour }}h {{ minutes }}m {{ second }}s
                </div>
              </div>
            </div>
          </div>
          <v-btn
            class="withdraw"
            dark
            rounded
            depressed
            width="91"
            height="28"
            @click="AbanBtn"
            v-if="
              value.auctionVo.lastBidPrice != null && !value.auctionVo.hasBid
            "
            >{{ $t("Withdraw") }}
          </v-btn>

          <!-- 拍卖完了 -->

          <template v-else-if="value.auctionVo.auctionStatus == '5'">
            <div class="mt-n11">
              <div class="text--sub-title text--secondary-rd">
                {{ $t("Final_price") }}
              </div>
              <div class="d-flex flex-row mt-2">
                <div>
                  <v-img
                    :src="
                      require('@/assets/icons/' +
                        getChainByName(value.auctionVo.coinDenom) +
                        '.png')
                    "
                    alt="logo"
                    width="22px"
                    height="22px"
                  />
                </div>
                <div
                  class="ml-2 text--number text--primary-rd font-weight-medium"
                  style="line-height: 22px"
                >
                  {{ value.auctionVo.lastBidPrice }}
                </div>
              </div>
            </div>
            <div class="auction_out">{{ $t("workCardSoldOut") }}</div>
          </template>
        </template>
      </template>
      <template v-if="value.useType == '6'">
        <template
          v-if="
            value.saleStatus == '6' ||
            value.saleStatus == '7' ||
            value.saleStatus == '8' ||
            value.saleStatus == '9'
          "
        >
          <div
            class="d-flex justify-center align-center pt-1 text--info text--secondary-rd"
          >
            {{ $t("workCardInprogress") }}
          </div>
        </template>
        <template v-else>
          <v-btn
            class="sale"
            dark
            rounded
            depressed
            width="178"
            height="28"
            @click="WithdrawBtn"
            >{{ $t("Withdraw") }}
          </v-btn>
        </template>
      </template>

      <!-- 被禁止/被投诉 -->
    </v-card-text>

    <!-- 弹层部分 -->
    <!-- 申诉 -->
    <v-dialog v-model="Appeal">
      <nftAppeal
        :Nft_sku="value"
        appealType="commonAppeal"
        @getOpen="Open"
      ></nftAppeal>
    </v-dialog>
    <!-- 转送/新建转送 -->
    <v-dialog v-model="Transfer">
      <new-transfer
        :imgUrl="imgUrl"
        :address="address"
        :level="1"
        :name="name"
        :contracId="value.id"
        :offSaleTokenIds="offSaleTokenIds"
        :parentValue="value"
        @getOpen="opentransfer"
        @promptBoxCommit="promptBoxCommit"
      ></new-transfer>
    </v-dialog>
    <!-- 新增 上架选择 -->
    <v-dialog v-model="openputonsale">
      <put-on-sale
        @getOpen="openPutOnSale"
        :address="address"
        :onSaleTokenIds="onSaleTokenIds"
        :offSaleTokenIds="offSaleTokenIds"
        :supplyLimit="supplyLimit"
        :name="name"
        :parentValue="value"
        :sourcemarket="sourcemarket"
        v-bind="$attrs"
        v-on="$listeners"
      ></put-on-sale>
    </v-dialog>
    <!-- 销毁 -->
    <v-dialog v-model="Burning">
      <burn v-if="Burning" :pageValue="value" @getOpen="openBurn"></burn>
    </v-dialog>
    <!-- 结束售卖 -->
    <v-dialog v-model="Sold">
      <soldout
        :address="address"
        :onSaleTokenIds="onSaleTokenIds"
        :offSaleTokenIds="offSaleTokenIds"
        :name="value.name"
        :parentValue="value"
        :sourcemarket="sourcemarket"
        @getOpen="openSold"
      ></soldout>
    </v-dialog>
    <!-- 销售统计 -->
    <v-dialog v-model="Total">
      <saletotal
        v-if="Total"
        :pageData="value"
        :address="address"
        :id="value.id"
        :name="value.name"
        :minPrice="value.minPrice"
        @getOpen="openTotal"
      ></saletotal>
    </v-dialog>
    <!-- 卖完了 -->
    <v-dialog v-model="Saleout">
      <soldout @getOpen="openSaleout"></soldout>
    </v-dialog>
    <!-- 未达到目的  放弃   / 及 撤销 使用  -->
    <v-dialog v-model="openaban">
      <auction-aban :parentValue="value" @getOpen="openAban"></auction-aban>
    </v-dialog>

    <!-- 达到目的待确认 -->
    <v-dialog v-model="opendeal">
      <auction-in-sure
        :auctionNo="auctionNo"
        @getOpen="openDeal"
      ></auction-in-sure>
    </v-dialog>
    <!-- 被投诉提醒框 -->
    <v-dialog v-model="Banprompt">
      <banprompt v-if="Banprompt" @getOpenBan="openBanprompt"></banprompt>
    </v-dialog>
    <v-dialog v-model="OpenQrCode">
      <receiveQrCode
        v-if="OpenQrCode"
        @getOpenQRcode="openQrCode"
        :Nft_sku="value"
      ></receiveQrCode>
    </v-dialog>
    <v-dialog v-model="OpenWithDraw">
      <withDraw
        v-if="OpenWithDraw"
        @getOpenDraw="OpenWithDraws"
        :parentValue="value"
        :name="value.name"
        :address="address"
      ></withDraw>
    </v-dialog>
    <!-- 跨链 crossChainTransfer-->
    <v-dialog v-model="crossChain">
      <CrossChainTransfer
        :parentValue="value"
        @getOpen="OpenCrossChain"
      ></CrossChainTransfer>
    </v-dialog>
    <!-- 跨链成功提示层 -->
    <v-dialog v-model="crossSuccess">
      <CrossSuccess
        :parentValue="value"
        @getOpen="OpenCrossSuccess"
      ></CrossSuccess>
    </v-dialog>
  </div>
</template>

<script lang='js'>
  import NftAppeal from "@/components/popup/nftAppeal.vue";
  // import Transfer from "@/components/popup/transfer.vue";
  import newTransfer from "@/components/popup/newTransfer.vue";
  import Burn from "@/components/popup/burn.vue";
  import Soldout from "@/components/popup/soldout.vue";
//   import withDraw from "@/components/popup/withDraw.vue";
  import Saletotal from "@/components/popup/saletotal.vue";
  import PutOnSale from "@/components/popup/putOnSale.vue";
  // import countLogo from "@/assets/icons/chain_iris_w.png";
  import AuctionAban from "@/components/popup/auctionAban.vue";
  import Market from "@/components/popup/market.vue";
  import Banprompt from "@/components/popup/banprompt.vue";
  import CrossChainTransfer from "@/components/popup/crossChainTransfer.vue";
  import CrossSuccess from "@/components/popup/crossSuccess.vue";
  
  //crossSuccess
//   import receiveQrCode from "@/components/popup/receiveQrCode.vue";
 


  export default {
    components: {
      NftAppeal,
      Burn,
      Soldout,
      Saletotal,
      newTransfer,
      PutOnSale,
      AuctionAban,
      Market,
      Banprompt,
      CrossChainTransfer,
      CrossSuccess
    
    },
    name: "MyCardContent",
    props: {
      value: {
        type: Object
      },
      sourcemarket:{
        type:String
      }
    },
    data: function() {
      return {
        crossSuccess:false,
        Banprompt:false,
        OpenQrCode:false,
        OpenWithDraw:false,
        show:false,
        Appeal: false,
        Transfer: false,
        openputonsale: false,
        Burning: false,
        Sold: false,
        Total: false,
        Saleout: false,
        openaban: false,
        openisdeal: false,
        opendeal: "",
         countLogo:'',
        now: "",
        start: "",
        end: "",
        insure: "",
        day: 0,
        hour: 0,
        minutes: 0,
        second: 0,
        seconds: "",
        hh: 0,
        mm: 0,
        ss: 0,
        moreseconds: "",
        address: "",
        auctionNo: "",
        imgUrl: "",
        name: "",
        level: 1,
        balance: "",
        onSaleTokenIds: "",
        offSaleTokenIds: "",
        supplyLimit: "",
        avliableLenth: 0,
        minPrice: "",
        nftId: "",
        auctionStatus:'',
        nftType: "",
        crossChain:false,
        showCross:false
      };
    },
    mounted() {
      this.init();
    },
     computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
    methods: {
        mousedCross() {
        this.showCross = true
      },
      leaveCross() {
        this.showCross = false
      },
       init() {
            this.Time(); //调用定时器
            this.address = this.value.contractAddress;
            this.auctionNo = this.value.auctionNo;
            this.imgUrl = this.value.imgUrl;
            this.name = this.value.name;
            this.nftId = this.value.nftId;
            this.onSaleTokenIds = this.value.onsaleTokenIds;
            this.offSaleTokenIds = this.value.offsaleTokenIds;
            this.supplyLimit = this.value.amount;
            // this.auctionStatus = this.value.auctionStatus;
            this.nftType = this.value.nftType;
            if (this.value.offsaleTokenIds != null ) {
               this.avliableLenth = this.value.offsaleTokenIds.length;
            }
            if (this.value.onSaleTokenIds != null) {
               this.avliableLenth += this.value.onSaleTokenIds.length;
            }
       },
      getTime() {
        if(this.value.auctionVo){
           this.now = Date.parse(new Date());
        this.start = this.value.auctionVo.startTime;
        this.end = this.value.auctionVo.endTime;
        // this.start = Date.parse(new Date("2022-01-10 14:18:00"));
        // this.end = Date.parse(new Date("2022-01-10 14:19:00"));
        this.insure = 1000 * 60 * 60 * 48 + this.end;//48小时 暂时1小时

        if (this.value.auctionVo.auctionStatus == 4 || this.value.auctionVo.auctionStatus == 0 || this.value.auctionVo.auctionStatus == 1) {
          if(this.value.auctionVo.auctionNoBid&&this.value.auctionVo.auctionNoBid==true){
			   this.insure = 1000 * 60 * 60 * 1 + this.end;//1小时 流拍1小时
		  }
		 
		  
		  if (this.now < this.start) {
            this.seconds = (this.start - this.now) / 1000;
          } else if (this.now < this.end) {
            this.seconds = (this.end - this.now) / 1000;
          } else if (this.now < this.insure) {
            this.moreseconds = (this.insure - this.now) / 1000;
          }
        }
        if (this.value.auctionVo.auctionStatus == 5) {
          if (this.insure > this.now) {
            this.moreseconds = (this.insure - this.now) / 1000;
          } else {
            this.moreseconds = 0;
          }

        }
        }
       
      },
      // 天 时 分 秒 格式化函数
      countDown() {
        if (this.seconds > 0) {
          let d = parseInt(this.seconds / (24 * 60 * 60));
          let h = parseInt(((this.seconds / (60 * 60)) % 24) + d * 24);
          this.hour = h < 10 ? "0" + h : h;
          let m = parseInt((this.seconds / 60) % 60);
          this.minutes = m < 10 ? "0" + m : m;
          let s = parseInt(this.seconds % 60);
          this.second = s < 10 ? "0" + s : s;
        }
      },
      moreCountDown() {
        if (this.moreseconds > 0) {
          let d = parseInt(this.moreseconds / (24 * 60 * 60));
          let h = parseInt(((this.moreseconds / (60 * 60)) % 24) + d * 24);
          this.hh = h < 10 ? "0" + h : h;
          let m = parseInt((this.moreseconds / 60) % 60);
          this.mm = m < 10 ? "0" + m : m;
          let s = parseInt(this.moreseconds % 60);
          this.ss = s < 10 ? "0" + s : s;
        }
      },
      //定时器没过1秒参数减1
      Time() {
        setInterval(() => {
          if (this.seconds > 0) {
            this.seconds -= 1;
            this.countDown();
          } else if (this.moreseconds > 0) {
            this.moreseconds -= 1;
            this.moreCountDown();
          } else {
            this.getTime();
          }

        }, 1000);
      },
      moused() {
				this.show = true
			},
			leave() {
				this.show = false
			},
      AbanBtn() {
        this.openaban = true;
      },
      openAban(e) {
        this.openaban = e;
      },
      iSdealBtn() {
        this.openisdeal = true;
      },
      openisDeal(e) {
        this.openisdeal = e;
      },
      dealBtn() {
        this.opendeal = true;
      },
      openDeal(e) {
        this.opendeal = e;
      },
      //申诉弹层
      AppealBtn() {
        this.Appeal = true;
      },
      Open(e) {
        this.Appeal = e;
      },
      //转送弹层
      TransferBtn() {
         this.$gtag.event('转送', { 'event_category': 'Click', 'event_label': '转送' })
        this.Transfer = true;
      },
       			 openBanprompt(e){
         this.Banprompt = e
      },
      openQrCode(e){
         this.OpenQrCode = e
      },
      OpenWithDraws(e){
        this.OpenWithDraw = e
      },
      opentransfer(e) {
        if(e == 1) {
           this.Transfer = false;
           location.reload();
        } else {
           this.Transfer = e;
        }
      },
      //开始售卖/拍卖弹层
      putOnSaleBtn() {
         let json = localStorage.getItem("key_user_grade");
      		let jsonObj = JSON.parse(json)

         if(jsonObj.grade == 2){
               this.Banprompt = true

            }else{
               this.openputonsale = true;
            }
      },
      openPutOnSale(e) {
         if(e == 1) {
            this.openputonsale = false;
            location.reload();
         } else {
            this.openputonsale = e;
         }
      },
       OpenCrossChain(e){
        this.crossChain = e
        // if(!e){
        //   this.crossSuccess = true
        // }
      },
      OpenCrossSuccess(e){
        this.crossSuccess =e
      },
      //销毁弹层
      burnBtn() {
        this.$gtag.event('销毁', { 'event_category': 'Click', 'event_label': '销毁' })
        this.Burning = true;
      },
      openCrossChain(){
         this.$gtag.event('跨链操作', { 'event_category': 'Click', 'event_label': '跨链操作' })
        this.crossChain = true
      },
      showQRcode(){
         this.OpenQrCode =true
      },
      WithdrawBtn(){
       this.OpenWithDraw = true
      },
      openBurn(e) {
         if(e == 1) {
            this.Burning = false;
            location.reload();
         }
         else {
            this.Burning = e;
         }
      },
      // 结束售卖弹层
      EndSaleBtn() {
        this.Sold = true;
      },
      openSold(e) {
         if(e == 1) {
            this.Sold = false;
            location.reload();
         } else {
            this.Sold = e;
         }
      },
      //销售统计弹层
      saleTotalBtn() {
         this.$gtag.event('销售统计', { 'event_category': 'Click', 'event_label': '销售统计' })
        this.Total = true;
      },
      openTotal(e) {
        this.Total = e;
      },
      // 卖完弹层
      saleoutBtn() {
        this.Saleout = true;
      },
      openSaleout(e) {
        this.Saleout = e;
        window.location.reload();
      },
      promptBoxCommit(v) {
        if (v != 3) {
          this.Transfer = false;
        }
      },
    
    }
  };

</script>

<style lang='scss' scoped>
.cards {
  .nobid {
    height: 13px;
    font-family: Helvetica;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #270645;
  }
  .text--primary-rd {
    color: #270645;
  }

  .text--secondary-rd {
    color: #766983;
  }

  .text--info {
    font-size: 10px !important;
    line-height: 1;
    letter-spacing: 0px;
  }
  .crossdes {
    position: absolute;
    top: 25px;
    right: 10px;
    width: 126px;
    height: 28px;
    background-color: #270645;
    border-radius: 5px;
    z-index: 10;
    text-align: center;
    padding: 4px 0;
    .name {
      font-family: Helvetica;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 20px;
      letter-spacing: 0px;
      color: #ffffff;
    }
  }

  .text--title {
    display: inline-block;
    font-size: 13px;
    line-height: 24px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 230px;
    margin-bottom: -17px;
  }

  .text--sub-title {
    zoom: 0.83;
    font-family: Helvetica;
    font-size: 13px;
    line-height: 1;
  }

  .text--number {
    font-size: 11px;
    line-height: 1;
    font-weight: bold !important;
  }

  .v-divider {
    border-color: #e3e3e3;
  }

  .satrtsale {
    width: 91px;
    height: 28px;
    background-color: #270645 !important;
    border-radius: 18px;
    font-family: Helvetica;
    font-weight: bold;
    font-size: 12px !important;
    font-weight: bold;
    font-stretch: normal;
    line-height: 100px;
    letter-spacing: 0px;
    color: #ffffff;
    text-align: center;
  }

  .btnbox {
    width: 91px;
    height: 28px;
    box-sizing: border-box;
    padding: 2px;
    // background-image: linear-gradient(
    //         0deg,
    //         #d202fd 0%,
    //         #a538fe 26%,
    //         #776eff 51%,
    //         #01facd 100%
    // );
    background-image: linear-gradient(
      0deg,
      #d202fd 0%,
      #a538fe 26%,
      #776eff 51%,
      #01facd 100%
    );
    border-radius: 17px;
    margin-left: -5px;
    margin-right: 10px;

    .rd {
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 17px;
      background: #fff;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0;
      color: #270645;
    }
  }
  .withdraw {
    width: 91px;
    height: 28px;
    background-color: #270645 !important;
    border-radius: 18px;
    font-family: Helvetica;
    font-weight: bold;
    font-size: 12px !important;
    font-weight: bold;
    font-stretch: normal;
    line-height: 100px;
    letter-spacing: 0px;
    color: #ffffff;
    text-align: center;
    border-radius: 17px;
    margin-left: 90px;
    margin-top: -50px;
  }

  .sold_out {
    width: 170px;
    height: 30px;
    margin-top: -10px;
    background-blend-mode: normal, normal;
    border-radius: 18px;
    text-align: center;
    font-family: Helvetica;
    font-weight: bold;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 30px;
    letter-spacing: 0px;
    color: #270645;
  }

  .auction_out {
    height: 36px;
    text-align: center;
  }

  .dialodup5 {
    width: 281px;
    height: 281px;
    background-color: rgba(255, 255, 255, 0.9);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;

    .tip {
      font-family: Helvetica;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      margin-top: 121px;
      margin-bottom: 9px;
      text-align: center;
    }

    .timer {
      font-family: Helvetica;
      font-weight: bold;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      margin-bottom: 32px;
      text-align: center;
    }
    .drawbtn {
      width: 244px;
      height: 36px;
      background-color: #270645;
      border-radius: 17px;
      margin-left: 18px;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 36px;
      text-align: center;
      letter-spacing: 0px;
      color: #ffffff;
      margin-top: 210px;
    }

    .dealbtn {
      width: 244px;
      height: 36px;
      background-color: #270645;
      border-radius: 17px;
      margin-left: 18px;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 36px;
      text-align: center;
      letter-spacing: 0px;
      color: #ffffff;
    }
  }

  .dialodup4 {
    width: 281px;
    height: 281px;
    background-color: rgba(255, 255, 255, 0.9);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;

    .tip {
      font-family: Helvetica;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      margin-top: 105px;
      margin-left: 28px;
      margin-bottom: 9px;
    }

    .timer {
      font-family: Helvetica;
      font-weight: bold;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      margin-bottom: 32px;
      text-align: center;
    }

    .btn {
      .box {
        width: 115px;
        height: 36px;
        box-sizing: border-box;
        padding: 2px;
        background-image: linear-gradient(
          0deg,
          #d202fd 0%,
          #a538fe 26%,
          #776eff 51%,
          #01facd 100%
        );
        border-radius: 18px;

        .abanbtn {
          width: 100%;
          height: 100%;
          border: none;
          border-radius: 20px;
          background: #fff;
          font-family: Helvetica;
          font-weight: bold;
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0;
          color: #270645;
        }
      }

      .dealbtn {
        width: 116px;
        height: 36px;
        background-color: #270645;
        border-radius: 17px;
        margin-left: 18px;
        font-family: Helvetica;
        font-weight: bold;
        font-size: 13px;
        font-weight: bold;
        font-stretch: normal;
        line-height: 36px;
        text-align: center;
        letter-spacing: 0px;
        color: #ffffff;
      }
    }
  }

  .sale {
    background-color: #270645 !important;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 17px;
    background: #fff;
    font-family: Helvetica;
    font-weight: bold;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    letter-spacing: 0;
    margin-top: -10px;
  }
  .explain {
    width: 185px;
    height: 56px;
    background-color: #270645;
    border-radius: 3px;
    position: absolute;
    top: 327px;
    right: 30px;
    z-index: 2;

    span {
      font-family: Helvetica;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 15px;
      letter-spacing: 0px;
      color: #ffffff;
      margin-left: 13px;
      margin-right: 13px;
    }
  }

  &.mobile {
    .sale {
      width: 100% !important;
      height: 70% !important;
    }
    .satrtsale {
      width: 50px;
    }
    .btnbox {
      width: 70px;
    }
    .sold_out {
      width: 130px;
    }
  }
}
</style>
