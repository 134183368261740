import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/home/index.vue";

Vue.use(VueRouter);
//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
   if (onResolve || onReject)
      return originalPush.call(this, location, onResolve, onReject);
   return originalPush.call(this, location).catch(err => err);
};
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
   if (onResolve || onReject)
      return originalReplace.call(this, location, onResolve, onReject);
   return originalReplace.call(this, location).catch(err => err);
};

const routes = [
   {
      path: "/",
      redirect: '/index',
   },
   {
      path: "/index",
      name: "Home",
      component: Home,
   },
   {
      path: "/downloads",
      name: "Download",
      component: () => import("@/views/app_download/index.vue"),
   },
   {
      path: "/marketplace",
      name: "Marketplace",
      component: () => import("@/views/marketplace/index.vue"),
   },
   // {
   //    path: "/adddata",
   //    name: "addData",
   //    component: () => import("@/views/addData/index.vue"),
   // },
   {
      path: "/launchpad",
      name: "LaunchPad",
      component: () => import("@/views/collection/index.vue"),
   },
   {
      path: "/competition",
      name: "Competition",
      component: () => import("@/views/competition/index.vue"),
   },
   {
      path: "/download",
      name: "Download",
      component: () => import("@/views/download/index.vue"),
   },
   {
      path: "/lottery",
      name: "Lottery",
      component: () => import("@/views/pool/lotteryList.vue"),
   },
   {
      path: "/lotterydetail",
      name: "Lotterydetail",
      component: () => import("@/views/pool/index.vue"),
   },
   {
      path: "/twitter",
      name: "Twitter",
      component: () => import("@/views/twitter/index.vue"),
   },
   {
      path: "/promo",
      name: "Promo",
      component: () => import("@/views/Promo/index.vue"),
   },
   {
      path: "/halloween",
      name: "Halloween",
      component: () => import("@/views/Promo/components/Halloween.vue"),
   },
   {
      path: "/thanksgiving",
      name: "Thanksgiving",
      component: () => import("@/views/Promo/components/Thanksgiving.vue"),
   },
   
   {
      path: "/constellation",
      name: "Constellation",
      component: () => import("@/views/Promo/components/constellationHtml.vue"),
   },
   {
      path: "/uptickzodiac",
      name: "UptickZodiac",
      component: () => import("@/views/Promo/components/constellationV2Html.vue"),
   },
   {
      path: "/atomrewards",
      name: "ATOMRewards",
      component: () => import("@/views/Promo/components/ATOMRewardsHtml.vue"),
   },
   {
      path: "/airdrop",
      name: "Airdrop",
      component: () => import("@/views/Promo/components/index_uptick.vue"),
   },
   {
      path: "/collectionhtml",
      name: "CollectionHtml",
      component: () => import("@/views/Promo/components/collectionHtml.vue"),
   },
   {
   path: "/interchainReward",
   name: "InterchainReward",
   component: () => import("@/views/Promo/components/InterchainReward.vue"),
},
  
   {
      path: "/photography",
      name: "Photography",
      component: () => import("@/views/Promo/components/photographyHtml.vue"),
   },
   {
      path: "/wedding",
      name: "Wedding",
      component: () => import("@/views/Promo/components/wedding.vue"),
   },
   {
      path: "/interchainNFTs",
      name: "InterchainNFTs",
      component: () => import("@/views/Promo/components/InterchainNFTs.vue"),
   },
   {
      path: "/detail",
      name: "Detail",
      component: () => import("@/views/detail/index.vue"),
   },
   {
      path: '/follows',
      name: 'Follows',
      component: () => import('../views/am/follows.vue')
   },
   {
      path: '/claimHistroy',
      name: 'ClaimHistroy',
      component: () => import('../views/pointsCenter/claimHistroy.vue')
   },
   {
      path: '/fans',
      name: 'Fans',
      component: () => import('../views/am/fans.vue')
   },
   {
      path: '/setting',
      name: 'Setting',
      component: () => import('../views/am/setting.vue')
   },

   {
      path: '/creatorlist',
      name: 'Creatorlist',
      component: () => import('../views/am/creatorNew.vue')
   },
   {
      path: '/featurelist',
      name: 'Featurelist',
      component: () => import('../views/am/featureList.vue')
   },
   {
      path: '/myreferences',
      name: 'MyReferences',
      component: () => import('../views/am/MyReferences.vue')
   },
   {
      path: '/referralmanagement',
      name: 'ReferralManagement',
      component: () => import('../views/am/referralManagement.vue')
   },
   {
      path: '/saledetail',
      name: 'Saledetail',
      component: () => import('../views/am/saledetails.vue'),
      meta: {
         metaInfo: {
            //  title: "首页",
            //  keywords: "这是网页的关键词",
            //  description: "这是网页的描述"
         }
      }
   },

   {
      path: '/otherpage',
      name: 'Otherpage',
      component: () => import('../views/am/otherpage.vue')
   },
   {
      path: '/carddetail',
      name: 'Carddetail',
      component: () => import('../views/am/carddetail.vue')
   },
   {
      path: '/create',
      name: 'Create',
      component: () => import('../views/am/create.vue')
   },

   {
      path: '/collect',
      name: 'Collect',
      component: () => import('../views/am/collect.vue')
   },

   {
      path:'/folder/:id/:source/:owner',
      name:'FolderPage',
      component: () => import("@/views/am/folderPage.vue")
   },
   {
      path:'/folder/:id',
      name:'FolderPage',
      component: () => import("@/views/am/folderPage.vue")
   },
   {
      path:'/folder_create/:id/:source/:owner',
      name:'FolderPage_create',
      component: () => import("@/views/am/folderPage.vue")
   },
   {
      path:'/collection/:id/:source/:owner',
      name:'CollectionPage',
      component: () => import("@/views/am/collectionPage.vue")
   },
   {
      path:'/collection/:id',
      name:'CollectionPage',
      component: () => import("@/views/am/collectionPage.vue")
   },
   {
      path:'/collection_create/:id/:source/:owner',
      name:'CollectionPage_create',
      component: () => import("@/views/am/collectionPage.vue")
   },
   {
      path:'/folder_create/:id',
      name:'FolderPagePage_create',
      component: () => import("@/views/am/folderPage.vue")
},
   {
      path: '/myInfo',
      name: 'MyInfo',
      component: () => import('../views/am/myInfo.vue')
   },
   {
      path: '/pointsCenter',
      name: 'PointsCenter',
      component: () => import('../views/pointsCenter/index.vue')
   },
   {
      path: '/myOrders',
      name: 'MyOrders',
      component: () => import('../views/am/myOrders.vue')
   },
   {
      path: '/lucky',
      name: 'Lucky',
      component: () => import('../views/am/lucky.vue')
   },
   {
      path: '/myasset',
      name: 'MyAsset',
      component: () => import('../views/am/myAsset.vue')
   },
   {
      path: '/officialAirdrop',
      name: 'OfficialAirdrop',
      component: () => import('../views/airDrop/officialAirdrop.vue')
   },
   {
      path: '/creatorAirdrop',
      name: 'CreatorAirdrop',
      component: () => import('../views/airDrop/creatorAirdrop.vue')
   },

   
   {
      path: '/message',
      component: () => import('../views/message/index.vue'),
      children: [
         {
            path: '',
            name: 'Message',
            component: () => import('../views/message/UserMessage.vue')
         },
         {
            path: 'notifications',
            name: 'Notifications',
            component: () => import('../views/message/Notifications.vue')
         },
         {
            path: 'system-alert',
            name: 'SystemAlert',
            component: () => import('../views/message/SystemAlert.vue')
         },
      ]
   },
   {
      path: '/personal-page',
      name: 'PersonalPage',
      component: () => import('../views/personalPage/index.vue'),
   },
 
  
   {
      path: '/helpcenter',
      name: 'HelpCenter',
      component: () => import('../layout/components/HelpPage.vue'),
   },
   {
      path: '/privacy',
      name: 'Privacy',
      component: () => import('../layout/components/privacy_en.vue'),
   },
   {
      path: '/term',
      name: 'Term',
      component: () => import('../layout/components/term_en.vue'),
   },
   {
      path: '/privacy_zh',
      name: 'Privacyzh',
      component: () => import('../layout/components/privacy_zh.vue'),
   },
   {
      path: '/term_zh',
      name: 'Termzh',
      component: () => import('../layout/components/term_zh.vue'),
   },
   {
      path: '/auctionrule_zh',
      name: 'Auctionrulezh',
      component: () => import('../assets/html/AuctionRules_zh'),
   },
   {
      path: '/auctionrule_en',
      name: 'Auctionruleen',
      component: () => import('../assets/html/AuctionRules_en'),
   },
 
];



const router = new VueRouter({
   mode: "history",
   base: process.env.BASE_URL,
   routes,
   scrollBehavior (to, from, savedPosition) {
      if (savedPosition) {
          return savedPosition
      } else {
          return { x: 0, y: 0 }
      }
   }
});




export default router;
