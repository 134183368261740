<template>
   <div id="container">
      <v-card :class="{ card: true, mobile: isMobile }">
         <img
             class="clo"
             @click="closeDialog"
             src="@/assets/btn_close.png"
             alt=""
         />
         <div class="top">
            <h3>{{ $t("popupNewTransferTransfer") }}</h3>
            <div class="content d-flex flex-lg-row flex-column">
               <img :src="Imgsrc" alt="" style="background-color:#FFF"/>
               <div class="rgh ml-12">
                  <h4>{{ transferName }}</h4>
                  <div class="ic-sp" v-if="islevel">
                     <img src="@/assets/bbb.png" alt="" class="icon"/>
                     <span>{{ $t("popupNewTransferPyroworld") }}</span>
                  </div>
               </div>
            </div>
         </div>
         <div class="form">
            <div class="ti-in">
               <span class="title">{{ $t("popupTransferReceiver") }}</span>
               <v-text-field
                   v-model="firstname"
                ref="addressValue"
				   :placeholder="this.$t('popupTransferInput')"
                   :rules="[rule.addressRules]"
                   outlined
                   type="input"
               ></v-text-field>
            </div>
            <div class="ti-tx">
               <span class="title">{{ $t("popupNewTransferRemarks") }}</span>
               <v-textarea
                   label=""
                   v-model="firsttxt"
                   auto-grow
                   outlined
                   rows="2"
                   row-height="18"
               ></v-textarea>
            </div>
         </div>
         <div class="count d-flex flex-column flex-lg-row">
            <div>
               <span class="title">{{ $t("popupNewTransferAmount") }}</span>
               <span>{{ $t("balanceCount") }}:{{ amount }}</span>
            </div>
             <div class="countrgh d-flex flex-row mr-lg-n5 mt-lg-0 mt-5 mb-n5">
          <v-btn class="counticon" small @click="reduceBtn">
            <v-icon dark>mdi-minus</v-icon>
          </v-btn>
          <v-text-field class="countinput" :rules="[rule.editionCount]" ref="countValue" v-model="countValue" oninput="value=value.replace(/\D|^0/g,'')"/>
          <v-btn class="counticon" small @click="increaseBtn">
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </div>
         </div>
         <div class="footer">  
            <v-btn class="sub" :class="{'sub-dis': isPay}" submit @click="subCommit">{{
               $t("popupTransferCommit")
               }}
            </v-btn>
            <span class="price"
            >{{ $t("popupNewTransferBalance") }}：{{ value2 }} IRIS</span
            >
         </div>
      </v-card>
      <PromptBox ref="promptBox" @commit="promptBoxCommit"></PromptBox>
      <uComponents  ref="ucom"></uComponents>
   </div>
</template>

<script>
  //创建转送他人/自己弹层
  import { getMyBalance, transferNft,quiryTx} from "../../keplr/iris/wallet";
  import { getFileSrc } from "@/utils/file";
  import api from "@/api";
  import PromptBox from "@/components/PromptBox.vue";

  const WORK_KEY = "WORK";
  export default {
    components: { PromptBox },
    name: "newTransfer",

	data:function(){
		return{
			newOpen: true,
			items: [
			  
			  {
			    text:  this.$t('ReceiverAddress'),
			    value: 2
			  }
			],
			firstname: "",
			countValue: "1",
			firsttxt:"",

			value1: "0.075",
			value2: "",
			islevel: false,
			transferName: "",
			Imgsrc: "",
			amount: "",
         commitType: 0,
         isPay:false,
         rule: {
      addressRules: (value) => !!value || "Address"+" "+this.$t("IsRequired"),
       editionCount: (value) => {
          // const pattern=/^(?!0)(?:[0-9]{1,3}|1000)$/;
          if (value <= 0 || value > this.parentValue.amount) {
            return "Out of amount range";
          }
          return true;
        }
    },
    flag: true,
      }
   },
    watch: {
   
  },

    computed: {
      isMobile: function() {
        return this.$vuetify.breakpoint.mobile;
      }
    },
    props: {
      address: {
        type: String
      },
      imgUrl: {
        type: String
      },
      level: {
        type: Number
      },
      name: {
        type: String
      },
      offSaleTokenIds: {
        type: Array
      },
        endType:{
       type: String,
    },
    parentValue:{
      type:Object
    }
    },
    async mounted() {
       console.log('ooooiiuuyyy');
      if (this.level == 1) {
        this.islevel = false;
      } else {
        this.islevel = true;
      }
      if (this.imgUrl != null) {
        this.Imgsrc = await getFileSrc(WORK_KEY, this.imgUrl);
      }
      
      this.amount = (this.parentValue.offsaleTokenIds).length;
      this.transferName = this.name;
      let balance = await getMyBalance();
      let mount = balance.format;
      this.value2 = mount;
      this.firsttxt = "I sent you【" + this.transferName + "】，please check it in your collection！";
    },
    methods: {
       verify() {
          let addresslVer = this.$refs.addressValue.validate(true);
          !addresslVer ? this.$refs.addressValue.focus() : "";
         //  if(!addresslVer) {
         //      this.$toast("error", "地址不能为空");
         //      return false;
         //  }
         
          return addresslVer;
       },

        sleep(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
      //提交转送数据
      async subCommit() {
         
         console.log("xxl firstname start ....");
         console.log(this.firstname);
         console.log("xxl firstname end ");
          if(!this.firstname.startsWith("iaa") || this.firstname.length!=42) {
              this.$toast("error", this.$t('invalidAddress'));
              return ;
          }
       
            if (!this.verify()) {
               return;
            }

        try {
          let nftIds = [];
          let offSaleTokenIds = this.parentValue.offsaleTokenIds;
          if (offSaleTokenIds.length > 0) {
            for (let i = 0; i < this.countValue; i++) {
              nftIds.push(offSaleTokenIds[i]);
            }
          }
             this.isPay = true
            let denomId = this.address;
            let sender = this.$store.state.did;
            let recipient = this.firstname;
            let fee = 0;
            let adminAddress = "";
            console.log("denomid",denomId)
              console.log("nftid",nftIds)

            let result = await transferNft(
              nftIds,
              denomId,
              sender,
              recipient,
              fee,
              adminAddress
            );
             let params = {
              contractAddress: this.address,
              tokenIds: nftIds,
              owner:this.$store.state.did,
              toOwner:this.firstname
            };
           
            console.log(result.hash);
                if (result.hash) {
             let transferRes = 	await api.home.transferToOther(params);
             if(!transferRes.success){
               this.$toast("error", this.$t("messageTransferFailed"));
             }
            let isShowTip = localStorage.getItem("IRIS_isShowTip_"+this.$store.state.did)
        if(isShowTip == 'true'){
            this.$mtip({
                title:this.$t("transactionInprogressSuccess"),
              });
          }
               
                this.isPay = false
               this.$emit("getOpen", false)

               if(this.endType != "collection"){
     
                    this.$router.push({name:"PersonalPage"})
               }else{
               
                  window.eventBus.$emit("tranferPay");
               }
            

            while (this.flag) {     
              await this.sleep(5000).then(async () => {
                 //console.log(result.hash)
                let res = await quiryTx(result.hash);
                console.log("wxl -----  quiryTx");
                console.log(res);
                if (res.code == 0) {
                   	let t = this.$t("transferSuccess")
					let reg=new RegExp('【N】','g')//g代表全部
					let regN=new RegExp('<br/>','g')//g代表全部
					let name = "【" + this.name + "】"
               let title=t.replace(reg,name).replace(regN," ");
               let isShowTip = localStorage.getItem("IRIS_isShowTip_"+this.$store.state.did)
               if(isShowTip == 'true'){
                     this.$mtip({
                        title:title,
                     });
                  }
             
                  this.flag = false;
                } else if (res.code == -1) {
                  console.log("error");
               let isShowTip = localStorage.getItem("IRIS_isShowTip_"+this.$store.state.did)
               if(isShowTip == 'true'){
                     this.$mtip({
                           title:res.log,
                     });
                  }
                
              this.flag = false;
                } else {
                  this.flag = true;
                }
              });
            }
                }      
          
        } catch (e) {
           console.log("e",e)
          // this.$refs.promptBox.show(this.$t("messageTransferFailed"), 3);
          this.$toast("error", this.$t("messageTransferFailed"));
           this.isPay = false
        }
      },
      //关闭弹框，发送open值
      closeDialog() {
        this.newOpen = false;
        this.$emit("getOpen", this.newOpen);
      },
      //增减
      reduceBtn() {
        if (this.countValue > 1) {
          this.countValue--;
        }
      },
      increaseBtn() {
        if (this.countValue < this.amount) {
          this.countValue++;
        }
      },

      promptBoxCommit(type) {
        this.$emit("promptBoxCommit", type);
      }
    }
  };
</script>

<style lang="scss" scoped>
.card ::v-deep .v-messages__message {
  color: red;
}
   .card {
      display: flex;
      flex-direction: column;
      min-height: 590px;
      width: 600px;

      .clo {
         position: absolute;
         right: 10px;
         top: 10px;
         width: 30px;
         height: 31px;
         background-color: #fff;
         border-radius: 50%;
         margin-right: 0;
         cursor: pointer;
      }

      .top {
         display: flex;
         flex-direction: column;

         h3 {
            margin: 41px 0 17px 52px;
           font-family:Helvetica;
            font-size: 25px;
            color: #270645;
         }

         .content {
            display: flex;
            flex-direction: row;

            .rgh {
               display: flex;
               flex-direction: column;

               h4 {
                 font-family:Helvetica;
                  font-size: 20px;
                  color: #270645;
                  margin-bottom: 10px;
               }

               .ic-sp {
                  display: flex;

                  .icon {
                     display: flex;
                     align-items: flex-end;
                     width: 41px;
                     height: 40px;
                     background-color: #6f58d9;
                     border-radius: 50%;
                     margin-left: 0;
                     margin-top: 10px;
                  }
               }
            }
         }
      }

      .form {
         margin: 10px 52px;
         display: flex;
         flex-direction: column;
      }

      .count {
         margin: 0 52px 47px;
         display: flex;
         flex-direction: row;
         justify-content: space-between;

         .countrgh {
      // position: absolute;
      // right: 32px;

      .counticon {
        width: 46px;
        height: 45px;
        font-size: 40px;
        margin: 6px 20px;
        background-color: #e3e3e3;
        border-radius: 5px;
        display: inline-block;
      }

      .countinput {
        width: 135px;
        height: 56px;
        text-align: center;
        vertical-align: middle;
        display: inline-block;
        padding: 12px 0;
        background-image: linear-gradient(#ffffff, #ffffff),
          linear-gradient(#f8f6fd, #f8f6fd);
        background-blend-mode: normal, normal;
        border-radius: 5px;
        // border: solid 2px #e3e3e3;
        &::v-deep input {
          text-align: center;
          vertical-align: middle;
        }
      }
    }
      }

      .footer {
         display: flex;
         flex-direction: column;
         text-align: center;
         margin-bottom: 50px;

         .price {
            display: block;
            font-family:Helvetica;
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 40px;
            color: #766983;
         }

         .sub {
            margin: 0 auto;
            width: 450px;
            height: 51px;
            background-image: linear-gradient(
                    90deg,
                    #d300fd 0%,
                    #a439fe 26%,
                    #7471ff 52%,
                    #00fdcf 100%
            ),
            linear-gradient(#270645, #270645);
            background-blend-mode: normal, normal;
            border-radius: 25px;
            opacity: 0.9;
            color: #fff;
           font-family:Helvetica;
            font-size: 20px;
         }
      }

      &.mobile {
         .count {
            .countrgh {
               .counticon {
                  margin: 6px;
               }
            }
         }

         .footer {
            .sub {
               width: 70%;
            }
         }
      }
   }

   img {
      display: inline-block;
      width: 126px;
      height: 126px;
      background-color: #8a73ff;
      border-radius: 5px;
      margin: 1px 22px 10px 52px;
   }

   span {
      display: flex;
      align-items: center;
      font-family:Helvetica;
      color: #270645;
   }

   .title {
     font-family:Helvetica;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      color: #270645;
   }
   .sub-dis {
    position: relative;
    pointer-events: none;
    background-image: linear-gradient(
      #766983, 
      #766983), 
     linear-gradient(
      #270645, 
      #270645) !important;
     background-blend-mode: normal, 
      normal;
     border-radius: 25px;
     opacity: 0.9;
}
.sub-dis::after {
    content: "";
    background-image: url(../../assets/loading.gif);
    background-size: 100%;
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 20px;
    margin-left: 130px;
    margin-top: 5px;
    
    // box-shadow: 0 0 10px #fff;
    // border-radius: 50%;
}
</style>
