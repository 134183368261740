<template>
  <v-tabs class="rd" hide-slider  exact   v-model="select">
    <v-tab :ripple="false" :to="{ name: 'Home'}" @click="navClick('Home')">{{ $t('headerNavHome') }}</v-tab>
    <v-tab :ripple="false" :to="{ name: 'Marketplace'}" @click="navClick('Marketplace')">{{ $t('headerNavMarketplace') }}</v-tab>
    <v-tab :ripple="false" :to="{name:'LaunchPad'}" @click="navClick('LaunchPad')">{{ $t('nav_launchpad') }}</v-tab> 
    <v-tab :ripple="false" href="https://www.uptick.network/marketplace.html" target="_blank">{{ $t('nav_learnmore') }}</v-tab> 
    <v-tab class="icon" :ripple="false" :to="{name:'Promo'}" @click="navClick('Promo')">{{ $t('headerNavPromo') }}</v-tab>
  </v-tabs>
</template>
<script lang="js">

export default {
  name: 'Nav',
  inheritAttrs: false,
  data: function() {
    return {select:''};
  },
  methods:{
     navClick(name){
       this.$gtag.event('导航切换-'+name, { 'event_category': 'Click', 'event_label': name })
      console.log("navClick",name);
    },

  },
};
</script>

<style lang="scss" scoped>
.v-tabs.rd {
  width: unset;
  flex: 0 1 auto;
  &::v-deep .v-item-group {
    flex: 0 1 auto;
    width: unset;
    .v-slide-group__content, .v-slide-group__wrapper {
      flex: 0 1 auto;
    }
  }
 
  .v-tab {
    padding-right: 9px;
    padding-left: 4px;
  }
  .img{
    margin-left: -18px;
    margin-top:12px;
  }
  
}

  // .icon:after {
  //   content: "";
  //   background-image: url(../../assets/icons/icon_promo.png);
  //   background-size: 100%;
  //   width: 20px; height: 25px;
  //   margin-left: 8px;
  // }
</style>
