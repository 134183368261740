<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        src="@/assets/btn_close.png"
        alt=""
      />
      <div class="top">
        <h3 v-if="this.Type == 'fixedPrice'">{{ $t("onsaleFixedPrice") }}</h3>
        <h3 v-else-if="this.Type == 'discountSale'">
          {{ $t("discountSale") }}
        </h3>
        <h3 v-else-if="this.Type == 'execlusiveSale'">
          {{ $t("execlusiveSale") }}
        </h3>
        <h3 class="pupils mt-n1">{{ NftName }}</h3>
      </div>
      <div class="form mt-2">
        <div class="forms mb-3">
          <div class="title mb-lg-3 mb-3">Token</div>
          <ChainSelectInput @change="chainChange"></ChainSelectInput>
      </div>
        <div class="title mb-lg-3 mb-3">{{ $t("popupMarketSalesPrice") }}</div>
        <v-text-field
          ref="SalePrice"
          v-model="SalePrice"
          outlined
          :suffix="chain"
          type="input"
          :rules="[rules.errorPrice]"
           onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,4})?).*$/g, '$1')"
          maxlength="10"
        ></v-text-field>
        <!-- <ChainSelectInput @change="chainChange" @input = "salePrice"></ChainSelectInput> -->
      </div>
      <div
        class="maxamount  mb-6 d-flex flex-row justify-space-between"
        v-if="!contractCollectionId"
      >
        <div class="box d-flex flex-row">
          <div class="amount mt-4">
            {{ $t("popupMarketMaximumPurchaseAmount") }}
          </div>
          <v-text-field
            class="txt"
            ref="amount"
            v-model="amount"
            height:10px
            full-width:false
            outlined
            :rules="[rules.maxCount]"
            v-if="isShow"
          ></v-text-field>
        </div>
        <div class="box2 mr-n4">
          <v-sheet class="sth">
            <v-switch v-model="switch1" inset></v-switch>
          </v-sheet>
        </div>
      </div>
      <!--2022/02/25新增-->
      <div
        class="sale d-flex flex-column mt-n2 mb-n3"
        v-if="this.Type == 'execlusiveSale'"
      >
        <div class="d-flex flex-column">
          <!-- <div class="newtop d-flex flex-row justify-space-between">
                  <div class="title">{{ $t("monopoly") }}</div>
                  <v-sheet class="sth">
                     <v-switch v-model="switch2" inset></v-switch>
                  </v-sheet>
               </div> -->
          <h3> {{ $t("relevance_nft") }}</h3>
          <div class="tip  mt-n1">{{ $t("monopoly_text") }}</div>
          <!-- 任意/全部 -->
          <div class="icons d-flex flex-row mt-4">
            <div class="any d-flex flex-row" @click="iconBtn1">
              <img
                style="background-color: white"
                src="../../assets/icons/icon_dx_n.png"
                alt="1"
                v-if="isYes"
              />
              <img
                style="background-color: white"
                src="../../assets/icons/icon_dx_y.png"
                alt="2"
                v-if="!isYes"
              />
              <div> {{ $t("onsale_or") }}</div>
            </div>
            <div class="all d-flex flex-row" @click="iconBtn2">
              <img
                style="background-color: white"
                src="../../assets/icons/icon_dx_n.png"
                alt="1"
                v-if="!isYes"
              />
              <img
                style="background-color: white"
                src="../../assets/icons/icon_dx_y.png"
                alt="2"
                v-if="isYes"
              />
              <div> {{ $t("onsale_and") }}</div>
            </div>
            <!-- <v-sapcer></v-sapcer>
          <v-sapcer></v-sapcer> -->
          </div>
          
        </div>

        <div class="selects d-flex flex-row flex-wrap">
          <div
            class="nftbox d-flex flex-row align-center"
            v-for="(item, index) in getNftList"
            :key="index"
          >
            <img :src="item.src"  style="border: solid 1px #c185fe;" alt="" />
            <div class="txt">{{ item.text }}</div>
            <img
              class="clos"
              @click="closenft(index)"
              src="@/assets/icons/btn_close.png"
              alt=""
            />
          </div>
          <div
            class="add d-flex flex-row align-center"
            v-if="this.getNftList.length < 12"
            @click="subCommit1"
          >
            <v-icon
              class="icon"
              style="font-size: 40px; margin-right: 15px;margin-left: 55px;"
              color="black"
              >mdi-plus</v-icon
            >
            <!-- <p>Select NFT for discount</p> -->
          </div>
        </div>
        <!-- <div class="select mt-3">
          <v-select
            :items="starsCardlist"
            :disabled="switch2 == false"
            :placeholder="this.$t('please_zm')"
            label=""
            v-model="starsCard"
            item-text="text"
            item-value="value"
            solo
            any
          ></v-select>
        </div> -->
      </div>
      <div
        class="you mt-n2 d-flex flex-column"
        v-if="this.Type == 'discountSale'"
      >
        <div class="d-flex flex-column">
          <!-- <div class="newtop d-flex flex-row justify-space-between">
                  <div class="title">{{ $t("coupon") }}</div>
                  <v-sheet class="sth">
                     <v-switch v-model="switch3" inset></v-switch>
                  </v-sheet>
               </div> -->
          <h3> {{ $t("relevance_nft") }}</h3>
           <div class="tip mt-n1">{{ $t("coupon_text") }}</div>
          <!-- 任意/全部 -->
          <div class="icons d-flex flex-row mt-4">
            <div class="any d-flex flex-row" @click="iconBtn1">
              <img
                style="background-color: white"
                src="../../assets/icons/icon_dx_n.png"
                alt="1"
                v-if="isYes"
              />
              <img
                style="background-color: white"
                src="../../assets/icons/icon_dx_y.png"
                alt="2"
                v-if="!isYes"
              />
              <div> {{ $t("onsale_or") }}</div>
            </div>
            <div class="all d-flex flex-row" @click="iconBtn2">
              <img
                style="background-color: white"
                src="../../assets/icons/icon_dx_n.png"
                alt="1"
                v-if="!isYes"
              />
              <img
                style="background-color: white"
                src="../../assets/icons/icon_dx_y.png"
                alt="2"
                v-if="isYes"
              />
              <div> {{ $t("onsale_and") }}</div>
            </div>
            <!-- <v-sapcer></v-sapcer>
          <v-sapcer></v-sapcer> -->
          </div>
         
        </div>
        <div class="selects d-flex flex-row flex-wrap">
          <div
            class="nftbox d-flex flex-row align-center"
            v-for="(item, index) in getNftList"
            :key="index"
          >
            <img :src="item.src" style="border: solid 1px #c185fe;" alt="" />
            <div class="txt">{{ item.text }}</div>
            <img
              class="clos"
              @click="closenft(index)"
              src="@/assets/icons/btn_close.png"
              alt=""
            />
          </div>
          <div
            class="add d-flex flex-row align-center"
            v-if="this.getNftList.length < 12"
            @click="subCommit1"
          >
            <v-icon
              class="icon"
              style="font-size: 40px; margin-right: 15px;margin-left: 55px;"
              color="black"
              >mdi-plus</v-icon
            >
            <!-- <p>Select NFT for discount</p> -->
          </div>
        </div>
        <div class="select mt-3 mb-n7 d-flex flex-column justify-space-between">
          <div class="shownft">
            <!-- <v-select
              :items="reducedPriceCardlist"
              label=""
              :disabled="switch3 == false"
              :placeholder="this.$t('please_yh')"
              v-model="reducedPriceCard"
              item-text="text"
              item-value="value"
              solo
              any
              style="width: 500px; height: 46px"
            ></v-select> -->
          </div>
          <div class="inp mt-2">
            <v-text-field
              ref="DiscountPrice"
              v-model="reducedPrice"
              outlined
              :suffix="chain"
              type="input"
              :rules="[rules.errorPrice]"
              :placeholder="this.$t('discountPriceNull')"
              :loader-height="5"
              :height="5"
               onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,4})?).*$/g, '$1')"
              maxlength="10"
            ></v-text-field>
          </div>
        </div>
      </div>

      <div class="footer mt-3 mb-9">
        <span class="price"
          >{{ $t("popupAuctionDefectPriceHandingFee") }}：{{
            feePrice
          }}
          IRIS</span
        >
        <!--        <span class="price">Gas：{{ value2 }} IRIS</span>-->
        <button class="sub" :class="{ 'sub-dis': isPay }" @click="subCommit">
          {{ $t("popupTransferCommit") }}
        </button>
        <span class="price"
          >{{ $t("popupNewTransferBalance") }}：{{ assetBalance }} IRIS</span
        >
      </div>
    </v-card>
    <!-- <PromptBox ref="promptBox"></PromptBox> -->
    <uComponents ref="ucom"></uComponents>
    <v-dialog v-model="selectnft">
      <select-nft
        :constructID="parentValue.contractId"
        :getNftList="getNftList"
		    :tokenId="onsaleTokenId"
        :parentValue="parentValue"
        :deductionType = "deductionType"
        @Openselectnft="Openselectnft"
        @getCardList="getCardList"
        v-if="selectnft"
      ></select-nft>
    </v-dialog>
  </div>
</template>

<script>
import api from "@/api";
import {transferNft,getMyBalance, quiryTx } from "../../keplr/iris/wallet";//
import { init as keplrInit, getBalances} from "../../keplr/wallet";
import SelectNft from "./selectNft.vue";
import ChainSelectInput from "@/components/ChainSelectInput.vue"
//import PromptBox from "@/components/PromptBox.vue"
export default {
  //components: {  PromptBox },
  components: { SelectNft ,ChainSelectInput},
  name: "transfer",
  data: () => ({
    open: true,
    selectnft: false,
    SalePrice: "",
    reducedPrice: "",
    // starsCard: "",
    // reducedPriceCard: "",
    amount: "",
    switch1: false,
    // switch2: false,
    switch3: false,
    feePrice: "",
    value2: "0.075",
    assetBalance: "",
    NftName: "",
    isShow: true,
    isPay: false,
    discountVer: "",
    rules: {
      //amount: (value) => !!value || "Place amount is required.",
      maxCount: (value) => {
        const pattern = /^(\+?[1-9][0-9]{0,8})$/;
        return pattern.test(value) || "verify amount";
      },
      errorPrice: (value) => {
        // value = value.replace(/^\s+|\s+$/g,"")
        // const pattern =/^\d*(?:\.\d{0,4})?$/;
            const pattern = /^((\+?[0-9][0-9]{0,8})\.([0-9]{1,4})$)|^(\+?[0-9][0-9]{0,8})$/;
        return pattern.test(value) || "verify price";
        // return pattern.test(value) || "verify price";
      },
    },
    starsCardlist: [],
    reducedPriceCardlist: [],
    getNftList: [],
    flag: true,
    isYes: false,
    deductionType: 2,
  onsaleTokenId:"",
  chain:'IRIS',
  coindenom:'uiris',
    multiNFTids:'',
  }),

  props: {
    sourcemarket:{
      type: String,
    },
    address: {
      type: String,
    },
    onSaleTokenIds: {
      type: Array,
    },
    offsaleTokenids: {
      type: Array,
    },
    name: {
      type: String,
    },
     onsaleType:{
      type:String
    },
    constructID: {
      type: Number,
    },
	contractCollectionId: {
      type: Number,
    },
    Type: {
      type: String,
    },
    parentValue:{
      type:Object
    }
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
    handler: function () {
      return {
        switch: this.switch1,
        // switch1: this.switch2,
        // switch2: this.switch3,
      };
    },
  },
  watch: {
    handler: {
      handler: function () {
        console.log("wxl --- switchStatus");
        // console.log(this.switch1, this.switch2, this.switch3);
        if (this.switch1 == true) {
          console.log("true");
          this.amount = 1;
          this.isShow = true;
        } else {
          console.log("false");
          this.amount = 0;
          this.isShow = false;
        }
      },
      deep: true,
    },
    switch1:{
       handler: function (value) {
         if(value){
            this.$gtag.event('普通上架-限购数量', { 'event_category': 'Click', 'event_label': '限购数量' })
         }
      },
      immediate: true,
    },
  
  },
  async mounted() {
    debugger
    console.log("wxl  Type", this.parentValue,this.sourcemarket);

     

     this.feePrice = (Number(this.offsaleTokenids.length) * 0.1).toFixed(1);
     let env =  process.env.VUE_APP_IRIS_CHAIN_ID
        if(env == 'nyancat-9'){
          this.coindenom = 'unyan'
        }
    this.switch1 = false;
    this.isShow = false
    this.getInfo();
    if(this.Type == "discountSale" || this.Type == "execlusiveSale")
    this.getList();
    this.NftName = this.name;
    //let balance =  await getMyBalance();
    let res = await getMyBalance();
    console.log("updata balance ----");
    console.log(res);
    let mount = localStorage.getItem("key_balance");
    this.assetBalance = mount;
    this.amount = 1;
   
	console.log("wxl -- contractCollectionId",this.contractCollectionId)
	if(this.contractCollectionId!=null&&this.contractCollectionId>0){
			  for (let i = 0; i < this.offsaleTokenids.length; i++) {
				this.onsaleTokenId+=this.offsaleTokenids[i]+","
				}
			if(this.onsaleTokenId){
				// 如果token不为空
				 this.onsaleTokenId = this.onsaleTokenId.substring(0, this.onsaleTokenId.lastIndexOf(','))		  
			}
				
	}
	
 
  },
  methods: {
    //chainType 0:uptick 1:iris 2:cosmos 3:gravity1gjmwgj0lduw0d75xggy3394ahq8fd2mnu0tlwm
     async chainChange(chain,coinname) {
      console.log("3333333",chain,coinname)
      this.chain = coinname
      this.coindenom = chain.denom
      console.log("wxl --- coindenom",this.coindenom)
    // 获取iris 的余额
     keplrInit(1);
    this.accountBalance = await getBalances();
    console.log("keplrInit(2);",this.accountBalance)

     let aviIris;
    if(this.accountBalance.balancesList){
      for(let i=0;i<this.accountBalance.balancesList.length;i++)
      {
        if(this.accountBalance.balancesList[i].denom == "uiris"){
          aviIris= this.accountBalance.balancesList[i].amount/1000000   
          this.assetBalance = aviIris
          
        }
      }
    };
    // if(chain.chain_type == 1){
    //   this.assetBalance = aviIris
    // }else if(chain.chain_type == 2){
    //    this.assetBalance = aviAtom
    // }else if(chain.chain_type == 3){
    //    this.assetBalance = aviUsdc
    // }
 

    },
    salePrice(value){
      console.log("ppppppppppp",value)
    },
    async getInfo() {
      console.log("wxl 22222c ------");
      let params = {
        denom: this.address,
      };
      
    },
    NumberMul(arg1, arg2) {
      var m = 0;
      var s1 = arg1.toString();
      var s2 = arg2.toString();
      try {
		  if(s1.indexOf(".") != -1){
			    m += s1.split(".")[1].length;
		  }
      
      } catch (e) {
        console.log(e)
      }
      try {
		  if(s2.indexOf(".") != -1){
		  			 m += s2.split(".")[1].length;
		  }
       
      } catch (e) {
        console.log(e)
      }

      return (
        (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
        Math.pow(10, m)
      );
    },
    async getList() {
      let params = {
        pageNumber: "1",
        contractId: this.parentValue.contractId,
        pageSize: "50",
        authenticationAddress: "iaa1xhhf3a80n7zamu32dyku77lactynx9sgs0vvz4",
        // authenticationAddress: this.$store.state.did,
      };
      let resList = await api.home.couponList(params);

   
      for (let i = 0; i < resList.data.list.length; i++) {
        this.reducedPriceCardlist.push({
          text: resList.data.list[i].name,
          value: resList.data.list[i].address,
        });
        this.starsCardlist.push({
          text: resList.data.list[i].name,
          value: resList.data.list[i].address,
        });
      }
    
    },

    verify() {
      let saleVer = this.$refs.SalePrice.validate(true);
      if (this.Type == "discountSale") {
        this.discountVer = this.$refs.DiscountPrice.validate(true);
      }

      let amountVer = true;
      if (this.switch1 == true) {
        amountVer = this.$refs.amount.validate(true);
      }

      !saleVer ? this.$refs.SalePrice.focus() : "";
      if (this.Type == "discountSale") {
        !this.discountVer ? this.$refs.DiscountPrice.focus() : "";
      }

      !amountVer ? this.$refs.amount.focus() : "";
      if (this.Type == "discountSale") {
        return saleVer && this.discountVer && amountVer;
      } else {
        return saleVer && amountVer;
      }
    },

    sleep(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },

    //提交转送数据
    async subCommit() {
      try {
        console.log("1");
       
        if (!this.verify()) {
          return;
        }
        if(!this.SalePrice ){
        this.$toast("error", this.$t("PleaseInPutBid"));
        return
        
      }
           console.log("2");

       if(!this.reducedPrice&&this.Type == "discountSale"){
        this.$toast("error", this.$t("PleaseInPutBid"));
        return
        
      }
         console.log("3",this.parentValue);
        if (this.amount > this.offsaleTokenids.length) {
          this.$toast("error", this.$t("LowerThanAmount"));
          return;
        }
           console.log("4"); 
        if (this.Type == "discountSale") {
          if (this.getNftList.length == 0) {
            this.$toast("error", this.$t("please_yh"));
            return;
          }
        } else if (this.Type == "execlusiveSale") {
          if (this.getNftList.length == 0) {
            this.$toast("error", this.$t("please_zm"));
            return;
          }
        }
      
        if (this.reducedPrice != "") {
          if (Number(this.SalePrice) <= Number(this.reducedPrice)) {
            console.log("wxl 111111  price_yh");
            console.log(this.SalePrice, this.reducedPrice);
            this.$toast("error", this.$t("price_yh"));
            return;
          }
        }

        this.isPay = true;
        let res = await api.home.getFee();
        console.log(res);
        let nftIds = [];
  console.log("4");
        let offsaleTokenids = this.offsaleTokenids;

  console.log("zxx start==============");
        console.log(offsaleTokenids);
        console.log("zxx end==============");
        let lables = [];
        if (offsaleTokenids.length > 0) {
          for (let i = 0; i < offsaleTokenids.length; i++) {
            nftIds.push(offsaleTokenids[i]);

            let lable = {
              price: this.NumberMul(this.SalePrice, 1000000),
              tokenId: offsaleTokenids[i],
              coinDenom:this.coindenom
            };
            lables.push(lable);
          }

          console.log("wxl  ------------");
          console.log(lables);
          console.log("________________");
        }

        let denomId = this.parentValue.contractAddress;
        let sender = this.$store.state.did;
        let recipient = res.data.irisMerchantAddress;
        let fee = res.data.ON_SALE_ASSET_FEE;
        let adminAddress = res.data.IrisAddress;
        let memo = '{"type":"ON_SALE"}';
debugger
        console.log(denomId);
        console.log(nftIds);
        console.log(sender);
        console.log(recipient);
        console.log(fee);
        console.log(adminAddress);

        //transfer
        let result = await transferNft(
         nftIds,
          denomId,
          sender,
          recipient,
          fee,
          adminAddress,
          memo
        );
		// let result={
		// 	hash:""
		// }
  //       console.log(nftIds);
  // console.log(memo);
  //   console.log(nftIds);
        

        //toDo  ---amount*数量

        let param = {
          callBack: "",
          denom: denomId,
          labels: lables,
          owner: sender,
          hash: result.hash,
        };
		let commodityTokenIds="";//执行上架的tokenID
	  for (let i = 0; i < offsaleTokenids.length; i++) {
          commodityTokenIds += offsaleTokenids[i] + ",";
        }
		if(commodityTokenIds){
			// 如果token不为空
			 commodityTokenIds = commodityTokenIds.substring(0, commodityTokenIds.lastIndexOf(','))		  
		}
		
		console.log("wxl--- callBack ",this.parentValue.collectionType ,this.Type);
        let callBack =
          `${process.env.VUE_APP_API_URL}` +
          "/api/3.0/fee/onSale.do?receiver=iaa1fgatwy03e8qq5j52h66h72mawj5l7vzvkffn9y&level=1&amount=0.1&contractAddress=" +
          denomId +
          "&payAddress=" +
          sender;
          
		  if(this.parentValue.collectionType == '1' && this.Type == "discountSale" ){
			  callBack+= "&commodityTokenIds=" +commodityTokenIds
		  }
        // 设置专卖卡
		let e_denom="";
		let e_tokenid=""
		// 折扣金额
		let deductions = [];
	
		if(this.getNftList.length > 0){
			
			for (let i = 0; i < this.getNftList.length; i++) {
				let deduction = {
				    amount: this.reducedPrice,
				    denom: this.getNftList[i].address,
				    way: 1,
				  };
			  if(this.getNftList[i].tokenId){
			    e_tokenid+=this.getNftList[i].tokenId+ ","
				deduction.tokenId=this.getNftList[i].tokenId;
				
			  }else{
				  	e_denom+= this.getNftList[i].address+ ",";
			  }
			
			
		 deductions.push(deduction);
		}
		if(e_denom){
			//如果优惠券denom不为空
			 e_denom = e_denom.substring(0, e_denom.lastIndexOf(',')) 
		}
		if(e_tokenid){
			// 如果token不为空
			 e_tokenid = e_tokenid.substring(0, e_tokenid.lastIndexOf(','))		  
		}
		
        }
		
			
		if (this.Type == "execlusiveSale" ) {
		  callBack +=
		    "&exclusivesType=" + this.deductionType ;
			 if(e_denom){
			  //如果优惠券denom不为空
			  callBack += "&exclusive_denom="+e_denom;
		  }
		  if(e_tokenid){
			  // 如果token不为空
			   callBack +="&exclusiveTokenIds="+e_tokenid;
		  }
		  }

        // 设置折扣价
        if (this.Type == "discountSale" && this.reducedPrice) {
          callBack += "&couponType=" +this.deductionType +
            "&way=1&deduction=" + this.reducedPrice ;
         if(e_denom){
			 //如果优惠券denom不为空
         	callBack += "&coupon_denom="+e_denom;
         }
         if(e_tokenid){
          // 如果token不为空
         	callBack +="&linkTokenIds="+e_tokenid;
         }
          param.deductions = deductions;
          param.deductionType = this.deductionType;
        }
        param.callBack = callBack;
        let ress = await api.home.onSale(param);
        // JSONObject objectObjectHashMap = new JSONObject();
        //   objectObjectHashMap.put("tokenIds",CollectionUtils.join(tokenIds));
        //   objectObjectHashMap.put("contractAddress", contractAddress);
        //   objectObjectHashMap.put("type", type);//类型（1：上架，2：下架
        //   if(buyLimit!=null){
        //       objectObjectHashMap.put("buyLimit",buyLimit);
        //   }
        let tokenIds = "";
        let buyLimit = "";
        if (this.switch1 == true) {
          buyLimit = this.amount;
        } else {
          buyLimit = 0;
        }
        for (let i = 0; i < offsaleTokenids.length; i++) {
          tokenIds += offsaleTokenids[i] + ",";
        }
        console.log(ress);
        let isDoingParams = {
          tokenIds: tokenIds,
          contractAddress: denomId,
          type: 1,
          buyLimit: buyLimit,
        };
        let isding = await api.home.isDoing(isDoingParams);
        console.log(isding);
        if (isding.success == true) {
       
          this.isPay = true;

          // 判断是否交易成功
          if (result.hash) {
            debugger
                let isShowTip = localStorage.getItem("IRIS_isShowTip_"+this.$store.state.did)
        if(isShowTip == 'true'){
                this.$mtip({
              title: this.$t("transactionInprogressSuccess"),
            });
          }
          

            this.$emit("getOpen", false);
            this.isPay = false;
            // 从集合主页上架，刷新列表页
              window.eventBus.$emit("collectionPay");
            // if(this.sourcemarket == 'collectMarket'){
            //    window.eventBus.$emit("collectionPay");
            // }else{
            //      this.$router.push({ name: "PersonalPage" });
            // }
        

            while (this.flag) {
              console.log("wwwwww");

              await this.sleep(5000).then(async () => {
                console.log(result.hash)
                let res = await quiryTx(result.hash);
                console.log("wxl -----  quiryTx");
                console.log(res);
                if (res.code == 0) {
                  let t = this.$t("onSaleSuccess");
                  let reg = new RegExp("【N】", "g"); //g代表全部
                  let regN = new RegExp("<br/>", "g"); //g代表全部
                  let name = "【" + this.name + "】";
                  let title = t.replace(reg, name).replace(regN, " ");
                  debugger
                  let isShowTip = localStorage.getItem("IRIS_isShowTip_"+this.$store.state.did)
        if(isShowTip == 'true'){
             this.$mtip({
                    title: title,
                  });
          }
                  
                  this.flag = false;
                } else if (res.code == -1) {
                  console.log("error");
                let isShowTip = localStorage.getItem("IRIS_isShowTip_"+this.$store.state.did)
                if(isShowTip == 'true'){
                    this.$mtip({
                            title: res.log,
                          });
                  }        
                  this.flag = false;
                } else {
                  this.flag = true;
                }
              });
            }
          }

        }
      } catch (e) {

        console.log("3333333333333",e);
        this.$toast("error", this.$t("transactionInprogressError"));
        this.isPay = false;
      }
    },
    subCommit1() {
      this.selectnft = true;
    },
    //弹框关闭后获取open值
    Openselectnft(e) {
      this.selectnft = e;
    },
    getCardList(data) {
      this.getNftList = data;
      console.log(data);
      this.$forceUpdate();
    },
    //关闭弹框，发送open值
    closeDialog() {
      this.open = false;
      this.$emit("getOpen", this.open);
    },
    iconBtn1() {
      this.$gtag.event('上架-Holding one', { 'event_category': 'Click', 'event_label': 'Holding one' })
      this.isYes = false;
      this.deductionType = 2;
      this.getNftList = []
    },
    iconBtn2() {
      this.$gtag.event('上架-Holding all', { 'event_category': 'Click', 'event_label': 'Holding all' })
      this.isYes = true;
      this.deductionType = 1;
        this.getNftList = []
    },
    closenft(index) {
      this.getNftList.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.card ::v-deep .v-messages__message {
  color: red;
}
.sth {
  margin-left: 108px;
  width: 60px;
  height: 35px;
  // background-color: #270645;
  display: block;
  position: relative;
  right: -15px;
  bottom: 15px;
  border-radius: 18px;
}
.tip {
  font-family: "\5FAE\8F6F\96C5\9ED1";
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #766983;
}
.card {
  display: flex;
  flex-direction: column;
  min-height: 470px;
  width: 600px;

  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }

  .top {
    margin-left: 52px;

    h3 {
      margin: 41px 0 29px;
      font-family: "\5FAE\8F6F\96C5\9ED1";
      font-size: 25px;
      color: #270645;
    }

    .pupils {
      margin: 0 0 22px;
      width: 496px;

      font-family: "\5FAE\8F6F\96C5\9ED1";
      font-size: 18px;
      color: #270645;
    }
  }

  .form {
    margin: 10px 52px;

    .title {
      font-family: "\5FAE\8F6F\96C5\9ED1";
      font-size: 15px !important;
      font-weight: bold;
      font-stretch: normal;
      color: #270645;
    }
    .forms {
  
    img{
      width: 25px;
      height: 25px;
      background-color:white;
    }
    .title {

      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      color: #270645;
    }
    	.reserveContant{
					position: absolute;
					right: 150px;
					top: 10px;
					width: 212px;
					height: 101px;
					background-color: #270645;
					border-radius: 3px;
					z-index:1;
					.des{
						margin: 15px;
						width: 183px;
						height: 71px;
						font-family: Helvetica;
						font-size: 12px;
					font-weight: normal;
					font-stretch: normal;
					letter-spacing: 0px;
					color: #ffffff;
					line-height: 15px;
					}
					
	
				}
  }
  }
  .sale {
    margin: 10px 52px;
    .newtop {
      .title {
        font-family: "\5FAE\8F6F\96C5\9ED1";
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #270645;
      }
    }
    h3 {
      min-width: 61px;
      height: 16px;
      font-family:Helvetica;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 16px;
      letter-spacing: 0px;
      color: #270645;
      margin-bottom: 14px;
    }
    .icons {
      margin-bottom: 10px;
      .any {
        margin-right: 100px;
        img {
          width: 27px;
          height: 27px;
          margin-right: 9px;
          margin-left: 0;
        }
        span {
          display: block;
          width: 61px;
          height: 15px;
          font-family:Helvetica;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #270645;
        }
      }
      .all {
        img {
          width: 27px;
          height: 27px;
          margin-right: 9px;
          margin-left: 0;
        }
        span {
          display: block;
          width: 61px;
          height: 15px;
          font-family:Helvetica;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #270645;
        }
      }
    }
    .selects {
      // margin-top: 13px;
      max-height: 200px;
      overflow-y: auto;
      .nftbox {
        width: 157px;
        height: 61px;
        background-color: #270645;
        border-radius: 3px;
        padding-left: 11px;
        margin-right: 5px;
        margin-bottom: 0;
        margin-top:5px;
        position:relative;
        img {
          width: 36px;
          height: 36px;
          margin: 0;
          object-fit: cover;
          
        }
        .txt {
          width: 76px;
          // min-height: 36px;
          font-family: Helvetica;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color:white;
          margin-left: 6px;
          overflow: hidden;
          line-height:12px;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }
        .clos {
          background:white;
          position: absolute;
          right:-5px;
          top: -5px;
          width: 23px;
          height: 23px;
          border-radius: 50%;
          margin-right: 0;
          cursor: pointer;
        }
      }
      .add {
        cursor: pointer;
        width: 157px;
        height: 61px;
        background-image: linear-gradient(#ffffff, #ffffff),
          linear-gradient(#f8f6fd, #f8f6fd);
        background-blend-mode: normal, normal;
        border-radius: 3px;
        border: solid 2px #e3e3e3;
        margin-top:5px;
        p {
          width: 70px;
          font-family:Helvetica;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #766983;
          margin: 0;
        }
      }
    }
  }
  .you {
    margin: 10px 52px;
    .newtop {
      .title {
        font-family: "\5FAE\8F6F\96C5\9ED1";
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #270645;
      }
    }
    h3 {
      min-width: 61px;
      height: 16px;
      font-family:Helvetica;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 16px;
      letter-spacing: 0px;
      color: #270645;
      margin-bottom: 14px;
    }
    .icons {
      margin-bottom: 10px;
      .any {
        margin-right: 100px;
        img {
          width: 27px;
          height: 27px;
          margin-right: 9px;
          margin-left: 0;
        }
        span {
          display: block;
          width: 61px;
          height: 15px;
          font-family:Helvetica;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #270645;
        }
      }
      .all {
        img {
          width: 27px;
          height: 27px;
          margin-right: 9px;
          margin-left: 0;
        }
        span {
          display: block;
          width: 61px;
          height: 15px;
          font-family:Helvetica;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #270645;
        }
      }
    }
    .selects {
      // margin-top: 13px;
      max-height: 200px;
      overflow-y: auto;
      .nftbox {
        width: 157px;
        height: 61px;
        background-color: #270645;
        border-radius: 3px;
        padding-left: 11px;
        margin-right: 5px;
        margin-bottom: 0;
        margin-top:5px;
        position:relative;
        img {
          width: 36px;
          height: 36px;
          margin: 0;
          object-fit: cover;
        }
        .txt {
          width: 76px;
          // min-height: 36px;
          margin-left: 6px;
          font-family: Helvetica;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #fff;
          line-height:12px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }
        .clos {
          background:white;
          position: absolute;
          right:-5px;
          top: -5px;
          width: 23px;
          height: 23px;
          border-radius: 50%;
          margin-right: 0;
          cursor: pointer;
        }
      }
      .add {
        cursor: pointer;
        width: 157px;
        height: 61px;
        background-image: linear-gradient(#ffffff, #ffffff),
          linear-gradient(#f8f6fd, #f8f6fd);
        background-blend-mode: normal, normal;
        border-radius: 3px;
        border: solid 2px #e3e3e3;
        margin-top:5px;
        p {
          width: 70px;
          font-family:Helvetica;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #766983;
          margin: 0;
        }
      }
    }
    .select ::v-deep fieldset {
      height: 57px;
      margin-top: -1px;
      // .v-text-field--outlined > .v-input__control ::v-deep.v-input__slot {
      //    min-height:48px!important;
      // }
    }
    .select ::v-deep .v-text-field__slot {
      height: 53px;
      // .v-text-field--outlined > .v-input__control ::v-deep.v-input__slot {
      //    min-height:48px!important;
      // }
    }
  }

  .maxamount {
    margin: 0 52px;
    height: 56px;

    .amount {
      margin-right: 18px;
      font-weight: bold;
      font-size: 15px;
      min-width: 212px;
      height: 17px;
      color: #270645;
      display: block;
    }

    .txt {
         &::v-deep  .v-input__slot {
      
                height:10px!important;
           
            }
      width: 98px;
      height: 55px;
      background-image: linear-gradient(#ffffff, #ffffff),
        linear-gradient(#f8f6fd, #f8f6fd);
      background-blend-mode: normal, normal;
      border-radius: 3px;
      display: inline-block;
    }

    .sth {
      margin-left: 108px;
      width: 60px;
      height: 35px;
      // background-color: #270645;
      display: block;
      position: relative;
      right: 0px;
      bottom: 0px;
      border-radius: 18px;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 53px auto;

    .price {
      display: block;
      font-family: "\5FAE\8F6F\96C5\9ED1";
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 40px;
      color: #766983;
    }

    .sub {
      margin: 0 auto;
      width: 450px;
      height: 51px;
      background-image: linear-gradient(
          90deg,
          #d300fd 0%,
          #a439fe 26%,
          #7471ff 52%,
          #00fdcf 100%
        ),
        linear-gradient(#270645, #270645);
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
      font-size: 20px;
      color: #ffffff;
    }
  }

  &.mobile {
    .maxamount {
      .sth {
        margin-left: 50px;
      }
    }

    .footer {
      margin: 10px 10%;

      .sub {
        width: 80%;
      }
    }
  }
}

img {
  display: inline-block;
  width: 126px;
  height: 126px;
  background-color: #8a73ff;
  border-radius: 5px;
  margin: 1px 22px 10px 52px;
}

span {
  align-items: center;
  font-family: "\5FAE\8F6F\96C5\9ED1";
  color: #270645;
}

.sub-dis {
  position: relative;
  pointer-events: none;
  background-image: linear-gradient(#766983, #766983),
    linear-gradient(#270645, #270645) !important;
  background-blend-mode: normal, normal;
  border-radius: 25px;
  opacity: 0.9;
}

.sub-dis::after {
  content: "";
  background-image: url(../../assets/loading.gif);
  background-size: 100%;
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  margin-top: 5px;

  // box-shadow: 0 0 10px #fff;
  // border-radius: 50%;
}
</style>
