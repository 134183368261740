<template>
  <div class="contain">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        src="@/assets/icons/btn_close.png"
        alt=""
      />
      <div class="bid d-flex flex-column" v-if="type != 'collection'">
        <span v-text="card.bidName" class="name"></span>
        <!-- <div class="flex-start-center d-flex flex-row" >
               <div class="pt-1 mr-2">
                  <v-img :src="countLogo" alt="logo" width="22px" height="22px"/>
               </div>
               <span class="count" style="line-height:22px;" v-text="card.bidCount"></span>
            </div> -->
      </div>
      <div class="bid d-flex flex-column" v-else>
        <span v-text="card.bidName" class="name"></span>
      </div>
      <div class="list">
        <div class="title">{{ $t("popupSaletotalSalesStatistics") }}</div>
        <div class="list_cont d-flex flex-row">
          <div class="col d-flex flex-column ml-3">
            <div class="row">
              {{ $t("popupSaletotalTotalRelease") }}:
              {{  totalInfo.totalInventory ?totalInfo.totalInventory:0}}
            </div>
            <div class="row">
              {{ $t("popupSaletotalSold") }}: {{ totalInfo.soldAmount }}
            </div>
          
            <!-- <div class="row">{{$t('popupSaletotalTotalIncome')}}: {{totalInfo.pincome}} IRIS</div> -->
          </div>
          <div class="col d-flex flex-column ml-8">
            <!-- <div class="row">
              {{ $t("popupSaletotalResale") }}: {{ totalInfo.presell }}
            </div> -->
              <div class="row">
              {{ $t("popupSaletotalInStock") }}: {{ totalInfo.inventory ?totalInfo.inventory:0 }}
            </div>
            <div class="row">
              {{ $t("popupNewTransferTransfer") }}: {{ totalInfo.transferrerAmount ? totalInfo.transferrerAmount:0}}
            </div>
            <!-- <div class="row">{{$t('popupSaletotalLikes')}}: {{totalInfo.loveNum}}</div>
                  <div class="row">{{$t('popupSaletotalViews')}}: {{totalInfo.clickNum}}</div> -->
          </div>
          <div class="col d-flex flex-column ml-8">
            <!-- <div class="row">{{$t('popupSaletotalResale')}}: {{totalInfo.presell}}</div>
                  <div class="row">{{$t('popupNewTransferTransfer')}}: {{totalInfo.pSend}}</div> -->
            <div class="row">
              {{ $t("popupSaletotalLikes") }}: {{ likeInfo.likeCount ? likeInfo.likeCount:0}}
            </div>
            <div class="row">
              {{ $t("popupSaletotalViews") }}: {{ likeInfo.viewCount ?likeInfo.viewCount:0 }}
            </div>
          </div>
        </div>
      </div>
      <div class="line"></div>

      <div class="totalIncome">
        <div class="title" v-if="aviIris>0 || aviAtom>0 || aviUsdc>0 ">Total Income</div>
        <div class="incoList d-flex flex-column">
          <div class="d-flex justify-space-between iconItem" v-if="aviIris>0">
            <div class="d-flex flex-row">
              <v-img
                :src="require('@/assets/icons/chain_iris_w' + '.png')"
                width="22px"
                height="22px"
              />
              <div>IRIS</div>
            </div> 
            <div class="iconBalance">{{aviIris}}</div>
          </div>
          <div class="d-flex justify-space-between iconItem" v-if="aviAtom>0">
            <div class="d-flex flex-row">
              <v-img
                :src="require('@/assets/icons/chain_atom_w' + '.png')"
                width="22px"
                height="22px"
              />
              <div>ATOM</div>
            </div>
            <div class="iconBalance">{{aviAtom}}</div>
          </div>
          <div class="d-flex justify-space-between iconItem"  v-if="aviUsdc>0">
            <div class="d-flex flex-row">
              <v-img
                :src="require('@/assets/icons/chain_usdc_w' + '.png')"
                width="22px"
                height="22px"
              />
              <div>USDC</div>
            </div>
            <div class="iconBalance">{{aviUsdc}}</div>
          </div>
        </div>
      </div>
      <div class="line1"  v-if="aviIris>0 || aviAtom>0 || aviUsdc>0 "></div>
      <div class="table mt-12 mb-4">
        <div class="d-flex flex-row justify-space-between">
          <div class="title">{{ $t("popupSaletotalOrders") }}</div>
          <img
            src="@/assets/icons/icon_message_all.png"
            class="icon"
            style="width: 30px; height: 30px"
            @click="messageAllClick"
            v-if="orderlist.length > 0"
          />
        </div>

        <div class="orderlist">
          <div
            class="itm mb-1 d-flex justify-space-between px-2 elevation-1"
            v-for="i in orderlist"
            :key="i.id"
          >
            <img
            style="width: 7%"
              class="collectionimg"
              v-if="i.nftImgUrl"
              :src="i.nftImgUrl"
              alt=""
            />
            <div style="width: 20%" class="d-flex flex-row justify-space-around mt-2">
              <Avatar
                :size="30"
                :did="i.assetOwner"
                :hash="i.profilePhoto"
                imgType="middlev"
                :grade="i.grade"
              />
              <span class="ml-2 mt-2 name item_font">{{ i.issuerName?i.issuerName :i.toOwner.substr(0,4)+ ".."+ i.toOwner.substr(-4) }}</span>
            </div>
            <div style="width: 15%" class="price">{{ i.orderPrice }} {{getChainNameByDenom(i.coinDenom)}}</div>
            <div style="width: 25%" class="time ml-2" :title="i.createTime | didfilter">
              {{ i.createTime | didfilter }}
            </div>
            <img
            style="width: 5%"
              class="mt-3 ml-2 ml-lg-6"
              width="25px"
              height="24px"
              :src="message"
              alt=""
              @click="msgBtn(i)"
            />
          </div>
        </div>
      </div>
    </v-card>
    <div
      v-if="openmsg"
      style="position: fixed; right: 20px; bottom: 20px; z-index: 99"
    >
      <message
        @getOpen="OpenMsg"
        v-if="openmsg"
        :currfriend="currfriend"
      ></message>
    </div>
    <div
      v-if="openmsg_group"
      style="position: fixed; right: 20px; bottom: 20px; z-index: 99"
    >
      <message-group
        @getOpen="OpenMsg_group"
        v-if="openmsg_group"
        :currfriend="currfriend_group"
      ></message-group>
    </div>
    <uToast ref="toast"></uToast>
  </div>
</template>

<script>
import message from "@/assets/icons/btn_message.png";
import countLogo from "@/assets/icons/chain_iris_w.png";
import Message from "./message";
import MessageGroup from "./messageGroup";
import api from "@/api";
import Avatar from "@/components/avatar/index.vue";
import { getFileSrc } from "@/utils/file";
const COLLECTION_KEY = "COLLECTION";

export default {
  components: { Avatar, Message, MessageGroup },
  data: () => ({
    message,
    countLogo,
    open: true,
    openmsg: false,
    openmsg_group: false,
    card: {
      bidName: "",
      bidCount: "",
    },
    orderlist: [],
    totalInfo: {},
    pageNumber: 1,
    pageSize: 400,
    totalPage: false,
    currfriend: {},
    currfriend_group: {},
    coinDenomIncomeList: [],
    aviIris:0,
   aviAtom:0,
   aviUsdc:0,
   likeInfo:{}
  }),
  props: {
    address: {
      type: String,
    },
    id: {
      type: Number,
    },
    name: {
      type: String,
    },
    minPrice: {
      type: String,
    },
    type: {
      type: String,
    },
    pageData:{
      type:Object
    }
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  filters: {
    didfilter: function (value) {
      let date = new Date(value);
      let Y = date.getFullYear() + " ";
      let M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "/";
      let D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "/";
      let h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      let m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      let s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      let strDate = M + D + Y + h + m + s;
      return strDate;
    },
  },
  mounted() {
    this.getInfo();
    this.getList();
    this.card.bidName = this.name;
    if (this.minPrice != undefined) {
      this.card.bidCount = this.minPrice;
    }
  },
  methods: {
    //统计的报表数据
    async getInfo() {
      let params = {
        contractAddress:this.pageData.contractAddress,
        tokenId: this.pageData.tokenId,
        owner: this.$store.state.did
      };

      let res = await api.home.cardDetailInfo(params);
      this.likeInfo = res.data;
      if(res.data.assetExtensionVo){
        this.totalInfo = res.data.assetExtensionVo;
  //      aviIris:0,
  //  aviAtom:0,
  //  aviUsdc:0,
    this.aviIris =  this.totalInfo.irisTotalPrice
    this.aviAtom =  this.totalInfo.atomTotalPrice
      this.aviUsdc =  this.totalInfo.usdcTotalPrice
      }
      // if (res.data.assetExtensionVo) {
      //   this.totalInfo = res.data.assetExtensionVo;
      //   this.coinDenomIncomeList = res.data.coinDenomIncomeList;
      //   if (this.totalInfo.lastBidPrice != undefined) {
      //     this.card.bidCount = this.totalInfo.lastBidPrice;
      //   }
      //   if (this.coinDenomIncomeList.length > 0) {
      //     for (    
      //       let i = 0;
      //       i < this.coinDenomIncomeList.length;
      //       i++
      //     ) {
      //       if (this.coinDenomIncomeList[i].coinDenom == "uiris") {
      //         this.aviIris =this.coinDenomIncomeList[i].coinDenomIncome;
      //       } else if (
      //         this.coinDenomIncomeList[i].coinDenom ==
      //         "ibc/27394FB092D2ECCD56123C74F36E4C1F926001CEADA9CA97EA622B25F41E5EB2"
      //       ) {
      //         this.aviAtom =this.coinDenomIncomeList[i].coinDenomIncome;
      //       } else if (
      //         this.coinDenomIncomeList[i].coinDenom ==
      //         "ibc/A5A5EBF3B7A180FBA2A3CE133153F5B904AA8B085691F66A15BE8156B6722652"
      //       ) {
      //         this.aviUsdc = this.coinDenomIncomeList[i].coinDenomIncome;
      //       }
      //     }
      //   }
      // }
    },
    //统计的列表数据
    async getList() {
      let params = {
        chainType: this.$store.state.chainType,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        did: this.$store.state.did,
        contractId: this.pageData.contractId,
      };
      if(this.pageData.collectionType == 1){
        params.tokenId = this.pageData.tokenId
      }
      let res = await api.home.getTotalList(params);
      let orderlist = res.data.list;
      orderlist.forEach(async (v) => {
        let src;
        if (v.nftImgUrl) src = await getFileSrc(COLLECTION_KEY, v.nftImgUrl);
        this.orderlist.push({
          id: v.id,
          nftImgUrl: src,
          assetOwner: v.assetOwner,
          profilePhoto: v.profilePhoto,
          grade: v.grade,
          issuerName: v.issuerName,
          orderPrice: v.orderPrice,
          createTime: v.createTime,
          hasLine: true,
          coinDenom:v.coinDenom,
          toOwner:v.toOwner
        });

        // }
      });
    },
    onScroll(e) {
      const {
        scrollTop,
        clientHeight,
        scrollHeight,
      } = e.target.scrollingElement;
      if (
        scrollTop + clientHeight === scrollHeight &&
        this.totalPage > this.pageNumber
      ) {
        this.getList();
      }
    },
    //关闭弹框，发送open值
    closeDialog() {
      this.open = false;
      this.$emit("getOpen", this.open);
    },
    msgBtn(i) {
      if (i.assetOwner == this.$store.state.did) {
        this.$refs.toast.show("error", this.$t("messageAlert"), 1000);
        return;
      }
      this.currfriend.fansAddress = i.assetOwner;
      this.currfriend.fansName = i.issuerName;
      this.openmsg_group = false;
      this.openmsg = true;
    },
    //弹框关闭后获取open值
    OpenMsg(e) {
      this.openmsg = e;
    },
    messageAllClick() {
      this.currfriend_group.group = 1;
      this.currfriend_group.fansAddress = this.address;
      this.currfriend_group.fansName = this.$t("notice_group_leavemsg");
      this.openmsg = false;
      this.openmsg_group = true;
    },
    //弹框关闭后获取open值
    OpenMsg_group(e) {
      this.openmsg_group = e;
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  min-height: 600px;
  width: 660px;
  //width: 100%;

  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }

  .bid {
    margin: 45px 0 10px;

    .name {
      font-family: Helvetica;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
    }

    .count {
      font-family: Helvetica;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
    }
  }

  .list {
    .title {
      font-family: Helvetica;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
    }

    .list_cont {
      height: 30px;

      .col {
        width: 50%;
        padding: 0;
        text-align: left;

        .row {
          font-family: Helvetica;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #270645;
          vertical-align: middle;
          margin-top: 16px;
        }
      }
    }
  }
  .line {
    border: solid 1px #e3e3e3;
    margin-top: 75px;
  }
  .line1 {
    border: solid 1px #e3e3e3;
    margin-top: 15px;
  }
  .totalIncome {
    .title {
      font-family: Helvetica !important;
      font-size: 20px !important;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      margin-top: 10px;
    }
    .incoList {
      .iconItem {
        margin-top: 10px;
      }
    }
  }

  .table {
    .title {
      font-family: Helvetica;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
    }

    .orderlist {
      width: 100%;
      height: 263px;
      overflow: hidden;
      overflow-y: auto;

      .itm {
        height: 51px;
        width: 556px;
        // background-image: linear-gradient(#ffffff, #ffffff),
        // linear-gradient(#6f58d9, #6f58d9);
        background-blend-mode: normal, normal;
        box-shadow: 2px 2px 5px #ccc !important;
        border-radius: 5px;

        .item_font {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 60px;
        }
        .collectionimg {
          width: 32px;
          height: 32px;
          margin-top: 8px;
        }

        .name {
          font-family: Helvetica;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #270645;
        }

        .price {
          font-family: Helvetica;
          font-size: 13px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0px;
          line-height: 50px;
          color: #270645;
        }

        .time {
          font-family: Helvetica;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 50px;
          letter-spacing: 0px;
          color: #270645;
        }

        img {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }

  &.mobile {
    .table {
      .orderlist {
        width: 100%;

        .itm {
          .price {
            min-width: 50px;
          }

          .time {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 40px;
          }

          .item_font {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 30px;
          }
        }
      }
    }
  }
}
</style>
