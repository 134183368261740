<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        src="@/assets/btn_close.png"
        alt=""
      />
      <div class="top">
        <span class="title" v-if="lastBid"> {{ $t("detailIndexPlaceBid") }}</span>
        <h3 class="content" v-if="lastBid">
          {{ $t("popupAuctionDefectPriceOnceTheBid") }}
        </h3>
        <h3 class="title" v-if="purchBid">
          {{ $t("popupBuyPriceBuyNow") }}
        </h3>
        <h3 class="contentPrice " v-if="isguarant && showMessage">
          {{ $t("hasReservePrice") }}
        </h3>

        <div class="form">
          <div class="ti-in">
            <span class="titles" v-if="lastBid">{{text}}<span class="price"> {{price}}</span>{{unit}}</span>
            <!-- <span class="titles" v-if="purchBid ">{{text}}<span class="price">{{Nft_sku.buyoutPrice}}</span>{{unit}}</span> -->
             <h3 class="content mt-10" v-if="purchBid">
              {{ $t("DoNotAuction") }}
            </h3>
            <br />
            <!-- <span class="minprice"> {{ value3 }} IRIS</span> -->
            <v-text-field
              ref="fixPrice"
              v-model.trim="fixPrice"
              :rules="[ rules.Bid]"
              outlined
              :suffix="getChainNameByDenom(Nft_sku.coinDenom)"
              type="input"
              class="textinput"
              style="margin-top: 8px"
              v-if="!isFix"
            ></v-text-field>
            <v-text-field
              v-model.trim="fixPrice"
              outlined
              :suffix="getChainNameByDenom(Nft_sku.coinDenom)"
              type="input"
              class="textinput"
              style="margin-top: 8px"
              readonly
              v-if="isFix"
            ></v-text-field>
          </div>
          <div></div>
        </div>
      </div>
      <div class="footer">
        <!-- <span class="price"
          >{{ $t("popupAuctionDefectPriceHandingFee") }}：{{
            value4
          }}
          IRIS</span
        > -->
<!--        <span class="price">Gas：{{ value1 }} IRIS</span>-->
 <button class="sub" :class="{'sub-dis': isPay}" submit @click="subCommit" v-if="isLoad">
            {{$t("loadBalance")}}
        </button>
        <button class="sub" :class="{'sub-dis': isPay}" submit @click="subCommit" v-else-if="isBalance">
          {{ $t("popupTransferCommit") }}
        </button>
        <button
          class="subdefect"
          submit
          @click="subCommit"
          v-else-if="!isBalance"
          disabled="disabled"
        >
          {{ $t("popupAuctionDefectPriceInsufficientFunds") }}
        </button>
        <span class="price"
          >{{ $t("popupNewTransferBalance") }}：{{ value2 }} {{getChainNameByDenom(Nft_sku.coinDenom)}}</span
        >
      </div>
    </v-card>
    <v-snackbar
      v-model="isShowFalse"
      color="error"
      centered
      timeout="2000"
      style="opacity: 0.8"
      ><span style="color: white; font-size: 20px">{{
        falseTitle
      }}</span></v-snackbar
    >
    <PromptBox ref="promptBox"></PromptBox>
    <uComponents  ref="ucom"></uComponents>
  </div>
</template>
<script>
import { orderPay,getMyBalance,quiryTx } from "../../keplr/iris/wallet";
import { init as keplrInit, getBalances} from "../../keplr/wallet";
import PromptBox from "@/components/PromptBox.vue";
import api from "@/api";
export default {
  components: { PromptBox },
  data: function() {
	  return{
    open: true,
    fixPrice: "",
    isFix: false,
    value1: "0.075",
    value2: "",
    value3: "",
    value4: "0.075",
    isShowFalse: false,
    falseTitle: "",
    text:'',
    unit:'',
    isBalance:false,
    isguarant:false,
    lastBid:false,
    purchBid:false,
    showMessage:false,
    isPay: false,
    price:'',
    isLoad:false,
    rules: {
      // BidRules: (value) => !!value || "Place a bid is required.",
      Bid: (value) => {
        value = value.replace(/^\s+|\s+$/g,"")
        const pattern = /^\d*(?:\.\d{0,4})?$/;
        return pattern.test(value) || this.$t("PleaseInPutBid");
      },
    },
    flag:true
	}
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },

  props: {
    auctionNo: {
      type: String,
    },
    Nft_sku: {
      type: Object,
    },
    buyType: {
      type: Number,
    },


  },
  async mounted() {
    console.log("this.Nft_sku",this.Nft_sku);
    
    if(this.Nft_sku.guaranteedPrice != 0)
    {
      console.log("ssssssss")
      this.isguarant = true
    }
    console.log(this.Nft_sku.guaranteedPrice)
    console.log("0000000000000");
    console.log(this.Nft_sku);
    console.log(this.auctionNo);
    let text = this.$t("popupAuctionDefectPriceYouMust")
  this.Nft_sku = this.Nft_sku.auctionVo
    // this.text = text.substring(0,text.length-3) + price + " "+"IRIS"
    let type = this.buyType;
    this.isLoad = true
    this.isPay = true
    if(this.Nft_sku.useType == 1)
    {
      this.lastBid =true
      // 10500 == 1.05 * 10000
      this.price = parseFloat(Math.ceil((this.Nft_sku.lastBidPrice)*10500))/10000
       this.text = text.substring(0,text.length-3)
       this.unit =  " "+this.getChainNameByDenom(this.Nft_sku.coinDenom)
       this.showMessage = true;

    }
    if (type == 0) {

      this.lastBid =true
      this.value3 = this.Nft_sku.lastBidPrice;
      this.price =parseFloat(Math.ceil(Number(this.Nft_sku.lastBidPrice)*10500))/10000
       console.log(" 00000000000",this.Nft_sku.lastBidPrice,this.price,)
       //this.text = text.substring(0,text.length-3) + this.Nft_sku.lastBidPrice + " "+"IRIS"
      //this.fixPrice = this.value3
      this.text = text.substring(0,text.length-3)
       this.unit =  " "+ this.getChainNameByDenom(this.Nft_sku.coinDenom)
      this.isFix = false;
      this.showMessage = true;
      let price;
        price = Number(this.price);
         let res = await getMyBalance();
    console.log("updata balance ----")
    console.log(res);

     keplrInit(1);
    this.accountBalance = await getBalances();
    console.log("keplrInit(2);",this.accountBalance)

     let aviIris = 0,aviUsdc=0,aviAtom=0;
       let mount;
        let env =  process.env.VUE_APP_IRIS_CHAIN_ID
       if(env == 'nyancat-9'){
            if(this.accountBalance.balancesList){
      for(let i=0;i<this.accountBalance.balancesList.length;i++)
      {
        if(this.accountBalance.balancesList[i].denom == "unyan"){
          aviIris= this.accountBalance.balancesList[i].amount/1000000   
          
        }else if(this.accountBalance.balancesList[i].denom == "ibc/733D9E6DE683B9726B2C6A6460566B11FAF4C075DC08861685F269537D43E24D")
        {
          aviAtom = this.accountBalance.balancesList[i].amount/1000000    
            
        }else if(this.accountBalance.balancesList[i].denom == "ibc/5C465997B4F582F602CD64E12031C6A6E18CAF1E6EDC9B5D808822DC0B5F850C")
        {
           aviUsdc = this.accountBalance.balancesList[i].amount/1000000   
           
        }
      }
    };
     
    if(this.Nft_sku.coinDenom == 'unyan'){
      mount = aviIris
    }else if(this.Nft_sku.coinDenom == 'ibc/733D9E6DE683B9726B2C6A6460566B11FAF4C075DC08861685F269537D43E24D'){
       mount = aviAtom
    }else if(this.Nft_sku.coinDenom == 'ibc/5C465997B4F582F602CD64E12031C6A6E18CAF1E6EDC9B5D808822DC0B5F850C'){
       mount = aviUsdc
    }
        }else{
            if(this.accountBalance.balancesList){
      for(let i=0;i<this.accountBalance.balancesList.length;i++)
      {
        if(this.accountBalance.balancesList[i].denom == "uiris"){
          aviIris= this.accountBalance.balancesList[i].amount/1000000   
          
        }else if(this.accountBalance.balancesList[i].denom == "ibc/27394FB092D2ECCD56123C74F36E4C1F926001CEADA9CA97EA622B25F41E5EB2")
        {
          aviAtom = this.accountBalance.balancesList[i].amount/1000000    
            
        }else if(this.accountBalance.balancesList[i].denom == "ibc/A5A5EBF3B7A180FBA2A3CE133153F5B904AA8B085691F66A15BE8156B6722652")
        {
           aviUsdc = this.accountBalance.balancesList[i].amount/1000000   
           
        }
      }
    };
     
    if(this.Nft_sku.coinDenom == 'uiris'){
      mount = aviIris
    }else if(this.Nft_sku.coinDenom == 'ibc/27394FB092D2ECCD56123C74F36E4C1F926001CEADA9CA97EA622B25F41E5EB2'){
       mount = aviAtom
    }else if(this.Nft_sku.coinDenom == 'ibc/A5A5EBF3B7A180FBA2A3CE133153F5B904AA8B085691F66A15BE8156B6722652'){
       mount = aviUsdc
    }

 }

    if(!mount || mount=="null" || mount=="undefined") mount = 0;
    this.value2 = mount;
        if (mount >=price) {
                this.isPay = false
        this.isLoad =false
          this.isBalance = true;
        } else {
                this.isPay = false
        this.isLoad =false
          this.isBalance = false;
        }
    } else {
      this.purchBid = true
      this.value3 = this.Nft_sku.buyoutPrice;
       //this.text = text.substring(0,text.length-3) + this.Nft_sku.buyoutPrice + " "+"IRIS"
       this.text = text.substring(0,text.length-3)
       this.unit =  " "+ this.getChainNameByDenom(this.Nft_sku.coinDenom)
       this.isFix = true;
      this.fixPrice = this.value3;
      this.showMessage = false
       let res = await getMyBalance();
    console.log("updata balance ----")
    console.log(res);

    let mount ;

     // 获取iris 的余额
  
     keplrInit(1);
    this.accountBalance = await getBalances();
    console.log("keplrInit(2);",this.accountBalance)

     let aviIris = 0,aviUsdc=0,aviAtom=0;
     debugger
    if(this.accountBalance.balancesList){
      for(let i=0;i<this.accountBalance.balancesList.length;i++)
      {
        if(this.accountBalance.balancesList[i].denom == "uiris" || this.accountBalance.balancesList[i].denom == "unyan" ){
          aviIris= this.accountBalance.balancesList[i].amount/1000000   
          
        }else if(this.accountBalance.balancesList[i].denom == "ibc/27394FB092D2ECCD56123C74F36E4C1F926001CEADA9CA97EA622B25F41E5EB2"
        || this.accountBalance.balancesList[i].denom == "ibc/733D9E6DE683B9726B2C6A6460566B11FAF4C075DC08861685F269537D43E24D")
        {
          aviAtom = this.accountBalance.balancesList[i].amount/1000000    
            
        }else if(this.accountBalance.balancesList[i].denom == "ibc/A5A5EBF3B7A180FBA2A3CE133153F5B904AA8B085691F66A15BE8156B6722652"
        || this.accountBalance.balancesList[i].denom == "ibc/5C465997B4F582F602CD64E12031C6A6E18CAF1E6EDC9B5D808822DC0B5F850C")
        {
           aviUsdc = this.accountBalance.balancesList[i].amount/1000000   
           
        }
      }
    };
    console.log("wxl --- balancesList",aviIris)
    if(this.Nft_sku.coinDenom == 'uiris' || this.Nft_sku.coinDenom == 'unyan' ){
      mount = aviIris
    }else if(this.Nft_sku.coinDenom == 'ibc/27394FB092D2ECCD56123C74F36E4C1F926001CEADA9CA97EA622B25F41E5EB2'
    || this.Nft_sku.coinDenom == 'ibc/733D9E6DE683B9726B2C6A6460566B11FAF4C075DC08861685F269537D43E24D'){
       mount = aviAtom
    }else if(this.Nft_sku.coinDenom == 'ibc/A5A5EBF3B7A180FBA2A3CE133153F5B904AA8B085691F66A15BE8156B6722652'
    || this.Nft_sku.coinDenom == 'ibc/5C465997B4F582F602CD64E12031C6A6E18CAF1E6EDC9B5D808822DC0B5F850C'){
       mount = aviUsdc
    }


    if(!mount || mount=="null" || mount=="undefined") mount = 0;
    this.value2 = mount;


    //价格
      //this.fixPrice = this.priceSecond;
        let price;
        price = Number(this.fixPrice);
        if (mount >=price) {
                this.isPay = false
        this.isLoad =false
          this.isBalance = true;
        } else {
                this.isPay = false
        this.isLoad =false
          this.isBalance = false;
        }

    }
    
  },
  methods: {
    verify() {
      let fixVer = this.$refs.fixPrice.validate(true);
      !fixVer ? this.$refs.fixPrice.focus() : "";
      return fixVer;
    },
         toDecimal(x) { 
 var f = parseFloat(x); 
 if (isNaN(f)) { 
 return; 
 } 
 f = Math.round(x*100)/100; 
 return f; 
 },

      sleep(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    //提交转送数据
    async subCommit() {
      if(this.lastBid)
      {
          if (!this.verify()) {
        return;
      }
      }
      this.isPay = true
      if (this.buyType == 0) {
        console.log(this.fixPrice);
        let fixPrice = Number(this.fixPrice);
        // let lastBidPrice = Number(this.Nft_sku.lastBidPrice);
        console.log(this.Nft_sku.lastBidPrice);
        if (fixPrice < this.price) {
          this.isShowFalse = true;
          this.falseTitle = this.$t("LowerThanCurrentPrice");
          this.isPay = false
          return;
        }
      }
      try {
        // 判断是否符合拍卖auctionUpdate
        //出价价格 BidPrice  拍卖人 bidder=did，  auctionNo 拍卖号
        let bidParams = {
          BidPrice: this.fixPrice,
          bidder: this.$store.state.did,
          auctionNo: this.auctionNo,
        };
        let result = await api.home.auctionUpdate(bidParams);
        if (result.success == true) {
          // get fee
          let res = await api.home.getFee();
          let recipient = res.data.irisMerchantAuctionAddress;

          console.log(this.fixPrice);
          let auctionNo = this.auctionNo;
          //let recipient = process.env.VUE_APP_RECIPENT_ADDRESS;
          let orderPrice = this.fixPrice;

          let memo = {
            type: "AUCTION_BID", //出价
            param: {
              auctionNo: auctionNo, //竞拍号
              coinDenom:this.Nft_sku.coinDenom
            },
          };

          //链上付款
          console.log("wxl ------ 222222");
          console.log(orderPrice, recipient, memo);

          let payResult = await orderPay(
            orderPrice,
            recipient,
            JSON.stringify(memo),
            this.Nft_sku.coinDenom
          );
          if (payResult.hash){
                     let isShowTip = localStorage.getItem("IRIS_isShowTip_"+this.$store.state.did)
        if(isShowTip == 'true'){
               this.$mtip({
                title:this.$t("transactionInprogressSuccess"),
              });
          }
        }
           
              this.$emit("getOpen", false)
              this.isPay = false
             while (this.flag) {
             
              console.log("wwwwww");
            
              await this.sleep(5000).then(async () => {
                 //console.log(result.hash)
                let res = await quiryTx(payResult.hash);
                console.log("wxl -----  quiryTx");
                console.log(res);
                if (res.code == 0) {
                let t = this.$t("bid_Placed")
					      let reg=new RegExp('【N】','g')//g代表全部
					      let regN=new RegExp('<br/>','g')//g代表全部
					      let name = "【" + this.name + "】"
                let title=t.replace(reg,name).replace(regN," ");
                            let isShowTip = localStorage.getItem("IRIS_isShowTip_"+this.$store.state.did)
        if(isShowTip == 'true'){
               this.$mtip({
                title:title,
              });
          }
              
                  this.flag = false;
                } else if (res.code == -1) {
                  console.log("error");
                               let isShowTip = localStorage.getItem("IRIS_isShowTip_"+this.$store.state.did)
        if(isShowTip == 'true'){
               this.$mtip({
                 title:res.log,
              });
          }
              
              this.flag = false;
                } else {
                  this.flag = true;
                }
              });
            }
            

          //    this.$toast("success", this.$t("putawaySuccess")).then(() => {
          //     this.$emit("getOpen", false)
          //     this.isPay = false
          // })

            //  this.$toast("success", this.$t("putawaySuccess"))
          if(this.buyType == 1){
            this.$router.push({name:'Marketplace'})
          }
          // hash = payResult.hash;
          console.log(payResult);
        } else {
        //this.$refs.promptBox.show(result.message);
        this.$toast("error", this.$t(result.msg))
        this.isPay = false
        }
      } catch (e) {
        //this.$refs.promptBox.show(this.$t("putawayFailed"));
        this.$toast("error", this.$t("putawayFailed"))
        this.isPay = false
      }
    },
    //关闭弹框，发送open值
    closeDialog() {
      this.open = false;
      this.$emit("getOpen", this.open);
    },
  },
};
</script>
<style lang="scss" scoped>
img {
  display: inline-block;
  width: 126px;
  height: 126px;
  background-color: #8a73ff;
  border-radius: 5px;
  margin: 1px 22px 10px 52px;
}

.card {
  display: flex;
  flex-direction: column;
  min-height: 400px;
  width: 600px;

  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }

  .top {
    display: flex;
    flex-direction: column;
    margin-left: 52px;
    margin-right: 52px;

    .title {
      margin: 42px 0 0 0;
      width: 300px;
      height: 21px;
     font-family:Helvetica;
      font-size: 25px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      font-weight:bold
    }

    .content {
      margin: 10px 0 15px 0;
      width: 485px;
      height: 62px;
      font-family:Helvetica;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #766983;
    }
    .contentPrice{
      margin: -10px 0 20px 0;
      width: 485px;
      height: 62px;
      font-family:Helvetica;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #766983;

    }

    .form {
      .ti-in {
        .titles {
          margin-top: 24px;
          width: 152px;
          height: 13px;
          font-family:Helvetica;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 48px;
          letter-spacing: 0px;
          color: #270645;
        }
        .price{
          font-weight:bold

        }

        .minprice {
          width: 51px;
          height: 13px;
         font-family:Helvetica;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #270645;
        }

        .textinput {
          //  width: 500px;
          height: 75px;
          background-image: linear-gradient(#ffffff, #ffffff),
            linear-gradient(#f8f6fd, #f8f6fd);
          background-blend-mode: normal, normal;
          border-radius: 5px;
        }
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 50px;

    .price {
      display: block;
      font-family:Helvetica;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      color: #766983;
    }

    .sub {
      margin: 20px auto;
      width: 450px;
      height: 51px;
      background-image: linear-gradient(
          90deg,
          #d300fd 0%,
          #a439fe 26%,
          #7471ff 52%,
          #00fdcf 100%
        ),
        linear-gradient(#270645, #270645);
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
      font-size: 20px;
      color: #ffffff;
    }
     .subdefect {
      margin: 20px auto;
      width: 450px;
      height: 51px;
      background-image: linear-gradient(#766983, #766983),
        linear-gradient(#270645, #270645);
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
      font-size: 20px;
      color: #ffffff;
    }
  }
  &.mobile {
    .top {
      margin: 0 10%;

      .content {
        width: 90%;
        height: auto;
      }
      .form {
        .ti-in {
          width: 100%;
          .textinput {
            width: 100%;
          }
        }
      }
    }

    .footer {
      .sub {
        width: 80%;
      }
    }
  }
}
.sub-dis {
    position: relative;
    pointer-events: none;
    background-image: linear-gradient(
      #766983, 
      #766983), 
     linear-gradient(
      #270645, 
      #270645) !important;
     background-blend-mode: normal, 
      normal;
     border-radius: 25px;
     opacity: 0.9;
}
.sub-dis::after {
    content: "";
    background-image: url(../../assets/loading.gif);
    background-size: 100%;
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-top: 5px;
    
    // box-shadow: 0 0 10px #fff;
    // border-radius: 50%;
}
</style>




