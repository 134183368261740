import axios from 'axios'

const OAuth = require('oauth-1.0a');
// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // baseURL: 'http://192.168.31.229:5090/',
  baseURL: '/twitter',
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000 ,// request timeoutd

})
// request interceptor
const oauth = OAuth({
  consumer: {
    key: 'M3ieFojAUrp5ggGrOVKLqFDt9',
    secret: 'hqKEWOFXjZnZ7uSMJ12cCqQLhh51Hkow1ryVZcAwKZezYscpe8'
  },
  signature_method: 'HMAC-SHA1',
  hash_function: (baseString, key) => crypto.createHmac('sha1', key).update(baseString).digest('base64')
});
 // const authHeader = oauth.toHeader(oauth.authorize({
 //    url: 'https://api.twitter.com/oauth/access_token',
 //    method: 'POST'
 //  }));


export default service