<template>
  <div class="horizontal-container" v-if="!isMobile">
    <div class="scroll-wrapper" ref="scroll">
      <div class="scroll-content">
        <slot />
      </div>
    </div>
  </div>
  <div v-else class="horizontal-container-mobile">
    <div class="scroll-content">
      <slot />
    </div>
  </div>
</template>

<script>
import BScroll from "@better-scroll/core";

export default {
  name: "ScrollX",
  props: {
    config: Object,
     source:String
  },
  data() {
    return { bs: undefined };
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  updated() {
    this.bs.refresh();
  },
  mounted() {
    if(this.source == 'feature'){
       this.$gtag.event('创作者推荐-滚动', { 'event_category': 'Scroll', 'event_label': 'creator' })
    }
    this.init();
  },
  beforeDestroy() {
    this.bs.destroy();
  },
  methods: {
    init() {
      this.bs = new BScroll(this.$refs.scroll, {
        scrollX: true,
        ...this.config,
        preventDefault: false,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.horizontal-container {
  .scroll-wrapper {
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    .scroll-content {
      display: inline-block;
    }
  }
}
.horizontal-container-mobile {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  .scroll-content {
    display: inline-block;
  }
}
::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  background-color: #333;
}
::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  background: transparent;
}
</style>
