<template>
  <div id="container">
    <v-card id="card" :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        src="@/assets/btn_close.png"
        alt=""
      />
      <div class="top">
        <span class="title"> {{ $t("popupBuyPriceBuyNow") }}</span>
        <h3 class="content">
          {{ $t("popupBuyPriceAfterSuccessful") }}
        </h3>
        <div class="form">
          <div class="titles mb-4">{{ $t("popupBuyPricePrice")}} {{ couponOldPrice }}</div>
		  <!-- 没有专卖卡显示 -->
		  <div v-if="exclusiveName!=''" class="redN" v-html="exclusiveName"></div>
		  <!-- 持有优惠卡显示 -->
		   <div v-if="haveCoupon">{{ $t("yh_des_bot") }}</div>
          <v-text-field
            v-model="fixPrice"
            outlined
            :suffix="getChainNameByDenom(Nft_sku.coinDenom)"
            type="input"
            class="textinput"
            style="margin-top: 8px"
            readonly
          ></v-text-field>
        </div>
      </div>
      <div class="footer">
         <button class="sub" :class="{'sub-dis': isPay}" submit @click="subCommit" v-if="isLoad">
           {{$t("loadBalance")}}
        </button>
        <button class="sub" :class="{'sub-dis': isPay}" submit @click="subCommit" v-else-if="isBalance">
          {{ $t("popupTransferCommit") }}
        </button>
         <!-- <button
          class="subdefect"
          submit
          @click="subCommit"
          v-else-if="isNotEexclusive"
          disabled="disabled"
        >
          {{ $t("popupTransferCommit") }}
        </button> -->
        <button
          class="subdefect"
          submit
          @click="subCommit"
          v-else-if="!isBalance"
          disabled="disabled"
        >
          {{showMeaage}}
        </button>
        <span class="price"
          >{{ $t("popupNewTransferBalance") }}：{{ value2 }} {{getChainNameByDenom(Nft_sku.coinDenom)}}</span
        >
        <div class="activity ml-12 pt-4 mt-5 mb-6" v-if="priceSecond && CardList.length >0">
          <div class="title mb-3">{{ $t("popupDiscountActivity") }}</div>
          <div class="list d-flex flex-row justify-space-between ml-4 mr-6" v-for="(item,index) in CardList" :key="index">
            <div class="name">{{item.contractName}}</div>
             <div class="choose" style="color:red" v-if="item.isUsed">{{ $t("used") }}</div>
            <div class="choose" v-if="!item.isUsed">{{ $t("unused") }}</div>
          </div>
        </div>
      </div>
    </v-card>
    <uComponents  ref="ucom"></uComponents>
  </div>
</template>
<script>
import api from "@/api";
import { orderPay,getMyBalance,quiryTx } from "../../keplr/iris/wallet";
import { init as keplrInit, getBalances} from "../../keplr/wallet";
export default {
  data: () => ({
    newOpen: true,
    fixPrice: "",
    value1: "0.075",
    value2: "",
    isBalance: false,
    isPay: false,
    isLoad:false,
	needExclusive:false,
	exclusiveName:"",
  couponOldPrice:"",
  isNotEexclusive:false,
  showMeaage:'',
  flag:true,
   CardList:[
        //   {
            
        //   name :'EOC GDAO DELTA-9 (BitCanna)',
        //   ischoose:true

        // },
        // { 
        //   name :'Beautiful young girl',
        //   ischoose:false

        // },
        //   {
            
        //   name :'EOC GDAO DELTA-9 (BitCanna)',
        //   ischoose:true

        // },
        // {
          
        //   name :'Beautiful young girl',
        //   ischoose:false

        // },
        ]
  }),
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  props: {
    Nft_sku: {
      type: Object,
    },
    assetLevel: {
      type: Number,
    },
    tokenId: {
      type: String,
    },
    onsaleOwner: {
      type: String,
    },
    priceSecond: {
      type: String,
    },
    minprice: {
      type: String,
    },
    buytype: {
      type: Number,
    },
	haveCoupon:{
		 type: Boolean,
	},
	haveExclusive:{
		 type: Boolean,
	}
    // topSku:{
    //    type: Object,
    // }
  },
  filters: {
      undef: function(value) {
          if(value != null && value != "null" && value != "underfined") {
              return "";
          }
          return value;
      }
  },
  async mounted() {
    console.log("wxl -- buyprice",this.Nft_sku,this.buytype)
      window.eventBus.$on('LangChange', this.onLangChange);
     this.showMeaage =  this.$t("popupAuctionDefectPriceInsufficientFunds") 
    
    this.fixPrice = "";
    this.isLoad = true;
    this.isPay = true
    // coinDenom
    // await getMyBalance();
   
    // let mount = localStorage.getItem("key_balance");
     // 获取iris 的余额
  
     keplrInit(1);
    this.accountBalance = await getBalances();
    console.log("keplrInit(2);",this.accountBalance)

     let aviIris = 0,aviUsdc=0,aviAtom=0;
       let mount;
        let env =  process.env.VUE_APP_IRIS_CHAIN_ID
       if(env == 'nyancat-9'){
            if(this.accountBalance.balancesList){
      for(let i=0;i<this.accountBalance.balancesList.length;i++)
      {
        if(this.accountBalance.balancesList[i].denom == "unyan"){
          aviIris= this.accountBalance.balancesList[i].amount/1000000   
          
        }else if(this.accountBalance.balancesList[i].denom == "ibc/733D9E6DE683B9726B2C6A6460566B11FAF4C075DC08861685F269537D43E24D")
        {
          aviAtom = this.accountBalance.balancesList[i].amount/1000000    
            
        }else if(this.accountBalance.balancesList[i].denom == "ibc/5C465997B4F582F602CD64E12031C6A6E18CAF1E6EDC9B5D808822DC0B5F850C")
        {
           aviUsdc = this.accountBalance.balancesList[i].amount/1000000   
           
        }
      }
    };
     
    if(this.Nft_sku.coinDenom == 'unyan'){
      mount = aviIris
    }else if(this.Nft_sku.coinDenom == 'ibc/733D9E6DE683B9726B2C6A6460566B11FAF4C075DC08861685F269537D43E24D'){
       mount = aviAtom
    }else if(this.Nft_sku.coinDenom == 'ibc/5C465997B4F582F602CD64E12031C6A6E18CAF1E6EDC9B5D808822DC0B5F850C'){
       mount = aviUsdc
    }
        }else{
            if(this.accountBalance.balancesList){
      for(let i=0;i<this.accountBalance.balancesList.length;i++)
      {
        if(this.accountBalance.balancesList[i].denom == "uiris"){
          aviIris= this.accountBalance.balancesList[i].amount/1000000   
          
        }else if(this.accountBalance.balancesList[i].denom == "ibc/27394FB092D2ECCD56123C74F36E4C1F926001CEADA9CA97EA622B25F41E5EB2")
        {
          aviAtom = this.accountBalance.balancesList[i].amount/1000000    
            
        }else if(this.accountBalance.balancesList[i].denom == "ibc/A5A5EBF3B7A180FBA2A3CE133153F5B904AA8B085691F66A15BE8156B6722652")
        {
           aviUsdc = this.accountBalance.balancesList[i].amount/1000000   
           
        }
      }
    };
     
    if(this.Nft_sku.coinDenom == 'uiris'){
      mount = aviIris
    }else if(this.Nft_sku.coinDenom == 'ibc/27394FB092D2ECCD56123C74F36E4C1F926001CEADA9CA97EA622B25F41E5EB2'){
       mount = aviAtom
    }else if(this.Nft_sku.coinDenom == 'ibc/A5A5EBF3B7A180FBA2A3CE133153F5B904AA8B085691F66A15BE8156B6722652'){
       mount = aviUsdc
    }

 }
  
   
    this.value2 = mount;
    console.log("wxl ---- this.value2",this.value2,this.Nft_sku)
    // 二手逻辑
    if(this.priceSecond)
    {
      let params={
          assetTokenId:this.tokenId,
        }
        let res = await api.home.userLog(params)
        this.CardList = res.data;

      this.fixPrice = this.priceSecond;
        let price;
        price = Number(this.priceSecond);
        if (mount >=price) {
        this.isPay = false
        this.isLoad =false
          this.isBalance = true;
        } else {
        this.isPay = false
        this.isLoad =false
          this.isBalance = false;
        }


    }
// 
    if (this.buytype == 0) {//首图购买
    console.log("wxl --- buytype",this.Nft_sku)
      if (this.Nft_sku.useType != 1 ) {
        let price;
        // if(this.Nft_sku.collectionType != 1)
        // {
        //    this.fixPrice = this.minprice;
        // }
       this.fixPrice = this.Nft_sku.onsalePrice;
       
        price = Number( this.Nft_sku.onsalePrice)
        if (mount >=price) {
           this.isPay = false
        this.isLoad =false
          this.isBalance = true;
        } else {
           this.isPay = false
        this.isLoad =false
          this.isBalance = false;
        }
debugger
		if(this.$store.state.did&&(this.Nft_sku.couponVo != null ||this.Nft_sku.exclusivesInfoList != null)){
    
      // owner: this.marketNftInfo.owner,
      //       address: this.marketNftInfo.nftAddress,
			// nftId:this.marketNftInfo.nftId,
			// address:this.$store.state.did
			api.home.getCouponInfoJson({
			  owner: this.Nft_sku.owner,
        contractAddress: this.Nft_sku.contractAddress,
			tokenId:this.Nft_sku.tokenId,
			address:this.$store.state.did
			  })
			  .then((resn) => {
			   if(resn.success&&resn.data){
				   if(resn.data.coupon){
					if(resn.data.have){
						this.haveCoupon=resn.data.have
						
          }  
					  if(this.Nft_sku.couponVo != null)
					  {
					   
					    let t = this.$t("yh_des")
					     let reg=new RegExp('【N】','g')//g代表全部
					     
					    let name = "【" + this.Nft_sku.couponVo.name + "】"
					   this.pictureName=t.replace(reg,name);
					   if(this.haveCoupon&&resn.data.usable){
						    this.fixPrice=this.Nft_sku.couponVo.deduction;
					   this.couponOldPrice=this.Nft_sku.onsalePrice+ ' '+this.getChainNameByDenom(this.Nft_sku.coinDenom);
					   
					   price = Number(this.fixPrice)
					   if (mount >= price) {
					     this.isPay = false
					     this.isLoad =false
					     this.isBalance = true;
					   }
					   
					   }else{
						   if(resn.data.usable==false){
                 this.haveCoupon=false
                  this.fixPrice = this.Nft_sku.onsalePrice;

                  // 	let t = this.$t("zm_des_bot")
							   	// 	  let reg=new RegExp('【N】','g')//g代表全部
							   	// 	  let regN=new RegExp('<br/>','g')//g代表全部
							   	// 	 let name = "【" + this.Nft_sku.exclusiveName + "】"
							   	// 	this.exclusiveName=t.replace(reg,name).replace(regN," ");
							    //  // btn显示灰色 btn上的文字显示 submit
							    //  this.isNotEexclusive = true
                  //  this.isBalance = false;
                  
							    //  this.showMeaage = this.$t("popupTransferCommit")  
						   }
					   }		   
					  }
					   
           }
				   this.haveExclusive=resn.data.hasExclusive
				   if(this.Nft_sku.exclusivesInfoList != null)
				     {
			  if(!this.haveExclusive){
							   	//需要专卖卡 但是当前没有专卖卡 
							   		let t = this.$t("zm_des_bot")
							   		  let reg=new RegExp('【N】','g')//g代表全部
							   		  let regN=new RegExp('<br/>','g')//g代表全部
							   		 let name = "【" + this.Nft_sku.exclusiveName + "】"
							   		this.exclusiveName=t.replace(reg,name).replace(regN," ");
							     // btn显示灰色 btn上的文字显示 submit
							     this.isNotEexclusive = true
							     this.isBalance = false;
							     this.showMeaage = this.$t("popupTransferCommit")  
							   	}
				   }
			   }
			  });
		}
		
      }
    } else if (this.buytype == 1) {//一手商品详情购买支付

    console.log("wxl -- 4444",this.haveCoupon,this.haveExclusive,this.Nft_sku.exclusivesInfoList)
      if (this.assetLevel == 2) {
        this.fixPrice = this.priceSecond;
      } else if (this.assetLevel == 1) {
        this.fixPrice = this.Nft_sku.onsalePrice;
      } else {
        this.fixPrice = this.minprice;
      }
      let price;
       price = Number(this.fixPrice)
      if (mount >= price) {
        this.isPay = false
        this.isLoad =false
        this.isBalance = true;
      } else {
        this.isPay = false
        this.isLoad =false
        this.isBalance = false;
      }
    
	  if(this.Nft_sku.exclusivesInfoList != null )
	  {
	    if(!this.haveExclusive){
		//需要专卖卡 但是当前没有专卖卡 
			let t = this.$t("zm_des_bot")
			  let reg=new RegExp('【N】','g')//g代表全部
			    let regN=new RegExp('<br/>','g')//g代表全部
			 let name = "【" + this.Nft_sku.exclusiveName + "】"
			this.exclusiveName=t.replace(reg,name).replace(regN," ");
      // btn显示灰色 btn上的文字显示 submit
      this.isNotEexclusive = true
      this.isBalance = false;
      this.showMeaage = this.$t("popupTransferCommit") 
		}
    }
  
	  if(this.Nft_sku.couponVo != null)
	  {
	    
	  
	    let t = this.$t("yh_des")
	     let reg=new RegExp('【N】','g')//g代表全部
	     
	    let name = "【" + this.Nft_sku.couponVo.name + "】"
	   this.pictureName=t.replace(reg,name);
	   if(this.haveCoupon){
		    this.fixPrice=this.Nft_sku.couponVo.deduction;
	   this.couponOldPrice=this.Nft_sku.onsalePrice+ ' '+ this.getChainNameByDenom(this.Nft_sku.coinDenom);
	   price = Number(this.fixPrice)
	   if (mount >= price) {
	     this.isPay = false
	     this.isLoad =false
	     this.isBalance = true;
	   }
	   
	   }
	  
	   
	  }
	  
    } else if (this.buytype == 2) {//二手商品详情购买支付
        console.log("二手购买",this.Nft_sku)
        let price;
        this.fixPrice = this.Nft_sku.buyoutPrice;
        price = Number(this.Nft_sku.buyoutPrice)
        console.log(mount);
        console.log(this.fixPrice);
        if (mount >= price) {
          console.log("true");
          this.isBalance = true;
        } else {
          console.log("false");
          this.isBalance = false;
        }
    }

    console.log("balance ------");
    console.log(mount);
  
    
  },
  methods: {

    async onLangChange(){
      this.showMeaage =  this.$t("popupAuctionDefectPriceInsufficientFunds")   
    this.fixPrice = "";
    this.isLoad = true;
    this.isPay = true
    await getMyBalance();
   
    let mount = localStorage.getItem("key_balance");
   
    this.value2 = mount;
    // 二手逻辑
    if(this.priceSecond)
    {
    
      this.fixPrice = this.priceSecond;
        let price;
        price = Number(this.priceSecond);
        if (mount >=price) {
        this.isPay = false
        this.isLoad =false
          this.isBalance = true;
        } else {
        this.isPay = false
        this.isLoad =false
          this.isBalance = false;
        }


    }
// 
    if (this.buytype == 0) {//首图购买
  
      if (this.Nft_sku.userType == 0 || this.Nft_sku.userType == null) {
        let price;
        this.fixPrice = this.minprice;
        price = Number(this.minprice)
        if (mount >= price) {
           this.isPay = false
        this.isLoad =false
          this.isBalance = true;
        } else {
           this.isPay = false
        this.isLoad =false
          this.isBalance = false;
        }
		
		if(this.$store.state.did&&(this.Nft_sku.couponVo != null ||this.Nft_sku.exclusivesInfoList != null)){
			api.home.getCouponInfoJson({
			    userAddress: this.$store.state.did,
			    denom: this.Nft_sku.address,
			  })
			  .then((resn) => {
			   if(resn.success&&resn.data){
				   if(resn.data.coupon){
					if(resn.data.have){
						this.haveCoupon=resn.data.have
						
          }  
        
					  if(this.Nft_sku.couponVo != null&&this.Nft_sku.couponVo.name != null)
					  {
					   
					    let t = this.$t("yh_des")
					     let reg=new RegExp('【N】','g')//g代表全部
					     
					    let name = "【" + this.Nft_sku.couponVo.name + "】"
					   this.pictureName=t.replace(reg,name);
					  
					   if(this.haveCoupon&&resn.data.usable){
						    this.fixPrice=this.Nft_sku.couponVo.deduction;
					   this.couponOldPrice=this.Nft_sku.onsalePrice+ ' ' +this.getChainNameByDenom(this.Nft_sku.coinDenom);
					   price = Number(this.fixPrice)
					   if (mount >= price) {
					     this.isPay = false
					     this.isLoad =false
					     this.isBalance = true;
					   }
					   
					   }else{
						   if(resn.data.usable==false){
							   this.haveCoupon=false
						   }
						   
					   }
					   
					  }
					   
				   }
           this.haveExclusive=resn.data.hasExclusive
				   if(this.Nft_sku.exclusivesInfoList != null)
				     {
				       if(!this.haveExclusive){
				   	//需要专卖卡 但是当前没有专卖卡 
				   		let t = this.$t("zm_des_bot")
				   		  let reg=new RegExp('【N】','g')//g代表全部
				   		    let regN=new RegExp('<br/>','g')//g代表全部
				   		 let name = "【" + this.Nft_sku.exclusiveName + "】"
				   		this.exclusiveName=t.replace(reg,name).replace(regN," ");
				     // btn显示灰色 btn上的文字显示 submit
				     this.isNotEexclusive = true
				     this.isBalance = false;
				     this.showMeaage = this.$t("popupTransferCommit") 
				   	}
				   }
			   }
			  });
		}
		
      }
    } else if (this.buytype == 1) {//一手商品详情购买支付
      if (this.assetLevel == 2) {
        this.fixPrice = this.priceSecond;
      } else if (this.assetLevel == 1) {
        this.fixPrice = this.Nft_sku.onsalePrice;
      } else {
        this.fixPrice = this.minprice;
      }
      let price;
       price = Number(this.fixPrice)
      if (mount >= price) {
        this.isPay = false
        this.isLoad =false
        this.isBalance = true;
      } else {
        this.isPay = false
        this.isLoad =false
        this.isBalance = false;
      }
	  if(this.Nft_sku.exclusivesInfoList != null)
	  {
	    if(!this.haveExclusive){
		//需要专卖卡 但是当前没有专卖卡 
			let t = this.$t("zm_des_bot")
			  let reg=new RegExp('【N】','g')//g代表全部
			    let regN=new RegExp('<br/>','g')//g代表全部
			 let name = "【" + this.Nft_sku.exclusiveName + "】"
			this.exclusiveName=t.replace(reg,name).replace(regN," ");
      // btn显示灰色 btn上的文字显示 submit
      this.isNotEexclusive = true
      this.isBalance = false;
      this.showMeaage = this.$t("popupTransferCommit") 
  
		}
    }
  
	  if(this.Nft_sku.couponVo != null&&this.Nft_sku.couponVo.name != null)
	  {
	    
	  
	    let t = this.$t("yh_des")
	     let reg=new RegExp('【N】','g')//g代表全部
	     
	    let name = "【" + this.Nft_sku.couponVo.name + "】"
	   this.pictureName=t.replace(reg,name);
	   if(this.haveCoupon){
		    this.fixPrice=this.Nft_sku.couponVo.deduction;
	   this.couponOldPrice=this.Nft_sku.onsalePrice+ ' '+ this.getChainNameByDenom(this.Nft_sku.coinDenom);
	   
	   price = Number(this.fixPrice)
	   if (mount >= price) {
	     this.isPay = false
	     this.isLoad =false
	     this.isBalance = true;
	   }
	   
	   }
	  
	   
	  }
	  
    } else if (this.buytype == 2) {//二手商品详情购买支付
        
        let price;
        this.fixPrice = this.Nft_sku.buyoutPrice;
        price = Number(this.Nft_sku.buyoutPrice)
        console.log(mount);
        console.log(this.fixPrice);
        if (mount >= price) {
          console.log("true");
          this.isBalance = true;
        } else {
          console.log("false");
          this.isBalance = false;
        }
    }
      
    },
    subCommit2() {
        this.$mtip({ title: "You succeed to create【The trip Rick and morty】" });
        this.$mtip({ title: "You have successfully transferred【The trip Rick and morty】" });
        this.$mtip({ title: "Your order【The trip Rick and morty】was successful, it can be viewed in 【My Collections】" });
        this.$mtip({ title: "Your ticket can be purchased by others--【The trip Rick and morty】" });
    },
    sleep(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    //提交转送数据
    async subCommit() {
      try {
        // 预下单
        debugger
        this.isPay = true
        console.log("--------");
        console.log(this.Nft_sku);
        console.log(this.assetLevel);
        console.log(this.tokenId);
        console.log(this.onsaleOwner);
        console.log(this.priceSecond);
        let info = this.Nft_sku;
        let owner = info.onsaleOwner;
        let toOwner = this.$store.state.did;
        let contractId = info.contractId;
        let name = info.name;

        let orderPrice = info.onsalePrice;
        let denom = info.contractAddress;
      
        console.log(owner);
        console.log(toOwner);
        console.log(contractId);
        console.log(orderPrice);
        let memo = "购买NFT[" + name + "]";
        let tokenIds = "";
		if(this.Nft_sku.tokenId){
			tokenIds = this.Nft_sku.tokenId;
		}
		
        if (this.assetLevel == 2) {
          tokenIds = this.tokenId;
          owner = this.Nft_sku.onsaleOwner;
        }

        let preOrderParams = {
          owner: owner,
          quantity: "1",
          level: this.assetLevel,
          chainType: "IRIS_IRISHUB",
          toOwner: toOwner,
          contractId: contractId,
          name: name,
          orderPrice: orderPrice,
          tokenIds: tokenIds,
        };
		
		
        console.log(preOrderParams);
        let preOrderRes = await api.home.preOrder(preOrderParams);
        if(preOrderRes.success == false)
        {
          console.log("wxl preOrderRes -------------")
           this.$toast("error", this.$t(preOrderRes.msg));
           this.isPay = false
           return;

        }
        let orderNo = preOrderRes.data.orderNo;
        let tokenId = preOrderRes.data.tokenId;
        console.log(preOrderRes);
        console.log(orderNo);
        if (preOrderRes.success != "" && preOrderRes.success == true) {
          orderPrice = preOrderRes.data.orderPrice;
        }

        console.log(preOrderRes);

        // get fee
        let res = await api.home.getFee();
        console.log("res ---");
         let recipient = res.data.irisMerchantAddress;
        console.log(res);
        let IrisPlatformMallFee = res.data.IrisPlatformMallFee;
        let IrisCreatorMallFee = 0;
        let IrisAddress = res.data.IrisAddress;
        if (!res.data.IrisCreatorMallFee) {
          IrisCreatorMallFee = 0;
        } else {
          IrisCreatorMallFee = res.data.IrisCreatorMallFee;
        }
        if (this.assetLevel == 2) {
          IrisPlatformMallFee = res.data.IrisPlatformMarketFee;
          IrisCreatorMallFee = res.data.IrisCreatorMarketFee;
        }
        console.log(IrisPlatformMallFee, IrisCreatorMallFee, IrisAddress);
        console.log(orderPrice);

        let hash;
        //链上付款
        if (orderPrice != "0") {
          if(tokenId == null || denom == null || owner==null)
          {
              this.$toast("error", this.$t("buyFailed"));
              return;
          }
          // this.$mtip(1);
          debugger
          let payResult = await orderPay(orderPrice, recipient, memo,this.Nft_sku.coinDenom);
          console.log("wxl --- payResult",payResult)
          
          hash = payResult.hash;
          if(hash){
             this.$mtip(1);
          }
          console.log(payResult);
        }

        // merchant
        if (orderPrice == "0" || hash != "") {
          if (orderPrice == "0") {
            hash = null;
          }
          let params = {
            callBack:
              `${process.env.VUE_APP_API_URL}` +
              "/api/3.0/orderInfo/wallet/pay.do?orderNo=" +
              orderNo,
            denom: denom,
            memo: memo,
            recipien: toOwner,
            shares: [
              { rate: IrisPlatformMallFee, received: IrisAddress },
              { rate: IrisCreatorMallFee, received: owner },
            ],
            hash: hash,
            tokenIds: [tokenId],
            coinDenom:this.Nft_sku.coinDenom
          };
		  params.deductionUseds=preOrderRes.data.deductionUseds
          let BuyNow = await api.home.BuyNow(params);
          console.log("BuyNow",params);
          console.log(BuyNow);
        }

        //payDo
        let payDoParam = {
          orderNo: orderNo,
        };
        let payDo = await api.home.payDo(payDoParam);
        console.log(payDo)
        //    setTimeout(() => {
        //    this.isPay = false
        // console.log(payDo);
        // this.closeDialog();
        //     // window.location.reload();
        //    this.$router.push({ name: 'MyOrders' });
        //   }, 2000);

      // 判断是否交易成功
       if (orderPrice != "0"){
          if (hash ) {
          let isShowTip = localStorage.getItem("IRIS_isShowTip_"+this.$store.state.did)
        if(isShowTip == 'true'){
              this.$mtip({
                title:this.$t("transactionInprogressSuccess"),
              });
          }
              
        this.isPay = false
        this.closeDialog();
            // window.location.reload();
      //this.$router.push({ name: 'MyOrders' });

            while (this.flag) {
             
              console.log("wwwwww");
            
              await this.sleep(5000).then(async () => {
                 //console.log(result.hash)
                let res = await quiryTx(hash);
                console.log("wxl -----  quiryTx");
                console.log(res);
                if (res.code == 0) {
                   	let t = this.$t("buySuccess")
					let reg=new RegExp('【N】','g')//g代表全部
					let regN=new RegExp('<br/>','g')//g代表全部
					let name = "【" + this.Nft_sku.name + "】"
          let title=t.replace(reg,name).replace(regN," ");
             let isShowTip = localStorage.getItem("IRIS_isShowTip_"+this.$store.state.did)
        if(isShowTip == 'true'){
               this.$mtip({
                title:title,
              });
          }
             
                  this.flag = false;
                } else if (res.code == -1) {
                  console.log("error");
                       let isShowTip = localStorage.getItem("IRIS_isShowTip_"+this.$store.state.did)
        if(isShowTip == 'true'){
                    this.$mtip({
                    title:res.log,
              });
          }
              
              this.flag = false;
                } else {
                  this.flag = true;
                }
              });
            }
          }
       }else{
		 
		   this.$toast("success", this.$t("success")).then(()=>{
			     this.isPay = false
		   this.closeDialog();
		   });
		
		   
	   }
        //   setTimeout(() => {
        //    this.isPay = false
        // console.log(payDo);
        // this.closeDialog();
        //     // window.location.reload();
        //    this.$router.push({ name: 'MyOrders' });
        //   }, 6000);

        
        // this.$toast("success", this.$t("buySuccess"));
        // this.$mtip(2);
        
        
      } catch (e) {
        console.log("file",e);
        this.$toast("error", this.$t("buyFailed"));
        this.isPay = false
      }
    },

    //关闭弹框，发送open值
    closeDialog() {
      this.newOpen = false;
      this.$emit("getOpen", this.newOpen);
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  min-height: 350px;
  width: 600px;

  .top {
    display: flex;
    flex-direction: column;
    margin-left: 52px;

    .title {
      margin: 42px 0 0 0;
      width: 300px;
      height: 26px;
     font-family:Helvetica;
      font-size: 25px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
    }

    .content {
      margin: 10px 0 0 0;
         // margin: 10px 0 20px 0;
      width: 470px;
      min-height: 30px;
      font-family:Helvetica;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #766983;
    }

    .form {
      .titles {
       // margin-top: 24px;
       margin-top: 2px;
		margin-bottom: 5px;
        height: 13px;
       font-family:Helvetica;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #270645;
      }

      .textinput {
        width: 500px;
        height: 75px;
        background-image: linear-gradient(#ffffff, #ffffff),
          linear-gradient(#f8f6fd, #f8f6fd);
        background-blend-mode: normal, normal;
        border-radius: 5px;
      }
	  .redN{
		  color: #cc0000;
		  font-size: 12px;
		  max-width: 467px;
	  }
    }
  }

  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }

  .footer {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 10px;

    .price {
      display: block;
      font-family:Helvetica;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      color: #766983;
	  width: 470px ;
	  margin-left: 52px;
    }
    .activity{
        text-align: center;
        width: 501px;
	min-height: 101px;
	background-color: #f8f6fd;
	border-radius: 5px;
      .title{
        width: 390px;
	min-height: 15px ;
     margin-left:15px;
	font-family:Helvetica;
	font-size: 15px!important;
	font-weight: normal;
	font-stretch: normal;
	line-height: 15px;
	letter-spacing: 0px;
	color: #270645;
  text-align: left;
      }

    }

    .sub {
      margin: 20px auto;
      width: 450px;
      height: 51px;
      background-image: linear-gradient(
          90deg,
          #d300fd 0%,
          #a439fe 26%,
          #7471ff 52%,
          #00fdcf 100%
        ),
        linear-gradient(#270645, #270645);
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
      font-size: 20px;
      color: #ffffff;
    }
    .subdefect {
      margin: 20px auto;
      width: 450px;
      height: 51px;
      background-image: linear-gradient(#766983, #766983),
        linear-gradient(#270645, #270645);
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
      font-size: 20px;
      color: #ffffff;
    }
  }

  &.mobile {
    width: 100%;

    .top {
      margin: 0 40px;

      .content {
        width: 100%;
        height: auto;
        word-wrap: break-word;
        margin-bottom: 20px;
      }
    }

    .footer {
      .sub {
        width: 90%;
      }
    }
  }
}

img {
  display: inline-block;
  width: 126px;
  height: 126px;
  background-color: #8a73ff;
  border-radius: 5px;
  margin: 1px 22px 10px 52px;
}

.sub-dis {
    position: relative;
    pointer-events: none;
    background-image: linear-gradient(
      #766983, 
      #766983), 
     linear-gradient(
      #270645, 
      #270645) !important;
     background-blend-mode: normal, 
      normal;
     border-radius: 25px;
     opacity: 0.9;
}
.sub-dis::after {
    content: "";
    background-image: url(../../assets/loading.gif);
    background-size: 100%;
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-top: 5px;
    
    // box-shadow: 0 0 10px #fff;
    // border-radius: 50%;
}
</style>




