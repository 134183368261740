<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img class="clo" @click="closeDialog" src="@/assets/btn_close.png" alt />
      <div class="top">
        <h3>{{$t('popupWalletConnect')}}</h3>
        <p>{{$t('popupWalletConnectWallet')}}</p>
      </div>
      <div class="link">
        <ul>
          <li v-if="keyChain!='IRIS'" class="fox d-flex flex-row" @click="metaMaskBtn">
            <img src="@/assets/icons/logo_metamask.png" alt />
            <span>{{$t('popupWalletMetaMask')}}</span>
          </li>
          <!-- <li class="wallet  d-flex flex-row" @click="wallentBtn">
            <img src="@/assets/icons/logo_walletconnect.png" alt />
            <span>WalletConnect</span>
          </li> -->
          <li v-else class="chain d-flex flex-row" @click="KeplrBtn">
            <img src="@/assets/icons/logo_keplr.png" alt />
            <span>{{$t('popupWalletKeplrWallet')}}</span>
          </li>
        </ul>
      </div>
        <spin >
      <a class="chormdetail"  @click="clickInstall" href="https://chrome.google.com/webstore/detail/keplr/dmkamcknogkgcdfhhbddcghachkejeap?utm_source=chrome-ntp-icon"  target="_blank">
		  {{$t('walletDownLoadNotice')}}
		  </a></spin>



    </v-card>
  </div>

</template>

<script>
import { getkeplaWeb3, getMatamaskWeb3 } from "../../utils/getAccount";
import { getMyBalance} from "../../keplr/iris/wallet";
//钱包登录弹层
export default {
  data: () => ({
    open: true,
    openWallet: false,
  }),
  props: {
     keyChain: String
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  async mounted() {
	// console.log(224,this.keyChain);
  },
  methods: {
     clickInstall(){
       this.$gtag.event('下载-keplr', { 'event_category': 'Click', 'event_label': '下载-keplr' })
    },
    //关闭弹框，发送open值
    closeDialog() {
      this.$gtag.event('登录-关闭', { 'event_category': 'Click', 'event_label': 'close' })
      this.open = false;
      this.$emit("getOpen", this.openWallet);
    },
    //进入小狐狸
    async metaMaskBtn() {
      let account = await getMatamaskWeb3();
      console.log(account)
       this.$store.commit('SET_DID', account[0])
      console.log(this.$store.state.did)
      if (account.length > 0) {
        this.$emit("getOpen", false);
        this.$emit("getStatus", true);
      } else {
        this.$emit("getStatus", false);
      }
    },

    //币安连接
    // async wallentBtn() {},
    //keplr 连接
    async KeplrBtn() {
       this.$gtag.event('登录-keplr', { 'event_category': 'Click', 'event_label': 'keplr' })
      let account = await getkeplaWeb3();
      let balance =  await getMyBalance();
      let mount = balance.format
      
      localStorage.setItem("key_balance",mount);
      console.log("xxl KeplrBtn ::: ");
      console.log(account)
      console.log(balance)

      this.$store.commit('SET_DID', account)

      console.log(this.$store.state.did)
      if (account.length > 0) {
        this.$emit("getOpen", false);
        this.$emit("getStatus", true);
        //  this.$router.push({name:"Home"})
         window.location.reload();

      } else {
        this.$emit("getStatus", false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  width: 500px;
  min-height: 407px;
  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }
  .chormdetail{
     margin: 0 52px;
    display: flex;
    flex-direction: column;
    color: #766983;
    font-size: 15px;
  }
  .top {
    margin: 0 52px;
    display: flex;
    flex-direction: column;
    h3 {
      margin: 41px 0 15px;
     font-family:Helvetica;
      font-size: 25px;
      color: #270645;
    }
    p {
      // width: 367px;
      height: 41px;
      font-family:Helvetica;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #766983;
    }
  }
  .link {
    margin: 45px 52px;
    ul {
      list-style: none;
      padding: 0;
      li {
        cursor: pointer;
        // width: 400px;
        height: 61px;
        background-image: linear-gradient(#ffffff, #ffffff),
          linear-gradient(#f8f6fd, #f8f6fd);
        background-blend-mode: normal, normal;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
        border-radius: 5px;
        display: block;
        margin-bottom: 9px;
        img {
          display: inline-block;
          margin: 10px 28px;
        }
        span {
          display: inline-block;
          //   width: 80px;
          position: relative;
          top: 3px;
          left: 0;
          height: 13px;
         font-family:Helvetica;
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 60px;
          letter-spacing: 0px;
          color: #270645;
        }
      }
      li:hover {
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.36);
      }
      .fox {
        img {
          width: 40px;
          height: 40px;
        }
      }
      .wallet {
        img {
          margin-top: 16px;
          width: 40px;
          height: 25px;
        }
        span {
          position: relative;
          top: 5px;
          left: 0;
        }
      }
      .chain {
        img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
  &.mobile {
    .top {
      h3 {
        font-size: 20px;
      }
      p {
        height: auto;
      }
    }
    .link {
      ul {
        li {
          img{
            margin:10px ;
          }
        }
      }
    }
  }
}
</style>
