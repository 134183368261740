import Encrypt from 'encryptlong'
const Base64 = require('js-base64').Base64

//公钥
var signPrivateKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCJSqtslCHtxOlEfEacw0OmYtbLAs5R+AvPCAVeF8ccPDJZtJU2UpABxg0l/10CFDVYUlI/FnVnhl8eOfDnDMATgVGGwZMI+nB7h09RynshQjOfPRPqgNUSVkMkfCdbz8JdVyC8nnZvMbbRYYpt2NB0G9XUqgSEWwBJ1MP56QNtcwIDAQAB';
var signAirDropPrivateKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCgE9U+txI/G4svtPVDIT+SOwkEGEEmVL5RysrHVS5nsksjBJ/koVZpfql4oyUUjQjn9Aaq28DvJxvIwtcME1b7DK8ETtt0SDwNHW/DquFAUXhJ+5awG6XK2kFhtAYf7CM+07rI4WGJR4AfugDBXc2ADKbGq+pYd9MtDywjGMtJzQIDAQAB';

export default {
    encrypt: function (data) {
        let encrypt = new Encrypt();
        encrypt.setPublicKey(signPrivateKey);
        console.log("xxx   --- info",JSON.stringify(data),data)
        let info = encrypt.encryptLong(JSON.stringify(data))
        // const exStr = Base64.encode(info)//加密
          const exStr = Base64.encode(info)//加密
        return exStr
    },
    encryptAirdrop: function (data) {
        
      let encrypt = new Encrypt();
      encrypt.setPublicKey(signAirDropPrivateKey);
  console.log("setPublicKey",JSON.stringify(data));
      let info = encrypt.encryptLong(JSON.stringify(data))
      const exStr = Base64.encode(info.toString())//加密
      return exStr
  },
    
}