import { render, staticRenderFns } from "./accountAppeal.vue?vue&type=template&id=16e4d361&scoped=true"
import script from "./accountAppeal.vue?vue&type=script&lang=js"
export * from "./accountAppeal.vue?vue&type=script&lang=js"
import style0 from "./accountAppeal.vue?vue&type=style&index=0&id=16e4d361&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16e4d361",
  null
  
)

export default component.exports