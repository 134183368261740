<template>
  <div>
    <CardList :list="list"  type="other"  :isShowLoading="isShowLoading" owerfrom ='gallery' />
    <div class="loadmore" v-if="!ending && !isShowLoading" @click="loadList">
      <div class="Text">{{$t("loadMore")}}</div>  
      
    
        <!-- <button class="subdefect" @click="loadList" v-if="!ending && !isShowLoading">
         加载更多...
        </button>  -->
    </div>
  </div>
</template>

<script>
import CardList from "@/components/workCard/CardList.vue";

import api from "@/api";

export default {
  name: "Gallery",
  components: { CardList },
  data: function () {
    return {
      list: [],
      pageNumber: 0,
      pageSize: 10,
      totalPage: false,
      isShowLoading: false,
      ending:false
     
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
  
    getList() {
      let params = {
        chainType: "IRIS_IRISHUB",
      };
      this.pageNumber++;
      //xxl home page
      //conole.log();
        this.isShowLoading = true;

      return api.home
        .getGalleryList({
          ...params,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        })
        .then((response) => {
          debugger
          const { list, totalPage } = response.data;
          this.pageNumber;
          this.totalPage = totalPage;
          if (this.pageNumber == 1) {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          this.isShowLoading = false;
          if(  this.totalPage == this.pageNumber){
             this.ending = true
          }
       
        });
    },
     async loadList(){
       if(  this.totalPage > this.pageNumber){
         this.$gtag.event('NFT浏览-加载更多', { 'event_category': 'Click', 'event_label': 'NFT' })
              await this.getList();
       }

    },
    async onScroll(e) {
      const {
        scrollTop,
        clientHeight,
        scrollHeight,
      } = e.target.scrollingElement;
      if (
        scrollTop + clientHeight === scrollHeight &&
        this.totalPage > this.pageNumber
      ) {
        await this.getList();
      } 
   
    },
  },
};
</script>

<style lang="scss" scoped>
.loadmore{
  width: 100%;
  text-align: center;
  font-size: 14px;
  margin-bottom:40px;
 
  cursor: pointer;
  .Text{
    font-family: Helvetica;
  color: #766983;
    
  }
  .Text::after{
     content: "";
    background-image: url(../../assets/icons/arrows.png);
    background-size: 100%;
    display: inline-block;
    position: absolute;
    width: 12px;
    height: 12px;
   margin-left: 10px;
  margin-top: 5px;
  }
  //  .subdefect {
  //     margin: 10px auto;
  //     width: 100px;
  //     height: 20px;
  //     background-image: linear-gradient(#766983, #766983),
  //       linear-gradient(#270645, #270645);
  //     background-blend-mode: normal, normal;
  //     border-radius: 25px;
  //     opacity: 0.9;
  //     font-size: 10px;
  //     color: #ffffff;
  //   }
}
</style>
