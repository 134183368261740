var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{mobile: _vm.isMobile},staticStyle:{"height":"130px"}},[_c('div',{staticClass:"d-flex flex-row justify-space-between",staticStyle:{"height":"25px"}},[_c('v-card-title',{staticClass:"d-block text--title",staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.value.name ? _vm.value.name : "No Name")+" ")]),_c('img',{staticStyle:{"height":"20px","width":"20px","margin-top":"14px","margin-right":"5px","display":"none"},attrs:{"src":require("@/assets/icons/officially_verified.png"),"alt":""},on:{"mouseenter":function($event){return _vm.mousedContract()},"mouseleave":function($event){return _vm.leaveContract()}}})],1),(_vm.owerfrom !='firspublish' && _vm.owerfrom !='onsale' && _vm.owerfrom !='offsale')?_c('v-card-text',{staticClass:"d-flex flex-row align-center pb-0 pl-3"},[_c('Avatar',{attrs:{"size":"40","did":_vm.value.nftOwner,"hash":_vm.value.profilePhoto,"imgType":"middlev","grade":_vm.value.grade,"showAvatarInfo":""}}),_c('div',{staticClass:"ml-2 text-truncate",staticStyle:{"max-width":"100%"}},[_vm._v(_vm._s(_vm.value.userName?_vm.value.userName:_vm.value.nftOwner.substr(0,6)+'...'+_vm.value.nftOwner.substr(-6)))])],1):_vm._e(),(_vm.value.useType == '1' && (_vm.owerfrom =='firspublish' || _vm.owerfrom =='onsale')&& _vm.value.amount!=0)?_c('v-card-text',{staticClass:"d-flex flex-row justify-space-between mt-12",staticStyle:{"min-height":"70px"}},[(_vm.value.auctionVo.auctionStatus == '0')?[_c('div',[_c('div',{staticClass:"text--bid-title text--secondary-rd",staticStyle:{"margin-bottom":"-2px"}},[_vm._v(" "+_vm._s(_vm.$t("popupAuctionStartingBid"))+" ")]),_c('div',{staticClass:"d-flex flex-row mt-1"},[_c('div',[(_vm.value.onsalePrice)?_c('v-img',{attrs:{"src":require('@/assets/icons/' +
      _vm.getChainByName(_vm.value.auctionVo.coinDenom)+'.png'),"alt":"logo","width":"23px","height":"23px"}}):_vm._e()],1),_c('div',{staticClass:"ml-2 text--price text--primary-rd font-weight-medium",staticStyle:{"line-height":"22px"}},[_vm._v(" "+_vm._s(_vm.value.auctionVo.lastBidPrice)+" ")])])]),_c('div',[_c('div',{staticClass:"text--sub-title text--secondary-rd",staticStyle:{"margin-bottom":"-2px"}},[_vm._v(" "+_vm._s(_vm.$t("workCardStartingin"))+" ")]),_c('div',{staticClass:"mt-1"},[_c('div',{staticClass:"text--number text--primary-rd font-weight-medium",staticStyle:{"margin-bottom":"-2px"}},[_vm._v(" "+_vm._s(_vm.hour)+"h "+_vm._s(_vm.minutes)+"m "+_vm._s(_vm.second)+"s ")])])])]:(_vm.value.auctionVo && _vm.value.auctionVo.auctionStatus == '5')?[_c('div',{staticClass:"d-flex justify-center align-center pt-1 text--info text--secondary-rd"},[_vm._v(" "+_vm._s(_vm.$t("token_state_end"))+" ")])]:(_vm.value.auctionVo.auctionStatus == '1')?[_c('div',[_c('div',{staticClass:"text--bid-title text--secondary-rd",staticStyle:{"margin-bottom":"-2px"}},[_vm._v(" "+_vm._s(_vm.$t("workCardCurrentBid"))+" ")]),_c('div',{staticClass:"d-flex flex-row mt-1"},[_c('div',[(_vm.value.onsalePrice)?_c('v-img',{attrs:{"src":require('@/assets/icons/' +
                  _vm.getChainByName(_vm.value.auctionVo.coinDenom)+
                  '.png'),"alt":"logo","width":"23px","height":"23px"}}):_vm._e()],1),_c('div',{staticClass:"ml-2 text--price text--primary-rd font-weight-medium",staticStyle:{"line-height":"22px"}},[_vm._v(" "+_vm._s(_vm.value.auctionVo.lastBidPrice)+" ")])])]),_c('div',[_c('div',{staticClass:"text--sub-title text--secondary-rd",staticStyle:{"margin-bottom":"-2px"}},[_vm._v(" "+_vm._s(_vm.$t("workCardEndingin"))+" ")]),_c('div',{staticClass:"mt-1"},[_c('div',{staticClass:"text--number text--primary-rd font-weight-medium"},[_vm._v(" "+_vm._s(_vm.hour)+"h "+_vm._s(_vm.minutes)+"m "+_vm._s(_vm.second)+"s ")])])])]:_vm._e()],2):_vm._e(),(_vm.value.useType == '1' && (_vm.owerfrom !='firspublish'&&_vm.owerfrom !='onsale')&& _vm.value.amount!=0)?_c('v-card-text',{staticClass:"d-flex flex-row justify-space-between mt-n2",staticStyle:{"min-height":"70px"}},[(_vm.value.auctionVo.auctionStatus == '0')?[_c('div',[_c('div',{staticClass:"text--bid-title text--secondary-rd",staticStyle:{"margin-bottom":"-2px"}},[_vm._v(" "+_vm._s(_vm.$t("popupAuctionStartingBid"))+" ")]),_c('div',{staticClass:"d-flex flex-row mt-1"},[_c('div',[(_vm.value.onsalePrice)?_c('v-img',{attrs:{"src":require('@/assets/icons/' +
                  _vm.getChainByName(_vm.value.auctionVo.coinDenom)+
                  '.png'),"alt":"logo","width":"23px","height":"23px"}}):_vm._e()],1),_c('div',{staticClass:"ml-2 text--price text--primary-rd font-weight-medium",staticStyle:{"line-height":"22px"}},[_vm._v(" "+_vm._s(_vm.value.auctionVo.lastBidPrice)+" ")])])]),_c('div',[_c('div',{staticClass:"text--sub-title text--secondary-rd",staticStyle:{"margin-bottom":"-2px"}},[_vm._v(" "+_vm._s(_vm.$t("workCardStartingin"))+" ")]),_c('div',{staticClass:"mt-1"},[_c('div',{staticClass:"text--number text--primary-rd font-weight-medium",staticStyle:{"margin-bottom":"-2px"}},[_vm._v(" "+_vm._s(_vm.hour)+"h "+_vm._s(_vm.minutes)+"m "+_vm._s(_vm.second)+"s ")])])])]:(_vm.value.auctionVo && _vm.value.auctionVo.auctionStatus == '5')?[_c('div',{staticClass:"d-flex justify-center align-center pt-1 text--info text--secondary-rd",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.$t("token_state_end"))+" ")])]:(_vm.value.auctionVo.auctionStatus == '1')?[_c('div',[_c('div',{staticClass:"text--bid-title text--secondary-rd",staticStyle:{"margin-bottom":"-2px"}},[_vm._v(" "+_vm._s(_vm.$t("workCardCurrentBid"))+" ")]),_c('div',{staticClass:"d-flex flex-row mt-1"},[_c('div',[(_vm.value.onsalePrice)?_c('v-img',{attrs:{"src":require('@/assets/icons/' +
                  _vm.getChainByName(_vm.value.auctionVo.coinDenom)+
                  '.png'),"alt":"logo","width":"23px","height":"23px"}}):_vm._e()],1),_c('div',{staticClass:"ml-2 text--price text--primary-rd font-weight-medium",staticStyle:{"line-height":"22px"}},[_vm._v(" "+_vm._s(_vm.value.onsalePrice)+" ")])])]),_c('div',[_c('div',{staticClass:"text--sub-title text--secondary-rd",staticStyle:{"margin-bottom":"-2px"}},[_vm._v(" "+_vm._s(_vm.$t("workCardEndingin"))+" ")]),_c('div',{staticClass:"mt-1"},[_c('div',{staticClass:"text--number text--primary-rd font-weight-medium"},[_vm._v(" "+_vm._s(_vm.hour)+"h "+_vm._s(_vm.minutes)+"m "+_vm._s(_vm.second)+"s ")])])])]:_vm._e()],2):_vm._e(),(((_vm.value.useType == '0' || _vm.value.useType == '3' || _vm.value.useType == '4' ) || _vm.value.useType == null || _vm.value.useType == '5')&& (_vm.owerfrom =='firspublish' || _vm.owerfrom =='otherpage' || _vm.owerfrom =='offsale' || _vm.owerfrom =='onsale' || _vm.owerfrom =='gallery' || _vm.owerfrom == 'resale' || _vm.owerfrom == 'detaillink' ) && _vm.value.amount!=0 && _vm.selectType !=  '3')?_c('v-card-text',{staticClass:"d-flex flex-row mt-14 text-number",class:{ gallery: _vm.owerfrom =='otherpage' || _vm.owerfrom =='gallery' || _vm.owerfrom == 'resale' || _vm.owerfrom == 'detaillink' }},[_c('div',[(_vm.value.onsalePrice)?_c('v-img',{attrs:{"src":require('@/assets/icons/' +
  _vm.getChainByName(_vm.value.coinDenom)+
                  '.png'),"alt":"logo","width":"23px","height":"23px"}}):_vm._e()],1),(_vm.value.onsalePrice)?_c('div',{staticClass:"ml-2 text--price text--primary-rd font-weight-medium",staticStyle:{"line-height":"22px"}},[_vm._v(" "+_vm._s(_vm.value.onsalePrice)+" ")]):_vm._e(),(_vm.value.saleStatus ==0)?_c('div',{staticStyle:{"width":"100%","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.$t("NotListedYet")))]):_vm._e()]):_vm._e(),(((_vm.value.useType == '0' || _vm.value.useType == '3' || _vm.value.useType == '4' ) || _vm.value.useType == null  || _vm.value.useType == '5')&& (_vm.owerfrom !='firspublish'&&_vm.owerfrom !='otherpage') && _vm.selectType ==  '3' )?_c('v-card-text',{staticClass:"d-flex flex-row mt-1 text-number"},[(_vm.selectType ==  '3')?_c('div',{staticClass:"lastsale"},[_vm._v("Last sale")]):_vm._e(),_c('div',[(_vm.value.onsalePrice)?_c('v-img',{attrs:{"src":require('@/assets/icons/' +
  _vm.getChainByName(_vm.value.coinDenom)+
                  '.png'),"alt":"logo","width":"23px","height":"23px"}}):_vm._e()],1),_c('div',{staticClass:"ml-2 text--price text--primary-rd font-weight-medium",staticStyle:{"line-height":"22px"}},[_vm._v(" "+_vm._s(_vm.value.onsalePrice)+" ")])]):_vm._e(),(_vm.value.amount == 0  && _vm.selectType !=  '3')?_c('v-card-text',{staticClass:"d-flex flex-row mt-1 text-number"},[_c('div',[(_vm.value.assetExtensionVo.issuerPrice)?_c('v-img',{staticClass:"mt-13",class:{ gallery: _vm.owerfrom =='otherpage'  },attrs:{"src":require('@/assets/icons/' +
  _vm.getChainByName(_vm.value.assetExtensionVo.issuerTokenType)+
                  '.png'),"alt":"logo","width":"23px","height":"23px"}}):_vm._e()],1),_c('div',{staticClass:"ml-2 mt-13 text--price text--primary-rd font-weight-medium",class:{ gallery: _vm.owerfrom =='otherpage'  },staticStyle:{"line-height":"22px"}},[_vm._v(" "+_vm._s(_vm.value.assetExtensionVo.issuerPrice)+" ")]),_c('img',{staticClass:"sold_out",class:{sold_out_mobile:_vm.isMobile},attrs:{"src":require("@/assets/icons/icon_soldout.png"),"width":"95px","height":"36px","alt":""}})]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }