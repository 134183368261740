<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        src="@/assets/btn_close.png"
        alt=""
      />
      <div class="top">
         {{$t("hasReservePriceSuccess")}}
      </div>
      <div class="footer">
        <button class="sub" :class="{'sub-dis': isPay}" submit @click="subCommit"> {{
          $t("popupTransferCommit")
        }} </button>
      </div>
    </v-card>
     <PromptBox ref="promptBox"></PromptBox>
     <uComponents  ref="ucom"></uComponents>
  </div>
</template>
<script>
  import api from "@/api";
  import { orderPay,quiryTx } from "../../keplr/iris/wallet";
  import PromptBox from "@/components/PromptBox.vue";
  export default {
  components: {  PromptBox },
  data: () => ({
    open: false,
    isPay: false,
    flag:true
  }),
  props: {
     auctionNo: {
      type: String,
    },
      name: {
      type: String,
    },
        value:{
      type:Object,
    }
    
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  mounted(){
    console.log("987654321 -------- w33")
    console.log(this.auctionNo,this.value)
  },
  methods: {
     sleep(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    //提交转送数据
     async subCommit() {
       try {
          this.isPay = true
         let res = await api.home.getFee();
        let auctionNo = this.auctionNo;
      let recipient = res.data.irisMerchantAuctionAddress;
        let orderPrice = 0.02;
        
        let memo = {
          type: "AUCTION_FINALIZE", //类型
          param: {
            auctionNo: auctionNo, //竞拍号
          },
        };
        
        //链上付款
        console.log("wxl ------ 222222");
        console.log(orderPrice, recipient, memo);
        
        let payResult = await orderPay(
          orderPrice,
          recipient,
          JSON.stringify(memo)
        );
        if(payResult.hash)
        {
            let isShowTip = localStorage.getItem("IRIS_isShowTip_"+this.$store.state.did)
        if(isShowTip == 'true'){
             this.$mtip({
                title:this.$t("transactionInprogressSuccess"),
              });
        }
             
              this.$emit("getOpen", false)
              this.isPay = false;
              this.$router.push({ name: "PersonalPage" });
               while (this.flag) {
              await this.sleep(5000).then(async () => {
                 //console.log(result.hash)
                let res = await quiryTx(payResult.hash);
                console.log("wxl -----  quiryTx");
                console.log(res);
                if (res.code == 0) {
        	let t = this.$t("bid_Accepted")
					let reg=new RegExp('【N】','g')//g代表全部
          let regN=new RegExp('<br/>','g')//g代表全部
          let regP=new RegExp('【P】','g')//g代表全部
          let name = "【" + this.name + "】"
          let accepPrice = this.value.lastBidPrice + this.getChainNameByDenom(this.value.coinDenom)
          let title=t.replace(reg,name).replace(regP,accepPrice).replace(regN," ");
                let isShowTip = localStorage.getItem("IRIS_isShowTip_"+this.$store.state.did)
        if(isShowTip == 'true'){
              this.$mtip({
                title:title,
              });
        }
              
                  this.flag = false;
                } else if (res.code == -1) {
                  console.log("error");
                          let isShowTip = localStorage.getItem("IRIS_isShowTip_"+this.$store.state.did)
                if(isShowTip == 'true'){
                        this.$mtip({
                    title:res.log,
              });
        }
             
              this.flag = false;
                } else {
                  this.flag = true;
                }
              });
            }
        
        }
        console.log(payResult);
         
       } catch (e) {
         this.$refs.promptBox.show(this.$t('transactionInprogressError'));
          this.isPay = false
         
       }
      
    },
    //关闭弹框，发送open值
    closeDialog() {
      this.$emit("getOpen", this.open);
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  min-height: 259px;
  width: 600px;
  .top {
    display: flex;
    flex-direction: column;
    margin-top: 66px;
    margin-left:50px;
	margin-right: 50px;
    margin-bottom:35px;
   font-family:Helvetica;
	font-size: 20px;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 0px;
	color: #270645;
  }
  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }
  .footer {
    margin: 0 auto;
    .sub {
      margin: 30px auto;
      width: 450px;
      height: 51px;
      background-image: linear-gradient(
          90deg,
          #d300fd 0%,
          #a439fe 26%,
          #7471ff 52%,
          #00fdcf 100%
        ),
        linear-gradient(#270645, #270645);
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
     font-family:Helvetica;
      font-weight: bold;
      font-size: 20px;
      color: #ffffff;
    }
  }
  &.mobile {
    .top {
      width: 90%;
      margin: 50px auto;
    }
    .footer {
      width: 90%;
     
      .sub {
        width: 80%;
         margin: 0 10%;
      }
    }
  }
}

img {
  display: inline-block;
  width: 126px;
  height: 126px;
  background-color: #8a73ff;
  border-radius: 5px;
  margin: 1px 22px 10px 52px;
}
.sub-dis {
    position: relative;
    pointer-events: none;
    background-image: linear-gradient(
      #766983, 
      #766983), 
     linear-gradient(
      #270645, 
      #270645) !important;
     background-blend-mode: normal, 
      normal;
     border-radius: 25px;
     opacity: 0.9;
}
.sub-dis::after {
    content: "";
    background-image: url(../../assets/loading.gif);
    background-size: 100%;
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 20px;
    margin-left: 50px;
    margin-top: 5px;
    
    // box-shadow: 0 0 10px #fff;
    // border-radius: 50%;
}
</style>




