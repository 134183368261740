<template>
  <v-menu
    content-class="download"
    :close-on-content-click="true"
    open-on-hover
    left
    offset-y
    z-index="999"
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
        class="d-flex justify-center align-center ml-2"
        style="padding: 10px 0"
      >
        <img class="icon" src="@/assets/image/download.png" />
      </div>
    </template>
    <div class="content">
      <div class="item">
        <img src="@/assets/image/qr-code.jpeg" />
        <div class="scan">Scan to Download App IOS & Android</div>
        <button class="btn" @click="todownload">More Download Options</button>
        <!-- <a href="https://www.uptickproject.com/downloadapp.html" target="blank"
          >iOS Testflight \ Android Google Play</a
        > -->
        <!-- <div class="describe">
          {{$t("download_desc1")}}
        </div> -->
      </div>
     
    </div>
  </v-menu>
</template>

<script lang="js">

export default {
  name: 'DownloadPortal',
  props: {},
  data: function() {
    return {};
  },
  methods:{
	  todownload(){
	  		  this.$router.push({ name: "Download" });
	  }
  }
};
</script>

<style lang="scss" scoped>
.icon {
  width: 18px;
  height: auto;
}
.download {
  z-index: 999;
  width: 218px;
  height: 280px;
  max-width: unset;
  overflow: hidden;
  contain: content;
  background-image: linear-gradient(#ffffff, #ffffff),
    linear-gradient(#6f58d9, #6f58d9);
  background-blend-mode: normal, normal;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
  border-radius: 5px;
  .content {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    height: 100%;
  }
  .item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 33px;
    width: 230px;
    margin: 20px 34px;
    img {
      width: 150px;
      height: 150px;
    }
    .btn{
      font-family: Helvetica;
      font-weight: bold;
	font-size: 11px;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 0px;
	color: #ffffff;
      width: 150px;
	height: 35px;
	background-image: linear-gradient(90deg, 
		#d300fd 0%, 
		#a439fe 26%, 
		#7471ff 52%, 
		#00fdcf 100%), 
	linear-gradient(
		#270645, 
		#270645);
	background-blend-mode: normal, 
		normal;
	box-shadow: 0px 0px 9px 0px 
		rgba(0, 0, 0, 0.09);
	border-radius: 5px;
    }
    .scan{
      text-align: center;
      width: 122px;
	font-family: Helvetica;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 12px;
	letter-spacing: 0px;
	color: #270645;
margin: 10px;
    }
    a {
      display: inline-block;
      margin-top: 15px;
    
	  font-family:Helvetica;
      text-decoration: underline;
      font-size: 11px;
      line-height: 13px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #8d52ff;
    }
    .describe {
      margin-top: 21px;
      font-family:Helvetica;
      font-size: 10px;
      white-space: 0;
      font-weight: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: 0px;
      color: #270645;
    }
  }
}
</style>
