<template>
  <div class="top" >
    <v-expand-transition>
      <v-main tag="div" class="drawer-nav" :elevation="3" v-if="value" absolute>
        <!-- <template v-if="navOpen">
               <v-container
                   class="d-flex flex-column py-0 pb-12 pb-lg-0"
                   style="height: 100%"
               >
                  <UserCenterNav @change="$emit('input', false); navOpen = false"/>
               </v-container>
            </template> -->
        <template v-if="navOpen">
          <v-container
            class="d-flex flex-column py-0 pb-12 pb-lg-0"
            style="height: 100%"
          >
            <DropSelectNav @input="close" />
            <!-- <v-spacer></v-spacer>
                <v-btn class="rd" rounded depressed>Create</v-btn>
                <v-btn
                  class="mt-10"
                  v-if="!user"
                  rounded
                  dark
                  depressed
                  @click="connectWallet"
                  >Connect Wallet</v-btn
                > -->
          </v-container>
        </template>
      </v-main>
    </v-expand-transition>
    <v-app-bar
      app
      absolute
      hide-on-scroll
      :scroll-target="`#${3}`"
      :fixed="value"
      class="pa-0"
      color="#fff"
      :elevation="0"
      :height="height"
    >
      <v-container style=" max-width: 1380px;">
        <div class="d-flex align-center py-0 px-lg-0">
          <Logo
            height="43px"
            :simple="isMobile"
            style="margin-right: 15px"
            :class="{ mobileLogo: isMobile }"
          />
          <!--            <ChainSelect class="ml-lg-7" :class="{mobileChain:isMobile}"/>-->
          <LangSelect
            class="ml-lg-2 langselect"
            style="z-index: 202"
            :class="{ mobileLang: isMobile }"
          />
            <MarketSelect class="ml-lg-2 langselect" style="z-index: 202;" :class="{mobileLang:isMobile}"/>
          <Pool class="ml-lg-2" :class="{ mobileLang: isMobile }" v-if="isShowPool == 'true'" />
          <!-- <Contest class="ml-lg-2" :class="{mobileLang:isMobile}"/> -->
          <v-spacer></v-spacer>
          <template v-if="isMobile">
            <div v-if="user" class="mr-7">
              <v-btn
                icon
                class="primary"
                v-if="userCenterOpen"
                @click="close()"
              >
                <v-icon color="#fff">mdi-close</v-icon>
              </v-btn>
              <!-- bug 576 隐藏头像 -->
              <!-- <v-avatar v-else @click="open()">
                     <v-img
                         src="https://cdn.vuetifyjs.com/images/john.jpg"
                         alt="Midoriya"
                     />
                  </v-avatar> -->
            </div>
            <template>
              <v-btn icon class="primary" v-if="navOpen" @click="close()">
                <v-icon color="#fff">mdi-close</v-icon>
              </v-btn>
              <v-btn icon class="primary" v-else @click="open()">
                <v-icon color="#fff">mdi-menu</v-icon>
              </v-btn>
            </template>
          </template>
          <template v-else>
            <Nav />

            <div class="btn" style="position: relative">
              <v-btn
                class="rd creatbtn "
                v-if="showCreate"
                @mouseenter="clicked()"
                rounded
                depressed
                >{{ $t("headerBtnCreate") }}</v-btn
              >
            </div>
            <div class="contant" v-if="isShowContent">
              <div class="d-flex justify-space-around mt-5">
                <div class="nft d-flex flex-row" style="cursor:pointer;" @click="nftCreate">
                  <img
                    src="@/assets/icons/icon_cj_nft.png"
                    class="img mt-2"
                    style="width: 22px; height: 28px"
                  />
                  <div class="ml-4">
                     <div class="title">{{ $t("create_nft") }}</div>
                  <br />
                  <div class="explain mt-n7">{{ $t("create_nft_explain") }}</div>
                  </div>
               
                </div>
                  <div class="collection d-flex flex-row" style="cursor:pointer;" @click="collectionCreate">
                   
                  <img
                    src="@/assets/icons/icon_cj_collection.png"
                    class="img mt-2"
                    style="width: 25px; height: 25px"
                  />
                  <div class="ml-4">
                     <div class="title">{{ $t("create_collection") }}</div>
                  <br />
                  <div class="explain mt-n7">{{ $t("create_collection_explain") }}</div>
                  </div>
               
                </div>
                
              </div>
            </div>

            <UserCenterSelect class="ml-4" v-if="user" />
            <v-btn
              v-else
              class="ml-4"
              rounded
              depressed
              style="background-color: #270645; color: #ffffff"
              @click="connectWallet"
              >{{ $t("headerBtnConnectWallet") }}
            </v-btn>
            <DownloadPortal />
          </template>
        </div>
        <!-- <SearchInput v-if="isMobile" class="mt-9" /> -->
      </v-container>
    </v-app-bar>
    <v-dialog v-model="openWallet">
      <wallet :keyChain="keyChain" @getOpen="Open" @getStatus="Status"></wallet>
    </v-dialog>
         <v-dialog v-model="openCreateCollection" v-if="openCreateCollection">
         <createCollection  @getOpen="OpenCreateCollection" ></createCollection>
      </v-dialog>
    <v-snackbar
      v-model="isShow"
      color="error"
      centered
      timeout="2000"
      style="opacity: 0.8"
      ><span style="color: white; font-size: 20px">{{
        title
      }}</span></v-snackbar
    >
  </div>
</template>

<script lang="js">
   import Logo from '@/components/logo/index.vue';
   import Nav from './components/Nav.vue';
   import Pool from './components/pool.vue';
   // import Contest from './components/contest.vue';
   // import ChainSelect from './components/ChainSelect.vue';
   import LangSelect from './components/LangSelect.vue';
   import UserCenterSelect from './components/UserCenterSelect.vue';
   import DropSelectNav from './components/DropSelectNav.vue';
   import Wallet from "../components/popup/wallet";
   import DownloadPortal from './components/DownloadPortal.vue';
   import api from "@/api";
   import {keplrKeystoreChange} from "../utils/getAccount.js";
   import CreateCollection from "@/components/popup/createCollection";
  import MarketSelect from './components/marketSelect.vue';



   export default {
      name: 'Header',
      components: {Wallet, Logo, Nav,  LangSelect, UserCenterSelect, DropSelectNav,Pool, DownloadPortal,CreateCollection,MarketSelect },
      // components: {Wallet, Logo, Nav, ChainSelect, LangSelect, UserCenterSelect, DropSelectNav Contest, },
      props: {
         value: Boolean,
         containerId: String
      },
      data: function () {
         return {
            openWallet: false,
            navOpen: false,
            userCenterOpen: false,
            isShow:false,
            title:'',
            isShowPool:'false',
            user: null,
			keyChain: "IRIS",
         showCreate: false,
         isShowContent:false,
         openCreateCollection:false
         };
      },
      computed: {
         isMobile: function () {
            return this.$vuetify.breakpoint.mobile;
         },
         height: function () {
            if ((  this.$route.name === 'Marketplace' || this.$route.name === 'LaunchPad') && this.isMobile) {
               return 180
            }
            return  100;
         },
      },
	  async mounted() {
//        let showTip = localStorage.getItem("IRIS_isShowTip_"+this.$store.state.did)
// if(!showTip){
//   localStorage.setItem("IRIS_isShowTip_"+this.$store.state.did,"true")
// }
           this.showPool();
            window.eventBus.$on("isWallet", this.walletShow);
          window.eventBus.$on('ShowLogoBox', this.toCreateBtn);
           window.addEventListener("click", this.clickOther);
          
        let json = window.localStorage.getItem("key_user");

        //xxl fix the bug 343
    

        if(json != null && JSON.stringify(json.length) != 0)
        {
            // 页面新开重新绑定 keplr_keystorechange
            if(performance.navigation.type == 0) {
                keplrKeystoreChange();
            }
            window.addEventListener("keplr_keystorechange", keplrKeystoreChange);
            //keplrKeystoreChange();
            this.showCreate = true;

            let data = JSON.parse(json);
            // console.log(77,data);
            this.user=data.user;
            this.$store.commit('SET_DID', data.did);
         let showTip = localStorage.getItem("IRIS_isShowTip_"+data.did)
         if(!showTip){
         localStorage.setItem("IRIS_isShowTip_"+data.did,"true")
         }
        } else {
            this.showCreate = false;
        }
        this.setShowCreate();

      let res2 = await api.home.getMarket();
      let marketPrice = {
        IrisPrice:res2.data.IRIS.quote.USD.price,
        AtomPrice:res2.data.ATOM.quote.USD.price
      }
      localStorage.setItem("KEY_USD_PRICE",JSON.stringify(marketPrice))
      
         
   

     },
     beforeDestroy() {  
    window.removeEventListener('click', this.clickOther);
},
      methods: {
         connectWallet: function () {
			 let key = localStorage.getItem("KEY_CHAIN");
             if(key) this.keyChain = key;

            this.openWallet = true
            this.user = false
         },
         clickOther: function () {
             this.isShowContent = false
         },
         open: function () {
            console.log(1)
            this.navOpen = true;
            this.$emit('input', true);
         },
         close: function () {
            this.navOpen = false;
            this.$emit('input', false);
         },
         //弹框关闭后获取open值
         Open(e) {
            this.openWallet = e;
         },
         Status(s){
            this.user=s;
            this.setShowCreate();
			window.localStorage.setItem("key_user", JSON.stringify({user:this.user,did:this.$store.state.did}));
         },
        toCreateBtn(){
           this.$gtag.event('创建资产', { 'event_category': 'Click', 'event_label': 'create' })
           if(this.$store.state.did){
             this.$router.push({name:'Create'})
           }else{
             let key = localStorage.getItem("KEY_CHAIN");
             if(key) this.keyChain = key;

            this.openWallet = true
            this.user = false

           }

        },
        clicked(){
           this.isShowContent = true
        },
        leave(){
      
            this.isShowContent = false
        },
           OpenCreateCollection(e){
             console.log("wxl -- wewewew",e)
             debugger
         this.openCreateCollection = e
    },
        collectionCreate(){
            this.$gtag.event('创建-Collection', { 'event_category': 'Click', 'event_label': '创建-Collection' })
           this.isShowContent = false
            if(this.$store.state.did){
            this.openCreateCollection = true
           }else{
             let key = localStorage.getItem("KEY_CHAIN");
             if(key) this.keyChain = key;

            this.openWallet = true
            this.user = false

           }
        },
        nftCreate(){
           this.$gtag.event('创建-NFT', { 'event_category': 'Click', 'event_label': '创建-NFT' })
           this.isShowContent = false
            if(this.$store.state.did){
             this.$router.push({name:'Create'})
           }else{
             let key = localStorage.getItem("KEY_CHAIN");
             if(key) this.keyChain = key;

            this.openWallet = true
            this.user = false

           }
        },
         walletShow(){
			this.openWallet = true;
		  },
         async showPool(){
            let res = await api.home.irisLuckEnable();
            if(res.success)
            this.isShowPool = res.data.enable
         },
        async setShowCreate() {
            if(this.$store.state.did) {
                let param = { did: this.$store.state.did };
                let myInfo = await api.home.getMyInfo(param);
                if(!this.showCreate)
				 window.eventBus.$emit("isLoading");
                if(!myInfo.data) {
                    this.showCreate = true;
                } else {
                   localStorage.setItem("key_user_grade", JSON.stringify({  grade: myInfo.data.grade}));
                    let grade = myInfo.data.grade;
                    if(grade == 2 || grade == 4) {
                        this.showCreate = false;
                    } else {
                        this.showCreate = true;
                    }
                }
            }
            
        }
      }
   };
</script>

<style lang="scss" scoped>
.langselect{
        &::v-deep .v-chip {
    box-shadow: none;
    border: none;
    margin-bottom: 2px;
    font-weight: bold;
  }
   }
.top {
  letter-spacing: 0px;

  .drawer-nav {
    height: 100vh;
  }
}

.creatbtn {
  font-family: "\5FAE\8F6F\96C5\9ED1";
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  line-height: 100px;
  letter-spacing: 0px;
  color: #ffffff;
}

.mobileLogo {
  margin-left: 10px;
}

.mobileChain {
  // border: solid 1px red;
  margin-left: -12px;
  margin-right: 3px;
}
.contant {
  width: 417px;
  height: 90px;
  background-image: linear-gradient(#ffffff, #ffffff),
    linear-gradient(#6f58d9, #6f58d9);
  background-blend-mode: normal, normal;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
  border-radius: 5px;
  position: absolute;
  top: 77px;
  right: 148px;
  .title{
  min-width: 63px;
	font-family:Helvetica;
	font-size: 15px !important;
	font-weight: bold;
	font-stretch: normal;

	letter-spacing: 0px;
	color: #270645;
  }
  .explain{
  min-width: 120px;
	font-family:Helvetica;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 0px;
	color: #766983;
  }
}
</style>
