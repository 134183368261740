<template>
  <div :class="{ home: true, mobile: isMobile }">
    <div class="home-work d-flex align-center">
      <Work
        style="cursor: pointer"
        contain
        :aspectRatio="isMobile ? null : -1"
        :hash="sku.imgUrl"
         :value='sku'
        :hashVideo="sku.fileUrl"
         sourcetop=true
          :videoPic='videoPic'
        @imgClick="workClick"
      />
      <v-icon v-if="sku.denomType == 3" class="icon" size="30" color="#fff"
        >mdi-video
      </v-icon>
    </div>
    <div class="d-flex flex-column product">
      <div class="d-flex align-center mt-9 mt-lg-11"
          :style="isMobile ? 'margin-top:13px !important' : ''">
        <Avatar
          :did="sku.nftOwner"
          :size="isMobile ? 42 : 40"
          :hash="sku.profilePhoto"
          imgType="middlev"
		  :grade="sku.grade"
          showAvatarInfo
        />
        <div class="ml-7 ml-lg-4 fs-15 name"
            :style="isMobile ? 'margin-left:15px !important' : ''"
        >{{  sku.userName ?sku.userName: (sku.onsaleOwner).substr(0,6)+"..."+(sku.onsaleOwner).substr(-6)}}</div>
      </div>
      <div class="mt-4 mt-lg-6 fs-60 mb-lg-5 font-weight-bold work-name " style=" max-height:156px; overflow:hidden;text-overflow:ellipsis;display:-webkit-box; -webkit-box-orient:vertical;-webkit-line-clamp:2;word-wrap: break-word;">
        {{ sku.name }}
      </div>
      <template v-if="sku.useType == '1'">
        <div v-if="!isStart">
          <div class="mt-3  fs-15 font-weight-bold t1">
            {{ $t("homeProductAuctionStartingIn") }}
          </div>
          <div class="mt-4 mt-lg-3 fs-30 font-weight-bold t2">
            {{ hour }}h {{ minutes }}m {{ second }}s
          </div>
          <div class="mt-3 mt-lg-6 fs-15 font-weight-bold t1">
            {{ $t("workCardStartBid") }}
          </div>
        </div>
        <div v-else>
          <div class="mt-3  fs-15 font-weight-bold t1">
            {{ $t("homeProductAuctionEndingIn") }}
          </div>
          <div class="mt-4 mt-lg-3 fs-30 font-weight-bold t2">
            {{ hour }}h {{ minutes }}m {{ second }}s
          </div>

		   <div v-if="sku.auctionNoBid==true" class="mt-3 mt-lg-6 fs-15 font-weight-bold t1">
		  		              {{ $t("auctionStartBid") }}
		  		            </div>
		  		  		   <div v-else  class="mt-3 mt-lg-6 fs-15 font-weight-bold t1">
		  		  			     {{ $t("workCardCurrentBid") }}
		  		  			</div>

        </div>
      </template>
      <div class="mt-4 mt-lg-2 fs-30 font-weight-bold t2 work-price">
        {{ sku.onsalePrice }} {{getChainNameByDenom(sku.coinDenom)}}
      </div>
      <div class="mt-5 mt-lg-2 fs-13" style="color: #766983">USD {{ usd }}</div>
      <div class="d-flex align-center mt-10 btn">
        <!--   拍卖-->
        <div v-if="sku.useType == '1'">
          <div
            v-if="
              sku.auctionVo.auctionStatus == '0' ||
              sku.auctionVo.auctionStatus == '1' ||
              sku.auctionVo.auctionStatus == '2'
            "
          >
            <!--   拍卖可点击 -->
            <div v-if="!isClick && sku.nftOwner != this.$store.state.did">
              <!--    / 买得起-->
              <v-btn
                v-if="isPrice"
                class="rd"
                rounded
                width="225px"
                height="51px"
                @click="bidBtn"
                >{{ $t("detailIndexPlaceBid") }}
              </v-btn>
              <!--    买不起-->
<!--              <v-btn v-else class="nord" rounded width="225px" height="51px"-->
<!--                >{{ $t("detailIndexPlaceBid") }}-->
<!--              </v-btn>-->
            </div>
            <!--   拍卖不可点击-->
            <div v-else>
              <v-btn class="nord" rounded width="225px" height="51px"
                >{{ $t("detailIndexPlaceBid") }}
              </v-btn>
            </div>
          </div>
          <div v-else>
            <v-btn class="nord" rounded width="225px" height="51px"
              >{{ $t("popupSaletotalSold") }}
            </v-btn>
          </div>
        </div>
        <!--  销售-->
        <div v-else-if="sku.useType != '1' || sku.denomType == '3'">
          <div v-if="sku.onsalePrice != 0">
            <v-btn
              v-if="isPrice && sku.nftOwner != this.$store.state.did"
              class="rd"
              rounded
              width="225px"
              height="51px"
              @click="buybtn"
              >{{ $t("popupBuyPriceBuyNow") }}
            </v-btn>
           <v-btn
              v-else
             class="nord"
              rounded
             width="225px"
              height="51px"
              >{{ $t("popupBuyPriceBuyNow") }}
            </v-btn>
          </div>
          <div v-else>
            <v-btn
              v-if="isPrice"
              class="rd"
              rounded
              width="225px"
              height="51px"
              @click="buybtn"
              >{{ $t("getForFree") }}
            </v-btn>
<!--            <v-btn-->
<!--              v-else-->
<!--              class="nord"-->
<!--              rounded-->
<!--              width="225px"-->
<!--              height="51px"-->
<!--              @click="buybtn"-->
<!--              >{{ $t("getForFree") }}-->
<!--            </v-btn>-->
          </div>
        </div>
        <img
          v-if="sku.useType != '1'"
          @click="buyBtn"
          class="ml-5 ml-lg-5 phone"
          :src="phoneBtn"
          alt="button"
          width="20px"
          height="31px"
        />
      </div>
    </div>
    <v-dialog v-model="openbid">
      <auction-price
        v-if="openbid"
        :auctionNo="auctionNo"
        :Nft_sku="sku"
        :buyType="buyType"
        @getOpen="OpenBid"
      ></auction-price>
    </v-dialog>
    <v-dialog v-model="openpay">
      <pay @getOpen="Open" :address="address" :price="price"  :Nft_sku="sku"></pay>
    </v-dialog>
    <v-dialog v-model="openbuy">
      <buy-price
        v-if="openbuy"
        :onsalePrice="onsalePrice"
        buytype="0"
        :Nft_sku="sku"
        :assetLevel="1"
        @getOpen="openBuy"
      ></buy-price>
    </v-dialog>
  </div>
</template>

<script>
import AuctionPrice from "@/components/popup/auctionPrice";
import Pay from "@/components/popup/pay.vue";
import BuyPrice from "../../components/popup/buyPrice";

import Work from "@/components/work/index.vue";
import Avatar from "@/components/avatar/index.vue";

import phoneBtn from "@/assets/icons/icon_pay.png";
import productPic from "@/assets/test.png";
import { getFileSrc } from "@/utils/file";
const WORK_KEY = "WORK";

export default {
  name: "Product",
  components: { Pay, AuctionPrice, Work, Avatar, BuyPrice },
  props: {
    sku: {
      type: Object,
      default: () => {},
    },
    address: {
      type: String,
    },
    price: {
      type: String,
    },
  },
  data() {
    return {
      openbid: false,
      openpay: false,
      openbuy: false,
      phoneBtn,
      productPic,
      showAvatarInfo: true,
      onsalePrice: "",
      usd: "",
      isStart: false,
      now: "",
      start: "",
      end: "",
      day: 0,
      hour: 0,
      minutes: 0,
      second: 0,
      seconds: "",
      buyType: "",
      auctionNo: "",
      isClick: false,
      // isPrice: false,
      isPrice: true,
       videoPic:''
    };
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  async mounted() {
    console.log("wxl -- sku info",this.sku)
    // let mount = localStorage.getItem("key_balance");
    // let price = Number(this.sku.onsalePrice);
    //售价大于余额，即买不起
    // if (price > mount) {
    //   this.isPrice = false;
    // } else {
    //   this.isPrice = true;
    // }
    //if (this.sku.auctionStartTime && this.sku.auctionEndTime) {
    this.Time(); //调用定时器
    // }
      if (this.sku.fileType =='MP4') {

    this.videoPic = await getFileSrc(WORK_KEY, this.sku.fileUrl, true);

      
    }

    console.log("wxl price ----------");
    console.log(this.sku);
    if(this.sku.useType == "1")
    this.auctionNo = this.sku.auctionVo.auctionNo;
    let _self = this;
    let count = 60;
    this.buyType = 0;
    (function fn() {
      count--;
      setTimeout(() => {

        let usd_price = localStorage.getItem("KEY_USD_PRICE");
        if(_self.sku.coinDenom == 'uiris' || _self.sku.coinDenom == 'unyan'){
           _self.usd =parseFloat((_self.sku.onsalePrice * JSON.parse(usd_price).IrisPrice).toFixed(4));
        if (_self.usd == "NaN") {
          _self.usd = "";
        }
        if (count > 0 && !!JSON.parse(usd_price).IrisPrice) {
          fn();
        }
        }else if(_self.sku.coinDenom == 'ibc/27394FB092D2ECCD56123C74F36E4C1F926001CEADA9CA97EA622B25F41E5EB2' || _self.sku.coinDenom == 'ibc/733D9E6DE683B9726B2C6A6460566B11FAF4C075DC08861685F269537D43E24D'){
           _self.usd =parseFloat((_self.sku.onsalePrice * JSON.parse(usd_price).AtomPrice).toFixed(4));
        if (_self.usd == "NaN") {
          _self.usd = "";
        }
        if (count > 0 && !!JSON.parse(usd_price).AtomPrice) {
          fn();
        }
        }else if(_self.sku.coinDenom == 'ibc/A5A5EBF3B7A180FBA2A3CE133153F5B904AA8B085691F66A15BE8156B6722652' || _self.sku.coinDenom == 'ibc/5C465997B4F582F602CD64E12031C6A6E18CAF1E6EDC9B5D808822DC0B5F850C' ){
           _self.usd = parseFloat(Number((_self.sku.onsalePrice)).toFixed(4));
        if (_self.usd == "NaN") {
          _self.usd = "";
        }
        if (count > 0) {
          fn();
        }
        }
       
      }, 1500);
    })();
  },
  methods: {
    getTime() {
      this.now = Date.parse(new Date());
     
      // this.start = Date.parse(new Date("2022-01-09 13:48:00"));
      // this.end = Date.parse(new Date("2022-01-09 13:49:00"));

      if (this.sku.useType == "1") {
         this.start = this.sku.auctionVo.startTime;
      this.end = this.sku.auctionVo.endTime;
        if (this.now < this.start) {
          this.isStart = false;
          this.isClick = true;
          this.seconds = (this.start - this.now) / 1000;
        } else if (this.now < this.end) {
          this.isStart = true;
          this.isClick = false;
          this.seconds = (this.end - this.now) / 1000;
        } else {
          this.isClick = true;
        }
      }
    },
    // 天 时 分 秒 格式化函数
    countDown() {
      let d = parseInt(this.seconds / (24 * 60 * 60));
      this.day = d < 10 ? "0" + d : d;
      let h = parseInt(((this.seconds / (60 * 60)) % 24) + d * 24);
      this.hour = h < 10 ? "0" + h : h;
      let m = parseInt((this.seconds / 60) % 60);
      this.minutes = m < 10 ? "0" + m : m;
      let s = parseInt(this.seconds % 60);
      this.second = s < 10 ? "0" + s : s;
    },
    //定时器没过1秒参数减1
    Time() {
      setInterval(() => {
        if (this.seconds > 0) {
          this.seconds -= 1;
          this.countDown();
        } else {
          this.getTime();
        }
      }, 1000);
    },
    buyBtn() {
      this.openpay = true;
    },
    //弹框关闭后获取open值
    Open(e) {
      this.openpay = e;
    },
    bidBtn() {
      if (!this.$vuetify.breakpoint.mobile) {
        if (!this.$store.state.did) {
          console.log("23");
          window.eventBus.$emit("ShowLogoBox");
          return;
        }
      }
      if (this.$vuetify.breakpoint.mobile) {
        this.openpay = true;
        return;
      }
      this.openbid = true;
    },
    OpenBid(e) {
      this.openbid = e;
    },
    buybtn() {
      if (!this.$vuetify.breakpoint.mobile) {
        if (!this.$store.state.did) {
          console.log("23");
          window.eventBus.$emit("ShowLogoBox");
          return;
        }
      }

      if (this.$vuetify.breakpoint.mobile) {
        this.openpay = true;
        return;
      }
      this.openbuy = true;
      this.onsalePrice = this.price;
    },
    openBuy(e) {
      this.openbuy = e;
    },
    workClick() {
      let query = { };
        query.contractAddress = this.sku.contractAddress;
          query.tokenId = this.sku.tokenId;
          query.owner = this.sku.nftOwner;
      if (this.sku.useType == "1" && this.sku.auctionVo.auctionNo != null) {
        this.$router.push({
          name: "Detail",
          query
        });
      } else {
        
          this.$router.push({ name: "Saledetail", query});
      //     if(this.sku.collectionType == 1)
      // {
      //   this.$router.push({ name: "Saledetail", query: { id: this.sku.id,tokenId: this.sku.tokenId } });
      // }else{
      //    this.$router.push({ name: "Saledetail", query: { id: this.sku.id } });
      // }
  
      }
    },
  },
};
</script>

<style lang="scss" scoped>
v-dialog {
  width: auto;
  height: auto;
}

.name {
  font-size: 15px;
 font-family:Helvetica;
}

.rd {
  width: 107px;
  height: 17px;
 font-family:Helvetica;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
}

.nord {
  width: 294px;
  height: 51px;
  background-image: linear-gradient(#766983, #766983),
    linear-gradient(#270645, #270645);
  background-blend-mode: normal, normal;
  border-radius: 25px;
  opacity: 0.9;
 font-family:Helvetica;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  /*line-height: 100px;*/
  letter-spacing: 0px;
  color: #ffffff !important;
}

.phone {
  width: 20px;
  height: 31px;
  display: inline-block;
}

.home {
  display: flex;
  letter-spacing: 0px;
  line-height: 1;

  .fs-13 {
    font-size: 13px;
  }

  // .fs-15 {
  //   font-size: 24px;
  // }

  .fs-60 {
    font-size: 50px;
  }

  .fs-30 {
    font-size: 40px;
  }

  .home-work {
    // margin-right: 100px;
    width: 640px;
    height: 575px;
    max-width: 640px;
    max-height: 575px;
    margin-left: 70px;
    margin-top:30px;

    .work {
      width: 100%;
      height: 100%;
    }
  }
 

  

  &.mobile {
    flex-direction: column;

    .home-work {
      margin-right: 0;
      width: 100%;
      height: unset;
      max-width: unset;
      max-height: unset;
      margin-top: unset;
      margin-left: unset;
    }

    .name {
      font-size: 15px;
      font-weight: bold;
    }

    .work-name {
      font-size: 30px !important;
      //height:70px !important;



    }
    .work-price {
      font-size: 30px !important;
    }
    .t1 {
      font-size: 15px;
    }

    .t2 {
      font-size: 30px;
    }

    .btn {
      margin-top: 69px;
    }
  }
}
</style>

<style lang="scss">
.work {
  position: relative;

  .icon {
    position: absolute;
    left: 15px;
    bottom: 110px;
  }
}

.product {
  //width: 400px;
  height: 575px;
  //max-width: 400px;
  max-height: 575px;
  flex: 1;
  justify-content: center;
  overflow: hidden;
  margin-right: 5px;
  margin-left: 45px;
}

.mobile {
  .product {
    width: 80%;
    height: unset;
    max-width: 80%;
     margin-left: 15px;
    .v-avatar {
      .v-image {
        max-width: 90% !important;
        max-height: 90% !important;
      }
    }

    .work-name {
      font-size: 30px !important;
      line-height: 50px !important;
      width: 250px;
      height: 60 !important;
      display: inline-block;
      overflow:hidden; 
      text-overflow:ellipsis;
      display:-webkit-box; 
      -webkit-box-orient:vertical;
      -webkit-line-clamp:2;
      display:block;
    }

    .work-price {
      font-size: 30px !important;
    }
  }
}
</style>
