<template>
  <div>
    <v-card-title class="d-block text--title pt-4 px-5 pb-2 text-truncate"
      >{{value.name}}</v-card-title
    >
    <v-card-text class="d-flex flex-row align-center pb-0 px-5 text-avatar">
      <Avatar  size="40" :did="value.owner" :hash="value.profilePhoto || value.issuerImg"
	  imgType="middlev"
	  :grade="value.grade"
	   showAvatarInfo/>
      <div class="ml-4 text-truncate" style="max-width: 100%;">{{value.issuerName}}</div>
    </v-card-text>

    <v-card-text class="d-flex flex-row px-5 pt-7 pb-5 text-number">
      <!-- <v-icon size="15">mdi-facebook</v-icon> -->
      <div >
        <v-img :src="
                require('@/assets/icons/' +
                  getChainByName(value.coinDenom)+
                  '.png')
          " width="22px" height="22px" />
      </div>
      <div class="ml-2 text--number text--primary-rd font-weight-medium" style="line-height:22px;">
        {{value.lastPrice}}
      </div>

    </v-card-text>

    <!-- xxl 474 -->
    <div style="float:right;display:flex;z-index:100;margin-top:-56px;margin-right:10px" v-if="value.saleStatus == 1">
      <v-img src="@/assets/icons/icon_onsale.png" alt="logo" width="71" height="56px"/>
    </div>


  </div>
</template>

<script lang='js'>
import Avatar from '@/components/avatar/index.vue';
import countLogo from "@/assets/icons/chain_iris_w.png";

export default {
  name: 'SellCardContent',
  components: { Avatar },
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data: function() {
    return {
      countLogo
    };
  },

  mounted(){
    // console.log(this.value);
  }
};

</script>

<style lang='scss' scoped>
.text--primary-rd {
  color: #270645;
}
.text--secondary-rd {
  color: #766983;
}
.text--title {
  font-size: 20px;
  line-height: 24px;
}
.text--sub-title {
  font-size: 12px;
  line-height: 1;
}
.text--number {
  font-size: 15px;
  line-height: 1;
   font-weight: bold !important;
  
}
.v-divider {
  border-color: #e3e3e3;
}
</style>
