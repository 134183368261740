<template>
  <v-list nav dense :light="false">
    <v-list-item-group mandatory :value="value" @change="change" color="#fff">
      <v-list-item v-for="item in list" :value="item.value" :key="item.value" color="#fff" @click="itemclick(item.value)">
        <v-list-item-content>
          <v-list-item-title v-if="$scopedSlots.default">
            <slot :value="item.value" :label="item.label"> </slot>
          </v-list-item-title>
          <v-list-item-title v-else v-text="item.label"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: "List",
  inheritAttrs: false,
  props: {
    list: {
      type: Array,
      defualt: () => [],
    },
    value: [String, Number],
  },
  methods: {
      itemclick(v) {
        this.$emit("input", v);
        this.$emit("change", v);
      },
    change: function (v) {
        console.log(v);
      // this.$emit("input", v);
      // this.$emit("change", v);
      // if(!this.mandatory) {
      //     v=undefined;
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list-item.v-item--active {
  background: $primary;
  &::v-deep .v-list-item__title {
		color: #fff;
	}
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: $primary;
}

</style>
