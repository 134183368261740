<template>
   <div id="container">
      <v-card :class="{card:true,mobile:isMobile}">
         <img class="clo" @click="closeDialog" src="@/assets/btn_close.png" alt="">
         <div class="top">
            <h3>{{$t('popupAccountAppealAccountAppeal')}}</h3>
            <p>{{$t('popupAccountAppealTheContent')}}</p>
         </div>
         <div class="ti-tx">
            <span class="title">{{$t('popupAccountAppealDescription')}}</span>
            <v-textarea ref="firsttxt" class="txt" label="" :placeholder="this.$t('popupAccountAppealPleaseDescribe')"
                        v-model="firsttxt" 
                        :rules="descRules"
                        outlined rows="5" 
                        row-height="18" 
                        counter="500"></v-textarea>
         </div>
         <div class="upload" style="margin-left: 52px;">
			 <div style="display: flex;">
				  <div class="uploadfile" v-for="(item,index) in uploadfile" :key="index">
						<img class="img" :src="item" />
					  <img class="icon" src="@/assets/btn_close.png" @click="imgRemove(index)" />
				  </div>

				  <div class="uploadfile" v-if="uploadfile.length<3">
					  <FIleInput class="FIleInput" @change="change"  :key="fileInputKey"  accept=".png,.jpg">
						<v-icon color="black">mdi-plus</v-icon>
					  </FIleInput>
				  </div>
			 </div>
            <span class="file-limit">{{$t('popupNftAppealLimit')}}</span>
         </div>
         <div class="emailinp">
            <span class="title">{{$t('popupNftAppealEmail')}}</span>
            <v-textarea label="" v-model="emailValue" 
            ref="emailValue"
             :rules="[rule.emailRules, rule.email]"
            auto-grow 
            outlined 
            rows="2" 
            row-height="18"></v-textarea>
         </div>
         <div class="footer">
            <button class="sub" submit @click="subCommit">{{$t('popupTransferCommit')}}</button>
         </div>
      </v-card>
	  
	  <PromptBox ref="promptBox"></PromptBox>
      <uComponents ref="ucom"></uComponents>
   </div>
</template>

<script>
import api from "@/api"
import FIleInput from "@/components/FIleInput.vue";
import PromptBox from "@/components/PromptBox.vue"
import { getFileSrc } from "@/utils/file";

const UPLOAD_KEY = "UPLOAD";
   //账号申诉
   export default {
	components: { FIleInput, PromptBox },
      name: "accountAppeal",
      data: () => ({
         open: true,
         firsttxt: '',
         rules: [v => v.length <= 500 || 'Max 500 characters'],
         emailValue: '',
         descRules: [(v) => !!v || "description is required."],
            rule: {
        emailRules: value => !!value || 'Email is required.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },

		uploadfile: [],
		 fileInputKey:0,
      }),
      computed: {
         isMobile: function () {
            return this.$vuetify.breakpoint.mobile;
         },
      },
      methods: {
            verify() {
        let emailVer = this.$refs.emailValue.validate(true);
        let nameVer = this.$refs.firsttxt.validate(true);
        !nameVer ? this.$refs.firsttxt.focus() : '';
        !emailVer ? this.$refs.emailValue.focus() : "";
        return emailVer && nameVer;
      },
         //提交转送数据
        async  subCommit() {
               if(!this.verify()) {
          return;
        }
            
                let params = {
                  complaintType:4,
                  complainants:this.$store.state.did,
                  email:this.emailValue,
                  content:encodeURIComponent(this.firsttxt),
				picture: this.uploadfile.join(',')

               }
               let res = await api.home.accountAppeal(params);
               if(res.success) {
                   this.$toast("success", this.$t('success'));
               } else {
                   this.$toast("error", this.$t('failed'));
               }
				// this.$refs.promptBox.show(res.msg);
         },
         //关闭弹框，发送open值
         closeDialog() {
            this.open = false;
            this.$emit('getOpen', this.open);
         },
		async change(file) {
         if (file && (file.type !== 'image/jpeg' && file.type !== 'image/png')) {
            return;
         }
		  console.log("xxl file 1111 :");
		  let res = await api.image.uploadImage(file);
		  // console.log(file.type)  //video/mp4
		  this.fileInputKey++;
		  // console.log(res)
		  let url = await getFileSrc(UPLOAD_KEY, res.data.data);
		  this.uploadfile.push(url);
		},

		imgRemove(idx) {
			this.uploadfile.splice(idx, 1);
			// console.log(idx);
		}
      }
   }
</script>

<style lang="scss" scoped>
   img {
      display: inline-block;
      width: 126px;
      height: 126px;
      background-color: #8a73ff;
      border-radius: 5px;
      margin: 1px 22px 10px 52px;
   }

   span {
      display: flex;
      align-items: center;
      font-family:Helvetica;
      color: #270645;
   }

   .title {
     font-family:Helvetica;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      color: #270645;
   }

   .card {
      display: flex;
      flex-direction: column;
      min-height: 569px;
      width: 600px;

      .clo {
         position: absolute;
         right: 10px;
         top: 10px;
         width: 30px;
         height: 31px;
         background-color: #fff;
         border-radius: 50%;
         margin-right: 0;
         cursor: pointer;
      }

      .top {
         h3 {
            margin: 41px 0 17px 52px;
           font-family:Helvetica;
            font-size: 25px;
            color: #270645;
         }

         p {
            margin: 0 52px;
            width: 494px;
            height: 65px;
            font-family:Helvetica;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 28px;
            letter-spacing: 0;
            color: #766983;
         }
      }

      .ti-tx {
         margin: 25px 52px;

         .txt {
            width: 500px;
            height: 166px;
            background-image: linear-gradient(#ffffff, #ffffff),
            linear-gradient(#f8f6fd, #f8f6fd);
            background-blend-mode: normal, normal;
         }
      }

      .upload {
         .uploadfile {
            margin-bottom: 14px;
            width: 101px;
            height: 101px;
            background-image: linear-gradient(#ffffff, #ffffff),
            linear-gradient(#f8f6fd, #f8f6fd);
            background-blend-mode: normal, normal;
            border-radius: 1px;
            border: solid 2px #e3e3e3;

			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			margin-right: 30px;
			cursor: pointer;
			.FIleInput {
					  width: 100%;
					  height: 100%;
					  .v-icon {
						  width: 100%;
						  height: 100%;
						  padding: 25px;
					  }

			}
			.icon {
					  width: 20px;
					  height: 20px;
					  border-radius: 50%;
					  position: absolute;
					  top: -10px;
					  left: 30px;
					  background-color: #fff;
					  z-index: 99;
					  cursor: pointer;
			}
			.img {
					  width: 100%;
					  height: 100%;
					  margin: 0;
			}
         }

         .file-limit {
            // width: 137px;
            height: 13px;
            font-family:Helvetica;
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            // line-height: 100px;
            letter-spacing: 0px;
            color: #766983;
         }
      }

      .emailinp {
         margin: 22px 52px;
      }

      .footer {
         display: flex;
         flex-direction: column;
         text-align: center;
         margin-bottom: 50px;

         .sub {
            margin: 0 auto;
            width: 450px;
            height: 51px;
            background-image: linear-gradient(90deg, #d300fd 0%, #a439fe 26%, #7471ff 52%, #00fdcf 100%),
            linear-gradient(#270645, #270645);
            background-blend-mode: normal, normal;
            border-radius: 25px;
            opacity: 0.9;
           font-family:Helvetica;
	         font-size: 20px;
            color: #ffffff;
         }
      }

   }
</style>


