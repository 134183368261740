<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img class="clo" @click="closeDialog" src="@/assets/btn_close.png" alt />
      <div class="top mb-12">
        <h3 >{{$t('popupMarketSelectSaleMode')}}</h3>
        <!-- <p>{{$t('onsaleChooseSaleType')}}</p> -->
      </div>
      <div class="link">
        <ul class="d-flex flex-lg-row flex-column">
          <li class="mr-lg-4" @click="fixedPriceBtn">
            <img
              src="@/assets/icons/sale_fixed.png"
              width="69px"
              height="69px"
              alt
            />
            <div class="infotit">{{$t('onsaleFixedPrice')}}</div>
            <div class="infos">{{$t('onsaleModifiabledPrice')}}</div>
          </li>
          <li v-if="parentValue.amount === 1 && parentValue.level == 1" class="" @click="StartSaleBtn">
            <img
              src="@/assets/icons/sale_auction.png"
              width="74px"
              height="71px"
              alt
            />
            <div class="infotitauc">{{$t('onsaleTimedAuction')}}</div>
            <div class="infos">{{$t('onSaleIssues')}}</div>
          </li>
          <li v-else class="onlyread">
            <img
              src="@/assets/icons/sale_auction1.png"
              width="74px"
              height="71px"
              alt
            />
            <div class="infotitauc">{{$t('onsaleTimedAuction')}}</div>
            <div class="infos">{{$t('onSaleIssues')}}</div>
          </li>
            <li class="ml-lg-4" @click="discountPriceBtn" v-if="parentValue.level == 1">
            <img
              src="@/assets/icons/sale_discount.png"
              width="69px"
              height="69px"
              alt
            />
            <div class="infotit mt-6">{{$t('discountSale')}}</div>
            <div class="infosDiscount ml-7">{{$t('discountSaleModifiabledPrice')}}</div>
          </li>
             <li class="ml-lg-4 onlyread"  v-else>
            <img
              src="@/assets/icons/sale_discount1.png"
              width="69px"
              height="69px"
              alt
            />
            <div class="infotit mt-6">{{$t('discountSale')}}</div>
            <div class="infosDiscount ml-7">{{$t('discountSaleModifiabledPrice')}}</div>
          </li>
           <li class="ml-lg-4" @click="execlusivePriceBtn" v-if="parentValue.level == 1">
            <img
              src="@/assets/icons/sale_execlusive.png"
              width="69px"
              height="69px"
              class="pb-2"
              alt
            />
            <div class="infotit ">{{$t('execlusiveSale')}}</div>
            <div class="infosExeclu ml-5">{{$t('execlusiveSaleModifiabledPrice')}}</div>
          </li>
           <li class="ml-lg-4 onlyread" v-else>
            <img
              src="@/assets/icons/sale_execlusive1.png"
              width="69px"
              height="69px"
              class="pb-2"
              alt
            />
            <div class="infotit ">{{$t('execlusiveSale')}}</div>
            <div class="infosExeclu ml-5">{{$t('execlusiveSaleModifiabledPrice')}}</div>
          </li>
        </ul>
      </div>
    </v-card>
    <!-- 拍卖 -->
    <v-dialog v-model="Sale" persistent>
      <auction
        :address="address"
       :parentValue="parentValue"
        :owerfrom ="owerfrom"
        :name="name"
        v-bind="$attrs"
        v-on="$listeners"
        @getOpen="openSale"
      ></auction>
    </v-dialog>
    <!-- 开始售卖 -->
    <v-dialog v-model="openmarket" persistent>
      <market
          :sourcemarket='sourcemarket'
        :address="address"
        :parentValue="parentValue"
        :offsaleTokenids="parentValue.offsaleTokenIds"
        :name="name"
        :constructID="constructID"
		    :contractCollectionId="contractCollectionId"
        :Type="Type"
        v-bind="$attrs"
        v-on="$listeners"
        v-if="openmarket"
        @getOpen="openMarket"
      ></market>
    </v-dialog>
  </div>
</template>

<script>
import Auction from "@/components/popup/auction.vue";
import Market from "@/components/popup/market.vue";
export default {
  components: { Auction, Market },
  props: {
    supplyLimit: {
      type: Number,
    },
    address: {
      type: String,
    },
    onSaleTokenIds: {
      type: Array,
    },
    offSaleTokenIds: {
      type: Array,
    },
    name:{
      type: String,
    },
    constructID:{
      type: Number,
    },
	contractCollectionId:{
      type: Number,
    },
     owerfrom: {
      type: String,
    },
    parentValue:{
      type:Object
    },
    sourcemarket:{
      type: String,
    }
  },
  data: () => ({
    open: false,
    sum: 1,
    openmarket:false,
    Sale:false,
    Type:""
  }),
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  mounted() {
   
  },
  methods: {
    //关闭弹框，发送open值
    closeDialog() {
      this.$emit("getOpen", this.open);
    },
    //拍卖发行
    StartSaleBtn() {
       this.$gtag.event('上架模式-Timed Auction', { 'event_category': 'Click', 'event_label': 'Timed Auction' })
       this.$emit("getOpen", false);
      this.Sale = true;
    },
    openSale(e) {
      this.Sale = e;
    },
    //定价销售
    fixedPriceBtn() {
      this.$gtag.event('上架模式-Fixed Price', { 'event_category': 'Click', 'event_label': 'Fixed Price' })
       this.$emit("getOpen", false);
      this.openmarket = true;
      this.Type = "fixedPrice"
    },
    discountPriceBtn(){
       this.$gtag.event('上架模式-Discounted Sale', { 'event_category': 'Click', 'event_label': 'Discounted Sale' })
       this.$emit("getOpen", false);
       this.openmarket = true;
       this.Type = "discountSale"
      
    },
    execlusivePriceBtn(){
      this.$gtag.event('上架模式-Exclusive Sale', { 'event_category': 'Click', 'event_label': 'Exclusive Sale' })
       this.$emit("getOpen", false);
      this.openmarket = true;
      this.Type = "execlusiveSale"
    },

    
    openMarket(e) {
      
      this.openmarket = e;
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
 	// width: 801px;
	// min-height: 326px;
  // margin:0 52px;
  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }

  .top {
    padding: 0 52px;
    display: flex;
    flex-direction: column;
    h3 {
      margin: 26px 0 5px;
     font-family:Helvetica;
      font-size: 25px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
    }
    p {
      font-family:Helvetica;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      margin-bottom: 25px;
    }
  }
  .link {
    margin: 0 52px;
    ul {
      list-style: none;
      padding: 0;
      li {
        text-align: center;
        cursor: pointer;
        width: 175px;
        height: 175px;
        background-image: linear-gradient(#ffffff, #ffffff),
          linear-gradient(#f8f6fd, #f8f6fd);
        background-blend-mode: normal, normal;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
        border-radius: 5px;
        display: block;
        margin-bottom: 34px;
        img {
          display: block;
          margin: 20px auto 20px;
        }
        .infotit,
        .infotitauc {
          height: 22px;
          font-family:Helvetica;
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0px;
          line-height: 22px;
          color: #270645;
          margin: 0 auto 7px;
        }
        .infos {
          height: 12px;
          font-family:Helvetica;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          line-height: 12px;
          color: #270645;
          margin-bottom: 17px;
        }
        .infosDiscount{
          font-family:Helvetica;
          width: 120px;
	        height: 26px;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 12px;
          letter-spacing: 0px;
          color: #270645;
          
        }
        .infosExeclu{
           font-family:Helvetica;
          width: 133px;
	        height: 29px;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 12px;
          letter-spacing: 0px;
          color: #270645;

        }
      }
      .onlyread {
        .infosDiscount,
        .infotit,
         .infosExeclu,
        .infotitauc,
        .infos {
          color: #ccc;
        }
       
      }
      li:hover {
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.36);
      }
    }
  }
  &.mobile {
    height: auto;
    .top {
      h3 {
        font-size: 20px;
      }
      p {
        height: auto;
      }
    }
    .link {
      ul {
        li {
          img {
            margin: 35px auto;
          }
        }
      }
    }
  }
}
</style>
