<template>
    <div class="content" :class="{ showInput: input }">
        <div class="select" @click="coinSelect" @mouseover="isMouseOver=true" @mouseout="isMouseOver=false">
               <img :src="
                require('../assets/icons/' +
                  showIcon+
                  '.png')
              " />
            <span class="txt">{{ coinData[coinIndex].name }}</span>
            <span class="icon">▼</span>
        </div>
        <div class="list" :class="{ reposition: Reposition }" :style="{ display: coinOpen }">
            <input ref="listBlur" type="text" style="width:0px;position:absolute;" @blur="coinListBlur" />
            <div class="item" v-for="(item, index) in coinData" :key="index" @mousedown="coinItemClick(item, index )">
                  <img :src="
                require('@/assets/icons/' +
                  item.img+
                  '.png')
              " />
                <span class="txt">{{ item.name }}</span>
            </div>
        </div>
        <input v-if="input" v-model.number="inputValue" type="text" placeholder="0.0000" @input="updateVal">
    </div>
</template>

<script lang="js">
import api from "@/api";

export default {
    name: 'ChainSelectInput',
    props: {
        input: {
            type: Boolean,
            default: false
        },
        ChainValue: Object,
        Reposition: Boolean
    },
    data: function () {
        return {
      coinData: [
      {
        name:"IRIS",
        img:"chain_iris_w",
        chain_type:1,
        denom:"uiris"
      },
     
      {
        name:"ATOM",
        img:"chain_atom_w",
        chain_type:2,
        denom:"ibc/27394FB092D2ECCD56123C74F36E4C1F926001CEADA9CA97EA622B25F41E5EB2"
      },
       {
        name:"USDC",
        img:"chain_usdc_w",
        chain_type:3,
        denom:"ibc/A5A5EBF3B7A180FBA2A3CE133153F5B904AA8B085691F66A15BE8156B6722652"
      },
    ],
            coinOpen: "none",
            coinIndex: 0,
            inputValue: "",

            isMouseOver: false
        }
    },
    computed: {
        showIcon() {
            if(this.coinData.length > 1) {
                return this.coinData[this.coinIndex].img;
            }
            return "";
        }
    },
    async mounted() {
          let res = await api.home.getFee();
  
        let usdcEnable = res.data.usdcEnable
        let atomEnable = res.data.atomEnable
        console.log("zzzzzzzzzzzzzzzzzzzzzz",usdcEnable,atomEnable)
        let env =  process.env.VUE_APP_IRIS_CHAIN_ID
        if(env == 'nyancat-9'){
         this.coinData =  [
      {
        name:"IRIS",
        img:"chain_iris_w",
        chain_type:1,
        denom:"unyan",
        isEnable:'true'
      },
     
      {
        name:"ATOM",
        img:"chain_atom_w",
        chain_type:2,
        denom:"ibc/733D9E6DE683B9726B2C6A6460566B11FAF4C075DC08861685F269537D43E24D",
        isEnable:atomEnable
      },
       {
        name:"USDC",
        img:"chain_usdc_w",
        chain_type:3,
        denom:"ibc/5C465997B4F582F602CD64E12031C6A6E18CAF1E6EDC9B5D808822DC0B5F850C",
        isEnable:usdcEnable
      },
    ]
    }else{
          this.coinData =  [
     {
        name:"IRIS",
        img:"chain_iris_w",
        chain_type:1,
        denom:"uiris",
        isEnable:'true'
      },
     
      {
        name:"ATOM",
        img:"chain_atom_w",
        chain_type:2,
        denom:"ibc/27394FB092D2ECCD56123C74F36E4C1F926001CEADA9CA97EA622B25F41E5EB2",
        isEnable:atomEnable
      },
       {
        name:"USDC",
        img:"chain_usdc_w",
        chain_type:3,
        denom:"ibc/A5A5EBF3B7A180FBA2A3CE133153F5B904AA8B085691F66A15BE8156B6722652",
        isEnable:usdcEnable
      },
    ]

    }
    this.coinData = this.coinData.filter((item) => item.isEnable == 'true')

   console.log("ssssss",this.coinData)
        let chainToken = localStorage.getItem("ChainToken");
        if(chainToken) {
            this.coinData = JSON.parse(chainToken);
            this.coinIndex = 1;
            this.coinItemClick(this.coinData[this.coinIndex], this.coinIndex);
        }
    },
    methods: {
        coinSelect() {
            if (this.coinOpen == "none"){
                this.coinOpen = "unset";
                this.$nextTick(() => {
                    this.$refs.listBlur.focus();
                });
            }
            else{
                this.coinOpen = "none";
            }
        },
        coinListBlur() {
            if(!this.isMouseOver) {
                this.coinOpen = "none";
            }
        },
        coinItemClick(item, index) {
            this.coinIndex = index;
            this.$emit("change", item, item.name);

            if(this.ChainValue) {
                this.setChainValue(item.name);
                const val = this.ChainValue[item.name];
                item.fee = val.format;
            }
        },
        updateVal() {
            this.$emit("input", this.inputValue, this.coinData[this.coinIndex].name);
        },
        setChainValue(name) {
            if(this.ChainValue) {
                const val = this.ChainValue[name];
                if(val) {
                    this.inputValue = val.format;
                } else {
                    this.inputValue = 0;
                }
            }
            this.updateVal();
        },
        setDepositValue(value) {
            this.inputValue = value;
        }
    }
};
</script>

<style lang="scss" scoped>
.content {
    border: solid 2px #e3e3e3;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 10px;
    position: relative;
    height: 58px;

    .select {
        display: flex;
        align-items: center;
        min-width: 140px;
        width: 100%;
        cursor: pointer;
        user-select: none;

        img {
            width: 40px;
            height: 40px;
        }

        .txt {
            font-family: Helvetica;
            font-size: 15px;
            color: #270645;
            margin-left: 10px;
        }

        .icon {
            margin-left: auto;
        }
    }

    .list {
        position: absolute;
        left: 14px;
        top: 50px;
        // margin-top: 270px;
        // margin-left: 2px;
        width: 112px;
        background-color: #ffffff;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
        border-radius: 5px;
        z-index: 999;
        
        .item {
            display: flex;
            align-items: center;
            margin: 5px;
            padding: 5px 10px;
            cursor: pointer;

            img {
                width: 27px;
                height: 27px;
            }

            .txt {
                font-size: 13px;
                color: #270645;
                margin-left: 10px;
                width: 360px;
            }

            &:hover {
                background-color: #1d42ff;
                border-radius: 5px;
                .txt {
                    color: #ffffff;
                }
            }
        }
    }

    .reposition {
        position: fixed;
        left: unset;
        top: unset;
        margin-top: 270px;
        margin-left: 2px;
    }

    input {
        font-family: Helvetica;font-weight: bold;
        font-size: 24px;
        color: #766983;
        text-align: right;
        width: 350px;
        margin-left: auto;
        margin-right: 10px;

        &:focus {
            outline: none;
        }
    }
}

.showInput {
    .select {
        width: unset;
    }
}
</style>
