<template>
	<div style="overflow: hidden" class="pb-3">
		<v-card-title class="text--title px-5 py-4">{{ value.name }}</v-card-title>
		<v-card-text class="d-flex flex-row align-center justify-space-between px-5 pb-6" style="height: 49px">
			<div class="d-flex flex-row mt-2" v-if="value.useType != '1'">
				<div>
					  <v-img :src="
                require('@/assets/icons/' +
                  getChainByName(value.coinDenom)+
                  '.png')
          " width="22px" height="22px" />
				</div>
				<div class="
		   ml-2
		   text--number text--primary-rd
		   font-weight-medium
		 " style="line-height:22px;">
					{{ value.minPrice ? parseFloat( value.minPrice) : value.minPrice }}
				</div>
			</div>
			<v-spacer></v-spacer>

			<div class="d-flex flex-row align-center">


				<!-- 销毁弹层按钮 -->
				<img class="ml-5" src="@/assets/icons/burn.png" width="25px" height="25px" @click="burnBtn"
					v-if="value.level == 1 && value.saleStatus == 0" alt="" />
				<!-- 被举报 禁止 弹层那妞 -->
				<img class="ml-5" src="@/assets/icons/ban.png" width="25px" height="25px" v-if="value.denomStatus == 3"
					alt="" />
				<!-- 销售统计弹层按钮 -->
 <!-- 被举报 禁止 弹层那妞 -->
            <img
                class="ml-5"
                src="@/assets/icons/ban.png"
                width="25px"
                height="25px"
                v-if="value.disabled == 3"
                alt=""
            />
			</div>
		</v-card-text>
		<v-card-text class="py-0 px-5" style="height: 44px">
			<!-- 正常状态 v-if="value.denomStatus == '0'"-->
			<template  v-if="value.disabled != 3&&(value.saleStatus == '0' || value.saleStatus == '1')">
				<!-- 收藏或拍卖 -->
				<template v-if="value.useType == null||value.useType == 0">
					<!-- 判断 saleStatusDtos 不为空 -->
					<template>
						<!-- 已上架，显示下架按钮 -->
						<template v-if="value.saleStatus == '1' && value.level == 1">
							<v-btn class="sale" dark rounded depressed width="225" height="35" @click="EndSaleBtn">
								{{ $t("popupSoldoutEndsale") }}
							</v-btn>
						</template>
						<!-- 已下架，显示上架按钮 -->
						<template v-else-if="value.saleStatus == '0' && value.level == 1">
							<div class="d-flex justify-space-between">
								<div class="btnbox">
									<v-btn class="rd" rounded @click="TransferBtn">{{
                              $t("popupNewTransferTransfer")
                              }}
									</v-btn>
								</div>
								<!-- 收藏部分的上架按钮  替换为 上架按钮 -->
								<template>
									<v-btn class="satrtsale" dark rounded depressed width="115" height="35"
										@click="putOnSaleBtn">{{ $t("popupMarketStartSale") }}
									</v-btn>
								</template>

							</div>
						</template>
						<!-- 进行中 -->
						<template v-else-if="value.saleStatus == '6'||value.saleStatus == '7'||value.saleStatus == '9'">
							<div class="
					  d-flex
					  justify-center
					  align-center
					  pt-3
					  text--info text--secondary-rd
					">
								{{ $t("workCardInprogress") }}
							</div>
						</template>
					</template>
					<!-- 售完状态 -->
					<template v-if="value.level == 2">
						<div class="sold_out">{{ $t("workCardSoldOut") }}</div>
					</template>
				</template>

				<!-- 拍卖 -->
				<template v-if="value.useType == '1'">
					<!-- 活动未开始 -->
					<template v-if="value.auctionStatus == '0'">
						<div class="d-flex flex-row justify-space-between">
							<div>
								<div class="text--sub-title text--primary-rd">
									{{ $t("popupAuctionStartingBid") }}
								</div>
								<div class="d-flex flex-row mt-2">
									<div>
										  <v-img :src="
                require('@/assets/icons/' +
                  getChainByName(value.coinDenom)+
                  '.png')
          " width="22px" height="22px" />
									</div>
									<div class="
                                    ml-2
                                    text--number text--primary-rd
                                    font-weight-medium
                                  " style="line-height:22px;">
										{{ value.minPrice ? parseFloat( value.minPrice) : value.minPrice}}
									</div>
								</div>
							</div>
							<div>
								<div class="text--sub-title text--primary-rd">
									{{ $t("popupAuctionStartDate") }}
								</div>
								<div class="mt-2">
									<div class="text--number text--primary-rd font-weight-medium">
										{{ hour }}h {{ minutes }}m {{ second }}s
									</div>
								</div>
							</div>
						</div>
						<!--遮罩 -->
						<div class="dialodup5" @click.stop="workClick">
							<button class="drawbtn" @click.stop="AbanBtn"> {{ $t("Withdraw") }}</button>
						</div>
					</template>
					<!-- 活动已开始但是没参与-->
					<template v-if="(value.auctionStatus == '1'||value.auctionStatus == '4')&&value.auctionNoBid==true">
						<div class="d-flex flex-row justify-space-between">
							<div>
								<div class="text--sub-title text--primary-rd">
									{{ $t("popupAuctionStartingBid") }}
								</div>
								<div class="d-flex flex-row mt-2">
									<div>
										  <v-img :src="
                require('@/assets/icons/' +
                  getChainByName(value.coinDenom)+
                  '.png')
          " width="22px" height="22px" />
									</div>
									<div class="
                                    ml-2
                                    text--number text--primary-rd
                                    font-weight-medium
                                  " style="line-height:22px;">
										{{ value.minPrice ? parseFloat( value.minPrice) : value.minPrice}}
									</div>
								</div>
							</div>
							<div>
								<div class="text--sub-title text--primary-rd">

									{{ $t("workCardEndingin") }}
								</div>
								<div class="mt-2">
									<div class="text--number text--primary-rd font-weight-medium">
										{{ hour }}h {{ minutes }}m {{ second }}s
									</div>
								</div>
							</div>
						</div>
						<!--遮罩 -->
						<div class="dialodup5" @click.stop="workClick">
							<button class="drawbtn" @click.stop="AbanBtn"> {{ $t("Withdraw") }}</button>
						</div>
					</template>

					<!-- 拍卖开始 -->
					<template
						v-if="(value.auctionStatus == '1'&&value.auctionNoBid == false)|| value.auctionStatus == '3'">
						<div class="d-flex flex-row justify-space-between">
							<div>
								<div class="text--sub-title text--primary-rd">
									{{ $t("workCardCurrentBid") }}
								</div>
								<div class="d-flex flex-row mt-2">
									<div>
										  <v-img :src="
                require('@/assets/icons/' +
                  getChainByName(value.coinDenom)+
                  '.png')
          " width="22px" height="22px" />
									</div>
									<div class="
                      ml-2
                      text--number text--primary-rd
                      font-weight-medium
                    " style="line-height:22px;">
										{{ value.minPrice ? parseFloat( value.minPrice) : value.minPrice}}
									</div>
								</div>
							</div>
							<div>
								<div class="text--sub-title text--primary-rd">
									{{ $t("workCardEndingin") }}
								</div>
								<div class="mt-2">
									<div class="text--number text--primary-rd font-weight-medium">
										{{ hour }}h {{ minutes }}m {{ second }}s
									</div>
								</div>
							</div>
						</div>
					</template>
					<!-- 达到心里价格待确认 -->
					<template v-if="value.auctionStatus == '5'">
						<div class="d-flex flex-row justify-space-between">
							<div>
								<div class="text--sub-title text--primary-rd">
									{{ $t("Final_price") }}
								</div>
								<div class="d-flex flex-row mt-2">
									<div>
										  <v-img :src="
                require('@/assets/icons/' +
                  getChainByName(value.coinDenom)+
                  '.png')
          " width="22px" height="22px" />
									</div>
									<div class="
                     ml-2
                     text--number text--primary-rd
                     font-weight-medium
                   " style="line-height:22px;">
										{{ value.minPrice ? parseFloat( value.minPrice) : value.minPrice }}
									</div>
								</div>
							</div>
							<div>
								<div class="text--sub-title text--primary-rd">
									{{ $t("workCardEndingin") }}
								</div>
								<div class="mt-2">
									<div class="text--number text--primary-rd font-weight-medium">
										0h 0m 0s
									</div>
								</div>
							</div>
						</div>
						<!--遮罩 -->
						<div class="dialodup5" @click.stop="workClick">
							<div class="tip">{{$t("pleaseAccept")}}</div>
							<div class="timer">{{ hh }}h {{ mm }}m {{ ss }}s</div>
							<button class="dealbtn" @click.stop="dealBtn">{{$t("deal")}}</button>
						</div>
					</template>
					<!-- 未达到心里价格待确认 -->
					<template v-if="value.auctionStatus == '4'&&value.auctionNoBid==false">
						<div class="d-flex flex-row justify-space-between">
							<div>
								<div class="text--sub-title text--primary-rd">
									{{ $t("workCardCurrentBid") }}
								</div>
								<div class="d-flex flex-row mt-2">
									<div>
									  <v-img :src="
                require('@/assets/icons/' +
                  getChainByName(value.coinDenom)+
                  '.png')
          " width="22px" height="22px" />
									</div>
									<div class="
                                ml-2
                                text--number text--primary-rd
                                font-weight-medium
                              " style="line-height:22px;">
										{{ value.minPrice ? parseFloat( value.minPrice) : value.minPrice }}
									</div>
								</div>
							</div>
							<div>
								<div class="text--sub-title text--primary-rd">
									{{ $t("workCardEndingin") }}
								</div>
								<div class="mt-2">
									<div class="text--number text--primary-rd font-weight-medium">
										0h 0m 0s
									</div>
								</div>
							</div>
						</div>
						<!--遮罩 -->
						<div class="dialodup4" @click.stop="workClick">
							<div class="tip">
								{{$t("NotReversePrice")}}
							</div>
							<div class="timer">{{ hh }}h {{ mm }}m {{ ss }}s</div>
							<div class="btn d-flex flex-row">
								<div class="box  ml-lg-5">
									<button class="abanbtn" @click.stop="AbanBtn">{{$t("abandon")}}</button>
								</div>
								<button class="dealbtn ml-lg-1" @click.stop="iSdealBtn">{{$t("deal")}}</button>
							</div>
						</div>
					</template>

					<!-- 拍卖完了 -->
					<template v-if="value.auctionStatus == '6'">
						<div>
							<div class="text--sub-title text--primary-rd">
								{{ $t("Final_price") }}
							</div>
							<div class="d-flex flex-row mt-2">
								<div>
									  <v-img :src="
                require('@/assets/icons/' +
                  getChainByName(value.coinDenom)+
                  '.png')
          " width="22px" height="22px" />
								</div>
								<div class="ml-2 text--number text--primary-rd font-weight-medium"
									style="line-height:22px;">
									{{ value.lastBidPrice }}
								</div>
							</div>
						</div>
						<div class="auction_out">{{ $t("workCardSoldOut") }}</div>
					</template>


				</template>
				<!-- 只能转送 -->
				<template v-else-if="value.useType == '2'">
					<div class="d-flex justify-center" v-if="value.pinventory > 0">
						<v-btn class="rd" rounded depressed outlined width="225" height="35" @click="TransferBtn">
							{{ $t("popupNewTransferTransfer") }}
						</v-btn>
					</div>
				</template>
			</template>
			<!-- 被禁止/被投诉 -->
			<template v-else-if="value.disabled == 3">
				<div v-if="value.complaintStatus==1" class="d-flex justify-space-between">
					<div class="mt-1 text-wrap text--info text--secondary-rd">
						{{ $t("workCardSuspected") }}
					</div>
					<v-btn class="haveAppeal" dark rounded width="116" height="35">
						{{ $t("headerUserCenterNavAppealed") }}
					</v-btn>
				</div>
				<div v-else class="d-flex justify-space-between">
					<div class="mt-1 text-wrap text--info text--secondary-rd">
						{{ $t("workCardSuspected") }}
					</div>
					<v-btn class="sale" dark rounded width="116" height="35" @click="AppealBtn">
						{{ $t("workCardAppeal") }}
					</v-btn>
				</div>
			</template>

			 <!-- 进行中 -->
         <template v-else>
            <div
                class="
            d-flex
            justify-center
            align-center
            pt-3
            text--info text--secondary-rd
          "
            >
               {{ $t("workCardInprogress") }}
            </div>
         </template>
		</v-card-text>

		<!-- 弹层部分 -->
		<!-- 申诉 -->
		<v-dialog v-model="Appeal">
			<nftAppeal :constructID="constructID" :Nft_sku="value" @getOpen="Open"></nftAppeal>
		</v-dialog>
		<!-- 转送/新建转送 -->
		<v-dialog v-model="Transfer">
			<new-transfer :imgUrl="imgUrl" :address="address" :level="1" :name="name" :offSaleTokenIds="offSaleTokenIds"
				@getOpen="opentransfer" @promptBoxCommit="promptBoxCommit"></new-transfer>
		</v-dialog>
		<!-- 新增 上架选择 -->
		<v-dialog v-model="openputonsale">
			<put-on-sale @getOpen="openPutOnSale" :address="address" :onSaleTokenIds="onSaleTokenIds"
				:offSaleTokenIds="offSaleTokenIds" :supplyLimit="supplyLimit" :name="name" :constructID="constructID" :contractCollectionId="contractCollectionId" >
			</put-on-sale>
		</v-dialog>
		<!-- 销毁 -->
		<v-dialog v-model="Burning">
			<burn :address="address" :tokenId="tokenId" :type="type" @getOpen="openBurn"></burn>
		</v-dialog>
		<!-- 结束售卖 -->
		<v-dialog v-model="Sold">
			<soldout :address="address" :onSaleTokenIds="onSaleTokenIds" :offSaleTokenIds="offSaleTokenIds"
				:name="value.name" endType="collection" @getOpen="openSold"></soldout>
		</v-dialog>
		<!-- 销售统计 -->
		<v-dialog v-model="Total">
			<saletotal :address="address" :id="value.id" :name="value.name" :minPrice="value.minPrice"
				@getOpen="openTotal"></saletotal>
		</v-dialog>
		<!-- 卖完了 -->
		<v-dialog v-model="Saleout">
			<soldout @getOpen="openSaleout"></soldout>
		</v-dialog>
		<!-- 未达到目的  放弃   / 及 撤销 使用  -->
		<v-dialog v-model="openaban">
			<auction-aban :auctionNo="auctionNo" :auctionStatus="auctionStatus" @getOpen="openAban"></auction-aban>
		</v-dialog>
		<!-- 未达到目的待确认 -->
		<v-dialog v-model="openisdeal">
			<auction-is-sure :auctionNo="auctionNo" :name="value.name" @getOpen="openisDeal"></auction-is-sure>
		</v-dialog>
		<!-- 达到目的待确认 -->
		<v-dialog v-model="opendeal">
			<auction-in-sure :auctionNo="auctionNo" :name="value.name" @getOpen="openDeal"></auction-in-sure>
		</v-dialog>
	</div>
</template>

<script lang='js'>
	import NftAppeal from "@/components/popup/nftAppeal.vue";
	// import Transfer from "@/components/popup/transfer.vue";
	import newTransfer from "@/components/popup/newTransfer.vue";
	import Burn from "@/components/popup/burn.vue";
	import Soldout from "@/components/popup/soldout.vue";
	import Saletotal from "@/components/popup/saletotal.vue";
	import PutOnSale from "@/components/popup/putOnSale.vue";
	import AuctionInSure from "@/components/popup/auctionInSure.vue";
	import AuctionAban from "@/components/popup/auctionAban.vue";
	import AuctionIsSure from "@/components/popup/auctionIsSure.vue";


	export default {
		components: {
			NftAppeal,
			Burn,
			Soldout,
			Saletotal,
			newTransfer,
			PutOnSale,
			AuctionInSure,
			AuctionAban,
			AuctionIsSure
		},
		name: "MyCardContent",
		props: {
			value: {
				type: Object
			}
		},
		data: function() {
			return {
				type:"collectionBurn",
				Appeal: false,
				Transfer: false,
				openputonsale: false,
				Burning: false,
				Sold: false,
				Total: false,
				Saleout: false,
				openaban: false,
				openisdeal: false,
				opendeal: "",
				now: "",
				start: "",
				end: "",
				insure: "",
				day: 0,
				hour: 0,
				minutes: 0,
				second: 0,
				seconds: "",
				hh: 0,
				mm: 0,
				ss: 0,
				moreseconds: "",
				address: "",
				tokenId:'',
				auctionNo: "",
				imgUrl: "",
				name: "",
				level: 1,
				balance: "",
				supplyLimit: "",
				avliableLenth: 0,
				minPrice: "",
				constructID: "",
				contractCollectionId: "",
				auctionStatus: '',
				 onSaleTokenIds: [],
       			 offSaleTokenIds:[],
				 AppealType:""
			};
		},
		mounted() {
			this.Time(); //调用定时器
			this.address = this.value.contractAddress;
			this.tokenId = this.value.tokenId
			this.auctionNo = this.value.auctionNo;
			this.imgUrl = this.value.imgUrl;
			this.name = this.value.name;
			this.constructID = this.value.id;
			if(this.value.collectionType&&this.value.collectionType==1){
				this.contractCollectionId=this.value.contractCollectionId;
			}
			
			this.offSaleTokenIds.push(this.value.tokenId);
			this.onSaleTokenIds.push(this.value.tokenId);
			this.supplyLimit = 1;
			this.auctionStatus = this.value.auctionStatus;
			
		},
		methods: {
			getTime() {
				this.now = Date.parse(new Date());
				this.start = this.value.auctionStartTime;
				this.end = this.value.auctionEndTime;
				// this.start = Date.parse(new Date("2022-01-10 14:18:00"));
				// this.end = Date.parse(new Date("2022-01-10 14:19:00"));
				this.insure = 1000 * 60 * 60 * 48 + this.end; //48小时 暂时1小时

				if (this.value.auctionStatus == 4 || this.value.auctionStatus == 0 || this.value.auctionStatus == 1) {
					if (this.value.auctionNoBid && this.value.auctionNoBid == true) {
						this.insure = 1000 * 60 * 60 * 1 + this.end; //1小时 流拍1小时
					}


					if (this.now < this.start) {
						this.seconds = (this.start - this.now) / 1000;
					} else if (this.now < this.end) {
						this.seconds = (this.end - this.now) / 1000;
					} else if (this.now < this.insure) {
						this.moreseconds = (this.insure - this.now) / 1000;
					}
				}
				if (this.value.auctionStatus == 5) {
					if (this.insure > this.now) {
						this.moreseconds = (this.insure - this.now) / 1000;
					} else {
						this.moreseconds = 0;
					}

				}
			},
			// 天 时 分 秒 格式化函数
			countDown() {
				if (this.seconds > 0) {
					let d = parseInt(this.seconds / (24 * 60 * 60));
					let h = parseInt(((this.seconds / (60 * 60)) % 24) + d * 24);
					this.hour = h < 10 ? "0" + h : h;
					let m = parseInt((this.seconds / 60) % 60);
					this.minutes = m < 10 ? "0" + m : m;
					let s = parseInt(this.seconds % 60);
					this.second = s < 10 ? "0" + s : s;
				}
			},
			moreCountDown() {
				if (this.moreseconds > 0) {
					let d = parseInt(this.moreseconds / (24 * 60 * 60));
					let h = parseInt(((this.moreseconds / (60 * 60)) % 24) + d * 24);
					this.hh = h < 10 ? "0" + h : h;
					let m = parseInt((this.moreseconds / 60) % 60);
					this.mm = m < 10 ? "0" + m : m;
					let s = parseInt(this.moreseconds % 60);
					this.ss = s < 10 ? "0" + s : s;
				}
			},
			//定时器没过1秒参数减1
			Time() {
				setInterval(() => {
					if (this.seconds > 0) {
						this.seconds -= 1;
						this.countDown();
					} else if (this.moreseconds > 0) {
						this.moreseconds -= 1;
						this.moreCountDown();
					} else {
						this.getTime();
					}

				}, 1000);
			},
			AbanBtn() {
				this.openaban = true;
			},
			openAban(e) {
				this.openaban = e;
			},
			iSdealBtn() {
				this.openisdeal = true;
			},
			openisDeal(e) {
				this.openisdeal = e;
			},
			dealBtn() {
				this.opendeal = true;
			},
			openDeal(e) {
				this.opendeal = e;
			},
			//申诉弹层
			AppealBtn() {
				this.Appeal = true;
			},
			Open(e) {
				this.Appeal = e;
			},
			//转送弹层
			TransferBtn() {
				this.Transfer = true;
			},
			opentransfer(e) {
				this.Transfer = e;
			},
			//开始售卖/拍卖弹层
			putOnSaleBtn() {
				this.openputonsale = true;
			},
			openPutOnSale(e) {
				this.openputonsale = e;
			},
			//销毁弹层
			burnBtn() {
				this.Burning = true;
			},
			openBurn(e) {
				this.Burning = e;
			},
			// 结束售卖弹层
			EndSaleBtn() {
				this.Sold = true;
			},
			openSold(e) {
				this.Sold = e;
			},
			//销售统计弹层
			saleTotalBtn() {
				this.Total = true;
			},
			openTotal(e) {
				this.Total = e;
			},
			// 卖完弹层
			saleoutBtn() {
				this.Saleout = true;
			},
			openSaleout(e) {
				this.Saleout = e;
			},
			promptBoxCommit(v) {
				if (v != 3) {
					this.Transfer = false;
				}
			},
			workClick() {
				if (!this.value.auctionNo) {
					this.$router.push({
						name: "Saledetail",
						query: {
							id: this.value.id
						}
					});
				} else {
					this.$router.push({
						name: "Detail",
						query: {
							auctionNo: this.value.auctionNo
						}
					});
				}
			}
		}
	};
</script>

<style lang='scss' scoped>
	.text--primary-rd {
		color: #270645;
	}

	.text--secondary-rd {
		color: #766983;
	}

	.text--info {
		font-size: 10px !important;
		line-height: 1;
		letter-spacing: 0px;
	}

	.text--title {
		display: inline-block;
		font-size: 20px;
		line-height: 24px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 230px;
	}

	.text--sub-title {
		font-size: 12px;
		line-height: 1;
	}

	.text--number {
		font-size: 15px;
		line-height: 1;
		 font-weight: bold !important;
	}

	.v-divider {
		border-color: #e3e3e3;
	}

	.satrtsale {
		background-color: #270645 !important;
		border-radius: 18px;
		font-family: "\5FAE\8F6F\96C5\9ED1";
		font-size: 13px;
		font-weight: bold;
		font-stretch: normal;
		line-height: 100px;
		letter-spacing: 0px;
		color: #ffffff;
		text-align: center;
	}

	.btnbox {
		width: 119px;
		height: 35px;
		box-sizing: border-box;
		padding: 2px;
		background-image: linear-gradient(0deg,
				#d202fd 0%,
				#a538fe 26%,
				#776eff 51%,
				#01facd 100%);
		border-radius: 17px;

		.rd {
			width: 100%;
			height: 100%;
			border: none;
			border-radius: 17px;
			background: #fff;
			font-family: "\5FAE\8F6F\96C5\9ED1";
			font-size: 13px;
			font-weight: bold;
			font-stretch: normal;
			letter-spacing: 0;
			color: #270645;
		}
	}

	.sold_out {
		width: 115px;
		height: 36px;
		background-image: linear-gradient(91deg,
				#d300fd 0%,
				#7471ff 58%,
				#00fdcf 100%),
			linear-gradient(#ffffff, #ffffff);
		background-blend-mode: normal, normal;
		border-radius: 18px;
		text-align: center;
		font-family: "\5FAE\8F6F\96C5\9ED1";
		font-size: 13px;
		font-weight: bold;
		font-stretch: normal;
		line-height: 36px;
		letter-spacing: 0px;
		color: #ffffff;
		position: absolute;
		right: -13px;
	}

	.auction_out {
		width: 115px;
		height: 36px;
		background-image: linear-gradient(91deg,
				#d300fd 0%,
				#7471ff 58%,
				#00fdcf 100%),
			linear-gradient(#ffffff, #ffffff);
		background-blend-mode: normal, normal;
		border-radius: 18px;
		text-align: center;
		font-family: "\5FAE\8F6F\96C5\9ED1";
		font-size: 13px;
		font-weight: bold;
		font-stretch: normal;
		line-height: 36px;
		letter-spacing: 0px;
		color: #ffffff;
		position: absolute;
		right: -13px;
		bottom: 19px;
	}

	.dialodup5 {
		width: 281px;
		height: 281px;
		background-color: rgba(0,0,0, 0.2);
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;

		.tip {
			font-family: "\5FAE\8F6F\96C5\9ED1";
			font-size: 13px;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0px;
			color:#ffffff;
			margin-top: 121px;
			text-align: center;
		}

		.timer {
			font-family: "\5FAE\8F6F\96C5\9ED1";
			font-size: 15px;
			font-weight: bold;
			font-stretch: normal;
			letter-spacing: 0px;
			color: #ffffff;
			margin-bottom: 32px;
			text-align: center;
		}

		.drawbtn {
			width: 244px;
			height: 36px;
			background-color: #270645;
			border-radius: 17px;
			margin-left: 18px;
			font-family: "\5FAE\8F6F\96C5\9ED1";
			font-size: 13px;
			font-weight: bold;
			font-stretch: normal;
			line-height: 36px;
			text-align: center;
			letter-spacing: 0px;
			color: #ffffff;
			margin-top: 210px;
		}

		.dealbtn {
			width: 244px;
			height: 36px;
			background-color: #270645;
			border-radius: 17px;
			margin-left: 18px;
			font-family: "\5FAE\8F6F\96C5\9ED1";
			font-size: 13px;
			font-weight: bold;
			font-stretch: normal;
			line-height: 36px;
			text-align: center;
			letter-spacing: 0px;
			color: #ffffff;
		}
	}

	.dialodup4 {
		width: 281px;
		height: 281px;
		background-color: rgba(255, 255, 255, 0.9);
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;

		.tip {
			font-family: "\5FAE\8F6F\96C5\9ED1";
			font-size: 13px;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0px;
			color: #270645;
			margin-top: 105px;
			margin-left: 28px;
			margin-bottom: 9px;
		}

		.timer {
			font-family: "\5FAE\8F6F\96C5\9ED1";
			font-size: 15px;
			font-weight: bold;
			font-stretch: normal;
			letter-spacing: 0px;
			color: #270645;
			margin-bottom: 32px;
			text-align: center;
		}

		.btn {
			.box {
				width: 115px;
				height: 36px;
				box-sizing: border-box;
				padding: 2px;
				background-image: linear-gradient(0deg,
						#d202fd 0%,
						#a538fe 26%,
						#776eff 51%,
						#01facd 100%);
				border-radius: 18px;

				.abanbtn {
					width: 100%;
					height: 100%;
					border: none;
					border-radius: 20px;
					background: #fff;
					font-family: "\5FAE\8F6F\96C5\9ED1";
					font-size: 15px;
					font-weight: bold;
					font-stretch: normal;
					letter-spacing: 0;
					color: #270645;
				}
			}

			.dealbtn {
				width: 116px;
				height: 36px;
				background-color: #270645;
				border-radius: 17px;
				margin-left: 18px;
				font-family: "\5FAE\8F6F\96C5\9ED1";
				font-size: 13px;
				font-weight: bold;
				font-stretch: normal;
				line-height: 36px;
				text-align: center;
				letter-spacing: 0px;
				color: #ffffff;
			}
		}
	}

	.sale {
		background-color: #270645 !important;
	}
	 .haveAppeal {
      background-image: linear-gradient(#766983, #766983),
            linear-gradient(#270645, #270645);
   }
</style>
