import Vue from "vue";
import App from "./App.vue";
 //import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "@/plugins/i18n";

import * as filters from "./filters";

import "@/styles/index.scss";
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'

import DatetimePicker from "vuetify-datetime-picker";
import toastInit from '@/components/toast';
import { init as extendInit} from "@/utils/extend";
extendInit();
import VueGtag from 'vue-gtag'
import { addIRISNetwork } from "@/keplr/wallet.js";
addIRISNetwork();

Vue.use(VueGtag, {
  config: { id: 'G-SVCSEKJLZ3' }
},router)

import {timestampToDate,timestampToDateTime,timestampToTimeHour,timestampToTimeMinute,timestampToTimeSecond } from "@/utils/helper.js"
Vue.prototype.timestampToDate = timestampToDate;
Vue.prototype.timestampToDateTime = timestampToDateTime;
Vue.prototype.timestampToTimeHour = timestampToTimeHour;
Vue.prototype.timestampToTimeMinute = timestampToTimeMinute;
Vue.prototype.timestampToTimeSecond = timestampToTimeSecond;

// import rsa from "@/utils/RSAEncrypt.js";
// Vue.prototype.$rsa = rsa;

window.eventBus = new Vue();//注册全局事件对象

// lj ## uptick全局引入。改动在切换币种事件调用
// import { init as keplrInit } from '@/keplr';
// keplrInit("UPTICK");

// const uptick1155 = require('./metaMask/evm/handler/uptick1155.js');
// (async ()=>{
//     await uptick1155.mint(126,100,"0x1234");
// })()



// 自定义全局组件 START
import uToast from "@/components/uToast.vue";
Vue.component("uToast", uToast);

// import basePage from "@/layout/BasePage.vue";
import uComponents from "@/components/uComponents.vue"
Vue.component("uComponents", uComponents);

import promptBox from "@/components/PromptBox.vue"
Vue.component("promptBox", promptBox);

// 全局提示框
//     type:success,error
Vue.prototype.$toast = function(type, txt, timer) {
    return this.$refs.ucom.toast(type, txt, timer);
}
// 全局确认框
Vue.prototype.$confirm = function(txt, ps) {
    this.$refs.ucom.confirm(txt, ps);
}

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import messageTip from "./components/MessageTip"
messageTip(Vue);

toastInit(Vue, {
  property: '$toastFn'
})

// import {
//   initWallet
// } from "../src/keplr/uptick/wallet"

// 自定义全局组件 END

// import {
//   $postBodyJson
// } from './utils/request'

// import { 
//   issueDenomAndMint,
//  // mintNFT
//  //  tranferWithMemo, 
//  // transferNft,
//  //getMyBalance
// } from "./keplr/wallet";

// import { 
//   // getMatamaskWeb3,
//   tranferValueByMetaMask
// } from "./metaMask/wallet";
Vue.use(DatetimePicker);


Vue.use(VueVideoPlayer)

Vue.config.productionTip = false;

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

// import {
//   ibcTransferFromCosmos
// } from "./keplr/cosmos/wallet";

// import {
//   ibcTransferFromUptick
// } from "./keplr/uptick/wallet";

// import {
//   // convertFromERC20ToDemon
//   convertFromDemonToERC20
// } from "./keplr/demon/wallet";

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
  async mounted() {



    // 1.cosoms->uptick

    // const token = {
    //   denom: 'uatom',
    //   amount: '12000'
    // };
    // let curTime = new Date().getTime();
    // let receiver = "uptick17d0c8nedfzlytj0kcs80rh3fk8el58fvnw8pmw";
    // let timespan = (curTime + 360000) * 1000000
    // //let sender = "cosmos1gjmwgj0lduw0d75xggy3394ahq8fd2mncle8tn";
    // // channel-91

    // await ibcTransferFromCosmos(
    //     "transfer",
    //     "channel-449",
    //     token,
    //     receiver,
    //     timespan
    // );


    // 2.uptick->cosoms

    // let balance = await getMyBalance();
    // console.log(balance);

    // let address = await getAddress();
    // console.log(address);

    // let curTime = new Date().getTime();
    // let timespan = (curTime + 360000) * 1000000
    // let res = await ibcTransferFromUptick(
    //     'transfer',
    //     'channel-0',
    //     {
    //         denom: 'auptick',
    //         amount: 2000,
    //     },
    //     'cosmos1gjmwgj0lduw0d75xggy3394ahq8fd2mncle8tn',
    //     timespan 
    // );
    // console.log(res);
    

    // let res = await convertFromERC20ToDemon();
    // let res = await convertFromDemonToERC20();
    // console.log(res);

    // await initWallet();
    /////-----------------metaMask---------------
    // console.log("---------------------------xxl metaMask---------------------------");
    // await getMatamaskWeb3();

    // let toAddress = '0x4f2C793DB2163A7A081b984E6E8e2c504825668b';
    // let value = "0.1"
    // let chainId = 97
    // //export const tranferValueByMetaMask = async(toAddress,value,chainId,gasPrice=0,gasLimit=0x7a1200,data="0x") => {
    //   console.log("---------------------------xxl tranferValueByMetaMask---------------------------");
    // await tranferValueByMetaMask(toAddress,value,chainId);



    /////-----------------keplr---------------
    // step0    
    // console.log("xxl step 0 issueDenom");
    // let orgName = 'orgName'
    // let sender = "iaa1xhhf3a80n7zamu32dyku77lactynx9sgs0vvz4";
    // //let sender = "iaa1t0j2ctvg2km2z6kjnaltd03wt2uvdae3u6xy3m";
    // let addminAddress = "iaa1kwkcczt4s0cxnl27xlldnu4d5jawjtu5mqrkt2";

    // let uri = "http://xxl"
    // let data = new String(Math.random())
    // let amount = 2;  
    // let recipient = "iaa1kwkcczt4s0cxnl27xlldnu4d5jawjtu5mqrkt2";

    // let fee = 0.001
    // let tx = await issueDenomAndMint(
    //   orgName,
    //   sender,
    //   recipient,
    //   uri,
    //   data,
    //   amount,
    //   fee,
    //   addminAddress
    // );
    // console.log(tx);

    // export async function issueDenomAndMint(
    //   orgName,
    //   sender,
    //   recipient,
    //   uri,
    //   data,
    //   amount,
    //   fee,
    //   adminAddress,
    // ){

    // step1 
    // console.log("xxl step 1 mintNFT");
    // let recipient = "iaa1kwkcczt4s0cxnl27xlldnu4d5jawjtu5mqrkt2";
    // let denomId = "station"
    // let denomName = "30662970"
    // let uri = "http://xxl"
    // let data = new String(Math.random())
    // let amount = 2;  <--- amount
    // let fee = 0.001
  

    // export async function mintNFT(
    //   recipient,
    //   denomId,
    //   denomName,
    //   uri,
    //   data,
    //   amount,
    //   fee,
    //   adminAddress
    // ){

    // step2 
    // console.log("xxl step 1 tranferWithMemo");
    // let memo = "xxl memo";
    // let amount = 1;
    // let fee = 0.001;
    // let addminAddress = "iaa1kwkcczt4s0cxnl27xlldnu4d5jawjtu5mqrkt2";

    // await tranferWithMemo(
    //   memo,amount,fee,addminAddress
    // )
    //export async function tranferWithMemo(memo,amount,fee){

    // step3 
    // console.log("xxl step 3 transferNft");
    // let nftId = "xxl12345";
    // let denomId = "station";
    // let sender = 'iaa1t0j2ctvg2km2z6kjnaltd03wt2uvdae3u6xy3m';
    // let recipient = 'iaa1kwkcczt4s0cxnl27xlldnu4d5jawjtu5mqrkt2'
    // let fee = 0.001;
    // let adminAddress = "iaa1kwkcczt4s0cxnl27xlldnu4d5jawjtu5mqrkt2";

    // // await transferNft(
    // //   memo,amount,fee,addminAddress
    // // )
    // await transferNft(nftId,denomId,sender,recipient,fee,adminAddress)

    // step4
    // let balance = await getMyBalance();
    // console.log(balance);


    //export async function transferNft(nftId,denomId,sender,recipient,fee,adminAddress){
    //export async function tranferWithMemo(memo,amount,fee){
    

    //. click commit 
    //1. get name,sender,fee(from interface),adminAddress(from interfer)
    //2. issueDenom
    //3. recipient(sender),denomId,denomName,uri(choose),data="",amount,---
    //4. 创建接口
    //5. 后台状态


    // let url = "http://13.213.149.227:9993/api/1/merchant/onsale";
    // let url =  "http://localhost:8084/api/1/merchant/onsale";
    // let data = {
    //   "callBack":"http://13.213.149.227:9991/api/1.0/fee/onSale.do?receiver=iaa1szkhaq37vsyhu69kgscxmn0swfl7atxkzql4dg&amount=0.1&contractAddress=uptick1cbda80821770e3938b7c7be6dd0bd67&payAddress=iaa15k328z4v65qd7tp82fcvrymfmfn6lnkq5ep30c",
    //   "denom":"uptick1cbda80821770e3938b7c7be6dd0bd67",
    //   "labels":[{"price":1000000,"tokenId":"uptick6dc6b4ca29a744bda0ca8b7a74ae6005"}],
    //   "owner":"iaa15k328z4v65qd7tp82fcvrymfmfn6lnkq5ep30c"
    // }
    
    // let result = await $postBodyJson(url,data);
    // console.log("xxl result is ::");
    // console.log(result);



  },
}).$mount("#app");
