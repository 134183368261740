var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card-title',{staticClass:"d-block text--title pt-4 px-5 pb-2 text-truncate",staticStyle:{"max-width":"100%"}},[_vm._v(_vm._s(_vm.value.name))]),_c('v-card-text',{staticClass:"d-flex flex-row align-center pb-3 px-5"},[_c('Avatar',{attrs:{"size":"40","did":_vm.value.owner,"hash":_vm.value.profilePhoto,"imgType":"middlev","grade":_vm.value.grade,"showAvatarInfo":""}}),_c('div',{staticClass:"ml-4 text-truncate",staticStyle:{"max-width":"100%"}},[_vm._v(" "+_vm._s(_vm.value.issuerName ? _vm.value.issuerName:this.value.owner.substr(0, 6) + "..." + this.value.owner.substr(-6))+" ")])],1),_c('v-divider'),_c('v-card-text',{staticClass:"d-flex flex-row justify-space-between px-5 pt-6 pb-7"},[(!_vm.isStart)?[_c('div',[_c('div',{staticClass:"text--sub-title text--secondary-rd"},[_vm._v(" "+_vm._s(_vm.$t("popupAuctionStartingBid"))+" ")]),_c('div',{staticClass:"d-flex flex-row mt-2"},[_c('div',[_c('v-img',{attrs:{"src":require('@/assets/icons/' +
                  _vm.getChainByName(_vm.value.coinDenom)+
                  '.png'),"width":"22px","height":"22px"}})],1),_c('div',{staticClass:"ml-2 text--number text--secondary-rd font-weight-medium",staticStyle:{"line-height":"22px"}},[_vm._v(" "+_vm._s(_vm.value.lastBidPrice)+" ")])])]),_c('div',[_c('div',{staticClass:"text--sub-title text--secondary-rd"},[_vm._v(" "+_vm._s(_vm.$t("workCardStartingin"))+" ")]),_c('div',{staticClass:"mt-2"},[_c('div',{staticClass:"text--number text--secondary-rd font-weight-medium"},[_vm._v(" "+_vm._s(_vm.hour)+"h "+_vm._s(_vm.minutes)+"m "+_vm._s(_vm.second)+"s ")])])])]:[(_vm.value.auctionStatus!=6)?_c('div',[_c('div',{staticClass:"text--sub-title text--primary-rd"},[_vm._v(" "+_vm._s(_vm.$t("workCardCurrentBid"))+" ")]),_c('div',{staticClass:"d-flex flex-row mt-2"},[_c('div',[_c('v-img',{attrs:{"src":require('@/assets/icons/' +
                  _vm.getChainByName(_vm.value.coinDenom)+
                  '.png'),"width":"22px","height":"22px"}})],1),_c('div',{staticClass:"ml-2 text--number text--primary-rd font-weight-medium",staticStyle:{"line-height":"22px"}},[_vm._v(" "+_vm._s(_vm.value.lastBidPrice)+" ")])])]):_vm._e(),(_vm.value.auctionStatus==6)?_c('div',[_c('div',{staticClass:"text--sub-title text--primary-rd"},[_vm._v(" "+_vm._s(_vm.$t("Final_price"))+" ")]),_c('div',{staticClass:"d-flex flex-row mt-2"},[_c('div',[_c('v-img',{attrs:{"src":require('@/assets/icons/' +
                  _vm.getChainByName(_vm.value.coinDenom)+
                  '.png'),"width":"22px","height":"22px"}})],1),_c('div',{staticClass:"ml-2 text--number text--primary-rd font-weight-medium",staticStyle:{"line-height":"22px"}},[_vm._v(" "+_vm._s(_vm.value.lastBidPrice)+" ")])])]):_vm._e(),_c('div',[_c('div',{staticClass:"text--sub-title text--primary-rd"},[_vm._v(" "+_vm._s(_vm.$t("workCardEndingin"))+" ")]),_c('div',{staticClass:"mt-2"},[_c('div',{staticClass:"text--number text--primary-rd font-weight-medium"},[_vm._v(" "+_vm._s(_vm.hour)+"h "+_vm._s(_vm.minutes)+"m "+_vm._s(_vm.second)+"s ")])])])]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }