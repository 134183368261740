<template>
   <div id="container">
      <v-card :class="{card:true,mobile:isMobile}">
         <img class="clo" @click="closeDialog" src="@/assets/btn_close.png" alt="">
          <div class="top">
            <h3 v-if="appealType == 'commonAppeal'">{{ $t('popupNftAppealNFTAppeal') }}</h3>
               <h3 v-else-if="appealType == 'folderAppeal'">{{ $t('popupNftAppealFolderAppeal') }}</h3>
            <h3 v-else>{{ $t('popupNftAppealCollectAppeal') }}</h3>
            <p v-if="appealType == 'commonAppeal'">{{ $t('popupNftAppealYourNFT') }}</p>
              <p v-else-if="appealType == 'folderAppeal'">{{ $t('popupFolderAppealYourNFT') }}</p>
             <p v-else>{{ $t('popupCollectAppealYourNFT') }}</p>
         </div>
         <div class="ti-tx">
            <span class="title mt-15">{{$t('popupAccountAppealDescription')}}</span>
            <v-textarea class="txt" label=""
			 :placeholder="this.$t('popupAccountAppealPleaseDescribe')"
                        v-model="firsttxt" 
                         ref="firsttxt"
                        :rules="descRules"
                         auto-grow outlined rows="9" 
                        row-height="18" counter="500"></v-textarea>
         </div>
        <div class="upload" style="margin-left: 52px">  <!-- class="mx-13 d-flex flex-column" -->
			<div style="display: flex;">
				  <div class="uploadfile" v-for="(item,index) in uploadfile" :key="index">
						<img class="img" :src="item" />
					  <img class="icon" src="@/assets/btn_close.png" @click="imgRemove(index)" />
				  </div>

				  <div class="uploadfile" v-if="uploadfile.length<3">
					  <FIleInput class="FIleInput" @change="change" :key="fileInputKey"  accept=".png,.jpg">
						<v-icon color="black">mdi-plus</v-icon>
					  </FIleInput>
				  </div>
			</div>
            <!-- <div class=" d-flex flex-row">
               <Work v-for="(url, index) in uploadFileUrls" :key="index" :src="url" width="101" height="101" contain/>
               <FIleInput @change="change"  multiple>
                  <div
                     class="d-flex flex-column justify-center align-center"
                     style="width: 101px; height: 101px; border: dashed 2px #e3e3e3"
                  >
                     <template>
                        <v-icon>add</v-icon>
                     </template>
                  </div>
               </FIleInput>
            </div> -->
            <div class="mt-2">{{$t('popupNftAppealLimit')}}</div>
        </div>

         <div class="emailinp">
            <span class="title">{{$t('popupNftAppealEmail')}}</span>
            <v-textarea label="" v-model="emailValue"
             ref="emailValue"
             :rules="[rule.emailRules, rule.email]"
              auto-grow 
              outlined 
              rows="2" 
              row-height="18"></v-textarea>
         </div>
         <div class="footer">
            <button class="sub" submit @click="subCommit">{{$t('popupTransferCommit')}}</button>
         </div>
      </v-card>
       <uComponents  ref="ucom"></uComponents>
   </div>
</template>

<script>
import FIleInput from "@/components/FIleInput.vue";
// import Work from '@/components/work/index.vue';
import api from "@/api"
import { getFileSrc } from "@/utils/file";
const UPLOAD_KEY = "UPLOAD";
   //NFT申诉
   export default {
      name: "nftAppeal",
       components: { FIleInput },
      data: () => ({
         newOpen: true,
         firsttxt: '',
         rules: [v => v.length <= 500 || 'Max 500 characters'],
         emailValue: '',
         uploadFileUrls: [],
     descRules: [(v) => !!v || "description is required."],
         rule: {
        emailRules: value => !!value || 'Email is required.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },

		 uploadfile: [],
		 fileInputKey:0,
      }),
   props: {
    constructID: {
      type: Number,
    },
    appealType:{
       type:String
    },
    parentValue:{
       type:Object
    }
  },
      computed: {
         isMobile: function () {
            return this.$vuetify.breakpoint.mobile;
         },
      },
      mounted(){
         console.log("33333333",this.appealType,this.parentValue)
      },
      methods: {
               verify() {
        let emailVer = this.$refs.emailValue.validate(true);
        let nameVer = this.$refs.firsttxt.validate(true);
        !nameVer ? this.$refs.firsttxt.focus() : '';
        !emailVer ? this.$refs.emailValue.focus() : "";
        return emailVer && nameVer ;
      },
         //提交转送数据
        async subCommit() {
          if(!this.verify()) {
          return;
        }
        let res;
		let params = {
		   complainants:this.$store.state.did,
		   complaintType:2,
		   content:encodeURIComponent(this.firsttxt),
		   email:this.emailValue,
		 picture: this.uploadfile.join(',')
		}
        if(this.appealType == "collectAppeal")
        {
		    params.contractCollectionId=this.parentValue.contractCollectionId
        }else if(this.appealType == "folderAppeal")
        {
     
          params.contractCollectionId=this.parentValue.contractCollectionId
        

        }else{
			if(this.parentValue&&this.parentValue.tokenId){
				  params.tokenId=this.parentValue.tokenId;
			}else{
				  params.contractId=this.parentValue.contractId;
			}
        }
            res =  await api.home.nftAppeal(params);
            if(res.success == true)
        {
           this.$toast("success", this.$t("success")).then(() => {
              this.$emit("getOpen", false)
          })
        }else{
           this.$toast("error", this.$t(res.msg));

        }
          console.log(res)

         },
         //关闭弹框，发送open值
         closeDialog() {
            this.newOpen = false;
            this.$emit('getOpen', this.newOpen);
         },
         //  change(files) {
         //    console.log('files', files)
         //    this.uploadFileUrls = this.uploadFileUrls.concat(files.map(URL.createObjectURL)).splice(-3, 3);
         //    console.log('this.uploadFileUrls', this.uploadFileUrls)
         // },
		async change(file) {
         if (file && (file.type !== 'image/jpeg' && file.type !== 'image/png')) {
            return;
         }
		  console.log("xxl file 1111 :");
		  let res = await api.image.uploadImage(file);
		  // console.log(file.type)  //video/mp4
		  this.fileInputKey++;

		  // console.log(res)
		  let url = await getFileSrc(UPLOAD_KEY, res.data.data);
		  this.uploadfile.push(url);
		},

		imgRemove(idx) {
			this.uploadfile.splice(idx, 1);
			// console.log(idx);
		}
      }
   }
</script>

<style lang="scss" scoped>
   .dia {
      overflow: hidden;
   }

   .card {
      display: flex;
      flex-direction: column;
      min-height: 769px;
      width: 600px;

      .clo {
         position: absolute;
         right: 10px;
         top: 10px;
         width: 30px;
         height: 31px;
         background-color: #fff;
         border-radius: 50%;
         margin-right: 0;
         cursor: pointer;
      }

      .top {
         h3 {
            margin: 41px 0 17px 52px;
           font-family:Helvetica;
            font-size: 25px;
            color: #270645;
         }

         p {
            word-wrap: break-word;
            margin: 0 52px;
            width: 494px;
            height: 65px;
            font-family:Helvetica;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 28px;
            letter-spacing: 0;
            color: #766983;
         }
      }

      .ti-tx {
         margin: 25px 52px;

         .txt {
            width: 500px;
            height: 166px;
            background-image: linear-gradient(#ffffff, #ffffff),
            linear-gradient(#f8f6fd, #f8f6fd);
            background-blend-mode: normal, normal;
         }
      }

      .upload {
         .uploadfile {
            margin-bottom: 14px;
            width: 101px;
            height: 101px;
            background-image: linear-gradient(#ffffff, #ffffff),
            linear-gradient(#f8f6fd, #f8f6fd);
            background-blend-mode: normal, normal;
            border-radius: 1px;
            border: solid 2px #e3e3e3;

			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			margin-right: 30px;
			cursor: pointer;
			.FIleInput {
					  width: 100%;
					  height: 100%;
					  .v-icon {
						  width: 100%;
						  height: 100%;
						  padding: 25px;
					  }

			}
			.icon {
					  width: 20px;
					  height: 20px;
					  border-radius: 50%;
					  position: absolute;
					  top: -10px;
					  left: 30px;
					  background-color: #fff;
					  z-index: 99;
					  cursor: pointer;
			}
			.img {
					  width: 100%;
					  height: 100%;
					  margin: 0;
			}
         }

         .file-limit {
            width: 137px;
            height: 13px;
            font-family:Helvetica;
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 100px;
            letter-spacing: 0px;
            color: #766983;
         }
      }

      .emailinp {
         margin: 22px 52px;
      }

      .footer {
         display: flex;
         flex-direction: column;
         text-align: center;
         margin-bottom: 50px;

         .sub {
            margin: 0 auto;
            width: 450px;
            height: 51px;
            background-image: linear-gradient(90deg, #d300fd 0%, #a439fe 26%, #7471ff 52%, #00fdcf 100%),
            linear-gradient(#270645, #270645);
            background-blend-mode: normal, normal;
            border-radius: 25px;
            opacity: 0.9;
           font-family:Helvetica;
	         font-size: 20px;
            color:white ;
         }
      }

      &.mobile {
         .top {
            width: 100%;

            p {
               width: 80%;
            }
         }

         .footer {
            .sub {
               width: 70%;
            }
         }
      }

   }


   img {
      display: inline-block;
      width: 126px;
      height: 126px;
      background-color: #8a73ff;
      border-radius: 5px;
      margin: 1px 22px 10px 52px;
   }

   span {
      display: flex;
      align-items: center;
      font-family:Helvetica;
      color: #270645;
   }

   .title {
     font-family:Helvetica;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      color: #270645;
   }


</style>


