<template>
<div class="system align-center" :class="{ mobile: isMobile }">

    <div class="top">
        <Logo height="63px"/>
        <div class="uptick" v-if="!isMobile">Uptick NFT Marketplace on IRISnet | Upgrade Notification</div>
          <div class="uptick" v-if="isMobile">Uptick NFT Marketplace on IRISnet </div>
          <div class="mobileuptick" v-if="isMobile">Public Testing Phase Complete</div>
        <div class="option">
        <div class="des">Uptick NFT Marketplace on IRISnet is being upgraded.   </div>
        <br />
         <div class="des">During March 1st @11:00-14:00 UTC+8, all functions in the market will be unavailable, please be informed. </div>
   
        </div>
      
    </div>

</div>



</template>

<script>
   import Logo from '@/components/logo/index.vue';

    export default {
        name: 'systemMaintain',
        components: {
           Logo
        },
        data: function() {
            return {
              
            };
        },
        computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
        
    };
</script>

<style lang="scss" scoped>
  .system{  
    width: 100%;
    height: 100%;
    border: 1px solid white;
    background: url(../../src/assets/image/bg01.gif);
    background-size:100% 100%;
  
        //    background-color: rgb(255, 255, 255,0.5);



// .top1{
//      width: 100%;
//     // height: 100%;
//            background-color: rgb(255, 255, 255,0.7);

// }
    .top{
       width: 100%;
       margin: 200px auto 0; 
       text-align: center;
    //    background-color: rgb(255, 255, 255,0.5);
    //    border: 1px solid red;
    .uptick{
	font-family: Helvetica;
	font-size: 36px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 40px;
	letter-spacing: 0px;
    color: #7800f4;
    margin-top: 69px;
    }
    .mobileuptick{
        font-family: Helvetica;
	font-size: 22px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 40px;
	letter-spacing: 0px;
    color: #7800f4;
   margin-bottom: 30px;
   margin-top: -25px;
    }
    .option{
        margin-top: 60px;
        margin-bottom: 60px;
         .des{
        width: 990px;
    margin: 0 auto;
	font-family: Helvetica;
	font-size: 28px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 40px;
	letter-spacing: 0px;
    color: #270645;
    text-align: left;
    }
    }
   
    }
    

    &.mobile{
        .top{
          margin: 50px auto 0;   
        }
        .uptick{
            min-width: 300px;
            margin: 20px auto;
            font-size: 22px;
        }
        .option{
            margin-top: 0px;
            .des{
                width: 0px;
                min-width: 325px;
                font-size: 15px;
            }
        }

    }
  }

</style>


