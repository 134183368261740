<template>
  <v-list nav dense :light="false">
    <v-list-item-group mandatory color="#fff" >
      <v-list-item :to="{ name: 'Home'}" @click="click" class="mt-5">
        <v-list-item-content>
          <v-list-item-title  style="font-size:20px;line-height:unset;" v-text="$t('headerNavHome')" ></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
       <v-list-item :to="{ name: 'Marketplace'}" @click="click" class="mt-5">
        <v-list-item-content>
          <v-list-item-title  style="font-size:20px;line-height:unset;" v-text=" $t('headerNavMarketplace')"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
        <v-list-item :to="{ name: 'LaunchPad'}" @click="click" class="mt-5">
        <v-list-item-content>
          <v-list-item-title  style="font-size:20px;line-height:unset;" v-text="$t('Collections')"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
         <v-list-item :to="{ name: 'Promo'}" @click="click" class="mt-5">
        <v-list-item-content>
          <v-list-item-title  style="font-size:20px;line-height:unset;" v-text="$t('headerNavPromo')"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
           <v-list-item :to="{ name: 'Lottery'}" @click="click" class="mt-5">
        <v-list-item-content>
          <v-list-item-title  style="font-size:20px;line-height:unset;" v-text="$t('headerNavPool')"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
         <!-- <v-list-item :to="{ name: 'Competition'}" @click="click" class="mt-5">
        <v-list-item-content>
          <v-list-item-title style="font-size:20px;line-height:unset;" v-text="$t('headerNavContest')"></v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
        <v-list-item :to="{ name: 'Download'}" @click="click" class="mt-5">
        <v-list-item-content>
          <v-list-item-title style="font-size:20px;line-height:unset;" v-text="$t('popupPayDownload')"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: "DropSelectNav",
  props: {
      value: Boolean
  },
  methods: {
   click: function() {
       this.$emit('input', !this.value)
       console.log(',,,,,,,,,', !this.value)
   }
  },
};
</script>

<style lang="scss" scoped>
.v-list-item.v-item--active {
  background: $primary;
  &::v-deep .v-list-item__title {
		color: #fff;
	}
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: $primary;
}
</style>
