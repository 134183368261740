<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        src="@/assets/btn_close.png"
        alt=""
      />
      <div class="top">
        <div class="d-flex flex-row">
          <h3>{{ $t("headerNavAuctions") }}</h3>
          <img
            class="rule"
            @click="openRules"
            src="@/assets/auction_rules.png"
            alt=""
            title="Viewing auction rules"
          />
        </div>
        <h3 class="pupils">{{ name }}</h3>
      </div>
      <div class="form">
        <div class="ti-in">
        <div class="forms mb-3">
          <div class="title mb-lg-3 mb-3">Token</div>
          <ChainSelectInput @change="chainChange"></ChainSelectInput>
      </div>
          <span class="title">{{ $t("auctionStartBid") }}</span>
          <span class="subtitle">{{ $t("BidsBelow") }}</span>
          <v-text-field
            v-model="startBid"
              ref="SalePrice"
             :rules="[rules.errorPrice]"
            outlined
           :suffix="chain"
            type="input"
            onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,4})?).*$/g, '$1')"
            maxlength="10"
          ></v-text-field>
        </div>
      </div>
      <div class="form">
        <div class="ti-in">
          <span class="title">{{ $t("auctionReservePrice") }}</span>
          <span class="subtitle">{{ $t("afterTheAuction") }}</span>
          <v-text-field
          ref="ReserveBid"
            v-model="ReserveBid"
          :rules="[rules.errorPrice1]"
            outlined
           :suffix="chain"
            type="input"
            onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,4})?).*$/g, '$1')"
            maxlength="10"
          ></v-text-field>
        </div>
      </div>
      <div class="form">
        <div class="ti-in">
          <span class="title">{{ $t("popupAuctionFixedPrice") }}</span>
          <span class="subtitle">{{ $t("buyoutPriceNotice") }}</span>
          <v-text-field
          ref="fixPrice"
            v-model="fixPrice"
            :rules="[rules.errorPrice1]"
            outlined
           :suffix="chain"
            type="input"
            onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,4})?).*$/g, '$1')"
            maxlength="10"
          ></v-text-field>
        </div>
      </div>
      <div class="form">
        <div class="ti-in">
          <span class="title">{{ $t("popupAuctionStartDate") }}</span>
          <v-datetime-picker label="" v-model="datetime">
            <template v-slot:dateIcon>
              <img
                style="
                  display: inline;
                  width: 24px;
                  height: 24px;
                  margin: 0;
                  background-color: transparent;
                "
                src="@/assets/icons/icon-calendar.png"
                alt
              />
            </template>
            <template v-slot:timeIcon>
              <img
                style="
                  display: inline;
                  width: 24px;
                  height: 24px;
                  margin: 0;
                  background-color: transparent;
                "
                src="@/assets/icons/icon-clock.png"
                alt
              />
            </template>
          </v-datetime-picker>
        </div>
      </div>
      <div class="form mt-lg-3">
        <div class="ti-in">
          <span class="title">{{ $t("popupAuctionEndDate") }}</span>
          <v-select
            :items="list"
            :label="this.$t('auctionTime')"
            v-model="TimeValue"
            item-text="text"
            item-value="value"
            solo
            any
          ></v-select>
          <span class="subtitle">{{ $t("ruleLast") }}</span>
        </div>
      </div>

      <div class="footer  mt-3 mb-9">
        <span class="price"
          >{{ $t("popupAuctionDefectPriceHandingFee") }}: {{ value1 }} IRIS</span
        >
<!--        <span class="price">Gas: {{ value2 }} IRIS</span>-->
        <v-btn class="sub" :class="{'sub-dis': isPay}" submit @click="subCommit">{{
          $t("popupTransferCommit")
        }}</v-btn>
        <span class="price"
          >{{ $t("popupNewTransferBalance") }}: {{ assetBalance }} IRIS</span
        >
      </div>
    </v-card>
    <v-snackbar
      v-model="isShowFalse"
      color="error"
      centered
      timeout="2000"
      style="opacity: 0.8"
      ><span style="color: white; font-size: 20px">{{
        falseTitle
      }}</span></v-snackbar
    >
    <PromptBox ref="promptBox"></PromptBox>
    <uComponents ref="ucom"></uComponents>
  </div>
</template>

<script>
import { addDate } from "../../../src/utils/helper";
import { transferNft,quiryTx } from "../../keplr/iris/wallet";
import { init as keplrInit, getBalances} from "../../keplr/wallet";
import { getMyBalance } from "../../keplr/iris/wallet";
import api from "@/api";
import PromptBox from "@/components/PromptBox.vue";
import ChainSelectInput from "@/components/ChainSelectInput.vue"
export default {
  name: "transfer",
  components: { PromptBox,ChainSelectInput },
  data: () => ({
    open: true,
    startBid: "",
    fixPrice: "",
    switch1: "false",
    value1: "0.1",
    value2: "0.075",
    assetBalance: "",
    TimeValue: "",
    ReserveBid: "",
    isShowFalse: false,
    falseTitle: "",
    isPay: false,
    // list: [
    //   {
    //     text: "24 hours",
    //     value: 1,
    //   },
    //   {
    //     text: "48 hours ",
    //     value: 2,
    //   },
    //   {
    //     text: "72 hours",
    //     value: 3,
    //   },
    // ],
    datetime: "",
    chain:'IRIS',
    coindenom:'uiris',
    flag:true,
        rules: {
      errorPrice: (value) => {
        // value = value.replace(/^\s+|\s+$/g,"")
        // const pattern = /^\d*(?:\.\d{0,4})?$/;
        // return pattern.test(value) || "verify price";
         const pattern = /^((\+?[0-9][0-9]{0,8})\.([0-9]{1,4})$)|^(\+?[0-9][0-9]{0,8})$/;
        return pattern.test(value) || "Out of price range";
      },
        errorPrice1:(value) => {
        if(value == ''){
          return true; 
        }
        //  const pattern =  /^(?:0|[1-9][0-9]?|100)(\.[0-9]{0,8})?$/;
        // return pattern.test(value) || "verify price";
        const pattern = /^((\+?[0-9][0-9]{0,8})\.([0-9]{1,4})$)|^(\+?[0-9][0-9]{0,8})$/;
        return pattern.test(value) || "Out of price range";
       },
     }
  }),
  props: {
    address: {
      type: String,
    },
    onSaleTokenIds: {
      type: Array,
    },
    offSaleTokenIds: {
      type: Array,
    },
    name: {
      type: String,
    },
     owerfrom: {
      type: String,
    },
    parentValue:{
      type:Object

    }
  },
  created() {
    this.datetime = new Date();
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
      list:function () {
      return  [
          {
            text: this.$t('auctionTime'),
            value: 1,
          },
          {
            text: this.$t('auctionTime48'),
            value: 2,
          },
          {
            text: this.$t('auctionTime72'),
            value: 3,
          },
        ];
      
    }
  },
  async mounted() {
    this.onLangChange();
    window.eventBus.$on('LangChange', this.onLangChange);
     let env =  process.env.VUE_APP_IRIS_CHAIN_ID
        if(env == 'nyancat-9'){
          this.coindenom = 'unyan'
        }
    this.TimeValue = 1;
    let balance = await getMyBalance();
    let mount = balance.format;
    this.assetBalance = mount;
  },
  methods: {
      verify() {
      	let saleVer = this.$refs.SalePrice.validate(true)
        !saleVer ? this.$refs.SalePrice.focus() :'';
        	let reserveVer = this.$refs.ReserveBid.validate(true)
        !reserveVer ? this.$refs.ReserveBid.focus() :'';
        	let fixPriceVer = this.$refs.fixPrice.validate(true)
      	!fixPriceVer ? this.$refs.fixPrice.focus() :'';
      	return saleVer && reserveVer && fixPriceVer  ;
      },
     sleep(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
      NumberMul(arg1, arg2) {
      var m = 0;
      var s1 = arg1.toString();
      var s2 = arg2.toString();
      try {
        m += s1.split(".")[1].length;
      } catch (e) {
        console.log(e)
      }
      try {
        m += s2.split(".")[1].length;
      } catch (e) {
        console.log(e)
      }

      return (
        (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
        Math.pow(10, m)
      );
    },
      async chainChange(chain,coinname) {
      console.log("3333333",chain,coinname)
      this.chain = coinname
      this.coindenom = chain.denom
      console.log("wxl --- coindenom",this.coindenom)
    // 获取iris 的余额
     keplrInit(1);
    this.accountBalance = await getBalances();
    console.log("keplrInit(2);",this.accountBalance)

     let aviIris = 0;
    if(this.accountBalance.balancesList){
      for(let i=0;i<this.accountBalance.balancesList.length;i++)
      {
        if(this.accountBalance.balancesList[i].denom == "uiris"){
          aviIris= this.accountBalance.balancesList[i].amount/1000000   
           this.assetBalance = aviIris
          
        }
      }
    };

 

    },
    //提交转送数据
    async subCommit() {
      console.log("222222",this.startBid,this.ReserveBid,this.fixPrice);

       if(!this.verify()) {
        return;
      }
      //this.startBid = Number(this.startBid)
      // this.fixPrice = Number(this.fixPrice)
      // this.ReserveBid = Number(this.ReserveBid)
      console.log(this.startBid,Number(this.ReserveBid),Number(this.fixPrice));

      try {
    
        if (!Number(this.startBid) || Number(this.startBid) <= 0) {
          this.$toast("error", this.$t("LowerThanStartBid"));
          return;
        }
        if (this.fixPrice != "") {
        //   this.startBid = Number(this.startBid)
        //  this.fixPrice = Number(this.fixPrice)
          if (Number(this.startBid) >= Number(this.fixPrice)) {
            this.$toast("error", this.$t("auctionPriceOnsaleError"));
            return;
          }
        }
        if(this.ReserveBid !== '' && this.fixPrice === '')
        {
        //   this.startBid = Number(this.startBid)
        //  this.ReserveBid = Number(this.ReserveBid)
          if(Number(this.startBid) >= Number(this.ReserveBid) )
          {
            this.$toast("error", this.$t("auctionPriceOnsaleError"));
            return;
          }

        }
        if(this.ReserveBid === '' && this.fixPrice !== '')
        {
        //   this.startBid = Number(this.startBid)
        //  this.fixPrice = Number(this.fixPrice)
          if(Number(this.ReserveBid) >= Number(this.fixPrice) )
          {
            this.$toast("error", this.$t("auctionPriceOnsaleError"));
            return;
          }


        }
        if(this.ReserveBid !== '' && this.fixPrice !== '')
        {
        //    this.startBid = Number(this.startBid)
        //  this.ReserveBid = Number(this.ReserveBid)
        //  this.fixPrice = Number(this.fixPrice)
         if(   Number(this.startBid) >= Number(this.ReserveBid) ||
          Number(this.ReserveBid) >= Number(this.fixPrice))
        {
          
            this.$toast("error", this.$t("auctionPriceOnsaleError"));
            return;
        }

        }
        if(Number(this.ReserveBid) != ''){
       this.$gtag.event('拍卖上架-设置Reserve price', { 'event_category': 'Click', 'event_label': '设置Reserve price' })
    }
    if(Number(this.fixPrice) != ''){
       this.$gtag.event('拍卖上架-设置Buy it now price', { 'event_category': 'Click', 'event_label': '设置Buy it now price' })
    }

        // if (
        //   this.startBid > this.ReserveBid ||
        //   this.ReserveBid > this.fixPrice
        // ) {
        //   this.$toast("error", this.$t("auctionPriceOnsaleError"));
        //   return;
        // }
        if (new Date() - this.datetime > 3600000) {
          this.datetime = null;
          // this.isShowFalse = true;
          //this.falseTitle = this.$t("LowerThanCurrentTime");
          this.$toast("error", this.$t("LowerThanCurrentTime"));
          return;
        }
         this.isPay = true;
        let startTimeStamp = parseInt(this.datetime.valueOf() / 1000);
        let endTimeStamp = addDate(startTimeStamp, this.TimeValue);
        console.log(this.address);
      
        console.log(this.datetime);
        console.log(this.ReserveBid);
        console.log(startTimeStamp);
        console.log(endTimeStamp);
        // get fee
        let res = await api.home.getFee();
         let denomId = this.parentValue.contractAddress;
        let sender = this.$store.state.did;
        let recipient = res.data.irisMerchantAuctionAddress;
        let fee = res.data.MINT_ASSET_FEE;
        let adminAddress = res.data.irisMerchantAuctionAddress;
        let offSaleTokenIds = this.parentValue.offsaleTokenIds;
        let nftIds = [];
        if (offSaleTokenIds.length > 0) {
          for (let i = 0; i < offSaleTokenIds.length; i++) {
            nftIds.push(offSaleTokenIds[i]);
          }
        }
        // create memo
        let memo = {
          type: "AUCTION_CREATE", //类型
          param: {
            startTime: startTimeStamp, //开始时间戳（秒）
            endTime: endTimeStamp, //结束时间戳（秒）
            reserve:this.NumberMul(this.startBid,1000000), //起拍价（uiris,  1 iris=1000000 uiris）
            buyout: this.NumberMul(this.fixPrice,1000000), //一口价（uiris,  1 iris=1000000 uiris）
            guaranteed: this.NumberMul(this.ReserveBid,1000000), //保底价
            auth: "uptick", //认证人
            coinDenom:this.coindenom
          },
        };
  
        //transfer

        let result = await transferNft(
          nftIds,
          denomId,
          sender,
          recipient,
          fee,
          adminAddress,

          JSON.stringify(memo)
        );
         let isDoingParams = {
          tokenIds: nftIds,
          contractAddress: denomId,
          type: 1,
        };
        let isding = await api.home.isDoing(isDoingParams);
        console.log(result.hash);
          if (result.hash) {
              let isShowTip = localStorage.getItem("IRIS_isShowTip_"+this.$store.state.did)
        if(isShowTip == 'true'){
             this.$mtip({
                title:this.$t("transactionInprogressSuccess"),
              });
        }
          
             
          }
           this.$emit("getOpen", false)
              this.isPay = false;
             // window.location.reload();
             
             if(this. owerfrom == "folderPage"){
                window.eventBus.$emit("collectionPay");
             }else{
            this.$router.push({ name: "PersonalPage" });
     
             }
   

            while (this.flag) {       
              await this.sleep(5000).then(async () => {
                 //console.log(result.hash)
                let res = await quiryTx(result.hash);
                if (res.code == 0) {
                   	let t = this.$t("onSaleSuccess")
					let reg=new RegExp('【N】','g')//g代表全部
					let regN=new RegExp('<br/>','g')//g代表全部
					let name = "【" + this.name + "】"
          let title=t.replace(reg,name).replace(regN," ");
               let isShowTip = localStorage.getItem("IRIS_isShowTip_"+this.$store.state.did)
        if(isShowTip == 'true'){
             this.$mtip({
                title:title,
              });
        }
              
                  this.flag = false;
                } else if (res.code == -1) {
                  console.log("error");
                   let isShowTip = localStorage.getItem("IRIS_isShowTip_"+this.$store.state.did+this.$store.state.did)
                   if(isShowTip == 'true'){
                      this.$mtip({
                    title:res.log,
              });
        }
             
              this.flag = false;
                } else {
                  this.flag = true;
                }
              });
            }

          //    console.log(memo)
          // this.$refs.promptBox.show(this.$t('putawaySuccess'));
          this.$toast("success", this.$t("transactionInprogressSuccess")).then(() => {
             this.isPay = true
          });
          // setTimeout(() => {
          //    this.$emit("getOpen", false)
          //     this.isPay = false;
          //    // window.location.reload();
          //   this.$router.push({ name: "PersonalPageCopy" });
          //   sessionStorage.setItem("PersonalPage_Tabs", 1);
          // }, 8000);
        }
       catch (e) {
        //this.$refs.promptBox.show(this.$t('putawayFailed'));
        this.$toast("error", this.$t("transactionInprogressError"));
         this.isPay = false
      }
    },
    //关闭弹框，发送open值
    closeDialog() {
      this.open = false;
      this.$emit("getOpen", this.open);
    },
    //查看规则
    openRules() {
        this.$gtag.event('拍卖上架-拍卖规则', { 'event_category': 'Click', 'event_label': '拍卖规则' })
      if (this.$vuetify.lang.current == "en") {
        let routeUrl = this.$router.resolve({
          path: "/auctionrule_en",
          query: { id: 96 },
        });
        window.open(routeUrl.href, "_blank");
      } else if(this.$vuetify.lang.current == "zh"){
        let routeUrl = this.$router.resolve({
          path: "/auctionrule_zh",
          query: { id: 97 },
        });

        window.open(routeUrl.href, "_blank");
      }
    else if(this.$vuetify.lang.current == "ja"){
        let routeUrl = this.$router.resolve({
          path: "/auctionrule_en",
          query: { id: 96 },
        });
        
        window.open(routeUrl.href, "_blank");
      }
      else if(this.$vuetify.lang.current == "ko"){
        let routeUrl = this.$router.resolve({
         path: "/auctionrule_en",
          query: { id: 96 },
        });
        
        window.open(routeUrl.href, "_blank");
      }else if(this.$vuetify.lang.current == "pt"){
        let routeUrl = this.$router.resolve({
         path: "/auctionrule_en",
          query: { id: 96 },
        });
        
        window.open(routeUrl.href, "_blank");
      }
    },
     onLangChange() {
       this.list= [
          {
            text: this.$t('auctionTime'),
            value: 1,
          },
          {
            text: this.$t('auctionTime48'),
            value: 2,
          },
          {
            text: this.$t('auctionTime72'),
            value: 3,
          },
        ];
     }
  },
};
</script>

<style lang="scss" scoped>
.card ::v-deep .v-messages__message {
  color: red;
}
.rule {
  width: 28px;
  height: 28px;
  display: inline-block;
  margin-top: 48px;
  background-color: #fff;
  margin-left: 15px;
  cursor: pointer;
}
.card {
  display: flex;
  flex-direction: column;
  min-height: 748px;
  width: 600px;

  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }

  .top {
    display: flex;
    flex-direction: column;

    h3 {
      margin: 41px 0 24px 52px;
     font-family:Helvetica;
      font-size: 25px;
      color: #270645;
    }

    .pupils {
      margin: 0 0 18px 51px;
     font-family:Helvetica;
      font-size: 25px;
      color: #270645;
    }
  }

  .form {
    margin: -6px 52px;
    display: flex;
    flex-direction: column;
  }

  .footer {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;

    .price {
      display: block;
      font-family:Helvetica;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      /* line-height: 40px; */
      color: #766983;
    }

    .sub {
      margin: 14px auto;
      width: 450px;
      height: 51px;
      background-image: linear-gradient(
          90deg,
          #d300fd 0%,
          #a439fe 26%,
          #7471ff 52%,
          #00fdcf 100%
        ),
        linear-gradient(#270645, #270645);
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
      font-size: 20px;
      color: #ffffff;
    }
  }
  &.mobile {
    .top {
      margin-left: -12px;
      .pupils {
        width: 90%;
        margin: 0 0 18px 52px;
        word-wrap: break-word;
      }
    }
    .form {
      margin-left: 40px;
    }

    .footer {
      margin-left: -12px;
      .sub {
        width: 80%;
      }
    }
  }
}

img {
  display: inline-block;
  width: 126px;
  height: 126px;
  background-color: #8a73ff;
  border-radius: 5px;
  margin: 1px 22px 10px 52px;
}

span {
  display: flex;
  align-items: center;
  font-family:Helvetica;
  color: #270645;
}

.title {
 font-family:Helvetica;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  color: #270645;
}
.subtitle {
  font-family:Helvetica;
  font-size: 15px;
  color: #766983;
}
.sub-dis {
    position: relative;
    pointer-events: none;
    background-image: linear-gradient(
      #766983, 
      #766983), 
     linear-gradient(
      #270645, 
      #270645) !important;
     background-blend-mode: normal, 
      normal;
     border-radius: 25px;
     opacity: 0.9;
}
.sub-dis::after {
    content: "";
    background-image: url(../../assets/loading.gif);
    background-size: 100%;
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 20px;
    margin-left: 115px;
    margin-top: 5px;
    
    // box-shadow: 0 0 10px #fff;
    // border-radius: 50%;
}
</style>
