<template>
  <div class="root-home">
    <Product v-if="isload " :sku="topSku" :address="address" :price="price"/>
    <div class="title" v-if="creators.length>0">
      <div v-if="creators.length>0" class="name">{{ $t('homeFeaturedCreatorsTitle') }}</div>
      <img @click="toCreatorList" :src="rightIcon" :alt="$t('homeFeaturedCreatorsTitle')" v-if="creators.length>0"/>
    </div>
    <FeaturedCreators v-if="creators.length>0" :creators="creators" class="mt-3"/>
       <div class="titleCenter" v-if="creators.length>0">
         
    </div>
     <TrandingCollection v-if="this.recommendList.length > 0" :recommendList="recommendList"/>
       <div v-else>
            <loading :isShowLoading="isShowLoading"></loading>
       </div>
      <div class="titleCenter" >
      <div class="name" :class="{mobile: isMobile}"  v-if="showGallery" >{{ $t('homeGalleryTitle') }}</div>
     
    </div>
    <Gallery v-if="showGallery"/>
      <div class="right_bottom" v-if="!isMobile">
	  <img  v-if="!openmsg_group" src="@/assets/image/btn_service-message.png" @click="openServiceMessage" alt="" />
	</div> 
  <div v-if="openmsg_group" style="position: fixed; right: 20px; bottom: 20px; z-index: 300;">
         <message-service @getOpen="OpenMsg_group" v-if="openmsg_group" :currfriend="currfriend_group"></message-service>
      </div> 
  </div>
  
</template>

<script>
import Product from "./Product.vue";
import FeaturedCreators from "./FeaturedCreators.vue";
 import Gallery from "./Gallery.vue";
  import TrandingCollection from "./trandingCollection.vue";
import rightIcon from "@/assets/icons/icon_r.png";
import MessageService from "@/components/popup/messageService.vue";
import Loading from "@/components/loading.vue";

import api from "@/api";

export default {
  name: "App",
  components: {
    Product,
    FeaturedCreators,
    Gallery, 
    TrandingCollection,
    MessageService,
    Loading
  },
  data: function() {
    return{
    rightIcon,
    topSku: {},
    creators: [],
    recommendList:[],
    address:'',
    price:'',
    isload:false,
     isShowLoading: false,
     showGallery:false,
      openmsg_group: false,
      currfriend_group:{},
   
    }
  },
  methods: {
     openServiceMessage(){
      console.log("openServiceMessage")
         this.currfriend_group.fansAddress = "0x91eB1DE0543a4AA41ac960E67c1ECF729AAEdf06";
        this.currfriend_group.fansName = "Uptick CS"
        this.openmsg_group = true;

    },
     OpenMsg_group(e) {
        this.openmsg_group = e;
      },
    toCreatorList() {
       this.$gtag.event('创作者推荐-查看更多', { 'event_category': 'Click', 'event_label': 'creator' })
      this.$router.push({name:'Creatorlist'})
    },
	scroll() {
		let top = document.documentElement.scrollTop || document.body.scrollTop;
		this.$store.commit('SET_HOME_SCROLLTOP', top);
		
		// console.log(223,this.$store.state.homeScrollTop);
		// sessionStorage.setItem("KEY_HOME_SCROLLTOP", top);
	}
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  async mounted() {
  
	  document.addEventListener("scroll", this.scroll);

    let params = {
      sortType:1,
      authenticationAddress:this.$store.state.did
    };
 
    // indexTopsku
     await api.home.getHomeNftListTopsku(params).then(res => {
      this.topSku = res.data;
      if( this.topSku){
            this.address = res.data.address;
      this.price = res.data.minPrice;
      this.isload = true;
      if(this.topSku.length >0)
        {
          this.isShowLoading = false;
        }
      }  
    })
   
    //indexCreators
     await api.home.getHomeNftListCreators(params).then(res => {
      this.creators = res.data.list;
      if(this.creators.length >0)
    {
      console.log("rrrrr")
      this.isShowLoading = false;
    }
    
    })

      let collectParams = {
        pageSize: 20,
          pageNumber: 1,
          sortType: 5,
      }
   this.isShowLoading = true
      let lists= await api.home.getRecommendList(collectParams)
      this.recommendList = lists.data;
      if( this.recommendList.length>=0  ){
        this.isShowLoading = false;
      }
    this.showGallery = true
  },
  
};


</script>

<style lang="scss" scoped>
.right_bottom{
   position: fixed;
  bottom: 100px;
  right: 50px;
  z-index: 99;

  img {
	display: block;
	width: 59px;
	height: 59px;
	margin-top: 35px;
  cursor: pointer;
  }
}
.title {
  margin-top: 80px;
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
 
  .name{
    width: 100%;
    	font-family: Helvetica;
	  font-size: 30px;
	  font-weight: bold;
	  font-stretch: normal;
	  letter-spacing: 0px;
	  color: #270645;

  }

  img {
    display: inline-block;
    width: 41px;
    height: 41px;
    cursor: pointer;
  }
}
.titleCenter {
  margin-top: 40px;
  min-width: 100%;
  text-align: center;
 .name{
  margin-bottom: 34px;
	font-family: Helvetica;
	font-size: 30px;
	font-weight: bold;
	font-stretch: normal;
	letter-spacing: 0px;
	color: #270645;
 }
}
.root-home {
  ::v-deep.card-box .v-card.rd {
    @media screen and (max-width: #{map-get($grid-breakpoints, 'lg')}) {
      width: 100%!important;
    }
  }
  
}
.mobile {
	margin-bottom: 0px !important;

}
</style>
