<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        src="@/assets/btn_close.png"
        alt=""
      />
      <div class="top">
        <h3 class="end">{{$t('popupSoldoutEndsale')}}</h3>
        <h3 class="content">{{nftName}}</h3>
      </div>
      <div class="footer">
        <button class="sub" :class="{'sub-dis': isPay}" submit @click="subCommit">{{$t('popupTransferCommit')}}</button>
      </div>
    </v-card>
     <v-snackbar
      v-model="isShowFalse"
      color="error"
      centered
      timeout="2000"
      style="opacity: 0.8"
      ><span style="color: white; font-size: 20px">{{
        falseTitle
      }}</span></v-snackbar
    >
    <v-snackbar
      v-model="isShowSuccess"
      color="success"
      centered
      timeout="2000"
      style="opacity: 0.8"
      ><span style="color: white; font-size: 20px">{{
        successTitle
      }}</span></v-snackbar
    >
    <uComponents  ref="ucom"></uComponents>
  </div>
</template>
<script>
import api from "@/api";
export default {
  data: () => ({
    newOpen: true,
  nftName:"",
  isShowFalse: false,
    isPay: false,
    isShowSuccess: false,
    falseTitle: "this is a error",
    successTitle: "create success",
    multiNFTids:[]
  }),
     props: {
    address: {
      type: String,
    },
	name: {
	  type: String,
	},
    onSaleTokenIds:{
      type: Array,
    },
    offSaleTokenIds:{
        type:Array ,
    },
    endType:{
       type: String,
    },
    parentValue:{
      type:Object
    },
     offsaleType:{
       type: String,
    },
    sourcemarket:{
      type: String,
    },
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  
  mounted() {
    console.log("wxl --- parentValue",this.parentValue,this.onSaleTokenIds,this.sourcemarket)
    debugger
    this.nftName = this.name;
     if(this.onSaleTokenIds &&this.onSaleTokenIds.length >0){
       for (let index = 0; index < this.onSaleTokenIds.length; index++) {
        this.multiNFTids.push(this.onSaleTokenIds[index]);
     
   }
     }
      
  },
  methods: {
    //提交转送数据
    async subCommit() {
     // this.$emit("getOpen", false);
     try {
        this.isPay = true
      let denomId = this.parentValue.contractAddress;
       let sender = this.$store.state.did;


      let onSaleTokenIds = this.parentValue.onsaleTokenIds;


     let param = {
        callBack:
          `${process.env.VUE_APP_API_URL}` +
          "/api/3.0/callback/offsale?&contractAddress=" +
          denomId +
          "&owner=" + sender,
        denom: denomId,
        tokenIds: this.offsaleType == 'multi'?  this.multiNFTids :onSaleTokenIds,
        owner: sender,
      };

      let res = await api.home.Saleout(param);
       let isDoingParams = {
          tokenIds: onSaleTokenIds,
          contractAddress: denomId,
          type: 2,
        };
        let isding = await api.home.isDoing(isDoingParams);
        debugger
        console.log("wxl -- 777",isding);
      if(res.data.success )
      {
          //  this.$emit("getOpen", false);
          console.log("successs")
          //this.$toast();
          this.$toast("success", this.$t("transactionInprogressSuccess")).then(() => {
              this.isPay = true
                 this.$emit("getOpen", false)
              this.isPay = false;
             // window.location.reload();
         
             window.eventBus.$emit("collectionPay");
          })
         
         

      }else
      {
        console.log("False")
        this.$toast("error", this.$t("transactionInprogressError"));
        this.isPay = false
      }
       
     } catch (error) {
       console.log('error,error',error);
     }
    
    },
    //关闭弹框，发送open值
    closeDialog() {
      this.open = false;
      this.$emit("getOpen", this.open);
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  min-height: 239px;
  width: 600px;

  .top {
    display: flex;
    flex-direction: column;
    margin: 30px auto 0;

    .end {
      margin: 0 auto 13px;
      width: 100%;
      height: 21px;
      text-align: center;
     font-family:Helvetica;
      font-size: 23px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      white-space: nowrap;
    }

    .content {
      margin: 19px auto 25px;
      width: 226px;
      min-height: 22px;
      font-family:Helvetica;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      text-align: center;
    }
  }

  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }

  .footer {
    margin:0 auto;

    .sub {
      margin: 30px auto;
      width: 450px;
      height: 51px;
      background-image: linear-gradient(
          90deg,
          #d300fd 0%,
          #a439fe 26%,
          #7471ff 52%,
          #00fdcf 100%
        ),
        linear-gradient(#270645, #270645);
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
     font-family:Helvetica;
      font-size: 20px;
      color: #ffffff;
    }
  }
  &.mobile {
    .footer {
       width:100%;margin:0;
      .sub {
         margin-left:10%;
        width: 80%;
      }
    }
  }
}

img {
  display: inline-block;
  width: 126px;
  height: 126px;
  background-color: #8a73ff;
  border-radius: 5px;
  margin: 1px 22px 10px 52px;
}
.sub-dis {
    position: relative;
    pointer-events: none;
    background-image: linear-gradient(
      #766983, 
      #766983), 
     linear-gradient(
      #270645, 
      #270645) !important;
     background-blend-mode: normal, 
      normal;
     border-radius: 25px;
     opacity: 0.9;
}
.sub-dis::after {
    content: "";
    background-image: url(../../assets/loading.gif);
    background-size: 100%;
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-top: 5px;
    
    // box-shadow: 0 0 10px #fff;
    // border-radius: 50%;
}
</style>

