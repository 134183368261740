var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card-title',{staticClass:"text--title px-5 py-4"},[_vm._v(_vm._s(_vm.value.name))]),(_vm.value.disabled == 3)?_c('img',{staticClass:"mt-3",staticStyle:{"position":"absolute"},attrs:{"src":require("@/assets/icons/ban.png"),"width":"25px","height":"25px","alt":""}}):_vm._e(),_c('div',{staticClass:"status"},[(_vm.value.disabled != 3 && _vm.value.saleStatus == '1'&&this.owerfrom =='cardPage')?_c('img',{attrs:{"src":require("@/assets/icons/icon_onsale.png"),"alt":"Onsale"}}):_vm._e()]),(_vm.value.useType == 1)?_c('v-card-text',{staticClass:"d-flex flex-row justify-space-between py-0 px-5"},[(_vm.value.auctionStatus == '0')?[_c('div',[_c('div',{staticClass:"text--sub-title text--secondary-rd"},[_vm._v(" "+_vm._s(_vm.$t("popupAuctionStartingBid"))+" ")]),_c('div',{staticClass:"d-flex flex-row mt-2",staticStyle:{"margin-bottom":"10px"}},[_c('div',[_c('v-img',{attrs:{"src":require('@/assets/icons/' +
               _vm.getChainByName(_vm.value.coinDenom)+
               '.png'),"width":"22px","height":"22px"}})],1),_c('div',{staticClass:"ml-2 text--number text--secondary-rd font-weight-medium",staticStyle:{"line-height":"22px"}},[_vm._v(" "+_vm._s(parseFloat(_vm.value.lastBidPrice))+" ")])])]),_c('div',[_c('div',{staticClass:"text--sub-title text--secondary-rd"},[_vm._v(" "+_vm._s(_vm.$t("workCardStartingin"))+" ")]),_c('div',{staticClass:"mt-2"},[_c('div',{staticClass:"text--number text--secondary-rd font-weight-medium"},[_vm._v(" "+_vm._s(_vm.hour)+"h "+_vm._s(_vm.minutes)+"m "+_vm._s(_vm.second)+"s ")])])])]:(_vm.value.auctionStatus == '1' )?[_c('div',[_c('div',{staticClass:"text--sub-title text--primary-rd"},[_vm._v(" "+_vm._s(_vm.$t("workCardCurrentBid"))+" ")]),_c('div',{staticClass:"d-flex flex-row mt-2",staticStyle:{"margin-bottom":"10px"}},[_c('div',[_c('v-img',{attrs:{"src":require('@/assets/icons/' +
               _vm.getChainByName(_vm.value.coinDenom)+
               '.png'),"width":"22px","height":"22px"}})],1),_c('div',{staticClass:"ml-2 text--number text--primary-rd font-weight-medium",staticStyle:{"line-height":"22px"}},[_vm._v(" "+_vm._s(parseFloat(_vm.value.lastBidPrice))+" ")])])]),_c('div',[_c('div',{staticClass:"text--sub-title text--primary-rd"},[_vm._v(" "+_vm._s(_vm.$t("workCardEndingin"))+" ")]),_c('div',{staticClass:"mt-2"},[_c('div',{staticClass:"text--number text--primary-rd font-weight-medium"},[_vm._v(" "+_vm._s(_vm.hour)+"h "+_vm._s(_vm.minutes)+"m "+_vm._s(_vm.second)+"s ")])])])]:(_vm.value.auctionStatus == '4' )?[_c('div',{staticClass:"d-flex justify-space-between status-7"},[_c('div',[_c('div',{staticClass:"text--sub-title text--primary-rd"},[_vm._v(" "+_vm._s(_vm.priceTitle)+" ")]),_c('div',{staticClass:"d-flex flex-row mt-2"},[_c('div',[_c('v-img',{attrs:{"src":require('@/assets/icons/' +
               _vm.getChainByName(_vm.value.coinDenom)+
               '.png'),"width":"22px","height":"22px"}})],1),_c('div',{staticClass:"ml-2 text--number text--primary-rd font-weight-medium",staticStyle:{"line-height":"22px"}},[_vm._v(" "+_vm._s(parseFloat(_vm.value.minPrice))+" ")])])])]),_c('div',{staticClass:"sold_out"},[_vm._v(_vm._s(_vm.$t("token_state_end")))])]:(_vm.value.auctionStatus == '6' )?[_c('div',{staticClass:"d-flex justify-space-between status-7"},[_c('div',[_c('div',{staticClass:"text--sub-title text--primary-rd"},[_vm._v(" "+_vm._s(_vm.$t("finalBid"))+" ")]),_c('div',{staticClass:"d-flex flex-row mt-2"},[_c('div',[_c('v-img',{attrs:{"src":require('@/assets/icons/' +
               _vm.getChainByName(_vm.value.coinDenom)+
               '.png'),"width":"22px","height":"22px"}})],1),_c('div',{staticClass:"ml-2 text--number text--primary-rd font-weight-medium",staticStyle:{"line-height":"22px"}},[_vm._v(" "+_vm._s(parseFloat(_vm.value.minPrice))+" ")])])])]),_c('div',{staticClass:"sold_out"},[_vm._v(_vm._s(_vm.$t("SoldOut")))])]:[_c('div',{staticClass:"d-flex justify-space-between status-7"},[_c('div',[_c('div',{staticClass:"text--sub-title text--primary-rd"},[_vm._v(" "+_vm._s(_vm.$t("workCardCurrentBid"))+" ")]),_c('div',{staticClass:"d-flex flex-row mt-2"},[_c('div',[_c('v-img',{attrs:{"src":require('@/assets/icons/' +
               _vm.getChainByName(_vm.value.coinDenom)+
               '.png'),"width":"22px","height":"22px"}})],1),_c('div',{staticClass:"ml-2 text--number text--primary-rd font-weight-medium",staticStyle:{"line-height":"22px"}},[_vm._v(" "+_vm._s(parseFloat(_vm.value.minPrice))+" ")])])])]),_c('div',{staticClass:"sold_out"},[_vm._v(_vm._s(_vm.$t("workCardSoldOut")))])]],2):_c('v-card-text',{staticClass:"py-0 px-5",staticStyle:{"height":"40px","margin-top":"13px"}},[[(_vm.value.onsale&&_vm.value.onsale==true  )?[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',[_c('div',{staticClass:"d-flex flex-row",staticStyle:{"margin-top":"5px"}},[_c('div',[_c('div',[_c('v-img',{attrs:{"src":require('@/assets/icons/' +
               _vm.getChainByName(_vm.value.coinDenom)+
               '.png'),"width":"22px","height":"22px"}})],1)]),_c('div',{staticClass:"ml-2 text--number text--primary-rd font-weight-medium",staticStyle:{"line-height":"22px"}},[_vm._v(" "+_vm._s(parseFloat(_vm.value.minPrice))+" ")])])])])]:[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',[_c('div',{staticClass:"d-flex flex-row",staticStyle:{"margin-top":"5px"}},[_c('div',[_c('v-img',{attrs:{"src":require('@/assets/icons/' +
               _vm.getChainByName(_vm.value.coinDenom)+
               '.png'),"width":"22px","height":"22px"}})],1),_c('div',{staticClass:"ml-2 text--number text--primary-rd font-weight-medium",staticStyle:{"line-height":"22px"}},[_vm._v(" "+_vm._s(parseFloat(_vm.value.minPrice))+" ")])])])]),(_vm.owerfrom != 'cardPage')?_c('div',{staticClass:"sold_out"},[_vm._v(_vm._s(_vm.$t("workCardSoldOut")))]):_vm._e()]]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }