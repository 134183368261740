<template>
  <div >
    <v-card-title class="d-block text--title pt-4 px-5 pb-2 text-truncate"
      >{{value.name}}</v-card-title
    >
    <v-card-text class="d-flex flex-row align-center pb-0 px-5 text-avatar">
      <Avatar  size="40" :did="value.creatorOwner" :hash="value.creatorPhoto || value.issuerImg" 
	  imgType="middlev"
	  :grade="value.grade"
	  showAvatarInfo/>
      <div class="ml-4 text-truncate" style="max-width: 100%;">{{value.creatorName ?value.creatorName:value.creatorOwner.substr(0,6)+"..."+value.creatorOwner.substr(-6)}}</div>
    </v-card-text>
    <v-card-text class="d-flex flex-row px-5 pt-7 pb-5 text-number">
        <div >    
         <v-img v-if="value.onsalePrice" :src="
                require('@/assets/icons/' +
                  getChainByName(value.coinDenom)+
                  '.png')
          " width="22px" height="22px" />
        </div>
         <div  class="ml-2 text--number text--primary-rd font-weight-medium" v-if="value.onsalePrice" style="line-height:22px;">
            {{value.onsalePrice }}
         </div>
         <div v-else style="height:22px"></div>
    
    </v-card-text>
  </div>
</template>

<script lang='js'>
import Avatar from '@/components/avatar/index.vue';
 import countLogo from "@/assets/icons/chain_iris_w.png";

export default {
  name: 'SellCardContent',
  components: { Avatar },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    isMinPrice:{
      type:String
    }
  },
  data: function() {
    return {
      countLogo
    };
  },
  mounted() {
    //console.log('获取到的price类型' + this.isMinPrice)
  }
};

</script>

<style lang='scss' scoped>
.text--primary-rd {
  color: #270645;
}
.text--secondary-rd {
  color: #766983;
}
.text--title {
  font-size: 20px;
  line-height: 24px;
}
.text--sub-title {
  font-size: 12px;
  line-height: 1;
}
.text--number {
  font-size: 15px;
  line-height: 1;
  font-weight: bold !important;
}
.v-divider {
  border-color: #e3e3e3;
}
</style>
