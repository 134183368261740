import api from "@/api";


export const getFileSrc = async (storageKey, hash, isVideo) => {
    try{
    	let src;
    	if (isVideo) {
			if(hash)
			{
				src = await api.video.getNftVideo(
					hash
				);
	
			
				return src;
			}
    	   
    	} else {
			if(hash )
			{
				if(hash?.includes('http')){
					return hash
				}else{
					src = await api.image.getNftImg(
						hash
					);
				
					return src;
				}
				
			}
    	  
    	}
    
    }catch(e){
    	return "";
    }
};