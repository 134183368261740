<template>
  <v-list nav dense :light="false">
    <v-list-item-group
      mandatory
      :value="value"
      active-class="border"
      color="#fff"
    >
      <v-list-item
        v-for="item in list"
        :value="item.value"
        :key="item.value"
        color="#fff"
        @click="itemclick(item.value)"
      >
        <img :src="item.icon" width="25" height="25" class="mr-3" alt="" />
        <v-list-item-content>
          <v-list-item-title v-if="$scopedSlots.default">
            <slot :value="item.value" :label="item.label"> </slot>
          </v-list-item-title>
          <v-list-item-title v-else v-text="item.label"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: "List",
  inheritAttrs: false,
  props: {
    list: {
      type: Array,
      defualt: () => [],
    },
    value: [String, Number],
  },
  methods: {
    itemclick(v) {
      console.log("sssss", v);
      if (v == "Uptick Chain") {
        this.$gtag.event("导航市场切换-Uptick Chain", {
          event_category: "Click",
          event_label: "Uptick Chain",
        });
        window.open("https://uptick.upticknft.com", "_blank");
      } else if (v == "Loopring") {
        this.$gtag.event("导航市场切换-Loopring", {
          event_category: "Click",
          event_label: "Loopring",
        });
        window.open("https://loopring.upticknft.com", "_blank");
      } else if (v == "Polygon") {
        this.$gtag.event("导航市场切换-Polygon", {
          event_category: "Click",
          event_label: "Polygon",
        });
        window.open("https://polygon.upticknft.com", "_blank");
      } else if (v == "BSC") {
        this.$gtag.event("导航市场切换-BSC", {
          event_category: "Click",
          event_label: "BSC",
        });
        window.open(" https://bsc.upticknft.com", "_blank");
      } else if (v == "Arbitrum") {
        this.$gtag.event("导航市场切换-Arbitrum", {
          event_category: "Click",
          event_label: "Arbitrum",
        });
        window.open("https://arbitrum.upticknft.com/", "_blank");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.border {
  // background: #7800f4;
}
</style>
