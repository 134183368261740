<template>
   <div class="contain">
      <v-card id="card" :class="{ card: true, mobile: isMobile }">
         <img
             class="clo"
             width="31px"
             height="31px"
             @click="closeDialog"
             src="@/assets/btn_close.png"
             alt=""
         />
         <vue-qr :text="addressUrl" :size="260" class="code"></vue-qr>
         <v-dialog v-model="show">
            <vue-qr :text="addressUrl" :size="260" class="codecopy"></vue-qr>
         </v-dialog>

         <div class="reminder">
          
            <div class="QR" v-html="$t('popupPayScanQR')"></div>
         </div>
         <div class="downloadApp">{{$t('popupPayDownload')}}</div>
         <div class="download d-flex flex-lg-row flex-column algin-center justify-center">
            <!-- <img class="downicon" :src="  $vuetify.theme.dark ? darkdownIcon : downIcon" alt=""/>
            <a class="downloadText" :class="{darkFont:$vuetify.theme.dark }"  @click="toDownLoad">{{$t('popupPayDownload')}}</a> -->
            <div class="box mr-lg-4 mr-0 d-flex flex-row" @click="toDownLoad" >
               <img class="upticklogo mr-3" src="@/assets/icons/uptick_download.png" alt=""/>
               <div >
                  <div class="titlename">Uptick</div>
                  <div class="sourceTitle">NFT Marketplace</div>
               </div>
            </div>
            <div class="box d-flex flex-row" @click="toOpenUpward">
               <img class="upticklogo mr-3" src="@/assets/icons/upward_download.png" alt=""/>
               <div>
                  <div class="titlename">Upward</div>
                  <div  class="sourceTitle"> Wallet</div>
               </div>
               </div>
            </div>
          
      </v-card>
   </div>
</template>
<script>
  import vueQr from "vue-qr";

  export default {
    data: () => ({
      open: true,
      show: false
    }),
    components: {
      vueQr
    },
    props: {
      address: {
        type: String
      },
      price:{
        type:String
      },
	  auctionNo: {
        type:String
      },
       Nft_sku: {
      type: Object,
    },
    },
    created() {
    
    },
    computed: {
      addressUrl() {
        let address = this.address;
        let chainId = process.env.VUE_APP_IRIS_CHAIN_ID
        console.log("this.Nft_sku",this.Nft_sku);
        if(this.Nft_sku.collectionType == 1){
       
            return `{"source":"web","denomType":"2","target":"buy","sumPrice":"1","contractAddress":"${this.Nft_sku.contractAddress == null?'':this.Nft_sku.contractAddress}","tokenId":"${this.Nft_sku.tokenId == null?'':this.Nft_sku.tokenId}","collectionType":"${this.Nft_sku.collectionType}","coinDenom":"${this.Nft_sku.coinDenom == null?'':this.Nft_sku.coinDenom}","id":"${this.Nft_sku.id}","chainId":"${chainId}","nftOwner":"${this.Nft_sku.nftOwner}"}`;
        }
        // let sumPrice = this.list[this.current]?.minPrice ?? '';
       // return `{"source":"web", "sumPrice":"1",chainType":"IRIS_IRISHUB","denomType":"2","target":"buy",contractAddress":"${address}"}`;
        return `{"source":"web","denomType":"2","target":"buy","sumPrice":"1","contractAddress":"${this.Nft_sku.contractAddress}","chainId":"${chainId}","nftOwner":"${this.Nft_sku.nftOwner}"}`;
      },
      isMobile: function() {
        return this.$vuetify.breakpoint.mobile;
      }
    },
    methods: {
      gethost(){
      let url = window.location.href 
 
     var urlstr = url.split("/"); 
    var urls = '';
    if (urlstr[2]) {
        urls = urlstr[0]+'//'+urlstr[2];
    }
      return urls
    },
       toDownLoad(){
         let host = this.gethost()
           window.open(host+'/downloads','_blank')
       },
       toOpenUpward(){
         window.open('https://upward.uptick.network','_blank')
      

       },
      //提交转送数据
      subCommit() {
      },
      //关闭弹框，发送open值
      closeDialog() {
        this.open = false;
        this.$emit("getOpen", this.open);
      },
      //打开app
      showBtn() {
        let that = this;
       
        let address = that.address;
        let price = that.price;
        let auctionNo=that.auctionNo
		let MainLink='denomId='+address;
		if(auctionNo){
			MainLink+='&auctionNo='+auctionNo+"&useType=1"
      }else if(this.Nft_sku.collectionType &&this.Nft_sku.collectionType == 1){
         MainLink+='tokenId='+this.Nft_sku.tokenId+'contractId='+this.Nft_sku.id
      }
    
        const u = navigator.userAgent;
        const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        if (isiOS) {
          return `uptickticket://${MainLink}&denomType=2&level=1&price=${price}&chainType=IRIS_IRISHUB`;
        } else {
          return `uptickticket://?${MainLink}&denomType=2&level=1&price=${price}&chainType=IRIS_IRISHUB`;
        }
      }
    }
  };
</script>
<style lang="scss" scoped>
   // .contain {
   .card {
      display: flex;
      flex-direction: column;
      // max-height: 490px;
      // width: 600px;
      .clo {
         display: block;
         position: absolute;
         right: 10px;
         top: 10px;
         background-color: #fff;
         border-radius: 50%;
         cursor: pointer;
      }

      .code {
         margin: 56px 200px 0 200px;
         width: 200px;
         height: 200px;
      }

      .reminder {
         margin-top: 15px;
         width: 100%;

         .QR {
            margin-left: 10px;
            text-align: center;
            width: 100%;
            height: 17px;
           font-family:Helvetica;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;
         }

         .codeicon {
            margin-left: 147px;
            margin-right: 0;
            vertical-align: middle;
            margin-bottom: 8px;
            display: inline-block;
            width: 20px;
            height: 20px;
            background-color: white;
         }
      }

     

      .downlink {
         display: none;
      }
      .downloadApp{
         width: 100%;
         font-family: Helvetica;
	font-size: 12px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 20px;
	letter-spacing: 0px;
	color: #270645;
   text-align: center;
   margin-top: 50px;
      }

      .download {
         margin-top: 15px;
         margin-bottom: 21px;
         width: 100%;

        .box{
         width: 201px;
         height: 81px;
         background-color: #f3e5f9;
         border-radius: 5px;
         padding:15px 0 15px 20px;
         cursor: pointer;
         .upticklogo {
    
         width: 50px;
         height: 50px;
         
      }
      .titlename{
         font-family: Helvetica;
         font-size: 12px;
         font-weight: bold;
         font-stretch: normal;
         line-height: 30px;
         letter-spacing: 0px;
         color: #270645;
      }
      .sourceTitle{
         font-family: Helvetica;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 13px;
	letter-spacing: 0px;
	color: #766983;
      }
        }
      }

      &.mobile {
         overflow-x: hidden;
         width: 300px !important;
         height: auto;
         padding: 0 10px;

         .code {
            display: none !important;
            margin: 30px auto;
         }

         .codecopy {
            width: 100px;
            height: 100px;
            margin: 60px auto;
         }

         .reminder {
            display: none;
         }

         .upticklogo {
        
         }

         .downlink {
            display: block;
           font-family:Helvetica;
            text-decoration: underline;
            font-size: 20px;
            font-weight: normal;
            text-decoration: underline;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;
            margin: 0 auto 35px;
         }

         .download {
            margin: 0 auto 20px;
            align-items: center;
            .box{
               margin-top: 20px;
            }

            .downicon {
               margin: 0;
            }

            .downloadText {
               margin-bottom: 45px;
            }

            .buttomtext {
               display: none;
            }
         }
      }
   }

   // }
</style>




