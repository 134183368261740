<template>
   <div>
      <v-menu v-model="show" :attach="$el" right offset-y nudge-bottom="10px">
         <template v-slot:activator="{ on, attrs, value }">
            <Chip v-bind="attrs" v-on="on" pill class="pl-0">
               <!-- [todo: size 40] -->
               <v-avatar :size="40" style="overflow:visible; position: relative;margin-left: 4px;">
               <v-img :src="usersrc"/>
               <img
			      v-if="grade==3"
			     src="@/assets/icons/icon_v.png"
			     alt="V"
			   	class="middlev"
			   />
               </v-avatar>
               <span class="ml-4 user" :class="{star:newMsgCount>0}">{{ userName }}</span>
               <v-icon class="ml-3" color="primary" v-if="value"
               >mdi-menu-up
               </v-icon>
               <v-icon class="ml-3" color="primary" v-else>mdi-menu-down</v-icon>
            </Chip>
         </template>
         <UserCenterNav @change="change"/>
      </v-menu>
      <v-dialog v-model="openAppeal">
         <accountAppeal @getOpen="Open"></accountAppeal>
      </v-dialog>

      <v-dialog v-model="disAppeal">
         <v-card>
            <img
                class="card-close"
                width="31px"
                height="31px"
                @click="disAppeal = false"
                src="@/assets/btn_close.png"
                alt=""
            />
            <div style="width: 500px; height: 300px;">
               <!-- <img alt="logo" src="/src/assets/image/raredroplogo.ico" height="43px" width="auto" class="logo"> -->
               <div class="text">
                  <h1>用户不可以申诉</h1>
               </div>
            </div>
         </v-card>
      </v-dialog>
   </div>
</template>

<script lang="js">
  import Chip from "@/components/Chip.vue";
  import UserCenterNav from "./UserCenterNav.vue";
  import AccountAppeal from "@/components/popup/accountAppeal.vue";
  import api from "@/api";
  import { getFileSrc } from "@/utils/file";
  import { getSettingArr } from "@/utils/helper";

  const AVATAR_KEY = "AVATAR";
  import defaultImg from "@/assets/default.png";


  export default {
    name: "UserCenterSelect",
    components: {
      Chip,
      UserCenterNav,
      AccountAppeal
    },
    props: {},
    data: function() {
      return {
        openAppeal: false,
        disAppeal: false,
        show: false,
        userName: "",
        usersrc: "",
        grade:"",
        defaultImg,
        newMsgCount: 0,
        isRefsh: false
      };
    },
    methods: {
      async loadUser() {
          if (this.$store.state.did) {
            let param = { did: this.$store.state.did };
            let res = await api.home.getMyInfo(param);
            this.grade = res.data.grade
            if (res.data != null) {
              console.log("xxl mounted getMyInfo 1....");
              if (res.data && res.data.profilePhoto) {
                this.userName = res.data.userName;
                this.imgUrl = res.data.profilePhoto;
                this.usersrc = await getFileSrc(AVATAR_KEY, this.imgUrl);
              } else {
                this.usersrc = defaultImg;
                this.userName = `${this.$store.state.did.substr(0, 3)}...${this.$store.state.did.substr(-3, 3)}`;
              }
          
            } else {
              this.usersrc = defaultImg;
              this.userName = `${this.$store.state.did.substr(0, 3)}...${this.$store.state.did.substr(-3, 3)}`;
              console.log("xxl come to user Center ...");
              this.$router.push({
                name: "MyInfo"
              });
            
            
            
            }
          
          
          }
      },
      async change(v) {
          if(v.toLowerCase() == "appeal") {
              this.openAppeal = true;
          }else if(v.toLowerCase() == "appealed") {
              this.disAppeal = true;
          }
        
        this.show = false;
      },
      Open(e) {
        this.openAppeal = e;
      }
    },
    async mounted() {
      await this.loadUser();
      
      
      // let res2 = await api.home.isNewMessage({});
      // this.newMsgCount = res2.data;
      window.eventBus.$on("ViewMessage", async () => {
          let res3 = await api.home.isNewMessage({pushTypes:getSettingArr()});
          this.newMsgCount = res3.data;
      });
      window.eventBus.$on("RefreshAvatar", async () => {
          await this.loadUser();
      });
    },
  };
</script>

<style lang="scss" scoped>
   .user {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 50px;
   }

   .card-close {
      display: block;
      position: absolute;
      right: 10px;
      top: 10px;
      background-color: #fff;
      border-radius: 50%;
      cursor: pointer;
   }
   .middlev{
	   width:15px;
	  height:15px;
	    position: absolute;
	  right: -4px;
	  bottom: 1px;
	}

   .logo {
      margin: 20px;
   }

   .text {
      display: flex;
      height: 300px;
      justify-content: center;
      align-items: center;
      letter-spacing: 0.4rem;
   }

   .star::before {
      content: ".";
      font-size: 60px;
      color: red;
      position: absolute;
      left: 30px;
      top: -19px;
      font-family: Comic Sans MS;
   }
</style>
