<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        :src="$vuetify.theme.dark?darkcloseIcon:closeIcon"
        alt=""
      />
    <div class="content">
        <div class="cross_title" :class="{darkFont:$vuetify.theme.dark }">{{$t('cross_transfer')}}</div>
        <div class="cross_des">{{$t('cross_des')}}</div>
        <div class="title-12 mt-3">{{$t('receiving_Address')}}</div>
        <div class="title-12">{{ this.uptickAddress }}</div>
        <div class="title-12">{{ this.BscAddress }}</div>
       
        <div class="cross d-flex flex-row mt-7" >
          <div class="left" v-if="!isMobile">
            <img class="img" :src="productPic" alt="">
            <div class="nftName mt-2" :class="{darkFont:$vuetify.theme.dark }">{{this.parentValue.name}}</div>
            <!-- <div class="des mt-5">{{$t('cross_des')}}</div> -->
            <div class="Quantity d-flex flex-row ">
                <div class="From d-flex flex-row ">
                    <div class="numbertitle mb-1">{{$t('Quantity')}}</div>
                    <div class="numbertitle ml-1" :class="{darkFont:$vuetify.theme.dark }">1</div>
                </div>   
            </div>
          </div>
          <div class="right ml-lg-14 ml-0">
            <div class="top d-flex flex-row align-center">
              <div class="from d-flex flex-column align-center" >
                <div class="fromtitle" :class="{darkFont:$vuetify.theme.dark }">{{$t('saledetailHistoryForm')}}</div>
                <img   src="@/assets/icons/chain_iris.png" class="mt-5" width="50" height="50" alt="">
                <div class="chainName mt-5" :class="{darkFont:$vuetify.theme.dark }">IRISnet</div>
              </div>
             
              <img src="@/assets/icons/icon_jtcross.png" class="ml-6 mr-6"  width="22" height="15" alt="">
              <div class="from d-flex flex-column align-center">
                <div class="fromtitle" :class="{darkFont:$vuetify.theme.dark }">{{$t('saledetailHistoryTo')}}</div>
                <img  :src="$vuetify.theme.dark?upticDarkLogo:uptickLogo" class="mt-5" width="50" height="50" alt="">
                <div class="chainName mt-5" :class="{darkFont:$vuetify.theme.dark }">Uptick Chain</div>
              </div>
            </div>
            
          <div class="footer">
      
         <button class="subdefect v-btn uc" :class="{ 'load-dis': isLoad }" v-if="isLoad">
          {{ $t("loadBalance") }}
        </button>
        <button class="sub" :class="{ 'sub-dis': isPay }" v-else-if="isBalance" @click="subCommit">
          {{ $t("popupTransferCommit") }}
        </button>
         <button class="subdefect" v-else-if="!isBalance" disabled="disabled">
          {{ $t("popupAuctionDefectPriceInsufficientFunds") }}
        </button>
        <span class="price mt-2"
          >{{ $t("popupNewTransferBalance") }}：{{ value3 }} IRIS
         </span
        >
      </div>

       </div>
        </div>
    </div>
     
    </v-card>
    <uComponents  ref="ucom"></uComponents>
  </div>
</template>
<script>
import api from '@/api'
import {getMyBalance,iris2Uptick,wasm2Uptick } from "../../keplr/iris/wallet";//
	import {
		getAddress
	} from "../../keplr/wallet";
export default {
  data: () => ({
    open: true,
    uptickLogo:require('@/assets/icons/chain_uptick.png'),
     upticDarkLogo:require('@/assets/icons/chain_dark_uptick.png'),
    isPay: false,
    multiNFTids:[],
    closeIcon:require('@/assets/btn_close.png'),
    currentToken:'BSC',
     isLoad: false,
     isBalance:false,
    fee:0,
     value3:'',
      chain:'UPTICK',
  chainAddress:'0x0000000000000000000000000000000000000000',
  productPic:"",
  contractAddress:'',
  uptickAddress:'',
  BscAddress:''
  }),
  props: {
    parentValue: {
      type: Object,
    },
  
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  async mounted() {

   
     let accountInfo = await getAddress(0)
     this.uptickAddress = accountInfo.address
    console.log("uptickAddress",this.uptickAddress,this.parentValue,accountInfo);

    // 获取BSC Address
     let param = { did: this.$store.state.did };
        let myInfo = await api.home.getMyInfo(param);
         this.BscAddress = myInfo.data.bscAddress
    if(this.parentValue.imgUrl){
      let imgUrl = this.parentValue.imgUrl.indexOf('https')
      if(imgUrl == -1){
        this.productPic = await api.image.getNftImg(this.parentValue.imgUrl);
      }else{
      this.productPic = this.parentValue.imgUrl
      }
        
    }
    // 获取余额
    
          let balance = await getMyBalance()
          console.log('balance',balance);
          this.value3 = balance.format
          if(this.value3 >0) {
          this.isBalance = true
        }else{
            this.isBalance = false
        }

  

      
  },
  methods: {
   
 
    async subCommit() {
      try {
          this.isPay = true
        // let result = await wasm2Uptick('ibc/D07CC37A9B289ED1C1FF1C9E20F797655095DA7A459760D2A0978B9F34479FC4','uptick1097544097365790120203114556750215185901564211')
       let result =  await iris2Uptick(this.parentValue.contractAddress,this.parentValue.tokenId)
       let isDoingParams = {
        tokenIds:this.parentValue.tokenId ,
          contractAddress: this.parentValue.contractAddress,
          type: 31
       
        };
        let isding = await api.home.isDoing(isDoingParams);
       console.log("result",result);  
       this.$toast("success", this.$t("transactionInprogressSuccess")).then(() => {
              this.isPay = true
                 this.$emit("getOpen", false)
              this.isPay = false;
             // window.location.reload();
         
             window.eventBus.$emit("collectionPay");
          })
  
    
      } catch (error) {
         this.$toast("error", this.$t("transactionInprogressError"));
             this.isPay = false
      }
       
    },
    closeDialog() {
      this.open = false;
      this.$emit("getOpen", this.open);
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  min-height: 480px;
  width: 760px;
 
  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }
  .content{
      margin:34px 52px;
      .cross_title{
          font-family: Helvetica;
	font-size: 23px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 30px;
	letter-spacing: 0px;
	color: #270645;
      }
      .cross_des{
        font-family: Helvetica;
        font-size: 13px;
        font-weight: bold;
        font-stretch: normal;
        line-height: 20px;
        letter-spacing: 0px;
        color: #270645;
      }
      .title-12{
        font-family: Helvetica;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 20px;
      letter-spacing: 0px;
      color: #766983;
      }
      .cross{
        .left{
          .img{
            width: 226px;
            height: 226px;
          
            border-radius: 10px;
            object-fit: cover;
          }
          .Quantity{
            .empty{
                width: 70px;
              }
            .From{
               width: 150px;
              .numbertitle{
                font-family: Helvetica;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                line-height: 20px;
                letter-spacing: 0px;
                color: #766983;
              }
              
              .number{
                font-family: Helvetica;
                font-size: 13px;
                font-weight: bold;
                font-stretch: normal;
                line-height: 20px;
                letter-spacing: 0px;
                color: #270645;
              }

            }
            .chain{
               width: 150px;

            }
          }
          .nftName{
            font-family: Helvetica;
            font-size: 15px;
            	width: 219px;
            font-weight: bold;
            font-stretch: normal;
            line-height: 20px;
            letter-spacing: 0px;
            color: #270645;
             overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .des{
            width: 226px;

            font-family: Helvetica;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #766983;
          }
        }
        .right{
          .top{
            .from{
              width: 150px;
              height: 156px;
    
              box-shadow: 0px 0px 9px 0px 
                rgba(0, 0, 0, 0.09);
              border-radius: 10px;
              border: solid 1px #ab8aca;
              padding-top: 10px;
              .fromtitle{
                font-family: Helvetica;
                font-size: 13px;
                font-weight: normal;
                font-stretch: normal;
                line-height: 20px;
                letter-spacing: 0px;
                color: #766983;
              }
              .chainName{
                	font-family: Helvetica;
            font-size: 13px;
            font-weight: bold;
            font-stretch: normal;
            line-height: 20px;
            letter-spacing: 0px;
            color: #000000;
              }
            }
            .to{

            }
          }
          

        }
      }
  }
  .footer {
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
   

    .price {
      display: block;
      font-family: Helvetica;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      /* line-height: 40px; */
      color: #766983;
    }

   
    .sub {
      margin: 0 auto;
      width: 360px;
      height: 45px;
        background-color: #7800f4;
      border-radius: 25px;
      opacity: 0.9;
      font-size: 18px;
      font-family: Helvetica;
      font-weight: bold;
      color: #ffffff;
    }
     .subdefect {
      margin: 0px auto;
      width: 360px;
      height: 51px;
      background-image: linear-gradient(#766983, #766983),
        linear-gradient(#270645, #270645);
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
      font-size: 20px;
      color: #ffffff;
    }
    .load-dis{
      
      }
      .load-dis::after {
  content: "";
  background-image: url(../../assets/loading.gif);
  background-size: 100%;
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: 190px;
}
  .sub-dis {
  position: relative;
  pointer-events: none;
  background-image: linear-gradient(#766983,
      #766983),
    linear-gradient(#270645,
      #270645) !important;
  background-blend-mode: normal,
    normal;
  border-radius: 25px;
  opacity: 0.9;
}

.sub-dis::after {
  content: "";
  background-image: url(../../assets/loading.gif);
  background-size: 100%;
  display: inline-block;
  position: absolute;

  width: 20px;
  height: 20px;
  margin-top: 5px;
  margin-left: 20px;
}
  }
//   .footer {
//     margin: 0 auto;
//     .sub {
//       margin: 30px auto;
//       width: 360px;
//       height: 51px;
//       background-blend-mode: normal, normal;
//       background-color: #7800f4;
//       border-radius: 25px;
//       opacity: 0.9;
//       font-weight: bold;;
//       font-size: 20px;
//       color: #ffffff;
//     }
//     .sub-dis {
//   position: relative;
//   pointer-events: none;
//   background-image: linear-gradient(#766983,
//       #766983),
//     linear-gradient(#270645,
//       #270645) !important;
//   background-blend-mode: normal,
//     normal;
//   border-radius: 25px;
//   opacity: 0.9;
// }

// .sub-dis::after {
//   content: "";
//   background-image: url(../../assets/loading.gif);
//   background-size: 100%;
//   display: inline-block;
//   position: absolute;
//   width: 20px;
//   height: 20px;
//   margin-left: 130px;
// }

//   }
  &.mobile {
    .top {
      width: 100%;
      margin: 30px 10% 0;

      .content {
        width: 80%;
        height: auto;
        word-wrap: break-word;
      }
    }
    .footer {
      width: 100%;
      margin-left: 10%;
      .sub {
        width: 80%;
      }
    }
  }
}


</style>




