import { render, staticRenderFns } from "./MyCardContentCollection.vue?vue&type=template&id=98ac1278&scoped=true"
import script from "./MyCardContentCollection.vue?vue&type=script&lang=js"
export * from "./MyCardContentCollection.vue?vue&type=script&lang=js"
import style0 from "./MyCardContentCollection.vue?vue&type=style&index=0&id=98ac1278&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98ac1278",
  null
  
)

export default component.exports