<template>
  <v-chip
    class="rd"
    color="#fff"
    text-color="primary"
    :ripple="false"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-chip>
</template>

<script lang="js">

export default {
  name: 'Chip',
  inheritAttrs: false
};
</script>

<style lang="scss" scoped>
.v-chip.rd {
  height: 40px;
  font-size: 13px;
  background-image: linear-gradient(#ffffff, #ffffff),
    linear-gradient(#270645, #270645);
  background-blend-mode: normal, normal;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
  border-radius: 20px;
  

}
</style>
