import   {$postThird } from '@/utils/request';
// import service from "@/utils/request"
import { BASE_IMAGE_URL 
} from '../config';


export function uploadImage(file, onUploadProgress) {
    
    
    const formData = new FormData();
    formData.append('image', file);

    console.log("xxl uploadFile ");
    console.log(`${BASE_IMAGE_URL}/upload`);

    //xxl post third
    return $postThird('/upImage', formData, {
        headers: {
            'Content-Type': 'application/octet-stream',
            accessKey: 'uptick',
            accessKeySecret: 'AF0F59F1EEC1728CCFCD5B7B6203D229'
        },
        onUploadProgress
    })
}

//图片显示
export function getNftImg(hash){
    // console.log("wxl --- getNftImg",hash,'https://d3i65oqeoaoxhj.cloudfront.net/' + hash + '/small')

    return 'https://d3i65oqeoaoxhj.cloudfront.net/' + hash + '/small'
};
//图片显示
export function getImgUrl2Hash(url){
   url=url.replace("https://d3i65oqeoaoxhj.cloudfront.net/","");
    url=url.replace("/small","");

    return url;
};
//图片IPFS地址 process.env.BASE_URL
export function getIPFSurl(contarctaddress,tokenId){
    //https://serviceiris.upticknft.com/metadata/uptick72c635502d44a34fff62dc693d73990d/uptickfab1a42fef8d7f60.json
    //https://serviceiris.upticknft.com/metadata/uptick0fff338ae1fd7585a04b30b48b87b6c0/uptickfab1a42fef8d7f60.json
    return process.env.VUE_APP_API_URL+'/metadata/'+ contarctaddress + '/' + tokenId+ '.json'
};


