<template>
   <v-list nav dense color="#fff" class="userList">
      <v-list-item exact v-for="item in userRuter" :to="{name: item.value}" :key="item.value" color="#fff" @click="change(item.value)">
        <v-list-item-content>
          <v-list-item-title>
             {{ item.label }}
            <template v-if="item.value === 'Message' && newMsgCount > 0">
            <span class="star"></span>
            </template>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
   
  </v-list>
  
</template>

<script lang="js">
  import api from "@/api"
  import { getSettingArr } from "@/utils/helper";

export default {
  name: 'UserCenterNav',
  props: {},
  data: function() {
    return {
        //  userRuter: [
        //        { label: this.$t('headerUserCenterNavMyHomePage'), value: 'PersonalPage'}, 
        //        { label: this.$t('headerUserCenterNavEditProfile'), value: 'MyInfo'},
        //        { label: this.$t('headerUserCenterNavMyFavorites'), value: 'Collect'},
        //        { label: this.$t('headerUserCenterNavMyOrders'), value: 'MyOrders'},
        //        { label: this.$t('avatarAvatarInfoFans'), value: 'Fans'},
        //        { label: this.$t('fansSubscrible'), value: 'Follows'},
        //        { label: this.$t('messageMessage'), value: 'Message'},
        //        // { label: this.$t('headerUserCenterNavAppealed'), value: 'appealed'},
        //        { label: this.$t('headerUserCenterNavdisConnect'), value: 'DisConnect'},
            
        //     ],
        newMsgCount: 0,
     
    };
  },
  computed:{
    userRuter:function () {
      return [
               { label: this.$t('headerUserCenterNavMyHomePage'), value: 'PersonalPage'}, 
               { label: this.$t('headerUserCenterNavEditProfile'), value: 'MyInfo'},
               { label: this.$t('scroe_task_center'), value: 'PointsCenter'},
               { label: this.$t('headerUserCenterNavMyFavorites'), value: 'Collect'},
               { label: this.$t('headerUserCenterNavMyOrders'), value: 'MyOrders'},
               { label: this.$t('headerUserCenterNavMyAsset'), value: 'MyAsset'},
               { label: this.$t('headerUserCenterNavMyLuckyDraw'),value:'Lucky'}, 
              { label: this.$t('headerUserCenterNavMyReferences'), value: 'MyReferences' },
               { label: this.$t('messageMessage'), value: 'Message'},
              { label: this.$t('headerUserCenterNavSetting'), value: 'Setting'},
               // { label: this.$t('headerUserCenterNavAppealed'), value: 'appealed'},
               { label: this.$t('headerUserCenterNavdisConnect'), value: 'DisConnect'},
            
            ]
      
    }

  },
  async mounted(){
      window.eventBus.$on('LangChange', this.onLangChange);
      
     if(this.$store.state.did)
     {
         let param = { did: this.$store.state.did };
	      let res = await api.home.getMyInfo(param);
          // console.log(2234,res.data.wordsStatus);
	      let grade = res.data.grade;
	      if(grade == 2 || grade == 4) {
              let isAppeal = await api.home.checkComplaint({
                did: this.$store.state.userAddress
              });
              console.log("isAppeal",isAppeal);
              if (!isAppeal.success) {
                //xxl hide appeal menu
                //this.userRuter.splice(7, 0, { label: this.$t('headerUserCenterNavAppeal'), value: 'appeal'});
              } else {
                 //xxl hide appeal menu
                //this.userRuter.splice(7, 0, { label: this.$t('headerUserCenterNavAppealed'), value: 'appealed'});
              }
          }
        
     }
     
     let res2 = await api.home.isNewMessage({pushTypes:getSettingArr()});
     this.newMsgCount = res2.data;
     window.eventBus.$on("ViewMessage", async () => {
         let res3 = await api.home.isNewMessage({pushTypes:getSettingArr()});
         this.newMsgCount = res3.data;
     });
  },
   methods: {
         change: function (v) {
           this.$gtag.event('菜单栏-'+ v, { 'event_category': 'Click', 'event_label': v })
         if (v == "DisConnect") {
            localStorage.setItem("key_user", "");
            localStorage.setItem("key_balance","");
                this.$store.commit('SET_DID', "");
            this.$router.push({name:'Home'})
				window.location.reload();
      }
      if(v == "MyAsset")
      {
        
        // window.history.go(-1);
      
      }
          
         },
         onLangChange() {
              this.userRuter = [
               { label: this.$t('headerUserCenterNavMyHomePage'), value: 'PersonalPage'}, 
               { label: this.$t('headerUserCenterNavEditProfile'), value: 'MyInfo'},
               { label: this.$t('headerUserCenterNavMyFavorites'), value: 'Collect'},
               { label: this.$t('headerUserCenterNavMyOrders'), value: 'MyOrders'},
                { label: this.$t('headerUserCenterNavMyReferences'), value: 'MyReferences' },
               { label: this.$t('messageMessage'), value: 'Message'},
               // { label: this.$t('headerUserCenterNavAppealed'), value: 'appealed'},
               { label: this.$t('headerUserCenterNavdisConnect'), value: 'DisConnect'},
            ]
         }
      }
};
</script>

<style lang="scss" scoped>
.v-list-item--active {
   background: #270645;
   .v-list-item__title {
   color: #fff;

   }
}
.userList {
  &::v-deep .v-list-item {
    min-height: 37px !important;
    .v-list-item__content {
      padding: 5px 0;
    }
  }
}
    .star {
        position: relative;
        &::after {
            content: ".";
            font-size: 60px;
            color: red;
            position: absolute;
            right: -20px;
            top: -24px;
            font-family: Comic Sans MS;
        }
    }
</style>
